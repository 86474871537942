import { fromJS } from 'immutable';

import { MaterializedReducer } from './base';

function sortTagsFunc(tagsArr) {
  return (left, right) => {
    const leftIdx = tagsArr.indexOf(left);
    const rightIdx = tagsArr.indexOf(right);
    if (leftIdx < rightIdx) return -1;
    if (leftIdx > rightIdx) return -1;
    return 0;
  };
}

function generateTagsArr(children, tagsArr, tagsMap, childMap, sortFunc = null) {
  if (!sortFunc) sortFunc = sortTagsFunc(tagsArr);
  children.sort(sortFunc);
  return children.map(tag => {
    if (childMap[tag.id]) {
      return {
        ...tag,
        children: generateTagsArr(
          childMap[tag.id], tagsArr, tagsMap, childMap, sortFunc,
        ),
      };
    }
    return tag;
  });
}

export class TagsMr extends MaterializedReducer {
  update(prevState, state, action) {
    if (!(
      action.type === 'HTTP_UPDATED'
      && action.group === 'fetchTags'
      && action.success
    )) {
      return state;
    }

    const items = state.getIn(['http', action.group, 'data', 'items']).toJS();
    const tags = {};
    const tagsByRole = {};
    const tagsChildren = {};
    const rootTags = [];

    let smartOrgId = null;
    let accredHolderId = null;

    items.forEach(tag => {
      tags[tag.id] = tag;
      if (tag.role) {
        tagsByRole[tag.role] = tag;
        if (tag.role === 'smart.organization.1') {
          smartOrgId = tag.id;
        }
        if (tag.role === 'smart.accreditation') {
          accredHolderId = tag.id;
        }
      }

      if (smartOrgId) {
        tagsByRole.smart = tag;
      }
    });

    Object.values(tags).forEach(tag => {
      if (!tag.parent || !tags[tag.parent.id]) {
        rootTags.push(tag);
      } else {
        tag.parent = tags[tag.parent.id];
        if (!tagsChildren[tag.parent.id]) tagsChildren[tag.parent.id] = [];
        tagsChildren[tag.parent.id].push(tags[tag.id]);
        if (tag.parent.id === smartOrgId) {
          tagsChildren[accredHolderId].push(tags[tag.id]);
        }
      }
    });

    Object.values(tags).forEach(tag => {
      tag.directChildrenCount = tagsChildren[tag.id]
        ? tagsChildren[tag.id].length : 0;
    });

    const tree = {};
    generateTagsArr(rootTags, items, tags, tagsChildren).forEach(tag => {
      tree[tag.role] = tag;
    });

    return state.set(
      'tagsMr', fromJS({
        tree,
        fetching: false,
        items,
        byIdent: { ...tags, ...tagsByRole },
      }),
    );
  }
}
