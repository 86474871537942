import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Modal, Spin, Tag } from 'antd';

import { getConstituent, setMfaOwner } from '../../../actions';

import { ReactRouterTabs } from '../../elements/ReactRouterTabs';

import { ConstituentDetailsForm } from './ConstituentDetailsForm';
import { AccreditationsList } from './Accreditations';
import { ConstituentPermissions } from './Permissions';
import { checkFeature, stripHints } from '../../../utils';
import { TotpLanding } from './TotpLanding';

@connect((state, ownProps) => ({
  organizationIsActive: state.doc.getIn(['authMr', 'constituent', 'organization', 'isActive'], false),
  emailVerified: state.doc.getIn(['authMr', 'emailVerified'], false),
  constituent: state.doc.getIn(['constituentsMr', 'byIdFull', ownProps.constituentId], null),
  getConstituentInProgress: state.doc.getIn(['http', 'getConstituent', 'inProgress']),
  getConstituentFailed: state.doc.getIn(['http', 'getConstituent', 'failed']),
  currentUserMfaStatus: state.doc.getIn(['auth', 'totpEnabled'], false),
}), {
  getConstituent, setMfaOwner,
})
class ConstituentPopup extends PureComponent {
  constructor(props) {
    super(props);
    this.footerRef = React.createRef();
  }

  state = {
    claims: [],
    enableMfa: false,
    userId: null,
  }

  setConstituentId = async id => {
    if (!id) {
      return;
    }
    // Debounce conditions?
    const response = await this.props.getConstituent(id);
    if (!response.success) return;
    if (!response.data.user || !response.data.user.emailVerified) return;
    this.setState({ userId: response.data.user.id, enableMfa: true });
    this.props.setMfaOwner(response.data.user.id);
  }

  componentDidMount() {
    this.setConstituentId(this.props.constituentId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.constituentId !== this.props.constituentId) {
      this.setConstituentId(this.props.constituentId);
    }
    if (prevProps.constituent !== this.props.constituent) {
      const { claims } = this.props.constituent.toJS();
      this.setState({ claims });
    }
  }

  setTabs = tabs => {
    if (checkFeature('mfa') || this.props.currentUserMfaStatus) return tabs;
    tabs.pop();
    return tabs;
  }

  render() {
    const {
      organizationIsActive,
      emailVerified,
      constituent,
      onSuccess,
      closeModal,
      isNew,
      constituentId,
    } = this.props;

    const name = constituent ? constituent.get('fullName') : 'Person';

    const displayName = stripHints(name);

    const title = isNew ? 'New Person' : `Editing ${displayName}`;

    const activeAccreditations = this.state.claims ? this.state.claims.filter(claim => claim.active) : 0;

    const accredWarning = this.state.claims ? this.state.claims.find(claim => !claim.nameMatch || !claim.active) : false;

    // will be sent through to all route children
    const childProps = {
      constituentId,
      constituent,
      isNew,
      footerRef: this.footerRef,
      onCancel: closeModal,
      userId: this.state.userId,
    };

    let disabledReason;
    if (!emailVerified) disabledReason = 'Please verify your email to access this feature';
    if (!organizationIsActive) disabledReason = 'Please activate your organisation to access this feature';

    const tabs = [{
      label: 'Details',
      routePath: '/details',
      enabled: true,
      Component: props => (
        <ConstituentDetailsForm onSuccess={onSuccess} {...props}/>
      ),
    }, {
      label: (<span>
        Accreditations
        &nbsp;
        <Tag style={{ marginRight: 0 }} color={ accredWarning ? 'red' : '#C88242' }>
          { activeAccreditations ? activeAccreditations.length : 0 }
        </Tag>
      </span>),
      enabled: !isNew && organizationIsActive && emailVerified,
      disabledReason,
      routePath: '/accreditations',
      Component: AccreditationsList,
    }, {
      enabled: !isNew && emailVerified,
      disabledReason,
      label: 'Permissions',
      routePath: '/permissions',
      Component: ConstituentPermissions,
    }, {
      enabled: this.state.enableMfa && emailVerified,
      disabledReason,
      label: 'MFA',
      routePath: '/mfa',
      Component: TotpLanding,
    },
    ];

    return (<Modal
      visible={true}
      title={title}
      width={ 900 }
      footer={<div ref={this.footerRef}/>}
      onCancel={ closeModal }
            >
      <Spin spinning={!isNew && !constituent}>
        <ReactRouterTabs tabs={this.setTabs(tabs)} childProps={childProps}/>
      </Spin>
    </Modal>);
  }
}

@withRouter
class ConstituentPopupRoute extends PureComponent {
  closeModal = () => {
    this.props.history.push({
      pathname: '/constituents',
      hash: this.props.location.hash,
      search: this.props.location.search,
    });
  }

  onSuccess = data => {
    if (this.props.match.params.id === 'new') {
      this.props.history.push({
        pathname: `/constituents/${data.id}/accreditations`,
        hash: this.props.location.hash,
        search: this.props.location.search,
      });
    } else {
      this.closeModal();
    }
  }

  render() {
    const {
      match: {
        params: {
          id,
        },
      },
      location: {
        pathname,
      },
    } = this.props;

    const [constituentId, isNew] = id === 'new' ? [null, true] : [Number(id), false];

    return (<ConstituentPopup
      constituentId={constituentId}
      isNew={isNew}
      _pathname={pathname} // to force render on change
      closeModal={this.closeModal}
      onSuccess={this.onSuccess}
            />);
  }
}

export {
  ConstituentPopupRoute,
};
