import { DatePicker, Form, Input } from 'antd';
import React, { PureComponent } from 'react';
import { API_DATE_FS, NON_ALPHA_NUM_SLASH_RE } from './shared';

export class QldBlueExemptFormItems extends PureComponent {
  static slug = 'qldblueex'

  static placeholder = '123456/4'

  static identLabel = 'Card number' // More id names and labels have to be added

  static getSubmissionData(checkData, constituent) {
    return {
      fullName: constituent.get('fullName'),
      expiry: checkData.expiry.format(API_DATE_FS),
    };
  }

  static regNumberGetValueFromEvent(evt) {
    return evt.target.value.replace(NON_ALPHA_NUM_SLASH_RE, '');
  }

  static disabledDate(current) {
    return current && current.valueOf() < Date.now();
  }

  render() {
    const { form, disabled, rules } = this.props;
    const { getFieldDecorator } = form;

    const {
      slug, identLabel, placeholder,
      regNumberGetValueFromEvent,
    } = this.constructor;

    const klass = this.constructor;

    const now = new Date();
    const nowDate = now.getDate().toString();
    const nowMonth = now.getMonth().toString();
    const strNowDate = nowDate.length === 1 ? `0${nowDate}` : nowDate;
    const strNowMonth = nowMonth.length === 1 ? `0${nowMonth}` : nowMonth;

    const datePlaceholder = `${now.getFullYear()}-${strNowMonth}-${strNowDate}`;

    return (<div>
      <Form.Item label={identLabel}>
        {getFieldDecorator(`${slug}.identifier`, {
          getValueFromEvent: regNumberGetValueFromEvent,
          rules: [
            {
              required: true,
              whitespace: true,
              message: `${identLabel} is required`,
            },
            ...(rules || []),
          ],
        })(
          <Input
            placeholder={placeholder}
            disabled={disabled}
          />,
        )
        }
      </Form.Item>
      <Form.Item label="Expiry">
        { /* TODO value is a moment. does this serialize correctly? */}
        {getFieldDecorator(`${slug}.expiry`, {
          rules: [
            {
              required: true,
              message: 'Expiry is required',
            },
            ...(rules || []),
          ],
        })(
          <DatePicker
            placeholder={datePlaceholder}
            disabled={disabled}
            disabledDate={klass.disabledDate}
          />,
        )}
      </Form.Item>
    </div>);
  }
}
