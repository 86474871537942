/* eslint comma-dangle: ["error", "never"] */
export async function getLibPhoneNumber() {
  return import(
    /* webpackChunkName: "libphonenumber" */ 'libphonenumber-js'
  );
}

export async function getCreditCards() {
  return import(
    /* webpackChunkName: "creditcards" */ 'creditcards'
  );
}

export async function getPaymentFont() {
  return import(
    /* webpackChunkName: "paymentfont" */ 'paymentfont/css/paymentfont.css'
  );
}

export async function getZxcvbn() {
  return import(
    /* webpackChunkName: "zxcvbn" */ 'zxcvbn'
  );
}

export async function getAdmin() {
  return (await import(
    /* webpackChunkName: "admin" */ './admin'
  )).default;
}
