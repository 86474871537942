import _ from 'lodash';

import { AuthMr, AuthReducer } from './auth';
import { createReducer } from './base';
import { ConstituentsMr } from './constituents';
import { AccreditationMr } from './accreditation';
import { DefineTypesMr } from './defineTypes';
import { HttpReducer } from './http';
import { ImportReducer } from './import';
import { ImportNdwsReducer } from './importNdws';
import { ImportQldblueReducer } from './importQldblue';
import { LinkageReducer } from './linkage';
import { NavigationReducer } from './navigation';
import { TagsMr } from './tags';
import { SsoReducer } from './sso';

import { STATE } from '../defaults';
import { parseQsHash } from '../utils';

function getToken() {
  const { loginToken } = parseQsHash(document.location.hash);
  if (loginToken) return loginToken;
  return localStorage.docToken;
}

function initReducer(prevState, state, action) {
  if (!_.isNil(state)) return state;
  return STATE.mergeIn(['auth'], {
    token: getToken(),
    resetToken: parseQsHash(document.location.hash).resetToken,
    verificationToken: parseQsHash(document.location.hash).token,
  });
}

export const reducer = createReducer([
  initReducer,

  new AuthReducer(),
  new HttpReducer(),
  new NavigationReducer(),
  new ImportReducer(),
  new ImportNdwsReducer(),
  new ImportQldblueReducer(),
  new LinkageReducer(),
  new SsoReducer(),

  new AuthMr(),
  new ConstituentsMr(),
  new TagsMr(),
  new DefineTypesMr(),
  new AccreditationMr(),
]);
