import { Reducer } from './base';

export class NavigationReducer extends Reducer {
  handlePushBreadcrumbLevelAction(prevState, state, action) {
    return state.setIn(
      ['navigation', 'breadcrumbs'],
      state.getIn(['navigation', 'breadcrumbs'])
        .push(action.text),
    );
  }

  handleSetBreadcrumbLevelAction(prevState, state, action) {
    return state.setIn(
      ['navigation', 'breadcrumbs'],
      state.getIn(['navigation', 'breadcrumbs'])
        .set(action.idx, action.text),
    );
  }

  handlePopBreadcrumbLevelAction(prevState, state, action) {
    return state.setIn(
      ['navigation', 'breadcrumbs'],
      state.getIn(['navigation', 'breadcrumbs'])
        .delete(action.idx),
    );
  }
}
