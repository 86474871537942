import React, { Component, PureComponent } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import moment from 'moment';

import {
  LocaleProvider,
  Spin,
  message,
  notification,
  Result,
  Button,
} from 'antd';

import enUS from 'antd/lib/locale-provider/en_US';

import { AxiosProvider, axiosContext } from './api/axios';
import { ConstituentListPage } from './components/pages/ConstituentListPage';
import { ConstituentScanPage } from './components/pages/ConstituentScanPage';
import { DashboardPage } from './components/pages/DashboardPage';
import { DashboardReportsPage } from './components/pages/DashboardReportsPage';
import { DefineTypeSettingsPage } from './components/pages/DefineTypeSettingsPage';
import { DataSharingPage } from './components/pages/DataSharingPage';
import { LoginPage } from './components/pages/LoginPage';
import { SAMLLoginPage } from './components/pages/SAMLLoginPage';
import { OrgSettingsPage } from './components/pages/OrgSettingsPage';
import { PasswordResetPage } from './components/pages/PasswordResetPage';
import { EmailVerificationPage } from './components/pages/EmailVerificationPage';
import { TagSettingsPage } from './components/pages/TagSettingsPage';
import { WebhookSettingsPage } from './components/pages/WebhookSettingsPage';
import { SAMLSettingsPage } from './components/pages/SAMLSettingsPage';
import { NpcSettingsPage } from './components/pages/NpcSettingsPage';
import { PcTrackingPage } from './components/pages/PcTrackingPage';
import { IcrTrackingPage } from './components/pages/IcrTrackingPage';
import { Version } from './components/Version';
import * as actions from './actions';
import { getAdmin } from './asyncModules';
import { SHORT_VERSION, JWT_BEST_BEFORE } from './defaults';
import { historyPushPath, tokenPayload, checkFeature, isPCTrackingEnabled } from './utils';
import { ActionText } from './components/elements';
import { Layout as DocLayout } from './components/layout/Layout';
import { CurrentUserProvider, withCurrentUser } from './whoami';

import './App.css';
import { ProviderSettingsPage } from './components/pages/ProviderSettingsPage';

message.config({ duration: 5 });

@connect(state => ({
  auth: state.doc.getIn(['auth'], null),
  token: state.doc.getIn(['authMr', 'token'], null),
}))
class WithReduxWhoamiContext extends PureComponent {
  render() {
    return (
      <CurrentUserProvider
        auth={this.props.auth}
        token={this.props.token}
      >
        {this.props.children}
      </CurrentUserProvider>
    );
  }
}

@withRouter
@connect(
  state => ({
    token: state.doc.getIn(['authMr', 'token'], null),
  }),
  actions,
)
class TokenReauthHandler extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    setTimeout(this.setupTimer, 0);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.token === this.props.token
    ) return;
    // eslint-disable-next-line no-unused-expressions
    checkFeature('autoRefreshJwt') && this.setupTimer();
  }

  setupTimer = () => {
    const { token } = this.props;
    if (!token) return;
    const { v, exp } = tokenPayload(token);
    const { timerId } = this.state;
    if (timerId) clearTimeout(timerId);
    if (_.isNil(v) || v < 1) {
      this.refresh();
    } else {
      const safeExpiry = moment.utc(exp * 1000).subtract(JWT_BEST_BEFORE, 'days');

      const newTimerId = setTimeout(
        this.refresh,
        safeExpiry.diff(moment()),
      );
      this.setState({ timerId: newTimerId });
    }

    historyPushPath(
      this.props.history,
      this.props.location,
      this.props.location.pathname,
      { loginToken: null },
    );
  }

  refresh = () => {
    this.props.refreshToken();
  }

  render() {
    return null;
  }
}

@connect(null, actions)
class LogoutPage extends PureComponent {
  componentWillMount() {
    this.props.logout();
  }

  render() {
    return (<Redirect
      to={{
        pathname: '/login',
      }}
            />);
  }
}

@withRouter
@connect(
  state => ({
    authenticated: state.doc.getIn(['authMr', 'authenticated'], false),
    token: state.doc.getIn(['authMr', 'token'], null),
    loaded: state.doc.getIn(['authMr', 'organizationId'], null) != null,
  }),
  actions,
)
class AuthWrapper extends PureComponent {
  componentDidMount() {
    this.props.getMe();
  }

  render() {
    if (this.props.authenticated) {
      return (<AxiosProvider value={axiosContext(this.props.token)}>
        {this.props.children}
      </AxiosProvider>);
    }
    return (<Redirect
      to={{
        pathname: '/login',
        state: {
          referrer: {
            path: document.location.pathname,
            search: document.location.search,
          },
        },
      }}
            />);
  }
}

class VersionFooter extends PureComponent {
  handleVersionClick = ev => {
    ev.preventDefault();
    notification.open({
      message: 'Versions',
      duration: null,
      description: <Version />,
    });
  }

  render() {
    return (
      <ActionText onClick={this.handleVersionClick} style={{
        position: 'fixed',
        bottom: '5px',
        right: '5px',
        fontSize: '0.8em',
        zIndex: 1,
        fontFamily: 'monospace',
        opacity: '0.6',
      }}
      >
        DoC {SHORT_VERSION}
      </ActionText>
    );
  }
}

const Reloader = () => {
  document.location.reload();
};

@withCurrentUser
class AdminPages extends Component {
  state = {
    adminModule: null,
  }

  componentRender = () => {
    if (this.state.adminModule) {
      return this.state.adminModule.components[this.componentName];
    }
    return null;
  }

  checkAdmin = () => {
    const { currentUser: { isAdmin } } = this.props;

    if (isAdmin) {
      getAdmin().then(adminModule => {
        this.setState({ adminModule });
      });
    }
  }

  componentDidMount() {
    this.checkAdmin();
  }

  componentDidUpdate(prev) {
    if (prev.organizationId !== this.props.organizationId) {
      this.checkAdmin();
    }
  }

  render() {
    const { adminModule } = this.state;
    if (!adminModule) {
      return <Spin />;
    }
    return (
      <Switch>
        <Route path='/admin/control' component={adminModule.components.ControlCentrePage} />
        <Route path='/admin/scheduled' component={adminModule.components.ScheduledPage} />
        <Route path='/admin/schedulerblock' component={adminModule.components.SchedulerBlockPage} />
        <Route path='/admin/impersonate' component={adminModule.components.ImpersonatePage} />
        <Route path='/admin/emailblock' component={adminModule.components.EmailBlockPage} />
        <Route path='/admin/deployment' component={adminModule.components.DeploymentPage} />
        <Route path='/admin/debug' component={adminModule.components.DebugPage} />
        <Route path='/admin/manualResult' component={adminModule.components.ManualResultPage} />
      </Switch>
    );
  }
}

// Restrict setting pages if email is not verified
@connect(state => ({
  emailVerified: state.doc.getIn(['authMr', 'emailVerified'], false),
}))
class ProtectedSettingsRoute extends Component {
  render() {
    const { component: Component, emailVerified, ...props } = this.props;

    return (
      <Route
        {...props}
        render={props => (
          emailVerified
            ? <Component {...props} />
            : <Redirect to='/' />
        )}
      />
    );
  }
}

function NotFoundPage({ history }) {
  const redirectToHomepage = () => {
    history.push('/');
  };
  return (
    <Result
      style={{ height: '80vh' }}
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Button type="primary" onClick={redirectToHomepage}>Back Home</Button>}
    />
  );
}

@withRouter
@connect(state => ({
  userOrganizationName: state.doc.getIn(['authMr', 'constituent', 'organization', 'name'], ''),
  icrTrackingEnabled: state.doc.getIn(['http', 'fetchCurrentOrganization', 'data', 'settings', 'screening', 'enrichmentRequestsTracking'], false),
  institutionsCredentials: state.doc.getIn(['authMr', 'constituent', 'organization', 'institutionsCredentials'], null),
}))
class SinglePageApplication extends PureComponent {
  handleMenuSelect = ({ key }) => {
    if (key === 'dashboard') {
      this.props.history.push('/');
    } else if (key === 'billingSettings') {
      window.location.href = '/billing.html';
    } else {
      this.props.history.push(`/${key}`);
    }
  }

  render = () => {
    const { match, location: { pathname }, icrTrackingEnabled, institutionsCredentials } = this.props;
    const pcTrackingEnabled = isPCTrackingEnabled(institutionsCredentials);

    return (
      <WithReduxWhoamiContext>
        <AuthWrapper>
          <DocLayout
            pathname={pathname}
            handleMenuSelect={this.handleMenuSelect}
          >
            <TokenReauthHandler />
            <Switch>
              <Route path={`${match.path}admin`} component={AdminPages} />
              <Route path={`${match.path}constituents/:id/addAccreditation`} component={ConstituentScanPage} />
              <Route path={`${match.path}constituents`} component={ConstituentListPage} />

              <ProtectedSettingsRoute path={`${match.path}dataSharing`} component={DataSharingPage} />
              <ProtectedSettingsRoute path={`${match.path}defineTypeSettings`} component={DefineTypeSettingsPage}/>
              <ProtectedSettingsRoute path={`${match.path}settings`} component={OrgSettingsPage}/>
              <ProtectedSettingsRoute path={`${match.path}providers`} component={ProviderSettingsPage}/>
              <ProtectedSettingsRoute path={`${match.path}tagSettings`} component={TagSettingsPage}/>
              <ProtectedSettingsRoute path={`${match.path}webhookSettings`} component={WebhookSettingsPage}/>
              <ProtectedSettingsRoute path={`${match.path}npcSettings`} component={NpcSettingsPage}/>
              {pcTrackingEnabled && <ProtectedSettingsRoute path={`${match.path}pcTracking`} component={PcTrackingPage} />}
              {icrTrackingEnabled && <ProtectedSettingsRoute path={`${match.path}icrTracking`} component={IcrTrackingPage} />}
              <ProtectedSettingsRoute path={`${match.path}samlSettings`} component={SAMLSettingsPage}/>
              <ProtectedSettingsRoute path={`${match.path}billing.html`} component={Reloader}/>
              <Route path={`${match.path}oneoffchecks.html`} component={Reloader} />
              {/* <Route path={ `${match.path}users` } component={ UsersPage } /> */}
              <Route exact path={`${match.path}logout`} component={LogoutPage} />
              <Route exact path={`${match.path}reports`} component={DashboardReportsPage} />
              <Route exact path={`${match.path}`} component={DashboardPage} />
              <Route path="*" component={NotFoundPage} />
            </Switch>
          </DocLayout>
        </AuthWrapper>
      </WithReduxWhoamiContext>
    );
  }
}

@withRouter
@connect(null, {
  initApp: actions.initApp,
})
class App extends PureComponent {
  componentDidMount() {
    this.props.initApp();
  }

  render() {
    return (<LocaleProvider locale={enUS}>
      <div>
        <VersionFooter />
        <Switch>
          <Route exact path="/logout" component={LogoutPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/saml/sso" component={SAMLLoginPage} />
          <Route exact path="/passwordReset" component={PasswordResetPage} />
          <Route exact path="/confirmEmail" component={EmailVerificationPage} />
          <Route exact path="/register" render={() => <Redirect to='https://weareoho.com/?show-signup=1' />} />
          <Route exact path="/support" render={() => <Redirect to='https://dutyofcare.supporthero.io/form' />} />
          <Route component={SinglePageApplication} />
        </Switch>
      </div>
    </LocaleProvider>);
  }
}

export {
  App,
};
