import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import { reducer } from './reducers';
import { unImmutable } from './utils';

const loggerMiddleware = createLogger({
  stateTransformer: unImmutable,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const STORE = createStore(
  combineReducers({
    doc: reducer,
  }),
  composeEnhancers(
    applyMiddleware(
      thunkMiddleware, // lets us dispatch() functions
      loggerMiddleware, // neat middleware that logs actions
    ),
  ),
);
