import { Button, Spin } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import * as actions from '../actions';
import { dynamicSort } from '../utils';

import { ConstituentTag } from './ConstituentTag';
import { IMPORTANT_TAG_COLORS, WARNING_TAG_COLORS } from '../defaults';

import './ConstituentTagList.css';

class ConstituentTagListComponent extends PureComponent {
  state = {
    extendedTagLimit: 4,
    restrictTagRows: true,
    showExtendedTags: false,
  };

  componentDidMount() {
    this.checkFetchTags(null, this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.checkFetchTags(this.props, nextProps);
  }

  checkFetchTags(prevProps, nextProps) {
    if (!nextProps.organizationId) return;
    if (nextProps.fetchTagsInProgress || nextProps.fetchTagsSuccess) return;
    nextProps.fetchTags(nextProps.organizationId);
  }

  determineTagSeverity(tag) {
    let severity = 3;
    if (tag.isImportant || IMPORTANT_TAG_COLORS[tag.role]) {
      severity = 1;
    } else if (WARNING_TAG_COLORS[tag.role]) {
      severity = 2;
    }
    return severity;
  }

  render() {
    const { children, fetchTagsInProgress, fetchTagsSuccess, selectedTags } = this.props;
    const selectedTagsJs = selectedTags && selectedTags.toJS
      ? selectedTags.toJS() : [];
    selectedTagsJs.sort((a, b) => {
      a.severity = this.determineTagSeverity(a);
      b.severity = this.determineTagSeverity(b);
      let order = 0;
      if (a.severity < b.severity) {
        order = -1;
      } else if (a.severity > b.severity) {
        order = 1;
      }
      return order;
    });
    const displayTagsJs = this.state.showExtendedTags ? (
      selectedTagsJs
    ) : (
      selectedTagsJs.slice(0, this.state.extendedTagLimit)
    );

    const hiddenTagCount = selectedTagsJs.length - displayTagsJs.length;

    return (<Spin spinning={ fetchTagsInProgress }>
      <ul className="constituent-tag-list">
        { fetchTagsSuccess
            && displayTagsJs.map(({ id: tagId, ...tagProps }) => (<li key={ tagId }>
              <ConstituentTag
                tagId={ tagId }
                { ..._.pick(this.props, ['tagProps', 'typeProps']) }
                { ...tagProps }
                />
            </li>))
        }
        { React.Children.map(children, child => <li>{ child }</li>) }
        {this.state.restrictTagRows && selectedTagsJs.length > 4 && (
          <p
            className={'show-more'}
            type="link"
            onClick={() => this.setState({ showExtendedTags: !this.state.showExtendedTags })}
          >
            {this.state.showExtendedTags ? 'Show less' : `Show more (${hiddenTagCount})`}
          </p>
        )}
      </ul>
    </Spin>);
  }
}

export const ConstituentTagList = connect(
  state => ({
    fetchTagsInProgress: state.doc.getIn(['http', 'fetchTags', 'inProgress']),
    fetchTagsSuccess: state.doc.getIn(['http', 'fetchTags', 'success']),
    fetchTagsFailed: state.doc.getIn(['http', 'fetchTags', 'failed']),
    organizationId: state.doc.getIn(['authMr', 'organizationId']),
  }),
  actions,
)(ConstituentTagListComponent);
