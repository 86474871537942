import { Reducer } from './base';

export class AccreditationMr extends Reducer {
  constructor() {
    super('accreditationMr');
  }

  handleSetAccreditationIdAction(prevState, state, action) {
    const { type, accreditation } = action;
    if (type === 'SET_ACCREDITATION_ID') {
      state = state.setIn(['current'], accreditation);
    }
    return state;
  }
}
