import { simpleAjaxDispatch } from './http';

export function fetchTags(organizationId, excludeTransient = false) {
  return simpleAjaxDispatch(
    `/tags?organization_id=${organizationId}&exclude_transient=${excludeTransient}`,
    'fetchTags',
  );
}

export function lazyTags() {
  return (dispatch, getState) => {
    const state = getState();
    const fetching = state.doc.getIn(['http', 'fetchTags', 'inProgress'], false);
    if (fetching) {
      return;
    }
    if (state.doc.getIn(['tagsMr', 'items'], []).length > 0) {
      return;
    }
    const organizationId = state.doc.getIn(['authMr', 'organizationId']);
    dispatch(fetchTags(organizationId));
  };
}

export function addTag(json) {
  return simpleAjaxDispatch(
    '/tags', 'addTag',
    { method: 'POST', json },
  );
}

export function patchTag(tagId, json) {
  return simpleAjaxDispatch(
    `/tags/${tagId}`,
    'patchTag',
    { method: 'PATCH', json },
  );
}

export function deleteTag(tagId) {
  return simpleAjaxDispatch(
    `/tags/${tagId}`,
    'deleteTag',
    { method: 'DELETE' },
  );
}

export function fetchTagStats(tagId) {
  return simpleAjaxDispatch(
    `/tags/${tagId}/stats`,
    'fetchTagStats',
    { useSeq: true, meta: { tagId } },
  );
}
