import { simpleAjaxDispatch } from './http';

export function fetchScheduled(qualname) {
  const qs = qualname ? `?qualname=${qualname}` : '';
  return simpleAjaxDispatch(
    `/scheduled${qs}`,
    'scheduled',
  );
}

export function fetchSchedulerBlocks() {
  return simpleAjaxDispatch(
    '/scheduler_blocks',
    'fetchSchedlerBlocks',
  );
}

export function addSchedulerBlock(json) {
  return simpleAjaxDispatch(
    '/scheduler_blocks',
    'postSchedulerBlock',
    { method: 'POST', json },
  );
}

export function deleteSchedulerBlock(id) {
  return simpleAjaxDispatch(
    `/scheduler_blocks/${id}`,
    'deleteSchedulerBlock',
    { method: 'DELETE' },
  );
}
