import { NON_ALPHA_NUM_RE, RegNumberFormItems } from './shared';

export class AhpraFormItems extends RegNumberFormItems {
  static slug = 'ahpra'

  static placeholder = 'XXX0123456789'

  static regNumberGetValueFromEvent(evt) {
    return evt.target.value.substr(0, 13).replace(NON_ALPHA_NUM_RE, '');
  }
}
