import {
  Alert, Form, Icon, Input, message,
} from 'antd';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import * as actions from '../../actions';
import { TRIAL_DAYS } from '../../defaults';

import { ActionText } from '../elements';
import { GreenPage } from './GreenPageBase';

const FormItem = Form.Item;

const docColor1 = '#e07a5f';

class Button extends PureComponent {
  render() {
    const { onClick, loading, children } = this.props;
    return (<ActionText onClick={ onClick } style={{
      backgroundColor: docColor1,
      borderColor: docColor1,
      color: 'white',
      padding: '.5em 2.5em',
      lineHeight: 1.5,
      borderRadius: '2em',
      display: 'block',
      opacity: loading ? 0.65 : 1,
    }}
            >{ loading && <Icon type="loading" style={{ paddingRight: '1em' }} spin /> }{ children }</ActionText>);
  }
}

class PasswordResetEmailComponent extends PureComponent {
  handleSubmit = evt => {
    evt.preventDefault();
    if (this.props.resetInProgress) return;
    this.props.form.validateFields((error, values) => {
      if (error) return;
      this.props.passwordResetEmail(values.email);
    });
  }

  render() {
    const { httpInProgress, httpSuccess, resetPasswordError, form } = this.props;
    const { getFieldDecorator } = form;
    let resetHelp;
    let resetStatus;

    if (httpSuccess) {
      return (<span style={{ textAlign: 'left' }}>
        <Alert
          message="Reset link sent"
          type="success"
          showIcon
          description={
            <Fragment>
              <p>If your email address matches a valid user, you will receive an email with password reset instructions</p>
              <p>If you don't receive this email within a few minutes, remember to check your junk mail folder!</p>
            </Fragment> }
        /></span>);
    }

    if (resetPasswordError) {
      const authMethodText = 'Your organisation is configured for an alternate Authentication method, so you cannot reset your Oho password. Please navigate to your organisation\'s alternate Authentication or ';
      resetHelp = (<div>
        {authMethodText}
        <Link style={{ textDecoration: 'underline' }} to={{ pathname: resetPasswordError }} title="Alternate Authentication Method" target="_blank">
            click here to login
        </Link>
      </div>);
      resetStatus = 'error';
    }

    return (<Fragment>
      <div style={{ paddingBottom: '2em' }}>Enter the email address you use to sign in</div>
      <Form onSubmit={ this.handleSubmit }>
        <FormItem validateStatus={resetStatus} help={resetHelp}>
          { getFieldDecorator('email', {
            rules: [
              {
                required: true,
                message: 'We need your username!',
              },
            ],
          })(
            <Input
              prefix={ <Icon type="mail" /> }
              placeholder="Email"
              disabled={ httpInProgress }
            />,
          ) }
        </FormItem>
        <button type="submit" style={{ display: 'none' }} />
      </Form>
      <p>
        <Button onClick={ this.handleSubmit } loading={ httpInProgress }>Send me reset link</Button>
      </p>
      <p>
        <Link to="/login">Back to sign in page</Link>
      </p>
      <p>
        Don't have an account?<br/>
        <a href="https://weareoho.com/?show-signup=1">Sign up for your free {TRIAL_DAYS} day trial</a>
      </p>
    </Fragment>);
  }
}
const PasswordResetEmailForm = Form.create()(PasswordResetEmailComponent);

export const PasswordResetEmail = connect(
  state => ({
    httpFailed: state.doc.getIn(['http', 'passwordResetEmail', 'failed'], false),
    httpSuccess: state.doc.getIn(['http', 'passwordResetEmail', 'success'], false),
    httpInProgress: state.doc.getIn(['http', 'passwordResetEmail', 'inProgress'], false),
    resetPasswordError: state.doc.getIn(['auth', 'resetPasswordError'], null),
  }),
  actions,
)(PasswordResetEmailForm);

class PasswordResetPasswordComponent extends PureComponent {
  componentWillReceiveProps(nextProps) {
    if (nextProps.httpSuccess) {
      nextProps.history.push('/login');
      nextProps.clearPasswordResetToken();
      message.success('All done! Please login again with your new password.');
    }
  }

  handleSubmit = evt => {
    evt.preventDefault();
    if (this.props.resetInProgress) return;
    this.props.form.validateFields((error, values) => {
      if (error) return;
      this.props.patchUserPassword('me', { password: values.password });
    });
  }

  render() {
    const { httpInProgress, form } = this.props;
    const { getFieldDecorator } = form;

    return (<Fragment>
      <Form onSubmit={ this.handleSubmit }>
        <FormItem>
          { getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: 'We need your password!',
              },
            ],
          })(
            <Input
              prefix={ <Icon type="lock" /> }
              placeholder="New password"
              type="password"
              disabled={ httpInProgress }
            />,
          ) }
        </FormItem>
        <button type="submit" style={{ display: 'none' }} />
      </Form>
      <div style={{ marginBottom: '1em' }}>
        <Button onClick={ this.handleSubmit } loading={ httpInProgress }>Reset my password</Button>
      </div>
    </Fragment>);
  }
}
const PasswordResetPasswordForm = Form.create()(PasswordResetPasswordComponent);

export const PasswordResetPassword = withRouter(connect(
  state => ({
    httpFailed: state.doc.getIn(['http', 'patchUserPassword', 'failed'], false),
    httpSuccess: state.doc.getIn(['http', 'patchUserPassword', 'success'], false),
    httpInProgress: state.doc.getIn(['http', 'patchUserPassword', 'inProgress'], false),
  }),
  actions,
)(PasswordResetPasswordForm));

class PasswordResetPageComponent extends PureComponent {
  componentWillReceiveProps(nextProps) {
    if (nextProps.authenticated) nextProps.history.push('/');
  }

  handleSubmit = evt => {
    evt.preventDefault();
    if (this.props.resetInProgress) return;
    this.props.form.validateFields((error, values) => {
      if (error) return;
      this.props.passwordResetEmail(values.email);
    });
  }

  render() {
    const { resetToken } = this.props;

    const subtitle = resetToken
      ? 'We recommend a minimum of 8 characters'
      : null;

    return (<GreenPage title="Reset password" subtitle={ subtitle }>
      { resetToken ? <PasswordResetPassword /> : <PasswordResetEmail />}
    </GreenPage>);
  }
}
const PasswordResetPageForm = Form.create()(PasswordResetPageComponent);

export const PasswordResetPage = connect(
  state => ({
    authenticated: state.doc.getIn(['authMr', 'authenticated'], false),
    resetToken: state.doc.getIn(['authMr', 'resetToken']),
    emailFailed: state.doc.getIn(['http', 'passwordResetEmail', 'failed'], false),
    emailInProgress: state.doc.getIn(['http', 'passwordResetEmail', 'inProgress'], false),
  }),
  actions,
)(PasswordResetPageForm);
