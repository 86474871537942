import { Form, Input, Modal, message } from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../actions';
import { hasFieldsError } from '../../../utils';

class SAMLModalComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
    };
  }

  handleOk = ev => {
    const { createSSOCreds, onOk, form: { validateFields } } = this.props;
    this.setState({ saving: true });
    validateFields(async (errs, values) => {
      if (errs) {
        this.setState({ saving: false });
        return;
      }

      try {
        const resp = await createSSOCreds({
          sso_id: values.ssoId,
          cert: values.certificate,
          idp_sso_url: values.idpSSOURL,
          idp_url: values.idpURL,
        });
        const { success } = resp;
        this.setState({ saving: false });
        if (success) {
          if (onOk) onOk(ev);
        } else {
          message.error(`${this.props.invalidSSOIdError && this.props.invalidSSOIdError.toJS().length > 0
            ? this.props.invalidSSOIdError.toJS()[0]
            : 'Error creating SSO Creds'}`, 5);
        }
      } catch (err) {
        this.setState({ saving: false });
        console.error('Error creating SSO Creds', err);
        message.error('Error creating SSO Creds');
      }
    });
  }

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
    } = this.props.form;

    return (<Modal
      destroyOnClose={true}
      title="Add SAML Settings"
      { ... this.props }
      okButtonProps={ {
        disabled: (
          hasFieldsError(getFieldsError())
        ),
      } }
      confirmLoading={ this.state.saving }
      onOk={this.handleOk}
            >
      <Form>
        <Form.Item
          label="SSO Id"
        >
          { getFieldDecorator('ssoId', {
            rules: [

              { required: true, message: 'SSO Id is required' },
            ],
          })(
            <Input />,
          ) }
        </Form.Item>
        <Form.Item
          label="Certificate"
        >
          { getFieldDecorator('certificate', {
            rules: [
              { required: true, message: 'Certification is required' },
            ],
          })(
            <Input />,
          ) }
        </Form.Item>
        <Form.Item
          label="IDP SSO URL"
        >
          { getFieldDecorator('idpSSOURL', {
            rules: [
              { type: 'url', message: 'Not a valid URL' },
              { required: true, message: 'IDP SSO URL is required' },
            ],
          })(
            <Input />,
          ) }
        </Form.Item>
        <Form.Item
          label="IDP URL"
        >
          { getFieldDecorator('idpURL', {
            rules: [
              { type: 'url', message: 'Not a valid URL' },
              { required: true, message: 'IDP URL is required' },
            ],
          })(
            <Input />,
          ) }
        </Form.Item>
      </Form>
    </Modal>);
  }
}

export const SAMLForm = Form.create()(SAMLModalComponent);

export const SAMLModal = connect(
  state => ({
    organizationId: state.doc.getIn(['authMr', 'organizationId']),
    invalidSSOIdError: state.doc.getIn(['http', 'createSSOCreds', 'data', '_errors', 'organization', 'ssoId'], null),
  }),
  actions,
)(SAMLForm);
