import React, { Component } from 'react';
import { Form, message } from 'antd';
import _ from 'lodash';
import { fromJS } from 'immutable';

function evalProps(props, config) {
  if (typeof config === 'function') {
    return config(props);
  }
  return config;
}

/* returns a connected form HOC.
 * config may either be an object or props => object
 */
export function connectForm(config) {
  return ChildComponent => React.forwardRef((props, ref) => {
    const {
      httpGroup,
      postAction,
      transformFromServer = d => d,
      transformToServer = d => d,
    } = evalProps(props, config);
    return (
      <FormWrapperHOC
        httpGroup={httpGroup}
        postAction={postAction}
        ChildComponent={ChildComponent}
        transformToServer={transformToServer}
        transformFromServer={transformFromServer}
        ref={ref}
        {...props}
      />
    );
  });
}

// Converts a sync or async validator and converts it into ant's callback style
// validator. The validator may return a promise or a value.
export const asyncValidator = validator => (rule, value, callback) => {
  try {
    const p = validator(rule, value);
    Promise.resolve(p)
      .then(callback)
      .catch(e => console.error('err in async', e));
  } catch (e) {
    console.error('Error in validator', e);
    callback(['Is Invalid']);
  }
};

@Form.create()
class FormWrapperHOC extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  save = ev => {
    this.handleSubmit(ev);
  }

  handleSubmit = ev => {
    if (ev) {
      ev.preventDefault();
    }
    this.props.form.validateFields((err, values) => {
      if (err) {
        message.error('Please address the validation issues below');
        return;
      }
      // Save posted state for filtering backend errors
      this.setState({
        postedValues: fromJS(values),
        inProgress: true,
      });

      this.props.postAction(this.props.transformToServer(values)).then(httpRes => {
        if (httpRes.success && typeof this.props.onSuccess === 'function') {
          this.props.onSuccess(httpRes.data);
          return;
        }
        this.setState({
          inProgress: false,
          validationErrors: _.get(httpRes, ['data', '_errors'], {}),
          success: httpRes.success,
          globalErrors: _.get(httpRes, ['data', '_errors', '_request'], null),
        });
        this.props.form.validateFields({ force: true });
      }).catch(err => {
        console.error(err);
        this.setState({
          inProgress: false,
        });
      });
    });
  }

  backendErrorsValidator = ({ backendValidateLinks = [] }) => asyncValidator(async (rule, value) => {
    const { postedValues } = this.state;
    if (!postedValues) return [];

    const backendErrors = this.state.validationErrors;
    if (!backendErrors) return [];

    const fieldPath = rule.fullField.split('.');
    const postedValue = postedValues.getIn(fieldPath, null);

    // Don't show if the user has edited since posting
    if (postedValue !== value) {
      return [];
    }

    // Did any group fields change?
    if (backendValidateLinks.find(key => {
      const currentValue = this.props.form.getFieldValue(key);
      const postedSubValue = postedValues.getIn(key.split('.'), null);
      return currentValue !== postedSubValue;
    })) {
      return [];
    }

    const fieldErrors = _.get(backendErrors, fieldPath);
    if (!fieldErrors) return [];

    return fieldErrors;
  })

  getConnectedFieldDecorator = (name, opts = {}) => this.props.form.getFieldDecorator(name, {
    ...opts,
    rules: [...(opts.rules || []), this.backendErrorsValidator(opts)],
  })

  render() {
    const { ChildComponent, form, ...remainder } = this.props;
    return (<ChildComponent
      handleSubmit={this.handleSubmit}
      globalErrors={this.state.globalErrors}
      isSuccess={this.state.success}
      saveInProgress={this.state.inProgress}
      form={{
        getConnectedFieldDecorator: this.getConnectedFieldDecorator,
        ...form,
      }}
      {...remainder}
            />);
  }
}
