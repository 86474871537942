import {
  Form, message, Button, Divider, Table, Tag, Result, Icon, Spin, Modal,
} from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PinInput from 'react-pin-input';
import * as actions from '../../../actions';
import { TotpRegisterComponent } from './TotpRegister';
import { PortalChild } from '../../elements';

class TotpLandingComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mountRegisterModal: false,
      verifyingHelp: undefined,
      verifyInputStatus: undefined,
      pinInput1: '',
      saving: false,
      deletePopConfirmVisibility: false,
      fetchedMfaData: [],
      regPinInput1: '',
      regVerifyingHelp: undefined,
      regVerifyInputStatus: undefined,
      regSaving: false,
      userId: null,
    };
    this.handleDisableMFA = this.handleDisableMFA.bind(this);
    this.handleDisableMFACancel = this.handleDisableMFACancel.bind(this);
    this.inputRef = React.createRef();
  }

  getHttpError(data) {
    if (!data._errors) return '';
    return Object.values(data._errors).flat().join('|');
  }

  async handleDisableMFA(evt) {
    evt.preventDefault();
    this.setState({ saving: true, verifyInputStatus: false, verifyingHelp: null });

    let response = null;

    if (this.props.currentUserTotpStatus && this.state.pinInput1.length !== 6) {
      this.setState({ saving: false });
      this.setState({ verifyingHelp: 'MFA code requires 6 digit code', verifyInputStatus: true, saving: false });
      return;
    }
    if (this.props.currentUserTotpStatus && this.state.pinInput1.length === 6) {
      response = await this.props.disableMFA(this.state.userId, {
        code: Number(this.state.pinInput1),
      });
    }
    if (!this.props.currentUserTotpStatus) response = await this.props.disableMFA(this.state.userId);
    if (response.failed) {
      this.setState({ verifyingHelp: this.getHttpError(response.data), verifyInputStatus: true, saving: false });
    }
    if (response.success) {
      message.success('Successfully removed MFA device!');
      this.setState({ saving: false, deletePopConfirmVisibility: false });
      this.getCurrentUserInfo(this.state.userId);
      this.props.getMe();
    }
  }

  handleDisableMFACancel() {
    if (this.props.currentUserTotpStatus) this.inputRef.current.clear();
    this.setState({ deletePopConfirmVisibility: false, pinInput1: '', verifyingHelp: undefined, verifyInputStatus: undefined });
  }

  getCurrentUserInfo = async id => {
    const { failed, success, data } = await this.props.getCurrentUserInfo(id);
    if (failed) {
      this.setState({ fetchCurrentUserDetailsFailed: true, fetchCurrentUserDetailsError: this.getHttpError(data) });
      return;
    }

    const fetchedData = [];
    if (success && this.props.fetchUserInfoMFAStatus) {
      fetchedData.push({
        key: '1',
        deviceName: `${this.props.fetchUserInfoEmail}'s MFA 1`,
        status: <Tag color="blue">Active</Tag>,
        type: 'Authenticator app',
      });
    }
    this.setState({ fetchedMfaData: fetchedData });
  }

  handleMfaRegisterModal = () => {
    this.setState(prevState => {
      return {
        mountRegisterModal: !prevState.mountRegisterModal,
      };
    });
    this.cleanupChild();
    this.getCurrentUserInfo(this.state.userId);
  }

  handleRegisterPinInput(value) {
    this.setState({ regPinInput1: value });
  }

  handleTotpRegister = async evt => {
    evt.preventDefault();
    this.setState({ regSaving: true });

    if (!this.state.regPinInput1 || !this.state.regPinInput1.split('').length === 6) {
      this.setState({ regSaving: false });
      this.setState({ regVerifyingHelp: 'MFA code requires 6 digit code', regVerifyInputStatus: 'error', regSaving: false });
      return;
    }
    const res = await this.props.verifyTotp({
      code: Number(this.state.regPinInput1),
    });
    if (res.failed) this.setState({ regVerifyingHelp: this.getHttpError(res.data), regVerifyInputStatus: 'error', regSaving: false });
    if (res.success) {
      this.setState({ regSaving: true });
      message.success('Successfully registered with MFA device!');
      this.props.getMe();
      this.handleMfaRegisterModal();
    }
  }

  componentDidMount() {
    if (!this.props.mfaOwner) return;
    this.setState({ userId: this.props.mfaOwner });
    this.getCurrentUserInfo(this.props.mfaOwner);
  }

  componentDidUpdate() { 
    if (this.state.userId || !this.props.mfaOwner) return;
    this.setState({ userId: this.props.mfaOwner });
    this.getCurrentUserInfo(this.props.mfaOwner);
  }

  componentWillUnmount() {
    this.cleanup();
  }

  cleanup() {
    this.setState({
      mountRegisterModal: false,
      verifyingHelp: undefined,
      verifyInputStatus: undefined,
      pinInput1: '',
      saving: false,
      fetchedMfaData: [],
      fetchCurrentUserDetailsFailed: false,
      fetchCurrentUserDetailsError: [],
    });
    this.cleanupChild();
  }

  cleanupChild() {
    this.setState({
      regPinInput1: '',
      regVerifyingHelp: undefined,
      regVerifyInputStatus: undefined,
      regSaving: false,
    });
  }

  regValidity(reduxId, propId) {
    let response = true;
    if (!reduxId || !propId) return false;
    if (reduxId === propId && !this.props.currentUserTotpStatus) response = false;
    return response;
  }

  render() {
    const columns = [
      {
        title: <p className='mfa-table-col'>Device name</p>,
        dataIndex: 'deviceName',
        key: 'deviceName',
      },
      {
        title: <p className='mfa-table-col'>Status</p>,
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: <p className='mfa-table-col'>Type</p>,
        dataIndex: 'type',
        key: 'type',
      },
      {
        key: 'remove',
        dataIndex: 'remove',
        render: () => (
          <>
            <span onClick={() => {
              this.setState({ deletePopConfirmVisibility: true }, () => {
                setTimeout(() => {
                  if (this.inputRef.current) {
                    this.inputRef.current.focus();
                  }
                }, 500);
              });
            }} style={{
              fontWeight: 'bold', color: '#82412C', cursor: 'pointer', backgroundColor: '#F8EFED', padding: '5px', borderRadius: '10px',
            }}
            >Remove</span>
          </>
        ),
      },
    ];

    if (this.props.fetchUserInfoInProgress) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spin loading={true} style={{ marginTop: '25px', marginBottom: '25px' }}/>
        </div>
      );
    }

    return (
      <>
        {
          this.state.fetchCurrentUserDetailsFailed
            ? <Result
              title={<p style={{ fontSize: '16px' }}>Failed to load MFA registration details</p>}
              subTitle={this.state.fetchCurrentUserDetailsError}
              icon={<Icon style={{ color: '#C88242' }} type="frown" />}
              />
            : <>
              <div style={{ display: 'flex', marginTop: '15px' }}>
                <p style={{ flexGrow: 1, paddingRight: '16px', fontWeight: '700' }}>
                Multi-factor authentication (MFA)
                </p>
                <Button type="primary" onClick={() => { this.setState({ mountRegisterModal: true }); }} disabled={ this.regValidity(this.props.currentConstituentIdFromRedux, this.props.constituentId)}>Register device</Button>
              </div>
              <Divider />
              <Table columns={columns} dataSource={this.state.fetchedMfaData} pagination={false} loading={this.props.fetchUserInfoInProgress} style={{ marginBottom: '25px' }}/>
              <PortalChild portal={this.props.footerRef}>
                <Button onClick={this.props.onCancel}>Close</Button>
              </PortalChild>
            </>
        }

        <Modal
          destroyOnClose={true}
          title="Setup device"
          visible={this.state.mountRegisterModal}
          okButtonProps={ {
            disabled: (
              !this.props.regTotpBarcode,
              this.props.regTotpFailed
            ),
            loading: (
              this.state.regSaving
            ),
          } }
          confirmLoading={ this.state.saving }
          onOk={this.handleTotpRegister}
          style={{ minWidth: '650px' }}
          closable={false}
          maskClosable={false}
          onCancel={() => { this.handleMfaRegisterModal(); }}
        >
          <TotpRegisterComponent
            saving={this.state.regSaving}
            verifyingHelp={this.state.regVerifyingHelp}
            verifyInputStatus={this.state.regVerifyInputStatus}
            setParentState={value => this.handleRegisterPinInput(value)}
          />
        </Modal>


        <Modal
          title= {null}
          onOk={this.handleDisableMFA}
          onCancel={this.handleDisableMFACancel}
          okText="Yes"
          cancelText="No"
          visible={this.state.deletePopConfirmVisibility}
          okButtonProps={{ loading: this.state.saving }}
          closable={false}
          maskClosable={false}
        >
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: 'transparent' }} >
            <div style={{ display: 'flex' }}><Icon style={{ color: 'orange', marginTop: '2px', marginRight: '5px' }} type="question-circle" theme="filled" /> <p style={{ fontWeight: '700' }}>Are you sure you want to deactivate this MFA device?</p> </div>
            { this.props.currentUserTotpStatus
              ? <>
                <p>Please enter the six digit code from your authenticator app.</p>
                <PinInput
                  length={6}
                  initialValue={this.state.pinInput1}
                  value={this.state.pinInput1}
                  onChange={(value, index) => { this.setState({ pinInput1: value }); }}
                  type="numeric"
                  inputMode="number"
                  style={{ fontSize: '16px', marginBottom: '5px', marginTop: '10px' }}
                  inputStyle={{ borderColor: 'black', height: '30px', width: '30px', borderRadius: '10px' }}
                  inputFocusStyle={{ borderColor: '#C88242' }}
                  onComplete={(value, index) => { this.setState({ pinInput1: value }); }}
                  autoSelect={true}
                  ref={this.inputRef}

                />
              </>
              : null
            }
            {
              this.state.verifyInputStatus ? <p style={{ color: 'red', marginLeft: '2px', marginBottom: '15px' }}>{this.state.verifyingHelp}</p> : null
            }
          </div>

        </Modal>

      </>
    );
  }
}

export const TotpLandingForm = Form.create()(TotpLandingComponent);

export const TotpLanding = connect(
  state => ({
    currentUserTotpStatus: state.doc.getIn(['auth', 'totpEnabled'], false),
    fetchUserInfoInProgress: state.doc.getIn(['http', 'mfaUserInfo', 'inProgress'], true),
    fetchUserInfoFailed: state.doc.getIn(['http', 'mfaUserInfo', 'failed'], false),
    fetchUserInfoMFAStatus: state.doc.getIn(['http', 'mfaUserInfo', 'data', 'totpEnabled'], true),
    fetchUserInfoEmail: state.doc.getIn(['http', 'mfaUserInfo', 'data', 'email'], null),
    currentConstituentIdFromRedux: state.doc.getIn(['authMr', 'constituent', 'id'], null),
    mfaOwner: state.doc.getIn(['constituentsMr', 'mfaOwner', 'id'], null),
  }),
  actions,
)(TotpLandingForm);
