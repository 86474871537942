import { Form, Input } from 'antd';
import React, { PureComponent } from 'react';

export const NON_NUM_RE = /[^0-9]/g;

export const NON_ALPHA_NUM_RE = /[^a-zA-Z0-9]/g;

export const NON_ALPHA_NUM_SLASH_RE = /[^a-zA-Z0-9/]/g;

export const GEN_ACC_IDENTIFIER = /[^a-zA-Z0-9-.()/]/g;

export const API_DATE_FS = 'YYYY-MM-DD';

export class RegNumberFormItems extends PureComponent {
  static identLabel = 'Registration number'

  static getSubmissionData() { return {}; }

  static regNumberGetValueFromEvent(evt) {
    return evt.target.value.replace(NON_ALPHA_NUM_RE, '');
  }

  render() {
    const { form, disabled, rules } = this.props;
    const { getFieldDecorator } = form;

    const {
      slug, identLabel, placeholder,
      regNumberGetValueFromEvent,
    } = this.constructor;

    return (<div>
      <Form.Item label={ identLabel }>
        { getFieldDecorator(`${slug}.identifier`, {
          getValueFromEvent: regNumberGetValueFromEvent,
          rules: [
            {
              required: true,
              whitespace: true,
              message: `${identLabel} is required`,
            },
            ...(rules || []),
          ],
        })(
          <Input
            placeholder={ placeholder }
            disabled={ disabled }
          />,
        ) }
      </Form.Item>
    </div>);
  }
}

export class RegNumberAndNameFormItems extends RegNumberFormItems {
  static getSubmissionData(checkData, constituent) {
    return {
      fullName: constituent.get('fullName'),
    };
  }
}
