import React, { PureComponent } from 'react';
import { Steps } from 'antd';
import { npcWorkflowStagesSteps } from './ContentInfo';

const { Step } = Steps;

class WorkflowStages extends PureComponent {
  render() {
    return (
      <div>
        <Steps direction="vertical" className='npc-workflow-custom-tail npc-workflow-text'>
          {npcWorkflowStagesSteps.map(item => <Step className='npc-workflow-custom-icon' status='process' title={item.title} description={item.description} />)}
        </Steps>
      </div>);
  }
}

export { WorkflowStages };
