import { Form, Spin, Col, Row } from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Svg } from '../Svg';
import logo from '../../logos/logo_dark.svg';

import * as actions from '../../actions';

const message = 'Attempting SAML Authentication';

class SAMLLoginPageComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { ...SAMLLoginPageComponent.state };
  }

  componentDidMount() {
    const uuid = new URLSearchParams(this.props.location.search).get('uuid');
    this.props.SAMLToken(uuid);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.authenticated) {
      props.refreshToken();
      props.history.push('/');
      props.initApp();
    }
    return null;
  }

  render() {
    return (
      <Spin type="flex" justify="center" align="middle" size="large" style={{ top: '20px', minHeight: '100vh', verticalAlign: 'middle' }} tip={message}>
        <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
          <Col span={8}>
            <Svg
              url={logo}
              style={{
                width: '220px',
                verticalAlign: 'middle'
              }}
            />
          </Col>
        </Row>
      </Spin>);
  }
}
const SAMLLoginPageForm = Form.create()(SAMLLoginPageComponent);

export const SAMLLoginPage = connect(
  state => ({
    authenticated: state.doc.getIn(['authMr', 'authenticated'], false),
  }),
  actions,
)(SAMLLoginPageForm);
