import { message } from 'antd';
import { genericAjaxDispatch, simpleAjaxDispatch } from './http';
import { authHeaderValue } from '../utils';

export function getMe() {
  return simpleAjaxDispatch('/users/me', 'getMe');
}

export function refreshToken(username = null, password = null) {
  const authOpts = username && password
    ? { auth: authHeaderValue(username, password) }
    : {};
  return (dispatch, getState) => simpleAjaxDispatch(
    '/users/me/token',
    'createToken',
    { method: 'POST', json: {}, ...authOpts },
  )(dispatch, getState).then(action => {
    if (action.success) {
      dispatch({
        type: 'SET_TOKEN',
        token: action.data.token,
      });
    } else if (action.response.status === 401) {
      message.warning('Please login again');
    }
    return action;
  });
}

export function SAMLToken(uuid = null) {
  return (dispatch, getState) => simpleAjaxDispatch(
    '/saml?sso',
    'createSAMLToken',
    { method: 'POST', json: { uuid } },
  )(dispatch, getState).then(action => {
    if (action.success) {
      dispatch({
        type: 'SET_TOKEN',
        token: action.data.token,
      });
    } else if (action.response.status === 401) {
      message.warning('SAML authentication failed');
    }
    return action;
  });
}

export function emailVerification(token) {
  return simpleAjaxDispatch(
    `/users/verify/${token}`,
    'emailVerification',
    { method: 'POST', json: {} },
  );
}

export function resendEmailVerification() {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.doc.getIn(['authMr', 'id'], null);
    return simpleAjaxDispatch(
      `/users/${userId}/resend_email`,
      'resendEmailVerification',
      { method: 'POST', json: {} },
    )(dispatch, getState).then(action => {
      if (action.success) {
        message.success('Successfully resent Email verification');
      } else if (action.response.status === 401) {
        message.warning('Failed to resend Email verification');
      }
      return action;
    });
  };
}

export function impersonateUser(id) {
  return async (dispatch, getState) => {
    const resp = await simpleAjaxDispatch(
      `/users/${id}/token`,
      'impersonateCreateToken',
      { method: 'POST', json: {} },
    )(dispatch, getState);
    if (resp.success) {
      localStorage.docToken = resp.data.token;
      document.location.reload();
    }
  };
}

export function completeImpersonation() {
  return async (dispatch, getState) => {
    await simpleAjaxDispatch(
      '/users/complete_impersonate',
      'completeImpersonation',
      { method: 'POST', json: {} },
    )(dispatch, getState);
  };
}

export function login(username, password) {
  return refreshToken(username, password);
}

export function logout() {
  return (dispatch, getState) => simpleAjaxDispatch(
    '/users/me/logout',
    'userLogout',
    { method: 'POST' },
  )(dispatch, getState).then(action => {
    if (!action.success) message.error('Unable to log out the user at this time');
    else dispatch({ type: 'LOGOUT' });
  });
}

function registerErrorAction(message) {
  return { type: 'REGISTER_ERROR', message };
}

function resetPasswordErrorAction(message) {
  return { type: 'RESET_PASSWORD_ERROR', message };
}

export function register(username, password) {
  return (dispatch, getState) => {
    if (!username || username.size < 5) return dispatch(registerErrorAction('Username too short'));
    if (!password || password.size < 6) return dispatch(registerErrorAction('Password too short'));
    return genericAjaxDispatch(
      dispatch, getState, '/users', 'register', {
        method: 'POST',
        redirect: 'manual',
        json: { email: username, password },
      },
    ).then(
      response => {
        if (response.ok) {
          login(username, password)(dispatch, getState);
        } else {
          response.text().then(
            text => dispatch(registerErrorAction(text)),
            error => dispatch(registerErrorAction(error)),
          );
        }
      },
      error => dispatch(registerErrorAction(error)),
    );
  };
}

export function passwordResetEmail(username) {
  return (dispatch, getState) => simpleAjaxDispatch(
    `/users/by-email/${username}/password_reset_request`,
    'passwordResetEmail',
    { method: 'POST' },
  )(dispatch, getState).then(action => {
    if (!action.data.okay && action.data.authMethod) {
      message.warning('Cannot reset password due to your organisation using an alternate Authentication method');
      dispatch(resetPasswordErrorAction(action.data));
    }
  });
}

function postTwoFactorCodeResult(message) {
  return { type: 'TWO_FACTOR_CODE_RESULT', message };
}

export function postTwoFactorCode(twoFactorCode) {
  return (dispatch, getState) => simpleAjaxDispatch(
    '/twofactor',
    'twoFactor',
    { method: 'POST', json: { two_factor_code: twoFactorCode } },
  )(dispatch, getState).then(action => {
    if (!action.data.saved) {
      message.warning('One-Time password did not save successfully.');
    } else {
      message.success('One-Time password saved successfully.');
    }
    dispatch(postTwoFactorCodeResult(action.data.saved));
  });
}

function initiateQldBlueLinkageResult() {
  return { type: 'TWO_FACTOR_CODE_RESULT', message };
}

function qldLinkageErrorAction(message) {
  return { type: 'QLD_LINKAGE_ERROR', message };
}

export function initiateQldBlueLinkage(uuid = null) {
  return (dispatch, getState) => simpleAjaxDispatch(
    '/qldblue/linkage',
    'initiateQldBlueLinkage',
    { method: 'POST', json: { uuid } },
  )(dispatch, getState).then(action => {
    if (!action.data.success) {
      message.warning('Qld Blue linkage initation failed.');
      console.error(action.data.error);
      dispatch(qldLinkageErrorAction(action.data.error));
    } else {
      message.success('Qld Blue linkage started successfully.');
      dispatch(qldLinkageErrorAction(null));
    }
    dispatch(initiateQldBlueLinkageResult(action.data));
  });
}

export function checkRedirectionDetails() {
  return simpleAjaxDispatch('/users/me', 'checkRedirectionDetails');
}
