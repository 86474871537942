import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { NdwsSyncModal } from '../../NdwsImportPopup';

import { historyPushPath } from '../../../utils';

@connect(
  state => ({
    organizationId: state.doc.getIn(['authMr', 'organizationId']),
  }),
)
class NdwsSyncRoute extends PureComponent {
  handleCancel = () => {
    historyPushPath(
      this.props.history,
      this.props.location,
      '/constituents',
    );
  }

  render() {
    return (<NdwsSyncModal
      title="NDWS Sync"
      onCancel={ this.handleCancel }
            />);
  }
}

export {
  NdwsSyncRoute,
};
