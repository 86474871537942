import React, { PureComponent } from 'react';

import { Layout as AntLayout } from 'antd';

import { SpinUntilUser, withCurrentUser } from '../../whoami';
import {
  Breadcrumb,
  BreadcrumbLevel,
  BreadcrumbLevelWrapper,
} from '../BreadcrumbLevel';
import { HeaderBar } from './HeaderBar';
import { Svg } from '../Svg';
import { MenuBar } from './MenuBar';

import logo from '../../logos/logo_light.svg';

import './Layout.css';

const SIDER_LG = 200;
const SIDER_SM = 80;
const SIDER_LOGO_PAD_LG = 24;
const SIDER_LOGO_PAD_SM = 12;
const SIDER_LOGO_MIN_HEIGHT_LG = 156;
const SIDER_LOGO_MIN_HEIGHT_SM = 56;

@withCurrentUser
class Layout extends PureComponent {
  state = {
    siderCollapsed: false,
    siderWidth: SIDER_LG,
    siderLogoPad: SIDER_LOGO_PAD_LG,
    siderLogoMinHeight: SIDER_LOGO_MIN_HEIGHT_LG,

    logoContent: '',
  }

  handleSiderCollapse = collapsed => {
    this.setState({
      siderCollapsed: collapsed,
      siderWidth: collapsed ? SIDER_SM : SIDER_LG,
      siderLogoPad: collapsed ? SIDER_LOGO_PAD_SM : SIDER_LOGO_PAD_LG,
      siderLogoMinHeight: collapsed ? SIDER_LOGO_MIN_HEIGHT_SM : SIDER_LOGO_MIN_HEIGHT_LG,
    });
  }

  render() {
    const {
      pathname,
      collapsedWidth,
      handleMenuSelect,
    } = this.props;
    const {
      siderCollapsed,
      siderLogoMinHeight,
      siderLogoPad,
      siderWidth,
    } = this.state;

    const pageKey = pathname === '/'
      ? 'dashboard'
      : pathname.substr(1);

    return (
      <AntLayout>
        <div className={ `doc-content-layout doc-page-${pageKey}` }>
          <AntLayout>
            <AntLayout.Sider
              style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                zIndex: 100,
              }}
              collapsible={ true }
              breakpoint="sm"
              collapsedWidth={ collapsedWidth }
              onCollapse={ this.handleSiderCollapse }
            >
              <Svg
                url={ logo }
                className="logo"
                style={{
                  padding: siderLogoPad,
                  minHeight: `${siderLogoMinHeight}px`,
                }}
              />
              <MenuBar
                collapsed={siderCollapsed}
                pathname={pathname}
                handleMenuSelect={handleMenuSelect}
              />
            </AntLayout.Sider>

            <AntLayout className="doc-content-layout" style={{ marginLeft: siderWidth }}>
              <HeaderBar/>

              <SpinUntilUser>
                {this.props.children}
              </SpinUntilUser>
            </AntLayout>
          </AntLayout>
        </div>
      </AntLayout>
    );
  }
}

const withContentLayout = WrappedComponent => class WithContentLayout extends PureComponent {
  render() {
    const currentUser = this.context;
    return (
      <BreadcrumbLevelWrapper>
        <Breadcrumb/>
        <BreadcrumbLevel text={(currentUser.organization || { name: '' }).name}/>

        <AntLayout.Content>
          <div className='doc-content-wrapper' >
            <WrappedComponent {...this.props}/>
          </div>
        </AntLayout.Content>
      </BreadcrumbLevelWrapper>
    );
  }
};

export {
  Layout, withContentLayout,
};
