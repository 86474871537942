import { NON_NUM_RE, RegNumberFormItems } from './shared';

export class WaTrbFormItems extends RegNumberFormItems {
  static slug = 'watrb'

  static placeholder = '12345'

  static identLabel = 'TRBWA registration number'

  static regNumberGetValueFromEvent(evt) {
    return evt.target.value.substr(0, 8).replace(NON_NUM_RE, '');
  }
}
