import { NON_NUM_RE, RegNumberFormItems } from './shared';

export class NtTrbFormItems extends RegNumberFormItems {
  static slug = 'nttrb'

  static placeholder = '12345'

  static regNumberGetValueFromEvent(evt) {
    return evt.target.value.substr(0, 5).replace(NON_NUM_RE, '');
  }
}
