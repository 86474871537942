import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ConstituentImportModal } from '../../ConstituentImportPopup';

import { historyPushPath } from '../../../utils';

@connect(
  state => ({
    organizationId: state.doc.getIn(['authMr', 'organizationId']),
  }),
)
class ConstituentImportRoute extends PureComponent {
  handleCancel = () => {
    historyPushPath(
      this.props.history,
      this.props.location,
      '/constituents',
    );
  }

  render() {
    return (<ConstituentImportModal
      title="Bulk import people"
      onCancel={ this.handleCancel }
            />);
  }
}

export {
  ConstituentImportRoute,
};
