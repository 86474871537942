import {
  Badge, Icon, List, Spin, Checkbox, Tag,
} from 'antd';
import _ from 'lodash';
import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import CellMeasurer from 'react-virtualized/dist/commonjs/CellMeasurer';
import CellMeasurerCache from 'react-virtualized/dist/commonjs/CellMeasurer/CellMeasurerCache';
import InfiniteLoader from 'react-virtualized/dist/commonjs/InfiniteLoader';
import VList from 'react-virtualized/dist/commonjs/List';
import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller';

import { ConstituentTagList } from '../../ConstituentTagList';
import { BulkActionBar } from './BulkActions';

import * as actions from '../../../actions';
import { checkFeature, historyPushPath, stripHints } from '../../../utils';

@withRouter
@connect(
  (state, props) => ({
    constituent: state.doc
      .getIn(['constituentsMr', 'byIdPartial', props.data.constituentId], null),
  }),
)
class ConstituentListItem extends Component {
  handleClick = () => {
    historyPushPath(
      this.props.history,
      this.props.location,
      `${this.props.match.path}/${this.props.constituent.get('id')}`,
    );
  }

  handleNoop = ev => {
    ev.stopPropagation();
  }

  handleClickCheckbox = ev => {
    ev.stopPropagation();
    this.props.setSelected(this.props.constituent.get('id'), ev.currentTarget.checked);
  }

  render() {
    // Small "blip" when reloading constituent list causes VList not to
    // update/reset its ID list right away, so we can have an empty
    // constituents list, but still a "requested" constituent row
    if (!this.props.constituent) return <span></span>;
    const { data: { selected: isSelected }, highlightTags } = this.props;
    const constituent = this.props.constituent.toJS();
    const displayedTags = highlightTags.filter(tag => {
      const id = tag.get('id');
      return (
        (constituent.directTags || []).some(t => t.id === id)
        || (constituent.implicitTags || []).some(t => t.id === id)
      );
    });
    const displayName = stripHints(constituent.fullName);
    let relativeBadge = null;
    if (constituent.organization && constituent.organization.name && this.props.organizationId !== constituent.organization.id) {
      relativeBadge = (
        <Tag color="#f1e5db" style={{ marginLeft: '10px', fontWeight: '2', color: '#4d524d' }} >
          <span style={{ fontSize: '12px' }}>{constituent.organization.name}</span>
        </Tag>
      );
    }
    const inactiveBadge = constituent.active ? null : (
      <Tag color="#f1e5db" style={{ marginLeft: '10px', fontWeight: '2', color: '#4d524d' }} >
        <span style={{ fontSize: '12px' }}>Inactive</span>
      </Tag>
    );
    const title = <span><Badge color={constituent.statusColor} />{displayName} {relativeBadge} {inactiveBadge}</span>;

    let claimsCount = 0;
    if (constituent.claimsCountActive) {
      claimsCount = constituent.claimsCountActive;
    } else if (constituent.claims) {
      claimsCount = constituent.claims.filter(c => c.active).length;
    }

    const description = displayedTags && displayedTags.size && checkFeature('filterTags') ? (
      <div style={{ height: '100%' }}>
        <ConstituentTagList selectedTags={displayedTags} />
      </div>
    ) : (<div style={{ height: '100%' }} />);
    return (
      <List.Item
        style={{ ...this.props.style, cursor: 'pointer', marginTop: 0, marginBottom: 0 }}
        onClick={this.handleClick}
        actions={[
          <Checkbox checked={isSelected} onClick={this.handleClickCheckbox} />,
          <span><Icon type="mail" /> {constituent.email
            ? <a style={{ color: '#4D524D' }} href={`mailto:${constituent.email}`} onClick={this.handleNoop}>{constituent.email}</a>
            : 'No email'
          }</span>,
          <span><Icon type="phone" /> {constituent.mobileNumber
            ? <a href={`tel:${constituent.mobileNumber}`} onClick={this.handleNoop}>{constituent.mobileNumber}</a>
            : 'No mobile'
          }</span>,
          <span>
            <Icon type="idcard" /> <strong>{claimsCount}</strong>
          </span>,
        ]}
      >
        <List.Item.Meta
          style={{ marginTop: 0, marginBottom: 0 }}
          title={title}
          description={description}
        />
      </List.Item>
    );
  }
}

class ConstituentsListInner extends Component {
  constructor(props) {
    super(props);
    this._measurementCache = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 80,
      keyMapper: idx => this.props.rows[idx].constituentId,
      // TODO: Caching on the index assumes that the height of the item won't change once created, which is probably false - trying to patch by setting minHeight
      minHeight: 110,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (_.isNil(this.props.rows) || this.props.rows.length === 0) this._measurementCache.clearAll();
  }

  // Infinite list
  isRowLoaded = ({ index }) => {
    if (!this.props.rows) return false;
    const loaded = this.props.rows.length - 1 >= index;
    return loaded;
  }

  rowRenderer = ({ index, key, style, parent }) => (
    <CellMeasurer
      cache={this._measurementCache}
      key={key}
      parent={parent}
      columnIndex={0}
      rowIndex={index}
    >
      <ConstituentListItem
        key={key}
        data={this.props.rows[index]}
        highlightTags={this.props.highlightTags}
        style={style}
        setSelected={this.props.setKeySelected}
        organizationId={this.props.organizationId}
      />
    </CellMeasurer>
  )

  render() {
    const {
      constituentsTotal,
      rows,
      loadingFinal,
      tagItems,
    } = this.props;

    // Wait for constituents, and tags before render so that measurement
    // accurately measures row height
    const constituentsTotalFinal = tagItems && constituentsTotal ? constituentsTotal : 0;
    const constituentsRowCountFinal = tagItems && rows ? rows.length : 0;

    const vlist = ({
      height,
      isScrolling,
      onChildScroll,
      scrollTop,
      onRowsRendered,
      width,
    }) => (<VList
      autoHeight
      overscanRowCount={2}
      rowCount={constituentsRowCountFinal}
      estimatedRowSize={80}
      rowRenderer={this.rowRenderer}
      rowHeight={this._measurementCache.rowHeight}
      deferredMeasurementCache={this._measurementCache}

      // Fake property name. Any props change triggers re-render
      // https://github.com/bvaughn/react-virtualized#pass-thru-props
      _forceRerender={rows}

      {...{
        height,
        isScrolling,
        onChildScroll,
        scrollTop,
        onRowsRendered,
        width,
      }}
           />);

    const autoSize = ({
      height,
      isScrolling,
      onChildScroll,
      scrollTop,
      onRowsRendered,
    }) => (<AutoSizer disableHeight>
      { ({ width }) => vlist({
        height,
        isScrolling,
        onChildScroll,
        scrollTop,
        onRowsRendered,
        width,
      })}
    </AutoSizer>);

    const infiniteLoader = ({
      height,
      isScrolling,
      onChildScroll,
      scrollTop,
    }) => (<InfiniteLoader
      isRowLoaded={this.isRowLoaded}
      loadMoreRows={this.props.handleLoadNext}
      rowCount={constituentsTotalFinal}
      {...{ height, isScrolling, onChildScroll, scrollTop }}
           >
      { ({ onRowsRendered }) => autoSize({
        height,
        isScrolling,
        onChildScroll,
        scrollTop,
        onRowsRendered,
      })}
    </InfiniteLoader>);

    return (<List
      itemLayout="vertical"
      style={{ paddingTop: '1em' }}
      className="constituents-page-list"
            >
      <WindowScroller>
        {infiniteLoader}
      </WindowScroller>
      <Spin spinning={loadingFinal} style={{ width: '100%', pointerEvents: 'none' }} />
      { !loadingFinal && constituentsTotal === 0
        && <div style={{ textAlign: 'center', opacity: 0.45 }}>
          <div style={{ fontSize: '4em' }}><Icon type="frown-o" /></div>
            No matching person
          </div>
      }
    </List>);
  }
}

@withRouter
@connect(
  state => ({
    getOrganizationConstituentsPageInProgress: state.doc.getIn(['http', 'getOrganizationConstituentsPage', 'inProgress'], false),
    uploadConstituentImportInProgress: state.doc.getIn(['import', 'state'], null),
    addConstituentInProgress: state.doc.getIn(['http', 'addConstituent', 'success'], false),
    constituentsTotal: state.doc.getIn(['constituentsMr', 'lists', 'constituentsPage', 'total'], null),
    constituentIds: state.doc.getIn(['constituentsMr', 'lists', 'constituentsPage', 'items'], null),
    organizationId: state.doc.getIn(['authMr', 'constituent', 'organization', 'id'], null),
    tagItems: state.doc.getIn(['tagsMr', 'items']),
  }),
  actions,
)
class ConstituentsList extends PureComponent {
  state = {
    selectedRowKeys: [],
    lastOffset: 0,
  };

  setKeySelected = (id, selected) => {
    this.setState(state => {
      const selectedRowKeys = state.selectedRowKeys.filter(key => key !== id);
      if (selected) {
        selectedRowKeys.push(id);
      }
      return {
        selectedRowKeys,
      };
    });
  };

  clearSelected = () => {
    this.setState({
      selectedRowKeys: [],
    });
  }

  componentWillMount() {
    this.props.lazyTags();
    this.reloadConstituents();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.constituentIds
      && prevProps.constituentIds !== this.props.constituentIds
    ) {
      if (this.state.lastOffset !== this.props.constituentIds.size) {
        this.setState({ lastOffset: this.props.constituentIds.size });
        this.props.handleOffset(this.props.constituentIds.size);
      }
    }
    const conditionArr = [
      (this.props.uploadConstituentImportInProgress === 'complete'
    && !_.isEqual(
      this.props.uploadConstituentImportInProgress,
      prevProps.uploadConstituentImportInProgress,
    )
      ),
      (
        this.props.addConstituentInProgress
    && !_.isEqual(
      this.props.addConstituentInProgress,
      prevProps.addConstituentInProgress,
    )
      ),
      (this.props.organizationId !== prevProps.organizationId
    || !_.isEqual(
      this.props.pageOptions,
      prevProps.pageOptions,
    )
      ),
    ];
    if (
      conditionArr.includes(true)
    ) this.reloadConstituents();
  }

  reloadConstituents = (props = this.props, state = this.state) => {
    const {
      sortBy, sortOrder, tags, query, orgSmartTag, dashboardType, organizationId,
    } = this.props.pageOptions;
    const pageOptions = {
      order: [sortBy, sortOrder].join(','),
      with_tag_ids: tags.filter(({ with_ }) => with_).map(({ id }) => id).join(','),
      without_tag_ids: tags.filter(({ with_ }) => !with_).map(({ id }) => id).join(','),
      query,
      org_smart: orgSmartTag ? orgSmartTag.id : 0,
      organizationId: typeof (organizationId) !== 'undefined' ? organizationId : null,
      dashboard_type: dashboardType || null,
    };
    if (!pageOptions.with_tag_ids) { delete pageOptions.with_tag_ids; }
    if (!pageOptions.without_tag_ids) { delete pageOptions.without_tag_ids; }
    props.getOrganizationConstituentsFirst(
      pageOptions,
      'constituentsPage',
    );
  }

  handleLoadNext = ({ startIndex, stopIndex }) => {
    if (this.props.getOrganizationConstituentsPageInProgress) {
      return;
    }
    this.props.getOrganizationConstituentsNext('constituentsPage', this.state.lastOffset);
  }

  render() {
    const {
      constituentsTotal,
      constituentIds,
      getOrganizationConstituentsPageInProgress,
      tagItems,
    } = this.props;
    const { selectedRowKeys } = this.state;

    if (!tagItems) {
      return <Spin style={{ width: '100%', pointerEvents: 'none' }} />;
    }

    const highlightTags = tagItems.filter(tag => tag.get('isImportant'));
    let ids = [];
    if (constituentIds) {
      ids = constituentIds.toJS();
    }

    const rows = ids.map(id => ({
      constituentId: id,
      selected: selectedRowKeys.indexOf(id) > -1,
    }));

    return (<div>

      { selectedRowKeys.length
        ? <BulkActionBar
          constituentIds={selectedRowKeys}
          onFinally={() => this.clearSelected()}
          /> : null}

      <ConstituentsListInner
        rows={rows}
        constituentsTotal={constituentsTotal}
        tagItems={tagItems}
        loadingFinal={getOrganizationConstituentsPageInProgress}
        highlightTags={highlightTags}
        handleLoadNext={this.handleLoadNext}
        offset={this.state.lastOffset}
        setKeySelected={this.setKeySelected}
        selectedKeys={selectedRowKeys}
        organizationId={this.props.organizationId}
      />
      { !_.isNil(constituentsTotal)
        && <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div style={{
            position: 'fixed',
            bottom: '16px',
            background: 'white',
            borderRadius: '16px',
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '4px',
            paddingBottom: '4px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
          }}
          >
            <span>{rows.length > constituentsTotal ? constituentsTotal : rows.length}</span>
            <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}> of </span>
            <span>{constituentsTotal}</span>
            <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}> loaded</span>
          </div>
        </div>
      }
    </div>);
  }
}

export {
  ConstituentsList,
};
