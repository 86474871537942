import { Button, Col, Row } from 'antd';
import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';

import { Svg } from '../Svg';
import logo from '../../logos/logo_dark.svg';

require('typeface-montserrat');

const docColor3Darken = '#82412C';

export class GreenPage extends PureComponent {
  render() {
    const { title, subtitle, children } = this.props;
    return (<div>
      <Helmet>
        <style>{'body { background-color: #CAD6DE; }'}</style>
      </Helmet>
      <Row>
        <Col span={8}>
          <Svg
            url={ logo }
            style={{
              width: '180px',
              position: 'relative',
              top: '18px',
              left: '24px',
              cursor: 'pointer',
            }}

            onClick={() => {
              document.location = 'https://weareoho.com/';
            }}
          />
        </Col>
        <Col span={8} offset={6} style={{ justifyContent: 'center' }} push={4}>
          <Button
            className='doc-header-button'
            onClick={() => {
              document.location = 'https://weareoho.com/';
            }}
          >
                Visit www.weareoho.com
          </Button>
        </Col>
      </Row>
      <Row type='flex' style={{ marginTop: '100px' }}>
        <Col xs={{ span: 24, order: 2 }} lg={{ span: 12, order: 1 }} style={{ display: 'flex', justifyContent: 'center', marginTop: '100px', marginBottom: '100px' }}>
          <div className='doc-login-description' >
            <p>Oho provides managers across Australia with continuous verification of staff and volunteers, enabling the ability to demonstrate compliance to others.</p>
            <Button style={{ width: '160px', height: '40px', fontWeight: '500' }} onClick={() => {
              document.location = 'https://weareoho.com/';
            }}
            >Find out more</Button>
          </div>
        </Col>
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 2 }} style={{ display: 'flex', justifyContent: 'center' }}>
          <div className='doc-login-container'>
            <h1 style={{
              color: docColor3Darken,
              fontSize: '1.6rem',
              fontWeight: 500,
              alignSelf: 'center',
            }}
            >{ title }</h1>
            { subtitle && <div style={{ paddingBottom: '4em' }}>{ subtitle }</div> }
            { children }
          </div>
        </Col>
      </Row>
    </div>);
  }
}
