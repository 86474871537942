import { simpleAjaxDispatch } from './http';

export function fetchWebhooks(organizationId) {
  return simpleAjaxDispatch(
    `/webhooks?organization_id=${organizationId}`,
    'fetchWebhooks',
  );
}

export function fetchWebhookLogs(webhookId) {
  return simpleAjaxDispatch(
    `/webhooks/${webhookId}/events`,
    'fetchWebhookLogs',
    { responseSnake: false }, // de-snake messes with headers
  );
}

export function getWebhook(webhookId) {
  return simpleAjaxDispatch(
    `/webhooks/${webhookId}`, 'getWebhook',
  );
}

export function addWebhook(json) {
  return simpleAjaxDispatch(
    '/webhooks', 'addWebhook',
    { method: 'POST', json },
  );
}

export function deleteWebhook(webhookId) {
  return simpleAjaxDispatch(
    `/webhooks/${webhookId}`, 'deleteWebhook',
    { method: 'DELETE' },
  );
}

export function patchWebhook(webhookId, json) {
  return simpleAjaxDispatch(
    `/webhooks/${webhookId}`, 'patchWebhook',
    { method: 'PATCH', json, meta: { webhookId } },
  );
}
/* export function addTag(json) {
  return simpleAjaxDispatch(
    '/tags', 'addTag',
    { method: 'POST', json }
  )
}
export function patchTag(tagId, json) {
  return simpleAjaxDispatch(
    `/tags/${tagId}`,
    'patchTag',
    { method: 'PATCH', json },
  )
}
export function deleteTag(tagId) {
  return simpleAjaxDispatch(
    `/tags/${tagId}`,
    'deleteTag',
    { method: 'DELETE' },
  )
}
*/
