import _ from 'lodash';
import React, { PureComponent } from 'react';
import { fromJS } from 'immutable';
import { withRouter } from 'react-router';

import { connect } from 'react-redux';
import {
  Icon, Input, List, Popover, Tag,
} from 'antd';

import { ActionText } from '../../elements';
import { ConstituentTagList } from '../../ConstituentTagList';
import { ConstituentTag } from '../../ConstituentTag';

import { parseQsHash, fullTagLabel, historyPushPath } from '../../../utils';

const EMPTY_OBJECT = fromJS({});

@withRouter
@connect(state => ({
  tagItems: state.doc.getIn(['tagsMr', 'items']),
  tagsByIdent: state.doc.getIn(['tagsMr', 'byIdent']),
}))
class FilterTagList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tagFilterQuery: '',
    };
  }

  getTagFiltersFromQs = (props = this.props) => {
    const qsData = parseQsHash(props.location.hash);

    // No filters in the QS
    if (!qsData.filters) return [];

    const tagItems = props.tagItems ? props.tagItems.toJS() : [];

    const filtersStr = qsData.filters;
    const filterArr = filtersStr.split(',').map(str => {
      const strWith = str[0] !== '!';
      const strId = strWith ? str : str.substr(1);
      const tag = this.getTagFromQsIdent(tagItems, strId);

      // with_ is with/without the tag
      return { with_: strWith, strId, tag };
    });
    return filterArr;
  }

  setTagFiltersInQs = (filterArr, { replace = false } = {}) => {
    historyPushPath(
      this.props.history,
      this.props.location,
      this.props.match.path,
      {
        filters: filterArr.map(
          filter => `${filter.with_ ? '' : '!'}${filter.strId}`,
        ).join(','),
      },
      {
        replace,
      },
    );
    this.reloadConstituents();
  }

  checkTagFilterMatch(ident, tagFilter) {
    return (
      tagFilter.id === ident
      || tagFilter.strId === ident
      || tagFilter.strId === `${ident}`
    );
  }

  addTagFilter = (ident, with_) => {
    const currentTags = this.props.tags;

    // Already exists
    if (currentTags.some(currentTag => (
      this.checkTagFilterMatch(ident, currentTag)
      && currentTag.with_ === with_ // with is with/without the tag
    ))) return;

    const tag = this.props.tagsByIdent.get(ident, EMPTY_OBJECT).toJS();

    this.props.setTags([...currentTags, { with_, role: tag.role, id: tag.id }]);
  }

  handleRemoveTagFilter = removeId => {
    const currentTags = this.props.tags;
    if (!this.props.tagItems) {
      return; // Would be odd if this is possible
    }

    const removeTagItem = this.props.tagItems.toJS()
      .find(({ id }) => id === removeId);
    this.props.setTags(currentTags.filter(({ role }) => role !== removeTagItem.role));
  }

  handleFilterSearchKeyUp = ev => {
    this.setState({ tagFilterQuery: ev.target.value.toLowerCase() });
  }

  getQsIdentFromTag = tag => (tag.role ? tag.role : `${tag.id}`)

  getTagFromQsIdent = (tags, ident) => {
    const intIdent = parseInt(ident, 10);
    const { field, match } = _.isNan(intIdent)
      ? { field: 'role', match: ident }
      : { field: 'id', match: intIdent };
    const tag = tags.find(t => t[field] === match);
    return tag;
  }

  render() {
    const {
      tags: appliedTags,
    } = this.props;
    const { tagFilterQuery } = this.state;

    const tagItems = this.props.tagItems ? this.props.tagItems.toJS() : [];

    const appliedTagFiltersIds = appliedTags.map(({ id }) => id);

    // Tags that aren't applied in the QS
    const unappliedTagFilters = tagItems
      .filter(tag => appliedTagFiltersIds.indexOf(tag.id) < 0)
      .map(tag => ({
        ...tag,
        qsIdent: this.getQsIdentFromTag(tag),
      }));

    // Filter out unwanted tags
    const applyableTagFilters = unappliedTagFilters.filter(tag => {
      if (tag.role === 'smart') return false;
      if (tag.role === 'smart.active') return false;
      if (tag.role === 'smart.active.inactive') return false;
      if (tag.role === 'smart.birth_date') return false;
      if (tag.role === 'smart.birth_date.blank') return false;
      if (tag.role === 'smart.email') return false;
      if (tag.role === 'smart.email.blank') return false;
      if (tag.role === 'smart.mobile_number') return false;
      if (tag.role === 'smart.mobile_number.blank') return false;
      if (tag.role === 'fixture') return false;
      if (tag.role && tag.role.startsWith('organization')) return false;
      const fullLabel = fullTagLabel(tag);
      if (!fullLabel || fullLabel.toLowerCase().indexOf(tagFilterQuery) < 0) return false;
      return true;
    });

    // Sort tags org-first, then alphabetically
    applyableTagFilters.sort((left, right) => {
      if (left.role && !right.role) return 1;
      if (!left.role && right.role) return -1;
      if (left.role && right.role) {
        if (left.role > right.role) return 1;
        if (left.role < right.role) return -1;
        return 0;
      }
      if (left.label > right.label) return 1;
      if (left.label < right.label) return -1;
      return 0;
    });

    return (
      <ConstituentTagList
        selectedTags={fromJS(appliedTags.map(
          tagFilter => ({
            ...tagFilter,
            ...tagFilter.with_ ? {} : { prefix: <strong>Not </strong> },
          }),
        ))}
        tagProps={{ closable: true, onClose: this.handleRemoveTagFilter }}
      >
        <Popover
          trigger="click"
          placement="bottomLeft"
          onVisibleChange={this.handleFilterSearchVisibility}
          content={<div>
            <Input
              onKeyUp={this.handleFilterSearchKeyUp}
            />
            <List
              dataSource={applyableTagFilters}
              renderItem={tag => (<List.Item
                actions={[
                  <ActionText onClick={() => this.addTagFilter(tag.qsIdent, true)}>with</ActionText>,
                  <ActionText onClick={() => this.addTagFilter(tag.qsIdent, false)}>without</ActionText>,
                ]}
                                  >
                <ConstituentTag tagId={tag.id} />
                { /* ugly hack to force list item into no-flex mode */}
              </List.Item>)
              }
              style={{ maxHeight: '600px', overflow: 'scroll' }}
            />
          </div>}
        >
          <Tag style={{ background: '#fff', borderStyle: 'dashed' }}>
            <Icon type="plus" /> More filters
          </Tag>
        </Popover>
      </ConstituentTagList>
    );
  }
}

export {
  FilterTagList,
};
