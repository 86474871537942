import React, { Fragment, PureComponent } from 'react';

import {
  NavLink,
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';

import {
  Tooltip,
} from 'antd';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

/*
 * ReactRouterTabs uses the ant.design classes for tab bars, but mounted with
 * react router routes.
 *
 * props.tabs is []{
 *  label: The element or string to use inside the tab label
 *  routePath: The path on top of the already mounted path
 *  Component: What to render when active
 * }
 * props.childProps will be sent to all children
 */
@withRouter
class ReactRouterTabs extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      historyLast: '',
      historyNext: '',
    };

    this.inkBarRef = React.createRef();
    this.currentPathRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    this.setInkBarPosition();
  }

  componentDidMount() {
    this.setInkBarPosition();
  }

  setInkBarPosition = () => {
    if (!this.currentPathRef.current || !this.inkBarRef.current) {
      return;
    }
    const srcElement = this.currentPathRef.current.parentElement;
    const left = srcElement.getBoundingClientRect().left - srcElement.parentElement.getBoundingClientRect().left;
    const destStyle = this.inkBarRef.current.style;
    destStyle.display = 'block';
    destStyle.width = `${srcElement.offsetWidth}px`;
    destStyle.transform = `translate3d(${left}px, 0px, 0px)`;
  }

  static getDerivedStateFromProps(props, state) {
    return {
      historyNext: props.location.pathname,
      historyLast: state.historyNext,
    };
  }

  render() {
    const {
      match: {
        path: matchPath,
        url: matchUrl,
      },
      tabs,
      childProps,
      location,
    } = this.props;

    const navLinks = tabs.map(({ routePath, label, enabled = true, disabledReason = '' }) => {
      if (!enabled) {
        return (
          <div key={routePath} className='ant-tabs-tab ant-tabs-tab-disabled' >
            {disabledReason ? (
              <Tooltip title={disabledReason}>
                {label}
              </Tooltip>
            ) : label}
          </div>
        );
      }

      return (
        <NavLink key={routePath} to={{
          pathname: matchUrl + routePath,
          search: location.search,
          hash: location.hash,
        }} className='ant-tabs-tab' activeClassName='ant-tabs-tab-active'
        >
          <div ref={matchUrl + routePath === location.pathname && this.currentPathRef}>
            {label}
          </div>
        </NavLink>
      );
    });

    const lastIndex = tabs.findIndex(({ routePath }) => matchUrl + routePath === this.state.historyLast);

    const routes = tabs.map(({ routePath, Component }, thisIndex) => (
      <Route key={routePath} path={matchPath + routePath} children={({ match }) => (
        <Fragment>
          {match && (
            <ReactCSSTransitionGroup
              transitionName={`tabSlideIn-${thisIndex > lastIndex ? 'left' : 'right'}`}
              transitionAppear={true}
              transitionAppearTimeout={300}
              transitionEnter={false}
              transitionLeave={false}
            >
              <div style={{ width: '100%' }} key={routePath}>
                <Component {...childProps }/>
              </div>
            </ReactCSSTransitionGroup>
          )}
        </Fragment>
      )}
      />
    ));
    return (<Fragment>
      <div className='ant-tabs-bar ant-tabs-top'>
        <div className='ant-tabs-nav'>
          <div>
            {navLinks}
          </div>
          <div className='ant-tabs-ink-bar ant-tabs-ink-bar-animated'
            style={{ bottom: 0 }}
            ref={this.inkBarRef}
          />
        </div>
      </div>
      <div className='tabSlideIn-wrap'>
        <Switch>
          <Redirect exact from={matchPath} to={{
            pathname: `${matchUrl}/details`,
            search: location.search,
            hash: location.hash,
          }}
          />
          {routes}
        </Switch>
      </div>
    </Fragment>);
  }
}

export {
  ReactRouterTabs,
};
