export const npcWorkflowStagesSteps = [
  {
    title: 'Check Initiated',
    description: 'A ‘Remote Link’ has been sent to the applicant, but they have not started the application yet.',
  },
  {
    title: 'Application In Progress',
    description: 'Applicant must present their original documents to their employer for verification. After ensuring the accuracy of all details, the applicant can proceed to release the police check by selecting the appropriate options in the menu.',
  },
  {
    title: 'Review and Approve',
    description: "Choosing 'Upload my Documents' sets the status as 'Review and Approve', requiring certified documents that match the selected ones in the police check application.",
  },
  {
    title: 'Returned Flagged / Held Check / Check Flagged',
    description: 'When a check is flagged, it indicates that the person\'s name has been matched. However, it does not necessarily imply that the individual being checked has a Disclosable Court Outcome. We must wait for the police jurisdiction to process and send the completed report, which can take 2 to 15 business days.',
  },
  {
    title: 'Returned OK',
    description: 'After processing, the police check results are ready for download.',
  },
];
