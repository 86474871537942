import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
  Badge,
  Icon,
  Menu,
} from 'antd';
import { withCurrentUser } from '../../whoami';
import { checkFeature, isPCTrackingEnabled } from '../../utils';
import * as actions from '../../actions';
import { determineCredsStatus } from '../fields/DoCEmail';

@withCurrentUser
class MenuBarComponent extends PureComponent {
  state = {
    loaded: false,
    showReportMenuItem: JSON.parse(window.localStorage.getItem('reportMenuToggle')) || false,
  };

  componentWillMount() {
    this.props.fetchCurrentOrganization();
    if (!this.props.organizationId) return;
    if (!this.state.loaded) this.props.fetchReportsSummary(this.props.organizationId);
  }

  componentWillUpdate() {
    if (!this.props.organizationId) return;
    if (!this.state.loaded) {
      this.props.fetchReportsSummary(this.props.organizationId);
      this.setState({ loaded: true });
    }
    const reportsSummaryDataJs = this.props.reportsSummaryData ? this.props.reportsSummaryData.toJSON() : {};
    const hasReports = reportsSummaryDataJs && Object.keys(reportsSummaryDataJs).length > 0;
    window.localStorage.setItem('reportMenuToggle', this.state.loaded && hasReports && !this.state.showReportMenuItem);
    this.setState({ showReportMenuItem: hasReports });
  }

  getProviderSettingsErrors = (userId, inCreds) => {

    try {
      const errors = {};
      const credsTypes = ['nsw', 'qldblue', 'sa', 'ndwsUserCreds'];

      if (inCreds.qld && inCreds.qld[userId]) {
        inCreds.qldblue = inCreds.qld[userId];
      }

      if (inCreds.ndws && inCreds.ndws[userId]) {
        inCreds.ndwsUserCreds = inCreds.ndws[userId];
      }

      const generalErrors = credsTypes.filter(item => {
        const { color } = determineCredsStatus(item, inCreds);
        return color === 'red' ? item : null;
      });

      if (generalErrors.length) errors.general = generalErrors;

      if ("npc" in inCreds) {
        const { npc } = inCreds;
        if (npc.hasOwnProperty('paperworkSignedTs') && npc.paperworkSignedTs && !npc.hasOwnProperty('industry')) errors.npc = ['industry not found'];
      }

      if (Object.keys(errors).length) {
        const count = Object.values(errors).reduce((acc, item)=> {
          acc = acc + item.length
          return acc;
        }, 0)

        return {
          errCount : count,
          errors,
        }
      }

      return {
        errCount: 0,
        errors,
      };
      
    } catch (error) {
      return {};
    }
  }

  RenderMenuItemWithError = (icon, errCount, errors, key, title, collapsed) => {
    try {
      return (
        <>
         {errCount && key in errors && !collapsed ? <Icon style={{ marginLeft: '-30px', marginRight: '15px', color: '#AA0000' }} type='exclamation-circle'/> : null }         
         <Icon type={icon} />
         <span className="doc-menu-text">{title}</span> 
         {errCount && key in errors && collapsed ? <Icon style={{ marginLeft: '20px',   color: '#AA0000' }} type='exclamation-circle'/> : null }
        </>
       )      
    } catch (error) {
      return (
        <>
         <Icon type={icon} />
         <span className="doc-menu-text">{title}</span> 
        </>
       )
    }
  }

  render() {
    const {
      currentUser: { isAdmin, managedBilling, emailVerified },
      pathname,
      collapsed,
      icrTrackingEnabled,
      institutionsCredentials,
      userId,
    } = this.props;

    const selectedMenuItems = pathname === '/' ? ['dashboard'] : [pathname.split('/')[1]];
    const defaultOpenKeys = selectedMenuItems[0]
      .toLowerCase()
      .indexOf('settings') >= 0 ? ['settings-wrapper'] : [];

    const pcTrackingEnabled = isPCTrackingEnabled(institutionsCredentials);
    const convertedInstitutionsCreds = institutionsCredentials && institutionsCredentials.toJSON() || {};
    const {errCount = 0, errors = {}} = this.getProviderSettingsErrors(userId, convertedInstitutionsCreds);

    return (
      <Menu
        theme="dark"
        mode="inline"
        style={{
          backgroundColor: '#C88242',
          color: 'black',
          padding: 'none',
        }}

        selectedKeys={ selectedMenuItems }
        defaultOpenKeys={ defaultOpenKeys }
        onSelect={ this.props.handleMenuSelect }
        onOpenChange={ this.handleMenuOpenChange }
      >
        <Menu.ItemGroup
          title={
            collapsed
              ? null
              : <span style={{ fontWeight: 'bold', color: 'black' }}>Your organisation</span>
          }
        >
          <Menu.Item key="dashboard" className="doc-menu-item">
            <Icon type="appstore-o" />
            <span className="doc-menu-text">Dashboard</span>
          </Menu.Item>
          {checkFeature('reportsDashboard')
            && checkFeature('filterTagLibrary')
            && this.state.showReportMenuItem
            && <Menu.Item key="reports" className="doc-menu-item">
              <Icon type="dashboard" />
              <span className="doc-menu-text">Reports</span>
            </Menu.Item>}
          <Menu.Item key="constituents" className="doc-menu-item">
            <Icon type="contacts" />
            <span className="doc-menu-text">People</span>
          </Menu.Item>
          { checkFeature('pcTracking') && pcTrackingEnabled && <Menu.Item key="pcTracking" className="doc-menu-item">
            <Icon type="search" />
            <span className="doc-menu-text">PC Tracking</span>
          </Menu.Item>}
          { checkFeature('icrTracking') && icrTrackingEnabled && <Menu.Item key="icrTracking" className="doc-menu-item">
            <Icon type="line-chart" />
            <span className="doc-menu-text">ICR Tracking</span>
          </Menu.Item>}
          {emailVerified && <Menu.SubMenu
            key="settings-wrapper"
            style={{ color: 'black' }}
            title={ <span>
              <Icon type="setting" />
              <span className="doc-menu-text">Settings</span>
              { errCount ? <>
                <Badge count={errCount} title={null}
                  style={{
                    marginTop:`${collapsed ? '-4px' : 'none'}`, paddingTop: '2px', marginLeft: `${collapsed ? '5px' : '25px'}`, pointerEvents: 'none', fontWeight: 700, backgroundColor: '#AA0000', color: 'white', paddingBottom:'2px',
                    boxShadow: 'none'
                  }}
                />
              </> : null}
            </span> }
                            >
            <Menu.Item key="settings" className="doc-menu-item">
              <Icon type="profile" />
              <span className="doc-menu-text">General</span>
            </Menu.Item>
            <Menu.Item key="providers" className="doc-menu-item">
             { this.RenderMenuItemWithError( 'deployment-unit', errCount, errors, 'general' ,'Provider Settings', collapsed)}
            </Menu.Item>
            <Menu.Item key="tagSettings" className="doc-menu-item">
              <Icon type="tags-o" />
              <span className="doc-menu-text">Tags</span>
            </Menu.Item>
            <Menu.Item key="webhookSettings" className="doc-menu-item">
              <Icon type="api" />
              <span className="doc-menu-text">Webhooks</span>
            </Menu.Item>
            { checkFeature('npcSettings') && <Menu.Item key="npcSettings" className="doc-menu-item">
              { this.RenderMenuItemWithError( 'alert', errCount, errors, 'npc' ,'Police Check', collapsed)}
            </Menu.Item>}
            <Menu.Item key="samlSettings" className="doc-menu-item">
              <Icon type="safety-certificate" />
              <span className="doc-menu-text">Security</span>
            </Menu.Item>
            <Menu.Item key="defineTypeSettings" className="doc-menu-item">
              <Icon type="idcard" />
              <span className="doc-menu-text">Define Types</span>
            </Menu.Item>
            { checkFeature('orgDataSharing')
            && <Menu.Item key="dataSharing" className="doc-menu-item">
              <Icon type="share-alt" />
              <span className="doc-menu-text">Community</span>
            </Menu.Item>
            }
            {managedBilling && <Menu.Item key="billingSettings" className="doc-menu-item">
              <Icon type="credit-card" />
              <span className="doc-menu-text">Billing</span>
            </Menu.Item>
            }
          </Menu.SubMenu>}
        </Menu.ItemGroup>
        <Menu.Divider style={{
          backgroundColor: '#C88242',
        }}
        />
        { isAdmin && (
          <Menu.ItemGroup>
            <Menu.SubMenu
              title={collapsed
                ? <span><Icon type='bug'/></span>
                : <span><Icon type="bug"/><span style={{ fontWeight: 'bold', color: 'black' }}>Administration</span></span>
              }
            >
              { checkFeature('controlCentre')
              && <Menu.Item key="admin/control" className="doc-menu-item">
                <Icon type="control" />
                <span className="doc-menu-text">Control Centre</span>
              </Menu.Item>}
              <Menu.Item key="admin/scheduled" className="doc-menu-item">
                <Icon type="clock-circle-o" />
                <span className="doc-menu-text">Scheduled tasks</span>
              </Menu.Item>
              <Menu.Item key="admin/schedulerblock" className="doc-menu-item">
                <Icon type="pause" />
                <span className="doc-menu-text">Scheduler blocks</span>
              </Menu.Item>
              <Menu.Item key="admin/impersonate" className="doc-menu-item">
                <Icon type="team" />
                <span className="doc-menu-text">Impersonate user</span>
              </Menu.Item>
              <Menu.Item key="admin/emailblock" className="doc-menu-item">
                <Icon type="mail" />
                <span className="doc-menu-text">Email blocks</span>
              </Menu.Item>
              <Menu.Item key="admin/deployment" className="doc-menu-item">
                <Icon type="deployment-unit" />
                <span className="doc-menu-text">Deployment</span>
              </Menu.Item>
              <Menu.Item key="admin/manualResult" className="doc-menu-item">
                <Icon type="build" />
                <span className="doc-menu-text">Manual Result</span>
              </Menu.Item>
              { checkFeature('manualResultInjector')
                && <Menu.Item key="admin/debug" className="doc-menu-item">
                  <Icon type="tool" />
                  <span className="doc-menu-text">Debug</span>
                </Menu.Item>
              }
            </Menu.SubMenu>
          </Menu.ItemGroup>
        )
        }
      </Menu>
    );
  }
}

const MenuBar = connect(
  state => ({
    reportsSummaryData: state.doc.getIn(['http', 'reportsSummary', 'data'], null),
    reportsSummaryDataFailed: state.doc.getIn(['http', 'reportsSummary', 'failed'], false),
    reportsSummaryDataInProgress: state.doc.getIn(['http', 'reportsSummary', 'inProgress'], true),
    organizationId: state.doc.getIn(['authMr', 'organizationId'], null),
    institutionsCredentials: state.doc.getIn(['authMr', 'constituent', 'organization', 'institutionsCredentials'], null),
    icrTrackingEnabled: state.doc.getIn(['http', 'fetchCurrentOrganization', 'data', 'settings', 'screening', 'enrichmentRequestsTracking'], false),
    userId: state.doc.getIn(['authMr', 'id'], null),
  }),
  actions,
)(MenuBarComponent);

export { MenuBar };
