import { Form, Input } from 'antd';
import React, { PureComponent } from 'react';
import { NON_ALPHA_NUM_SLASH_RE } from './shared';

export class NtWwcFormItems extends PureComponent {
  static slug = 'ntwwc';

  static placeholder = '';

  static identLabel = 'Clearance Notice No:';

  static getSubmissionData(checkData, constituent) {
    return {
      fullName: constituent && constituent.get('fullName'),
      origin: 'doc',
    };
  }

  static regNumberGetValueFromEvent(evt) {
    return evt.target.value.replace(NON_ALPHA_NUM_SLASH_RE, '');
  }

  static disabledDate(current) {
    return current && current.valueOf() < Date.now();
  }

  render() {
    const { form, disabled, rules } = this.props;
    const { getFieldDecorator } = form;

    const {
      slug, identLabel, placeholder,
      regNumberGetValueFromEvent,
    } = this.constructor;

    return (<div>

      <Form.Item label={identLabel}>
        {getFieldDecorator(`${slug}.identifier`, {
          getValueFromEvent: regNumberGetValueFromEvent,
          rules: [
            {
              required: true,
              whitespace: true,
              message: `${identLabel} is required`,
            },
            ...(rules || []),
          ],
        })(
          <Input
            placeholder={placeholder}
            disabled={disabled}
          />,
        )
        }
      </Form.Item>
    </div>);
  }
}
