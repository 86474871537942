import { fromJS } from 'immutable';

import { Reducer } from './base';

function cleanNumber(value) {
  if (!value) return value;
  if (value.startsWith('+61')) value = value.substr(3);
  if (value.startsWith('4')) value = `0${value}`;
  return value;
}

function getStatusColor(constituentJS) {
  let statusColor = 'lightgray';
  for (const claim of constituentJS.claims || []) {
    if (!claim.active) {
      continue;
    }
    if (claim.statusColor === 'red') {
      statusColor = 'red';
      break; // Nowhere to go after red
    } else if (claim.statusColor === 'yellow') {
      statusColor = 'yellow';
      break; // Nowhere to go after yellow
    } else {
      statusColor = 'green';
    }
  }
  return statusColor;
}

/* Takes an API constituent Dump schema and turns it into an immutable as we
  * want it presented */
function prepareConstituent(asJS) {
  const constituent = fromJS(asJS);
  return constituent.merge({
    mobileNumber: cleanNumber(constituent.get('mobileNumber', null)),
    alternateNumber: cleanNumber(constituent.get('alternateNumber', null)),
    statusColor: constituent.get('statusColor', getStatusColor(asJS)),
    alternateName: constituent.get('alternateName', null),
  });
}

export class ConstituentsMr extends Reducer {
  constructor() {
    super('constituentsMr');
  }

  handleSetConstituentsFilterAction(prevState, state, action) {
    const { filterHash, namespace } = action;

    return state.setIn(['lists', namespace], fromJS({
      fetching: true,
      items: [],
      filterHash,
    }));
  }

  handleSetOwnerAction(prevState, state, action) {
    return state.mergeIn(['mfaOwner'], { id: action.id });
  }

  handleHttpUpdatedAction(prevState, state, action) {
    if (!action.success) return state;

    if (
      action.group === 'patchConstituent'
      || action.group === 'addConstituent'
      || action.group === 'getConstituent'
    ) {
      state = state.setIn(['byIdFull', action.meta.constituentId], prepareConstituent(action.data));
    } else if (
      action.group === 'addUser'
      || action.group === 'deleteUser'
    ) {
      const update = action.group === 'addUser' ? action.data : null;
      state = state.setIn(['byIdFull', action.meta.constituentId, 'user'], fromJS(update));
    } else if (
      action.group === 'getOrganizationConstituentsPage'
      || action.group === 'bulkPatchConstituents'
    ) {
      action.data.items.forEach(constituent => {
        state = state.setIn(['byIdPartial', constituent.id], prepareConstituent(constituent));
      });
    }

    if (
      action.group === 'getOrganizationConstituentsPage'
    ) {
      const { items, ...meta } = action.data;
      const { namespace, filterHash } = action.meta;

      const newItems = items.map(({ id }) => id);

      let list = state.getIn(['lists', namespace], fromJS({
        fetching: true,
        items: [],
        filterHash,
      }));

      // Discard results for an outdated hash
      if (list.get('filterHash') === filterHash) {
        list = list.merge({
          fetching: false,
          items: list.get('items').concat(newItems),
          ...meta,
        });

        state = state.setIn(['lists', namespace], list);
      }
    }

    return state;
  }
}
