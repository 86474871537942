import React, { PureComponent } from 'react';

export class Svg extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    fetch(this.props.url)
      .then(resp => resp.text())
      .then(data => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(data, 'image/svg+xml');
        this.setState({ content: xmlDoc.getElementsByTagName('svg')[0].outerHTML });
      })
      .catch(err => console.error('Error loading SVG:', err));
  }

  render() {
    const { url, ...otherProps } = this.props;
    return (<div
      dangerouslySetInnerHTML={{ __html: this.state.content }}
      { ...otherProps }
            />);
  }
}
