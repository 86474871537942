import { Tag, Input, Tooltip, Icon } from 'antd';
import React, { PureComponent } from 'react';

export class CheckPurposePresetGroup extends PureComponent {
  constructor(props) {
    super(props);
    const value = props.value || [];
    this.state = {
      checkPurposePresets: value,
      inputVisible: false,
      inputValue: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    const { value } = nextProps;
    this.setState({ checkPurposePresets: value });
  }


  handleClose = removedTag => {
    const checkPurposePresets = this.state.checkPurposePresets.filter(tag => tag !== removedTag);
    this.setState({ checkPurposePresets });
    const { onChange } = this.props;
    if (onChange) {
      // This will provide the form with changes
      onChange(checkPurposePresets);
    }
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let { checkPurposePresets } = this.state;
    if (inputValue && checkPurposePresets.indexOf(inputValue) === -1) {
      checkPurposePresets = [...checkPurposePresets, inputValue];
    }
    this.setState({
      checkPurposePresets,
      inputVisible: false,
      inputValue: '',
    });
    const { onChange } = this.props;
    if (onChange) {
      // This will provide the form with changes
      onChange(checkPurposePresets);
    }
  };

  saveInputRef = input => (this.input = input); // eslint-disable-line no-return-assign


  render() {
    const { checkPurposePresets, inputVisible, inputValue } = this.state;
    return (
      <div style={{ maxWidth: '550px' }}>
        {checkPurposePresets.map((tag, index) => {
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag key={tag} closable={true} onClose={() => this.handleClose(tag)}>
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {inputVisible && (
          <Input
            ref={this.saveInputRef}
            type="text"
            size="small"
            style={{ width: 78 }}
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
          />
        )}
        {!inputVisible && (
          <Tag onClick={this.showInput} style={{ background: '#fff', borderStyle: 'dashed' }}>
            <Icon type="plus" /> New Check Purpose
          </Tag>
        )}
      </div>
    );
  }
}
