import React, { PureComponent } from 'react';
import moment from 'moment';
import {
  Button, Table, Tooltip, DatePicker, Row, Col, Modal, Checkbox, Icon,
} from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { BreadcrumbLevel } from '../../BreadcrumbLevel';
import { withContentLayout } from '../../layout/Layout';

import * as actions from '../../../actions';
import { WorkflowStages } from './WorkflowStages';
import { FilterSearch } from '../ConstituentListPage/FilterSearch';

const { confirm } = Modal;


const ABBR_PC = {
  npc: 'National Police Check',
  ipc: 'International Police Check',
};

const PC_PENDING_DECISION = 'Pending';
const PC_ATTACHMENT_LABEL = 'Police Check Result';

const PC_TYPE_FILTERS = [
  {
    text: 'IPC',
    value: 'ipc',
  },
  {
    text: 'NPC',
    value: 'npc',
  },
];

const PC_STATUS_FILTERS = [
  {
    text: 'Check Initiated',
    value: 'Check Initiated',
  },
  {
    text: 'Held Check',
    value: 'Held Check',
  },
  {
    text: 'Review and Approve',
    value: 'Review and Approve',
  },
  {
    text: 'Application in Progress',
    value: 'Application in Progress',
  },
  {
    text: 'Returned Flagged',
    value: 'Returned Flagged',
  },
  {
    text: 'Check Flagged',
    value: 'Check Flagged',
  },
  {
    text: 'Returned OK',
    value: 'Returned OK',
  },
];

const PoliceCheckResult = props => {
  const meta = props.meta || {};
  const attachments = props.attachments || [];
  const certificate = attachments.find(el => el.filename === PC_ATTACHMENT_LABEL && el.active);

  return (
    <Row type='flex' gutter={8}>
      <Col>
        {meta.result}
      </Col>
      { certificate && meta.result && <Col>
        <Tooltip title={PC_ATTACHMENT_LABEL}>
          <a href={certificate.download} alt={PC_ATTACHMENT_LABEL} target="_blank" rel="noopener noreferrer">
            <Icon type='audit' />
          </a>
        </Tooltip>
      </Col>}
    </Row>
  );
};

@withContentLayout
class PcTrackingPageComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: '',
      fromDate: null,
      toDate: null,
      myRequests: true,
      includeCompletedItems: false,
      filtersInfo: {},
      sorterInfo: {},
      pagination: {},
    };
  }

  setQuery = searchQuery => {
    this.setState({ searchQuery });
  }

  fetchPcAccreditationsNext = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current || 0;
    if (sorter.order === 'ascend') sorter.order = 'asc';
    else sorter.order = 'desc';
    this.setState({
      pagination: pager,
      filtersInfo: filters,
      sorterInfo: sorter,
    });
    const opts = {
      constituent_name: this.state.searchQuery || null,
      from_date: this.state.fromDate || null,
      to_date: this.state.toDate || null,
      my_request: this.state.myRequests,
      completed_items: this.state.includeCompletedItems,
      type: filters.type || 'npc,ipc',
      status: filters.status,
      order: `id,${sorter.order}`,
      offset: (pagination.current * 10) - 10 || 0,
    };
    if (!opts.from_date) delete opts.from_date;
    if (!opts.to_date) delete opts.to_date;
    if (_.isEmpty(opts.type)) delete opts.type;
    if (_.isEmpty(opts.status)) delete opts.status;
    this.props.fetchPcAccreditations(opts);
  };

  getContent = (record, action) => (
    <>
      <p>You are confirming that the Police Check result of <b>{record.pcMeta.result}</b> for <b>{record.name}</b> is {action === 'Accept' ? <b>suitable</b> : <b>NOT suitable</b>} when compared against your organizational policies.</p>
      <p>Acknowledging this result confirms this person is {action === 'Accept' ? 'suitable' : 'NOT suitable'} to continue their current duties.</p>
    </>
  );


  handleApproveRequest = async record => {
    const handleOk = async () => {
      await this.props.patchPcAccreditation(record.id, {
        decision: 'Suitable',
      });
      this.props.fetchPcAccreditations();
    };
    confirm({
      title: 'Do you want to send this decision request?',
      content: this.getContent(record, 'Accept'),
      onOk() {
        handleOk();
      },
    });
  }

  handleRejectRequest = async record => {
    const handleOk = async () => {
      await this.props.patchPcAccreditation(record.id, {
        decision: 'Not Suitable',
      });
      this.props.fetchPcAccreditations();
    };
    confirm({
      title: 'Do you want to send this decision request?',
      content: this.getContent(record, 'Reject'),
      onOk() {
        handleOk();
      },
    });
  }

  disableFuturedDate = current => current && current > moment().endOf('day');

  onDateChange = (dates, datesStr) => {
    if (!datesStr && !datesStr.length === 2) return;
    const [fromDate, toDate] = datesStr;
    const fromDateUtc = fromDate ? moment(fromDate).startOf('day').toDate().toISOString() : null;
    const toDateUtc = toDate ? moment(toDate).endOf('day').toDate().toISOString() : null;
    this.setState({ fromDate: fromDateUtc, toDate: toDateUtc });
  }

  onMyRequestChange = ev => {
    this.setState({ myRequests: ev.target.checked });
  }

  onCompletedItemsChange = ev => {
    this.setState({ includeCompletedItems: ev.target.checked });
  }

  handleRefresh = query => {
    const { filtersInfo, sorterInfo: { order } } = this.state;
    const searchQuery = query || this.state.searchQuery;
    const opts = {
      from_date: this.state.fromDate || null,
      to_date: this.state.toDate || null,
      constituent_name: searchQuery || null,
      my_request: this.state.myRequests,
      completed_items: this.state.includeCompletedItems,
      type: filtersInfo.type || '',
      status: filtersInfo.status || '',
      order: order ? `id,${order}` : 'id,desc',
    };
    if (!opts.from_date) delete opts.from_date;
    if (!opts.to_date) delete opts.to_date;
    if (_.isEmpty(opts.type)) delete opts.type;
    if (_.isEmpty(opts.status)) delete opts.status;
    this.props.fetchPcAccreditations(opts);
  }

  componentWillMount() {
    if (this.props.organizationId) {
      this.props.fetchPcAccreditations();
    }
  }

  handleButtonRefresh = () => {
    this.handleRefresh();
  }

  render() {
    const { fetchPcAccreditationsInProgress, PcAccreditationItems, PcAccreditationTotal } = this.props;
    const items = PcAccreditationItems ? PcAccreditationItems.toJS() : [];
    const { myRequests, includeCompletedItems, pagination } = this.state;
    pagination.total = PcAccreditationTotal;
    return (
      <div>
        <BreadcrumbLevel text="Police Check Request Tracking" />
        <Row type='flex' justify="space-between" align='middle'>
          <Col>
            <FilterSearch query={this.state.searchQuery} allowSearchOnKeyChange setQuery={this.setQuery} onEnter={this.handleRefresh} />
          </Col>
          <Col style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '26px', width: '240px' }}>
              <DatePicker.RangePicker
                ranges={{
                  Today: [moment().startOf('day'), moment().endOf('day')],
                  'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
                  'This Month': [moment().startOf('month'), moment().endOf('day')],
                }}
                onChange={this.onDateChange}
                disabledDate={this.disableFuturedDate}
              />
            </div>
            <div style={{ marginRight: '16px' }}>
              <Checkbox onChange={this.onMyRequestChange} defaultChecked={myRequests}>My Requests</Checkbox>
            </div>
            <div style={{ marginRight: '16px' }}>
              <Checkbox onChange={this.onCompletedItemsChange} defaultChecked={includeCompletedItems}>Include Completed Items</Checkbox>
            </div>
            <Button type="primary" onClick={this.handleButtonRefresh}>Refresh</Button>
          </Col>
        </Row>
        <Row style={{ marginTop: '30px' }}>
          <Table
            dataSource={items}
            loading={fetchPcAccreditationsInProgress}
            onChange={this.fetchPcAccreditationsNext}
            pagination={pagination}
          >
            <Table.Column title= 'ACIC NO.' dataIndex= 'identifier' key= 'identifier' render={(text, record) => (record.constituentId ? <Link to={`/constituents/${record.constituentId}/accreditations`}>
              {record.identifier}
            </Link> : <span>{record.identifier}</span>)}
            />
            <Table.Column title= 'Type' dataIndex= 'type' key= 'type' filters={PC_TYPE_FILTERS} onFilter={(value, record) => record.type === value} render= {type => <Tooltip title={ABBR_PC[type] ? ABBR_PC[type] : 'National Police Check'}><span>{type.toUpperCase()}</span> </Tooltip>}/>
            <Table.Column title= 'For' dataIndex= 'name' key= 'name' width={150} style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}/>
            <Table.Column title= 'Submitted' dataIndex= 'createdAt' key= 'createdAt' sorter={true} sortDirections={['ascend', 'descend', 'ascend']} render= {createdAt => <Tooltip title={createdAt}><span>{moment(createdAt).format('YYYY-MM-DD')}</span> </Tooltip>}/>
            <Table.Column title= {
              <div style={{ backgroundColor: 'transparent' }} >Status
                <span>
                  <Tooltip overlayClassName='npc-workflow-tooltip' placement="right" title={<div><WorkflowStages/></div>} style={{ marginTop: '350px' }}>
                    <Icon onClick={this.handleWorkflowOpen} type={'info-circle'} style={{ cursor: 'pointer', color: 'gray', marginLeft: '10px' }} />
                  </Tooltip>
                </span>
              </div> }
              dataIndex= 'pcMeta.checkStatus' key= 'status' filters={PC_STATUS_FILTERS} onFilter={(value, record) => record.pcMeta.checkStatus === value}
            />
            <Table.Column title= 'Paid By' dataIndex= 'pcMeta.payer' key= 'payer'/>
            <Table.Column title= 'Result' key= 'result' render={(text, record, index) => <PoliceCheckResult meta={record.pcMeta} attachments={record.attachments}/>}/>
            <Table.Column title= 'Outcome' key= 'outcome' render={(text, record, index) => (record.pcMeta && record.pcMeta.decision === PC_PENDING_DECISION ? <><span>Pending decision</span><br/>
              <Row type='flex' gutter={8}>
                <Col>
                  <Button type='primary' onClick={() => {
                    this.handleApproveRequest(record);
                  }}
                  >Suitable</Button>
                </Col>
                <Col>
                  <Button onClick={() => {
                    this.handleRejectRequest(record);
                  }}
                  >Not Suitable</Button>
                </Col>

              </Row> </> : <span>{(record.pcMeta && record.pcMeta.decision) || ''}</span>)}
            />
          </Table>
        </Row>
      </div>);
  }
}

export const PcTrackingPage = connect(
  state => ({
    fetchPcAccreditationsInProgress: state.doc.getIn(['http', 'fetchPcAccreditations', 'inProgress'], true),
    fetchPcAccreditationsFailed: state.doc.getIn(['http', 'fetchPcAccreditations', 'failed'], false),
    patchPcAccreditationInProgress: state.doc.getIn(['http', 'patchPcAccreditation', 'inProgress'], true),
    patchPcAccreditationFailed: state.doc.getIn(['http', 'patchPcAccreditation', 'failed'], false),
    organizationId: state.doc.getIn(['authMr', 'constituent', 'organization', 'id'], null),
    PcAccreditationItems: state.doc.getIn(['http', 'fetchPcAccreditations', 'data', 'items']),
    PcAccreditationTotal: state.doc.getIn(['http', 'fetchPcAccreditations', 'data', 'total'], null),
  }),
  actions,
)(PcTrackingPageComponent);
