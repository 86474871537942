/* eslint no-console: ["off"] */

import * as BBPromise from 'bluebird';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';

import { BrowserRouter as Router } from 'react-router-dom';

import { AppContainer } from 'react-hot-loader';

// Pollyfill.
import 'core-js';
import 'whatwg-fetch';

import { App } from './App';

// import registerServiceWorker from './registerServiceWorker'
import { STORE } from './store';

if (window.SERVER_DATA.sentryDsn) {
  Sentry.init({
    dsn: window.SERVER_DATA.sentryDsn,
    ...window.SERVER_DATA.sentryConfig,
  });
  console.log('*** Sentry has been initialized ***');
}

BBPromise.config({
  cancellation: true,
});

const render = () => {
  ReactDOM.render(
    <Provider store={ STORE }>
      <Router>
        <AppContainer>
          <App />
        </AppContainer>
      </Router>
    </Provider>,
    document.getElementById('root'),
  );
};

render();

if (module.hot) {
  module.hot.accept(() => {
    render();
  });
}

// registerServiceWorker()
if (navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then(
    workers => workers.forEach(worker => worker.unregister()),
  );
}
