import moment from 'moment';
import { DatePicker } from 'antd';
import React from 'react';

import { getLatestDateByYear } from '../../utils';

export const latestAvailableBirthDate = (yearsToRestrict, initialValue) => {
  const latestAvailableDate = yearsToRestrict ? getLatestDateByYear(yearsToRestrict) : (initialValue || new Date());
  return latestAvailableDate;
};

export const generateBirthDateTooltip = birthdate => {
  const latestAvailableDate = latestAvailableBirthDate(10, birthdate);
  const nowDate = latestAvailableDate.getDate().toString();
  const nowMonth = latestAvailableDate.getMonth().toString();
  const strNowDate = nowDate.length === 1 ? `0${nowDate}` : nowDate;
  const strNowMonth = nowMonth.length === 1 ? `0${nowMonth}` : nowMonth;
  const datePlaceholder = `${strNowDate}-${strNowMonth}-${latestAvailableDate.getFullYear()}`;
  return `Must be before: ${datePlaceholder}`;
};

const getDateOfBirthField = props => {
  const {
    initialValue, yearsToRestrict, disabled,
  } = props;

  const latestAvailableDate = latestAvailableBirthDate(yearsToRestrict, initialValue);
  const defaultValue = initialValue || latestAvailableDate;

  return (
    <DatePicker
      placeholder={'DD-MM-YYYY'}
      disabled={disabled}
      disabledDate={current => current && current.valueOf() > latestAvailableDate}
      defaultPickerValue={moment(defaultValue)}
      format={'DD-MM-YYYY'}
    />
  );
};

export default getDateOfBirthField;
