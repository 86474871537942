import { simpleAjaxDispatch } from './http';

export function getOrganizationCompliance(organizationId) {
  return simpleAjaxDispatch(
    `/compliance/${organizationId}?detail=full`,
    'getOrganizationCompliance',
  );
}

export function patchOrganizationCompliance(organizationId, standardId, json) {
  return simpleAjaxDispatch(
    `/compliance/${organizationId}/${standardId}`,
    'patchOrganizationCompliance',
    { method: 'PATCH', json },
  );
}
