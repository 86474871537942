import React, { PureComponent } from 'react';
import { Form, Input } from 'antd';
import { API_DATE_FS } from './shared';

import getDateOfBirthField from '../../fields/DateOfBirthField';

export class SaWwcFormItems extends PureComponent {
  static slug = 'sawwc';

  static identLabel = 'Card number'; // More id names and labels have to be added

  static getSubmissionData(checkData, constituent) {
    return {
      fullName: constituent && constituent.get('fullName'),
      birth_date: (constituent && constituent.get('birthDate')) || (checkData && checkData.birth_date.format(API_DATE_FS)),
      isNew: !constituent.get('birthDate'),
      origin: 'doc',
    };
  }

  static regNumberGetValueFromEvent(evt) {
    return evt.target.value;
  }

  render() {
    const { form, disabled, rules, constituent } = this.props;
    const { getFieldDecorator } = form;
    const birthDate = constituent && constituent.get('birthDate');
    const { slug, regNumberGetValueFromEvent } = this.constructor;
    const placeholder = 'SRN 0000-0000';

    return (<div>
      { !this.props.isSAConfigured
        && <div style={{ paddingBottom: '30px' }}>
          <span style={{ color: 'tomato' }}>**SA WWC requires configuration for your organisation before it can be used. Please go to the Settings / Provider Settings to complete the relevant setup.</span>
        </div>
      }
      <Form.Item label="Card Number">
        {getFieldDecorator(`${slug}.identifier`, {
          getValueFromEvent: regNumberGetValueFromEvent,
          rules: [
            {
              required: true,
              whitespace: true,
              message: 'Card Number is required',
            },
            ...(rules || []),
          ],
        })(
          <Input
            placeholder={placeholder}
            disabled={disabled}
            size="default"
          />,
        )}
      </Form.Item>
      <Form.Item label="Date of Birth">
        {birthDate || (getFieldDecorator(`${slug}.birth_date`, {
          rules: [
            {
              required: true,
              message: 'Date of Birth is required',
            },
            ...(rules || []),
          ],
        }))(getDateOfBirthField({ initialValue: birthDate, yearsToRestrict: 18, disabled: false }))
        }
      </Form.Item>
    </div>);
  }
}
