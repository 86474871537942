import { NON_ALPHA_NUM_RE, RegNumberAndNameFormItems } from './shared';

export class VicWwcFormItems extends RegNumberAndNameFormItems {
  static slug = 'vicwwc'

  static placeholder = '1234567A'

  static identLabel = 'Card number'

  static regNumberGetValueFromEvent(evt) {
    return evt.target.value.substr(0, 8).replace(NON_ALPHA_NUM_RE, '');
  }
}
