export function pushBreadcrumbLevel(text) {
  return dispatch => dispatch({
    type: 'PUSH_BREADCRUMB_LEVEL', text,
  });
}

export function setBreadcrumbLevel(idx, text) {
  return dispatch => dispatch({
    type: 'SET_BREADCRUMB_LEVEL', idx, text,
  });
}

export function popBreadcrumbLevel(idx) {
  return dispatch => dispatch({
    type: 'POP_BREADCRUMB_LEVEL', idx,
  });
}
