import React, { PureComponent } from 'react';

import _ from 'lodash';
import axios from 'axios';

import { camelPreservePrivate, deepTransformKeys } from '../utils';

const Axios = React.createContext(null);

const withAxios = WrappedComponent => class WithCurrentUser extends PureComponent {
    static contextType = Axios;

    render() {
      return (
        <WrappedComponent axios={this.context} {...this.props} />
      );
    }
};

const AxiosProvider = Axios.Provider;

const axiosContext = (token, isPublic = false) => {
  const headers = {};

  if (!isPublic) {
    const authToken = token || localStorage.docToken;
    if (authToken) {
      headers.Authorization = `Bearer ${authToken}`;
    }
  }

  const transformRequest = (data, headers) => {
    headers['Content-Type'] = 'application/json';
    return JSON.stringify(deepTransformKeys(data, _.snakeCase));
  };

  const transformResponse = data => deepTransformKeys(data, camelPreservePrivate);

  return axios.create({
    headers,
    transformRequest,
    transformResponse,
    responseType: 'json',
  });
};

export {
  AxiosProvider,
  withAxios,
  axiosContext,
};
