import _ from 'lodash';
import { Tooltip } from 'antd';
import React, { PureComponent } from 'react';
import moment from 'moment';

export class LiveDate extends PureComponent {
  static defaultInterval = 10000

  static defaultTimeout = 2000

  static state = {
    text: '',
    tooltip: '',
  }

  constructor(props) {
    super(props);
    this.state = LiveDate.state;
  }

  setupInterval = props => {
    if (this.intervalTimer) clearTimeout(this.intervalTimer);
    this.intervalTimer = setTimeout(
      this.handleInterval,
      this.props.interval || LiveDate.defaultInterval,
    );
  }

  setup = props => {
    const { date } = props;
    if (_.isString(date)) {
      this.timeoutTimer = setTimeout(this.handleTimeout, props.timeout || LiveDate.defaultTimeout);
      if (this.updater.isMounted(this)) {
        this.datetime = moment(date);
        this.setupInterval(props);
        this.handleInterval();
      }
    } else {
      this.datetime = date;
    }
  }

  handleInterval = () => {
    this.setState({
      text: this.datetime.fromNow(),
      tooltip: this.datetime.calendar(null, { sameElse: 'LL' }),
    });
  }

  handleTimeout = () => {
    if (this.state.text === '') {
      this.setState({
        text: this.props.date,
      });
    }
  }

  componentWillMount() {
    this.setup(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setup(nextProps);
  }

  componentWillUnmount() {
    clearTimeout(this.intervalTimer);
    clearTimeout(this.timeoutTimer);
  }

  render() {
    return (<Tooltip title={ this.state.tooltip }>
      { this.state.text }
    </Tooltip>);
  }
}
