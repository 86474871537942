import { simpleAjaxDispatch } from './http';

export function checkUniqueEmail(email) {
  return simpleAjaxDispatch(
    `/users/by-email/${email}`,
    'checkUniqueEmail',
    { useSeq: true, redirect: 'manual' },
  );
}

export function checkUniqueAbn(abn) {
  return simpleAjaxDispatch(
    `/organizations/by-abn/${abn}`,
    'checkUniqueAbn',
    { useSeq: true, redirect: 'manual' },
  );
}

export function registerOrganization(json) {
  return simpleAjaxDispatch(
    '/register',
    'registerOrganization',
    { method: 'POST', json },
  );
}

export function activateOrganization(json) {
  return simpleAjaxDispatch(
    '/organizations/me/activate',
    'activateOrganization',
    { method: 'POST', json },
  );
}
