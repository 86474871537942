import { Tag } from 'antd';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { TAG_COLORS } from '../defaults';
import { fullTagLabel, rootTagRole } from '../utils';

class ConstituentTagComponent extends PureComponent {
  handleAfterClose = () => {
    this.props.tagProps.onClose(this.props.tagId);
  }

  roleTagType(role) {
    if (!role) return 'organization';
    return role.split('.')[0];
  }

  render() {
    const { prefix, tagId, tagItems, tagProps } = this.props;
    const tagDataImm = tagItems.find(data => data.get('id') === tagId);

    if (!tagDataImm) return null;

    const tagData = tagDataImm.toJS();

    let maxDots = 0;
    let typeProps = this.props.typeProps._default;
    const tagBorder = {
      '#E4EBEF': '#4D524D',
      '#E6D9D5': '#8F5441',
    };
    Object.entries(this.props.typeProps).forEach(([typePrefix, thisTypeProps]) => {
      const thisRole = tagData.role || rootTagRole(tagData);
      if (typePrefix === thisRole) {
        typeProps = thisTypeProps;
        return;
      }
      if (thisRole && thisRole.startsWith(`${typePrefix}.`)) {
        const numDots = typePrefix.split('.').length;
        if (numDots > maxDots) {
          maxDots = numDots;
          typeProps = thisTypeProps;
        }
      }
    });

    const overrideProps = {};
    if (tagProps.onClose) overrideProps.onClose = this.handleAfterClose;

    return (<Tag { ...tagProps } { ...typeProps } { ...overrideProps } style={{ color: tagBorder[typeProps.color] ? tagBorder[typeProps.color] : '', borderColor: tagBorder[typeProps.color] ? tagBorder[typeProps.color] : '', marginBottom: '3px' }}>
      { prefix }
      { fullTagLabel(tagData, { ignoreRoots: true }) }
    </Tag>);
  }
}
ConstituentTagComponent.defaultProps = {
  typeProps: {
    ..._.fromPairs(Object.entries(TAG_COLORS).map(
      ([role, color]) => [role, { color }],
    )),
    _default: {},
  },
  tagProps: {},
};

export const ConstituentTag = connect(
  state => ({
    tagItems: state.doc.getIn(['tagsMr', 'items']),
  }),
)(ConstituentTagComponent);
