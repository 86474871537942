import { Tag, Input, Tooltip, Icon } from 'antd';
import React, { PureComponent } from 'react';

export class PurposeLocationPresetGroup extends PureComponent {
  constructor(props) {
    super(props);
    const value = props.value || [];
    this.state = {
      purposeLocationPresets: value,
      inputVisible: false,
      inputValue: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    const { value } = nextProps;
    this.setState({ purposeLocationPresets: value });
  }


  handleClose = removedTag => {
    const purposeLocationPresets = this.state.purposeLocationPresets.filter(tag => tag !== removedTag);
    this.setState({ purposeLocationPresets });
    const { onChange } = this.props;
    if (onChange) {
      // This will provide the form with changes
      onChange(purposeLocationPresets);
    }
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value, validationError: null });
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;

    if (inputValue && inputValue.length < 3) {
      this.setState({validationError: "Purpose location has to be 3 or more characters"});
      return;
    }

    let { purposeLocationPresets } = this.state;
    if (inputValue && purposeLocationPresets.indexOf(inputValue) === -1) {
      purposeLocationPresets = [...purposeLocationPresets, inputValue];
    }
    this.setState({
      purposeLocationPresets,
      inputVisible: false,
      inputValue: '',
      validationError: null,
    });
    const { onChange } = this.props;
    if (onChange) {
      // This will provide the form with changes
      onChange(purposeLocationPresets);
    }
  };

  saveInputRef = input => (this.input = input); // eslint-disable-line no-return-assign


  render() {
    const { purposeLocationPresets, inputVisible, inputValue } = this.state;
    return (
      <div style={{ maxWidth: '550px' }}>
        {purposeLocationPresets.map((tag, index) => {
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag key={tag} closable={true} onClose={() => this.handleClose(tag)}>
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {inputVisible && (
          <Input
            ref={this.saveInputRef}
            type="text"
            size="small"
            style={{ width: 78 }}
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
          />
        )}
        {this.state.validationError && <div style={{ color: "red" }}>{this.state.validationError}</div>}
        {!inputVisible && (
          <Tag onClick={this.showInput} style={{ background: '#fff', borderStyle: 'dashed' }}>
            <Icon type="plus" /> New Purpose Location
          </Tag>
        )}
      </div>
    );
  }
}
