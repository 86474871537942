import React from 'react';
import _ from 'lodash';
import {
  Tooltip, Icon, Button, Spin, Form, Switch, Row, Col,
} from 'antd';
import { checkFeature } from '../../utils';

export const determineCredsStatus = (stateName, institutionsCredentials) => {
  const lastCheckedStatus = _.get(institutionsCredentials[stateName], 'lastCheckedStatus', undefined);
  let color = null;
  let icon = null;
  switch (lastCheckedStatus) {
    case 'success':
      color = 'green';
      icon = 'check-circle';
      break;
    case 'error':
      color = 'red';
      icon = 'exclamation-circle';
      break;
    case 'pending':
      color = 'orange';
      icon = 'info-circle';
      break;
    default:
      color = 'grey';
      icon = 'exclamation-circle';
      if (institutionsCredentials[stateName] && institutionsCredentials[stateName].username) color = 'orange';
  }

  return { color, icon };
};

const DoCEmail = ({
  stateName,
  emailAddress,
  onCopy,
  onShow,
  institutionsCredentials,
  submitClicked,
  otherServices,
  form,
  orgMode,
}) => {
  let { lastChecked } = institutionsCredentials && (institutionsCredentials[stateName] || {});
  let stateTitle;
  const { getFieldDecorator, setFieldsValue } = form;

  const types = ['qldblue', 'sa', 'saSync', 'ndwsImport', 'ndwsSync', 'ndwsUserCreds'];
  if (types.includes(stateName)) stateTitle = stateName.toUpperCase();
  lastChecked = lastChecked || 'Not verified';

  const { color, icon } = determineCredsStatus(stateName, institutionsCredentials);

  const saveAndTestButton = (
    <Button onClick={onShow} type="primary" style={{ marginLeft: '20px' }}>
      {lastChecked ? 'Update' : 'Add'}{' '}Credentials
    </Button>
  );
  const nswToolTip = (
    <p>This will be the email address setup to receive email notifications from OCG.
      You will need to configure the OCG portal registration to send to this address.
      A guide is provided <a href='https://help.weareoho.com/article/show/122610-setting-up-for-nsw' style={{ color: 'blue' }} target='_blank' rel='noopener noreferrer'>here.</a></p>
  );
  const generateTooltip = ({ title, color, iconType }) => (
    <Tooltip placement="top" title={title}>
      <Icon type={iconType} style={{ color, paddingLeft: '20px', paddingRight: '20px', fontSize: '20px' }} />
    </Tooltip>
  );
  const credentialsTestStatus = generateTooltip({ title: `Last checked on: ${lastChecked}`, color, iconType: icon });

  const saSyncComponent = (
    <Form.Item>
      {getFieldDecorator('institutionsCredentials.sa.saSync', {
        valuePropName: 'checked',
      })(
        <Switch />,
      )}
    </Form.Item>
  );

  const handleNdwsImportChange = checked => {
    if (!checked) setFieldsValue({ 'institutionsCredentials.ndws.syncEnabled': checked });
  };

  const handleNdwsSyncChange = checked => {
    if (checked) setFieldsValue({ 'institutionsCredentials.ndws.importEnabled': checked });
  };

  const ndwsImportComponent = (
    <Form.Item>
      {getFieldDecorator('institutionsCredentials.ndws.importEnabled', {
        valuePropName: 'checked',
      })(
        <Switch onChange={handleNdwsImportChange} />,
      )}
    </Form.Item>
  );

  const ndwsSyncComponent = (
    <Form.Item>
      {getFieldDecorator('institutionsCredentials.ndws.syncEnabled', {
        valuePropName: 'checked',
      })(
        <Switch onChange={handleNdwsSyncChange} />,
      )}
    </Form.Item>
  );

  const services = [];
  switch (stateTitle) {
    case 'QLDBLUE':
      services.push({
        key: '1',
        service: 'Blue Card Services',
        status: credentialsTestStatus,
        actions: saveAndTestButton,
      });
      if (checkFeature('qldLinking') && otherServices) {
        otherServices.forEach(s => {
          services.push({
            key: '2',
            service: s.name,
            status: generateTooltip({
              title: 'When Oho is linking people to your organisation, we will use these defaults for the values.',
              color: s.statusColor,
              iconType: s.statusIcon,
            }),
            actions: <Button onClick={s.action} type="primary">{s.actionLabel}</Button>,
          });
        });
      }
      break;
    case 'NDWSIMPORT':
      if (checkFeature('ndwsImport')) {
        services.push({
          key: '1',
          service: 'NDWS Update only (via Import) - updates existing data in Oho only',
          status: generateTooltip({
            title: 'Enabling this feature allows you to import data from NDWS export files, as imports into Oho - updating accreditation details for existing constituents.',
            color,
            iconType: 'question-circle',
          }),
          actions: ndwsImportComponent,
        });
      }
      break;
    case 'NDWSSYNC':
      if (checkFeature('ndwsSync')) {
        services.push({
          key: '2',
          service: 'NDWS Sync - updates existing and adds new data to Oho',
          status: generateTooltip({
            title: 'Enabling this feature allows you to sync data from NDWS export files, as imports into Oho - adding the new People and adding/updating accreditation details.',
            color,
            iconType: 'question-circle',
          }),
          actions: ndwsSyncComponent,
        });
      }
      break;
    case 'NDWSUSERCREDS':
      services.push({
        key: '3',
        service: 'NDWS Safeguard Credentials',
        status: credentialsTestStatus,
        actions: saveAndTestButton,
      });
      if (otherServices) {
        otherServices.forEach(s => {
          services.push({
            key: '4',
            service: s.name,
            status: generateTooltip({
              title: 'When Oho is linking people to your organisation, we will use these defaults for the values.',
              color: s.statusColor,
              iconType: s.statusIcon,
            }),
            actions: <Button onClick={s.action} type="primary">{s.actionLabel}</Button>,
          });
        });
      }
      break;
    case 'SA':
      services.push({
        key: '1',
        service: 'DHS Screening Unit',
        status: credentialsTestStatus,
        actions: saveAndTestButton,
      });
      break;
    case 'SASYNC':
      services.push({
        key: '2',
        service: 'DHS Screening Unit Sync',
        status: generateTooltip({
          title: 'Add people to Oho who are already linked in SA Screening Unit Portal',
          color: 'grey',
          iconType: 'question-circle',
        }),
        actions: saSyncComponent,
      });
      break;
    default:
      if (orgMode === 'ongoing') {
        services.push({
          key: '1',
          service: 'Office of the Children\'s Guardian',
          status: credentialsTestStatus,
          actions: saveAndTestButton,
        });
      }
      if (emailAddress) {
        services.push({
          key: '2',
          service: emailAddress,
          status: generateTooltip({
            title: nswToolTip,
            color: 'grey',
            iconType: 'question-circle',
          }),
          actions: (
            <Button onClick={onCopy} type="primary" style={{ marginLeft: '20px' }}>
              Copy to Clipboard
            </Button>
          ),
        });
      }
      if (otherServices && orgMode === 'oneoff') {
        otherServices.forEach(s => {
          services.push({
            key: '3',
            service: s.name,
            status: generateTooltip({
              title: 'When Oho is using NSW One Time Check, we will use these defaults for the values.',
              color: s.statusColor,
              iconType: s.statusIcon,
            }),
            actions: <Button onClick={s.action} type="primary">{s.actionLabel}</Button>,
          });
        });
      }
  }

  let credentialComponent = (
    <Spin spinning={submitClicked}>
      {services.map(({ key, service, status, actions }, idx) => (
        <Row
          key={key}
          align={'middle'}
          style={{ marginTop: idx > 0 ? '1.2rem' : '0' }}
        >
          <Col span={10}>{service}</Col>
          <Col span={4}>{status}</Col>
          <Col span={10}><div style={{ float: 'right' }}>{actions}</div></Col>
        </Row>
      ))}
    </Spin>
  );

  // overrides
  switch (stateTitle) {
    case 'QLDBLUE':
      if (!checkFeature('qldLinking')) {
        credentialComponent = null;
      }
      break;
    default:
      break;
  }

  return credentialComponent;
};

export { DoCEmail };
