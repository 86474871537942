import React, { PureComponent } from 'react';

import { Button, Tooltip } from 'antd';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as actions from '../../../actions';

import { checkFeature, historyPushPath } from '../../../utils';

@withRouter
@connect(state => ({
  emailVerified: state.doc.getIn(['authMr', 'emailVerified'], false),
  ndwsImportEnabled: state.doc.getIn(['authMr', 'constituent', 'organization', 'institutionsCredentials', 'ndws', 'importEnabled'], false),
}), actions)
class GlobalActions extends PureComponent {
  handleInviteClick = () => {
    historyPushPath(
      this.props.history,
      this.props.location,
      `${this.props.match.path}/new`,
    );
  }

  handleImportClick = () => {
    historyPushPath(
      this.props.history,
      this.props.location,
      `${this.props.match.path}/import`,
    );
  }

  handleNdwsSyncClick = () => {
    historyPushPath(
      this.props.history,
      this.props.location,
      `${this.props.match.path}/ndws-sync`,
    );
  }

  handleQldBlueClick = () => {
    historyPushPath(
      this.props.history,
      this.props.location,
      `${this.props.match.path}/qldblue-import`,
    );
  }

  handleExportClick = () => {
    const { tags, query } = this.props;
    const options = {
      with_tag_ids: tags.filter(({ with_ }) => with_).map(({ id }) => id).join(','),
      without_tag_ids: tags.filter(({ with_ }) => !with_).map(({ id }) => id).join(','),
      query,
    };
    if (!options.with_tag_ids) { delete options.with_tag_ids; }
    if (!options.without_tag_ids) { delete options.without_tag_ids; }
    this.props.constituentExport(
      options,
      'constituentExport',
    );
  }

  render() {
    const {
      emailVerified,
      ndwsImportEnabled,
    } = this.props;
    return (
      <div style={{ display: 'flex', gap: '10px' }}>
        {checkFeature('ndwsImport') && <Button icon="cloud-upload" disabled={!(emailVerified && ndwsImportEnabled)} onClick={this.handleNdwsSyncClick}>NDWS Sync</Button>}
        {checkFeature('qldblueSync') && <Button icon="cloud-upload" onClick={this.handleQldBlueClick}>QLDBlue Sync</Button>}
        <Button icon="cloud-upload" disabled={!emailVerified} onClick={this.handleImportClick}>Import</Button>
        <Button icon="plus" onClick={ this.handleInviteClick }>Add</Button>
        <Tooltip title="Export">
          {checkFeature('constituentExport')
            && <Button shape="circle" icon="cloud-download" style={{ minWidth: '50px' }} onClick={ this.handleExportClick }/>}
        </Tooltip>
      </div>
    );
  }
}

export { GlobalActions };
