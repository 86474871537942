/* eslint camelcase: ["off"] */
import {
  Alert, Col, Collapse, Icon, Row, Spin, Tag,
} from 'antd';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { DefinedTerm } from '../../elements';
import * as actions from '../../../actions';

class LogItemStatusTag extends PureComponent {
  render() {
    const { http_code, network_error } = this.props;
    if (network_error) return <Tag color="red">{network_error}</Tag>;
    return (<Tag
      color={http_code >= 200 && http_code < 300 ? 'green' : 'red'}
            >
      { http_code}
    </Tag>);
  }
}

class HttpLog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { bodyPlain: null };
  }

  renderLimitedMsg(limited, bytesLimit, name, are, have) {
    return limited
      && <Alert key="limited" message={<span>
        {name} {are} more than
        <DefinedTerm definition={`${bytesLimit} bytes`}>
          {bytesLimit / 1000}kb
        </DefinedTerm>
            and {have} been truncated.
      </span>} type="warning" showIcon
         />;
  }

  renderLimitedMark() {
    return <Tag><Icon type="ellipsis" /></Tag>;
  }

  renderRequestBody = () => {
    const { body } = this.props;
    return body;
  }

  renderResponseBody = () => {
    if (_.isNil(this.state.bodyPlain)) {
      fetch(`data:text/plain;charset=utf-8;base64,${this.props.body}`)
        .then(resp => resp.text())
        .then(bodyPlain => this.setState({ bodyPlain }));
    } else {
      return this.state.bodyPlain;
    }
    return <Tag>Decoding</Tag>;
  }

  renderBody = () => {
    const { body, bodyBytesLimit, bodyLimited, isResponse } = this.props;
    const renderBodyType = isResponse ? this.renderResponseBody : this.renderRequestBody;
    return [
      _.isNil(body)
        ? <Tag key="content">No body</Tag>
        : <pre key="content"><code>{renderBodyType()}</code>{bodyLimited && this.renderLimitedMark()}</pre>,
      this.renderLimitedMsg(bodyLimited, bodyBytesLimit, 'Body', 'is', 'has'),
    ];
  }

  render() {
    const { headers, headersBytesLimit, headersLimited } = this.props;
    return (<div>
      <h3>Headers</h3>
      { headers
        ? Object.entries(headers).map(([key, value]) => (<Row key={key}>
          <Col span={10}>
            <strong><pre style={{ textOverflow: 'ellipsis' }}><code>
              {key}
            </code></pre></strong>
          </Col>
          <Col span={14} style={{ overflow: 'scrollX' }}>
            <pre><code>{value}</code></pre>
          </Col>
        </Row>))
        : <Tag>No headers</Tag>
      }
      { headersLimited && this.renderLimitedMark()}
      { this.renderLimitedMsg(headersLimited, headersBytesLimit, 'Headers', 'are', 'have')}
      <h3>Body</h3>
      { this.renderBody()}
    </div>);
  }
}

class LogsBodyComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      failed: false,
      logs: null,
    };
  }

  async componentWillMount() {
    const resp = await this.props.fetchWebhookLogs(this.props.webhookId);
    this.setState({
      loading: false,
      failed: resp.failed,
      logs: resp.failed ? null : resp.data,
    });
  }

  renderHttpLog = (item, isResponse) => {
    const prefix = isResponse ? 'response' : 'request';
    const keys = ['body', 'headers'];
    const suffixes = ['', '_limited', '_bytes_limit'];
    const logProps = {};
    keys.forEach(key => {
      suffixes.forEach(suffix => {
        const propKeySnake = `${key}${suffix}`;
        const propKey = _.camelCase(propKeySnake);
        const itemKey = `${prefix}_${propKeySnake}`;

        if (!_.isUndefined(item[itemKey])) logProps[propKey] = item[itemKey];
      });
    });
    return <HttpLog isResponse={isResponse} {...logProps} />;
  }

  renderItem = item => (
    <div>
      <LogItemStatusTag {...item} />
      { item.url}
    </div>
  )
  // renderItem = item => (
  //   <Collapse.Panel
  //     header={
  //         <span>
  //             <LogItemStatusTag { ...item } />
  //               { item.url }
  //           </span>
  //         }
  //         key={ item.id }
  //         >
  //     <Tabs>
  //       <Tabs.TabPane tab="Request" key="request">
  //         { this.renderHttpLog(item, false) }
  //       </Tabs.TabPane>
  //       <Tabs.TabPane tab="Response" key="response">
  //         { this.renderHttpLog(item, true) }
  //       </Tabs.TabPane>
  //     </Tabs>
  //   </Collapse.Panel>
  // )

  render() {
    const { loading, failed, logs } = this.state;
    return (<Spin
      spinning={loading}
            >
      { failed && <Alert type="error" message="Couldn't load logs" showIcon />}
      { logs && <Collapse bordered={false}>
        {logs.items.map(this.renderItem)}
      </Collapse>}
    </Spin>);
  }
}

export const LogsBody = connect(
  null,
  actions,
)(LogsBodyComponent);
