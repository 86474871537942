import { RegNumberAndNameFormItems } from './shared';

export class TasWwcFormItems extends RegNumberAndNameFormItems {
  static slug = 'taswwc'

  // TODO static placeholder =
  static identLabel = 'Card number'

  static getSubmissionData(checkData, constituent) {
    return {
      fullName: constituent && constituent.get('fullName'),
      origin: 'doc',
    };
  }
}
