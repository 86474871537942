import React from 'react';
import { Modal } from 'antd';

const { confirm } = Modal;

const genericConfirmationModal = async ({
  title, content, handleSubmit, relationshipId, isRequestor, status,
}) => {
  confirm({
    title,
    content,
    onOk: async () => await handleSubmit(relationshipId, isRequestor, status),
    onCancel: () => {},
  });
};

export default genericConfirmationModal;
