import { Alert } from 'antd';
import React, { PureComponent } from 'react';

export class SaTrbSpecialFormItems extends PureComponent {
  static slug = 'satrbspec'

  static getSubmissionData(checkData, constituent) {
    return {
      fullName: constituent.get('fullName'),
    };
  }

  render() {
    return <Alert message="We don't need any more information!" />;
  }
}
