import axios from 'axios';
import * as Promise from 'bluebird';
import { message } from 'antd';

import { UNKNOWN_HTTP_ERROR_MESSAGE } from '../defaults';

function getHttpStreamHandlers(callback, split = '\n') {
  const splitLen = split.length;
  let bytesComplete = 0;

  function handler(allData, final = false) {
    // Get the data from the unhandled byte
    const thisData = allData.substr(bytesComplete);
    const chunks = thisData.split(split);

    // Don't process the last entry, because it's only partially complete
    // ... unless it's the final handler call
    const toProcess = final ? chunks.length : chunks.length - 1;

    for (let idx = 0; idx < toProcess; idx++) {
      // Add that we've completed processing of this chunk
      bytesComplete += chunks[idx].length + splitLen;

      // Process the chunk
      try {
        callback(chunks[idx]);
      } catch (err) { console.error('Error handling chunk:', err); }
    }
  }

  function progressHandler(progress) {
    return handler(progress.target.response);
  }

  function completeHandler(data) {
    return handler(data, true);
  }

  return { progressHandler, completeHandler };
}

export function watchCorrelationId(correlationId, callback) {
  let started = false;
  const cancelToken = axios.CancelToken.source();
  const promise = new Promise((resolve, reject, onCancel) => {
    const { progressHandler, completeHandler } = getHttpStreamHandlers(chunk => {
      // Resolve the promise on the first chunk (headers done) so that
      if (!started) { resolve(); started = true; }
      if (chunk) callback(null, JSON.parse(chunk));
    });

    axios.get(`/longpoll/rpc/${correlationId}`, {
      onDownloadProgress: progressHandler,
      cancelToken: cancelToken.token,
    })
      .then(res => completeHandler(res.data))
      .catch(err => {
        if (axios.isCancel(err)) {
          callback(new Error('Request was canceled'), null);
        }

        message.error(UNKNOWN_HTTP_ERROR_MESSAGE);
        callback(err, null);
        if (!started) reject(err);
      });
    // onCancel(cancelToken.cancel);
  });

  promise.cancel = () => {
    cancelToken.cancel('Request was canceled');
  };
  return promise;
}
