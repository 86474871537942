import { fromJS } from 'immutable';
import { Reducer } from './base';

import { STATE as defaultState } from '../defaults';

export class ImportNdwsReducer extends Reducer {
  shouldHandle(state, action) {
    const runningId = state.getIn(['importNdws', 'correlationId'], null);
    if (runningId === action.correlationId) return true; // Current import
    if (action.state === 'starting') return true; // New start event
    return false;
  }

  handleUpdateNdwsImportStatusAction(prevState, state, action) {
    if (!this.shouldHandle(state, action)) return state;
    const { type, ...data } = action;
    if (action.state === 'starting') {
      return state.set('importNdws', fromJS(data));
    }
    return state.mergeIn(['importNdws'], fromJS(data));
  }

  handleAddNdwsImportErrorAction(prevState, state, action) {
    if (!this.shouldHandle(state, action)) return state;
    return state.setIn(
      ['importNdws', 'errors'],
      state.getIn(['importNdws', 'errors']).push(action.error),
    );
  }

  handlePushNdwsImportCompletionAction(prevState, state, action) {
    if (!this.shouldHandle(state, action)) return state;
    return state.setIn(
      ['importNdws', 'done'],
      state.getIn(['importNdws', 'done'], 0) + 1,
    );
  }

  handleClearNdwsImportAction(prevState, state, action) {
    return state.set('importNdws', defaultState.get('importNdws'));
  }

  handleUpdateNdwsSyncStatusAction(prevState, state, action) {
    return state.set('ndwsSync', action);
  }

  handleUpdateNdwsSyncStatusToDefaultAction(prevState, state, action) {
    return state.set('ndwsSync', defaultState.get('ndwsSync'));
  }
}
