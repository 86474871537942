import { fromJS } from 'immutable';
import { Reducer } from './base';

import { STATE as defaultState } from '../defaults';

export class ImportReducer extends Reducer {
  shouldHandle(state, action) {
    const runningId = state.getIn(['import', 'correlationId'], null);
    if (runningId === action.correlationId) return true; // Current import
    if (action.state === 'starting') return true; // New start event
    return false;
  }

  handleUpdateConstituentImportStatusAction(prevState, state, action) {
    if (!this.shouldHandle(state, action)) return state;
    const { type, ...data } = action;
    if (action.state === 'starting') {
      return state.set('import', fromJS(data));
    }
    return state.mergeIn(['import'], fromJS(data));
  }

  handleAddConstituentImportErrorAction(prevState, state, action) {
    if (!this.shouldHandle(state, action)) return state;
    return state.setIn(
      ['import', 'errors'],
      state.getIn(['import', 'errors']).push(action.error),
    );
  }

  handlePushConstituentImportCompletionAction(prevState, state, action) {
    if (!this.shouldHandle(state, action)) return state;
    return state.setIn(
      ['import', 'done'],
      state.getIn(['import', 'done'], 0) + 1,
    );
  }

  handleClearConstituentImportAction(prevState, state, action) {
    return state.set('import', defaultState.get('import'));
  }
}
