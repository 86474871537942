import { Alert, Button, Icon, message } from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Svg } from '../Svg';
import logo from '../../logos/logo_dark.svg';
import { TotpRegisterComponent } from './ConstituentPage/TotpRegister';
import * as actions from '../../actions';

class FrontTotpRegisterComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      regPinInput1: '',
      regVerifyingHelp: undefined,
      regVerifyInputStatus: undefined,
      regSaving: false,
    };
  }

  getHttpError(data) {
    if (!data._errors) return '';
    return Object.values(data._errors).flat().join('|');
  }

  componentWillUnmount() {
    this.cleanup();
  }

  cleanup() {
    this.setState({
      regPinInput1: '',
      regVerifyingHelp: undefined,
      regVerifyInputStatus: undefined,
      regSaving: false,
    });
  }

  handleTotpRegister = async evt => {
    evt.preventDefault();
    this.setState({ regSaving: true });

    if (!this.state.regPinInput1 || this.state.regPinInput1.split('').length !== 6) {
      this.setState({ regSaving: false });
      this.setState({ regVerifyingHelp: 'MFA code requires 6 digit code', regVerifyInputStatus: 'error', regSaving: false });
      return;
    }
    const res = await this.props.verifyTotp({
      code: Number(this.state.regPinInput1),
    });
    if (res.failed) {
      this.setState({ regVerifyingHelp: this.getHttpError(res.data), regVerifyInputStatus: 'error', regSaving: false });
    }
    if (res.success) {
      this.setState({ regSaving: true });
      message.success('Successfully registered with MFA device!');
      this.props.onVerifySuccess();
    }
  }

  handleRegisterPinInput(value) {
    this.setState({ regPinInput1: value });
  }

  render() {
    return (
      <div style={{
        display: 'flex', backgroundColor: '#CAD6DE', minHeight: '100vh', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <div style={{
          display: 'flex', flexDirection: 'column', height: 'fit-content', alignItems: 'center', backgroundColor: 'white', padding: '30px', borderRadius: '15px',
        }}
        >
          <div>
            <Svg url={ logo } style={{ width: '180px', position: 'relative' }} />
          </div>

          <div style={{
            backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px',
          }}
          >
            <Alert
              message="Your organization has enforced you to register with multifactor authentication (MFA)."
              type="info"
              showIcon
              icon={<Icon type="info-circle" theme="filled" style={{ color: '#82412C' }} />}
              style={{
                width: '100%', marginBottom: '25px', backgroundColor: 'transparent', borderColor: '#82412C', color: '#82412C', borderRadius: '10px'
              }}
            />

            <div style={{ backgroundColor: 'transparent', width: '600px' }}>
              <TotpRegisterComponent
                saving={this.state.regSaving}
                verifyingHelp={this.state.regVerifyingHelp}
                verifyInputStatus={this.state.regVerifyInputStatus}
                setParentState={value => this.handleRegisterPinInput(value)}
              />
            </div>

            <Button type="primary" style={{ marginTop: '15px' }} loading={this.regSaving} onClick={this.handleTotpRegister}disabled={this.props.regTotpFailed} >Register & Sign In</Button>

          </div>
        </div>
      </div>
    );
  }
}

export const FrontTotpRegisterPage = connect(
  state => ({
    regTotpFailed: state.doc.getIn(['http', 'regTotp', 'failed'], false),
  }),
  actions,
)(FrontTotpRegisterComponent);
