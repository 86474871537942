import React, { memo } from 'react';

export const MaybeLink = memo(({ href, children, ...remainder }) => (
  <a href={href} {...remainder}>{children}</a>
));

// TODO: Upgrade react-router, then switch on the context
/*
import { Link } from 'react-router-dom'
import { __RouterContext as RouterContext } from 'react-router'

export const MaybeLink = memo(({ href, children, ...remainder }) => (
  <RouterContext.Consumer>
    {context => (context
      ? <Link to={href} {...remainder}>{children}</Link>
      : <a href={href} {...remainder}>{children}</a>)
    }
  </RouterContext.Consumer>
))
*/
