import _ from 'lodash';
import React, { PureComponent } from 'react';

export class ErrorsList extends PureComponent {
  render() {
    const { errors: fieldErrors } = this.props;

    if (!fieldErrors) {
      return null;
    }

    let errorEntries = [];

    if (_.isArray(fieldErrors)) {
      errorEntries = [{ field: 'global', errors: fieldErrors }];
    } else {
      errorEntries = Object.entries(fieldErrors).map(([field, errors]) => ({
        field,
        label: _.startCase(field),
        errors: _.isArray(errors) ? errors : [errors],
      }));
    }

    return (<div>
      {
        errorEntries.map(({ field, label, errors }) => (
          <ul key={field} style={{ listStyle: 'none', padding: 0, color: 'red' }}>
            {
            errors.map((error, idx) => (
              <li key={ idx }>
                { label && (<strong>{label}: </strong>) }{ error }
              </li>
            ))
          }
          </ul>
        ))
      }
    </div>);
  }
}
