import _ from 'lodash';

import { message } from 'antd';

import { HTTP_GROUP, UNKNOWN_HTTP_ERROR_MESSAGE } from '../defaults';
import { camelPreservePrivate, deepTransformKeys } from '../utils';

const URI_PREFIX = '/api';

function getAuthOpts(getState, auth = null) {
  if (!auth) return {};
  if (!_.isString(auth)) {
    const token = getState().doc.getIn(['auth', 'token'], localStorage.docToken);
    // Check to see if it roughly looks like a valid token, will help catch
    // bugs like reading from local storage returning the string "undefined"
    if (!token || token.indexOf('.') === -1) return {};
    auth = `Bearer ${token}`;
  }
  return auth ? { headers: { authorization: auth } } : {};
}

const builtinFetch = (uri, opts) => fetch(uri, opts);

export async function genericAjaxDispatch(
  dispatch, getState, uri, group, opts = null,
) {
  const {
    auth = true,
    json, jsonSnake = true, responseSnake = true,
    clear = true,
    api = true,
    meta = {},
    seq,
    fetch = builtinFetch,
    messageOnServerError = true,
    ...fetchOpts
  } = opts || {};

  if (api && uri[0] === '/') uri = `${URI_PREFIX}${uri}`;

  const baseAction = {
    type: 'HTTP_UPDATED',
    group,
    uri,
    ...HTTP_GROUP,
  };
  const baseOpts = { headers: { accept: 'application/json' } };

  if (json && fetchOpts.body) {
    console.warn('opts.json and opts.body are mutually exclusive');
  } else if (json) {
    const finalJson = jsonSnake ? deepTransformKeys(json, _.snakeCase) : json;
    baseOpts.headers['content-type'] = 'application/json';
    baseOpts.body = JSON.stringify(finalJson);
  }

  const authOpts = getAuthOpts(getState, auth);

  const finalOpts = _.merge(baseOpts, authOpts, opts);

  const clearExtra = clear ? { data: null } : {};

  dispatch(_.defaults({ inProgress: true, seq, meta, ...clearExtra }, baseAction));

  let response;
  try {
    response = await fetch(uri, finalOpts);
  } catch (error) {
    dispatch(_.defaults({
      inProgress: false, failed: true, error, seq, meta,
    }, baseAction));
    console.error(error);
    throw error;
  }

  const contentType = response.headers.get('content-type');
  const isRedirect = response.type === 'opaqueredirect';

  const dispatchEvent = {
    ...baseAction,
    inProgress: false,
    response,
    meta,
    seq,
  };

  try {
    if (contentType && contentType.startsWith('application/json')) {
      const data = await response.json();
      if (responseSnake) {
        dispatchEvent.data = deepTransformKeys(data, camelPreservePrivate);
      } else {
        dispatchEvent.data = data;
      }
    } else {
      const body = await response.text();
      dispatchEvent.body = body;
    }
  } catch (error) {
    return dispatch({
      ...dispatchEvent,
      failed: true,
      error,
    });
  }

  if (messageOnServerError && response.status >= 500) {
    dispatchEvent.errorHandled = true;
    message.error(UNKNOWN_HTTP_ERROR_MESSAGE);
  }

  if (isRedirect) {
    dispatchEvent.success = false;
    dispatchEvent.failed = false;
  } else if (response.ok) {
    dispatchEvent.success = true;
  } else {
    dispatchEvent.failed = true;
  }

  return dispatch(dispatchEvent);
}

export function simpleAjaxDispatch(uri, group, { useSeq = false, ...opts } = {}) {
  return (dispatch, getState) => {
    if (useSeq) {
      opts.seq = (simpleAjaxDispatch.seqs[group] || 0) + 1;
      simpleAjaxDispatch.seqs[group] = opts.seq;
    }
    return genericAjaxDispatch(dispatch, getState, uri, group, opts);
  };
}
simpleAjaxDispatch.seqs = {};
