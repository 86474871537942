import { Form, Input } from 'antd';
import React, { PureComponent } from 'react';
import { NON_NUM_RE } from './shared';

export class NtOchreFormItems extends PureComponent {
  static slug = 'ntochre'

  static getSubmissionData(checkData, constituent) {
    return {
      fullName: constituent.get('fullName'),
    };
  }

  static clearanceNoticeNumberGetValueFromEvent(evt) {
    return evt.target.value.replace(NON_NUM_RE, ''); // TODO notice number length
  }

  render() {
    const { form, disabled, rules } = this.props;
    const { getFieldDecorator } = form;
    const klass = this.constructor;
    return (<div>
      <Form.Item label="Clearance notice number">
        { getFieldDecorator(`${klass.slug}.identifier`, {
          getValueFromEvent: klass.clearanceNoticeNumberGetValueFromEvent,
          rules: [
            {
              required: true,
              whitespace: true,
              message: 'Notice number is required',
            },
            ...(rules || []),
          ],
        })(
          <Input
            placeholder={ null /* TODO notice number example */ }
            disabled={ disabled }
          />,
        ) }
      </Form.Item>
    </div>);
  }
}
