import { message } from 'antd';
import { simpleAjaxDispatch } from './http';

export function ssoLogin(username = null) {
  return (dispatch, getState) => simpleAjaxDispatch(
    `/sso/${username}`,
    'fetchSSOurl',
    { method: 'GET' },
  )(dispatch, getState).then(action => {
    if (action.success) {
      dispatch({
        type: 'SET_SSO_URL',
        ssoURL: action.data.authMethod,
      });
    } else if (action.response.status === 401) {
      message.warning('Please login again');
    }
    return action;
  });
}

export function loginSSO(username) {
  return ssoLogin(username);
}

export function createSSOCreds(json) {
  return simpleAjaxDispatch(
    '/saml/creds',
    'createSSOCreds',
    { method: 'POST', json },
  );
}

export function deleteSSOCreds(ssoId) {
  return simpleAjaxDispatch(
    `/saml/creds/${ssoId}`,
    'deleteSSOCreds',
    { method: 'DELETE' },
  );
}

export function fetchSSOCreds() {
  return simpleAjaxDispatch(
    '/saml/creds',
    'fetchSSOCreds',
  );
}
