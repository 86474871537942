import _ from 'lodash';

import { fromJS } from 'immutable';

import { Reducer } from './base';

export class SsoReducer extends Reducer {
  handleHttpUpdatedAction(prevState, state, action) {
    const isSSOAuthAction = action.group === 'fetchSSOurl';

    if (action.success && isSSOAuthAction) {
      return state.setIn(['sso'], { authMethod: action.data.authMethod });
    }

    if (action.inProgress && isSSOAuthAction) {
      return state.mergeIn(['sso'], { loginError: null });
    }

    if (action.failed && isSSOAuthAction) {
      const msgs = _.get(
        action.data,
        ['_errors', 'authentication'],
        ['Incorrect username'],
      );
      return state.mergeIn(['sso'], {
        authMethod: null,
        loginError: fromJS(msgs),
      });
    }

    return state;
  }
}
