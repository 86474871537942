import { fromJS } from 'immutable';
import { Reducer } from './base';

import { STATE as defaultState } from '../defaults';

export class LinkageReducer extends Reducer {
  shouldHandle(state, action) {
    const runningId = state.getIn(['linkage', 'correlationId'], null);
    if (runningId === action.correlationId) return true; // Current linkage
    if (action.state === 'starting') return true; // New start event
    return false;
  }

  handleQldLinkageLoginMessage(prevState, state, action) {
    if (!this.shouldHandle(state, action)) return state;
    const { type, ...data } = action;
    return state.setIn('linkage', 'qld', fromJS(data));
  }

  handleClearQldLinkage(prevState, state, action) {
    return state.set('linkage', defaultState.get('linkage'));
  }

  handleUpdateNdwsLinkageStatusAction(prevState, state, action) {
    return state.set('ndwsLinkage', action);
  }

  handleUpdateNdwsLinkageStatusToDefaultAction(prevState, state, action) {
    return state.set('ndwsLinkage', defaultState.get('ndwsLinkage'));
  }

  handleNDWSLinkageErrorAction(prevState, state, action) {
    return state.setIn(['linkageError', 'ndws'], action.message);
  }
}
