import { Col, Modal, Row } from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { LogsBody } from './LogsBody';

class LogsModalComponent extends PureComponent {
  render() {
    const { webhookId, webhooksItems, ...modalProps } = this.props;
    const webhookIdInt = parseInt(webhookId, 10);
    const webhookData = webhooksItems
      ? webhooksItems.find(item => item.get('id') === webhookIdInt).toJS()
      : null;
    return (<Modal
      title="Webhook log"
      okButtonProps={{ style: { display: 'none' } }}
      width={ 800 }
      maskClosable={ true }
      { ... modalProps }
            >
      { webhookData
        && <Row>
          <Col span={ 10 }>
            <strong>Current HMAC secret</strong>
          </Col>
          <Col span={ 14 } style={{ overflow: 'scrollX' }}>
            <pre><code>{ webhookData.secret }</code></pre>
          </Col>
        </Row>
      }
      <LogsBody webhookId={ webhookId } />
    </Modal>);
  }
}

export const LogsModal = connect(
  (state, props) => ({
    webhooksItems: state.doc.getIn(['http', 'fetchWebhooks', 'data', 'items']),
  }),
)(LogsModalComponent);
