import {
  Form, Input, Modal, message, Spin, Alert,
} from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import * as actions from '../../../actions';
import { hasFieldsError } from '../../../utils';

class WebhookDetailsModalComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
    };
  }

  handleOk = ev => {
    const {
      addWebhook, patchWebhook, onOk, organizationId, form: { validateFields }, isNew, webhookId,
    } = this.props;
    this.setState({ saving: true });
    validateFields(async (errs, values) => {
      if (errs) return;
      try {
        const resp = isNew ? await addWebhook({
          organization: { id: organizationId },
          ...values,
        }) : await patchWebhook(
          webhookId,
          { ...values },
        );
        const { success } = resp;
        this.setState({ saving: false });
        if (success) {
          if (onOk) onOk(ev);
        } else {
          const msg = _.get(resp, ['data', '_errors', '_request']);
          message.error(msg || `Error ${isNew ? 'adding' : 'deleting'} webhook`);
        }
      } catch (err) {
        this.setState({ saving: false });
        console.error(`Error ${isNew ? 'adding' : 'deleting'} webhook`, err);
        message.error(`Error ${isNew ? 'adding' : 'deleting'} webhook`);
      }
    });
  }

  setWebhookId = id => {
    if (!id) {
      return;
    }
    this.props.getWebhook(id);
  }

  componentDidMount() {
    this.setWebhookId(this.props.webhookId);
  }


  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      isFieldTouched,
    } = this.props.form;

    const { webhook, getWebhookFailed, isNew } = this.props;
    const initialData = !isNew && webhook ? webhook.toJS() : [];
    return (<Modal
      title={ isNew ? 'Add web hook' : 'Edit web hook'}
      { ... this.props }
      okButtonProps={ {
        disabled: (
          hasFieldsError(getFieldsError())
          || isNew && !isFieldTouched('url')
          || isNew && !isFieldTouched('label')
        ),
      } }
      confirmLoading={ this.state.saving }
      onOk={ this.handleOk }
            >
      {getWebhookFailed && !isNew && <Alert type="error" message="Couldn't load webhook details" showIcon />}
      <Spin spinning={ !isNew && getWebhookFailed}>
        <Form>
          <Form.Item
            label="Endpoint URL"
          >
            { getFieldDecorator('url', {
              initialValue: initialData.url,
              rules: [
                { type: 'url', message: 'Not a valid URL' },
                { required: true, message: 'Endpoint URL is required' },
              ],
            })(
              <Input disabled={!isNew}/>,
            ) }
          </Form.Item>
          <Form.Item
            label="Label"
          >
            { getFieldDecorator('label', {
              initialValue: initialData.label,
              rules: [
                { required: true, message: 'A label is required' },
              ],
            })(
              <Input />,
            ) }
          </Form.Item>
          <Form.Item
            label="Username"
          >
            { getFieldDecorator('username', {
              initialValue: initialData.username,
              rules: [
                { required: false },
              ],
            })(
              <Input />,
            ) }
          </Form.Item>

          <Form.Item
            label="Password"
          >
            { getFieldDecorator('password', {
              rules: [
                { required: false },
              ],
            })(
              <Input type="password" />,
            ) }
          </Form.Item>
        </Form>
      </Spin>
    </Modal>);
  }
}

export const WebhookDetailsModalForm = Form.create()(WebhookDetailsModalComponent);

export const WebhookDetailsModal = connect(
  state => ({
    organizationId: state.doc.getIn(['authMr', 'organizationId']),
    webhook: state.doc.getIn(['http', 'getWebhook', 'data'], null),
    getWebhookFailed: state.doc.getIn(['http', 'getWebhook', 'failed']),

  }),
  actions,
)(WebhookDetailsModalForm);
