import _ from 'lodash';
import {
  Alert, Icon, Modal, Progress, Upload, message,
} from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import * as actions from '../actions';
import { flattenObj } from '../utils';
import { IMPORT_HELP_LINK, IMPORT_TEMPLATE_LINK } from '../defaults';

export class ConstituentImportModalComponent extends PureComponent {
  state = {
    fileId: null,
    fileName: null,
  }

  componentWillMount() {
    this.props.clearConstituentImport();
  }

  handleOk = () => {
    this.props.completeConstituentImport(this.state.fileId);
  }

  handleCancel = evt => {
    if (this.props.onCancel) this.props.onCancel(evt);
  }

  handleSubmit = evt => {
  }

  customRequest = ({ file, onError, onProgress, onSuccess }) => {
    this.props.uploadConstituentImport(file).then(
      action => {
        if (action.success) {
          onSuccess(action.response.body);
          this.setState({
            fileId: action.meta.id,
            fileName: action.meta.originalFn,
          });
          if (this.props.onSaved) this.props.onSaved(action);
        } else {
          message.error("We're having trouble uploading your file right now.");
          onError(action.response, action.response.body);
          if (this.props.onSaveFailed) this.props.onSaveFailed(action);
        }
      },
      err => {
        if (this.props.onSaveFailed) this.props.onSaveFailed(err);
      },
    );
  }

  render() {
    const {
      title,
      saveInProgress,
      authInProgress,
      importData,
    } = this.props;
    const { fileId, fileName } = this.state;

    const {
      state: importState,
      errors: importErrors,
      total: importTotal,
      done: importDone,
    } = importData.toJS();
    const importComplete = importState === 'complete';
    const importIncomplete = !_.isNil(importState) && !importComplete;

    const importErrorsCount = importErrors ? importErrors.length : 0;
    const importHasErrors = importErrorsCount > 0;
    const importPercent = _.isNil(importTotal)
      ? 100 : (importDone + importErrorsCount) / importTotal * 100;
    // TODO this successPercent doesn't work for some reason
    /* const importSuccessPercent = _.isNil(importTotal) ?
      0 : (importDone - importErrors.length) / importTotal * 100 */
    // Note that the "exception" at the end of this is overridden by the
    // successPercent being 100% unless errors
    const importProgressStatus = (() => {
      if (!_.isNil(importTotal)) return null;
      return importHasErrors ? 'exception' : 'active';
    })();

    let importErrorMessages = [];
    if (importErrors) {
      importErrorMessages = _.flatten(importErrors.map((error, idx) => (error._errors
        ? Object.entries(flattenObj(error._errors)).map(
          ([field, innerError], innerIdx) => ({
            content: <span><em>{ _.startCase(field) }</em>: { innerError }</span>,
            rowIdx: error._import ? error._import.rowIdx : null,
          }),
        )
        : [{
          content: error,
          rowIdx: error._import ? error._import.rowIdx : null,
        }])));
    }

    const {
      true: generalErrorMessages,
      false: rowErrorMessages,
    } = _.groupBy(importErrorMessages, descr => _.isNil(descr.rowIdx));
    if (rowErrorMessages) {
      rowErrorMessages.sort((left, right) => {
        if (left.rowIdx < right.rowIdx) return -1;
        if (left.rowIdx > right.rowIdx) return 1;
        return 0;
      });
    }

    return (<Modal
      key="modal"
      wrapClassName={ fileName && !importComplete ? null : 'modal-hide-ok' }
      visible={ true }
      title={ title }
      onOk={ this.handleOk }
      onCancel={ this.handleCancel }
      okText="Import"
      cancelText="Close"
      confirmLoading={ authInProgress || saveInProgress || importIncomplete }
            >
      <div style={{ display: 'block', marginBottom: '20px' }}>
        <a href={IMPORT_HELP_LINK} alt='import help' target="_blank" rel="noopener noreferrer">Need help preparing an import file?</a><br/>
        <a href={IMPORT_TEMPLATE_LINK} alt='import template' target="_blank" rel="noopener noreferrer">Download import template</a>
      </div>
      { !fileId
        ? <Upload.Dragger
          style={{ padding: '16px' }}
          accept="text/csv,.csv"
          multiple={ false }
          customRequest={ this.customRequest }
          >
          <p className="ant-upload-drag-icon"><Icon type="inbox" /></p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
        </Upload.Dragger>
        : <p>{ fileName }</p>
      }
      { importIncomplete || importComplete
        ? <div>
          <Progress
            percent={ importPercent }
            status={ importProgressStatus }
            showInfo={ false }
          />
          { importTotal ? <div>
            <span><strong>{ importDone }</strong> successful / </span>
            <span><strong>{ importErrorsCount }</strong> errors / </span>
            <span><strong>{ importTotal }</strong> total</span>
          </div> : null }
        </div>
        : null
      }
      { importHasErrors
        ? <Alert
          message="Oh no! Looks like we had some problems"
          description={
            <div>
              { generalErrorMessages
                ? <ul>{ generalErrorMessages.map(msg => <li>{ msg.content }</li>) }</ul>
                : null
                }
              { rowErrorMessages
                ? <ol>{ rowErrorMessages.map(msg => <li value={ msg.rowIdx + 1 }>{ msg.content }</li>) }</ol>
                : null
                }
            </div>
            }
          type="error"
          showIcon
          />
        : null
      }
    </Modal>);
  }
}

export const ConstituentImportModal = connect(
  (state, props) => ({
    authErrors: state.doc.getIn(['http', 'uploadConstituentImportAuthorize', 'data', '_errors'], null),
    saveErrors: state.doc.getIn(['http', 'uploadConstituentImport', 'data', '_errors'], null),
    authInProgress: state.doc.getIn(['http', 'uploadConstituentImportAuthorize', 'inProgress'], false),
    saveInProgress: state.doc.getIn(['http', 'uploadConstituentImport', 'inProgress'], false),
    authFailed: state.doc.getIn(['http', 'uploadConstituentImportAuthorize', 'failed'], false),
    saveFailed: state.doc.getIn(['http', 'uploadConstituentImport', 'failed'], false),
    authSuccess: state.doc.getIn(['http', 'uploadConstituentImportAuthorize', 'success'], false),
    saveSuccess: state.doc.getIn(['http', 'uploadConstituentImport', 'success'], false),
    importData: state.doc.getIn(['import']),
  }),
  actions,
)(ConstituentImportModalComponent);
