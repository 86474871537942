import { fromJS } from 'immutable';
import { Reducer } from './base';

import { STATE as defaultState } from '../defaults';

export class ImportQldblueReducer extends Reducer {
  shouldHandle(state, action) {
    const runningId = state.getIn(['importQldblue', 'correlationId'], null);
    if (runningId === action.correlationId) return true; // Current import
    if (action.state === 'starting') return true; // New start event
    return false;
  }

  handleUpdateQldblueImportStatusAction(prevState, state, action) {
    if (!this.shouldHandle(state, action)) return state;
    const { type, ...data } = action;
    if (action.state === 'starting') {
      return state.set('importQldblue', fromJS(data));
    }
    return state.mergeIn(['importQldblue'], fromJS(data));
  }

  handleAddQldblueImportErrorAction(prevState, state, action) {
    if (!this.shouldHandle(state, action)) return state;
    return state.setIn(
      ['importQldblue', 'errors'],
      state.getIn(['importQldblue', 'errors']).push(action.error),
    );
  }

  handlePushQldblueImportCompletionAction(prevState, state, action) {
    if (!this.shouldHandle(state, action)) return state;
    return state.setIn(
      ['importQldblue', 'done'],
      state.getIn(['importQldblue', 'done'], 0) + 1,
    );
  }

  handleClearQldblueImportAction(prevState, state, action) {
    return state.set('importQldblue', defaultState.get('importQldblue'));
  }
}
