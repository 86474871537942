import { Spin } from 'antd';
import React, { PureComponent } from 'react';

import { VERSION } from '../defaults';

export class Version extends PureComponent {
  constructor() {
    super();
    this.state = {};
  }

  componentWillMount() {
    fetch('/api/health').then(resp => resp.json().then(
      data => this.setState({ api: data.version }),
    ));
  }

  render() {
    const { api } = this.state;
    return (<div style={{ fontFamily: 'monospace', fontSize: '12px' }}>
      <div><strong>App:</strong> { VERSION }</div>
      <div><Spin spinning={ !api }><strong>API:</strong> { api }</Spin></div>
    </div>);
  }
}
