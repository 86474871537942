import {
  Alert, Card, Col, Row, Spin, Statistic, Typography,
} from 'antd';
import qs from 'querystringify';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { dynamicSort } from '../../utils';

import { BreadcrumbLevel } from '../BreadcrumbLevel';
import { withContentLayout } from '../layout/Layout';

import * as actions from '../../actions';

const { Title } = Typography;


class ReportsSummaryComponent extends PureComponent {
  createClickHandler = ({ tags, organizationId }) => ev => {
    const tempTags = tags.slice();
    const qsData = { filters: tempTags.join(','), organizationId };
    this.props.history.push(`/constituents#${qs.stringify(qsData)}`);
  }

  buildTile = (report, organizationId, idx) => (
    <Col key={idx}>
      <Card
        style={{
          marginRight: '16px', marginBottom: '16px', borderRadius: '20px', alignContent: 'center', display: 'flex',
        }}
        onClick={this.createClickHandler({ tags: [report.tagId], organizationId })}
        hoverable
      >
        <Title level={4} style={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center' }}>{report.label}</Title>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Statistic
            key={idx}
            style={{
              backgroundColor: `#${report.color}20`, height: '100px', width: '100px', textAlign: 'center', padding: '30px 25px', borderRadius: '50%',
            }}
            value={report.count}
            valueStyle={{ color: `#${report.color}` }}
          />
        </div>
      </Card>
    </Col>
  )

  buildReportRow = (
    reportsSummaryDataFailed,
    reportsSummaryDataInProgress,
    reportsSummaryDataJs,
    organizationId,
  ) => {
    let sorted = null;
    if (reportsSummaryDataJs && Object.keys(reportsSummaryDataJs).length === 0) return null;
    sorted = reportsSummaryDataJs.sort(dynamicSort('category'));
    const uniqueCategories = sorted.map(item => item.category).filter((value, index, self) => self.indexOf(value) === index);

    const reportRow = sorted ? uniqueCategories.map((category, idx) => (
      <Row key={idx}>
        <h1 style={{ marginBottom: '20px' }}><b>{category}</b></h1>
        {reportsSummaryDataFailed
          ? <Alert type="error" message="Couldn't retrieve data" />
          : <Spin spinning={reportsSummaryDataInProgress}>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
              {
                reportsSummaryDataJs.map((report, idx) => {
                  let tile = null;
                  if (report.category === category) {
                    tile = this.buildTile(report, organizationId, idx);
                  }
                  return tile;
                })
              }
            </div>
          </Spin>
        }
      </Row>
    )) : null;
    return reportRow;
  }

  render() {
    const {
      organizationId, reportsSummaryData, reportsSummaryDataFailed, reportsSummaryDataInProgress,
    } = this.props;
    const reportsSummaryDataJs = reportsSummaryData ? reportsSummaryData.toJSON() : {};

    if (reportsSummaryDataInProgress) return <Spin />;

    return this.buildReportRow(
      reportsSummaryDataFailed,
      reportsSummaryDataInProgress,
      reportsSummaryDataJs,
      organizationId,
    );
  }
}
const ReportsSummary = withRouter(connect(
  state => ({
    reportsSummaryData: state.doc.getIn(['http', 'reportsSummary', 'data'], null),
    reportsSummaryDataFailed: state.doc.getIn(['http', 'reportsSummary', 'failed'], false),
    reportsSummaryDataInProgress: state.doc.getIn(['http', 'reportsSummary', 'inProgress'], true),
    organizationId: state.doc.getIn(['authMr', 'organizationId'], null),
  }),
  actions,
)(ReportsSummaryComponent));

@withContentLayout
class DashboardReportsPageComponent extends PureComponent {
  componentWillMount() {
    if (!this.props.organizationId) return;
    this.props.fetchReportsSummary(this.props.organizationId);
  }

  render() {
    const { reportsSummaryData } = this.props;
    return (
      <>
        <div>
          <BreadcrumbLevel text="Reports" />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <ReportsSummary
            reports={reportsSummaryData}
          />
        </div>
      </>
    );
  }
}

export const DashboardReportsPage = connect(
  state => ({
    authConstituentId: state.doc.getIn(['authMr', 'constituent', 'id'], null),
    reportsSummaryData: state.doc.getIn(['http', 'reportsSummary', 'data'], null),
    organizationId: state.doc.getIn(['authMr', 'organizationId'], null),
  }),
  actions,
)(DashboardReportsPageComponent);
