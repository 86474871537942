import _ from 'lodash';
import {
  Avatar, Button, Divider, List, Tooltip, Modal, Row, Col, Switch, message,
} from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as actions from '../../../actions';
import { BreadcrumbLevel } from '../../BreadcrumbLevel';
import { SAMLModal as AddModal } from './SAMLModal';
import { withContentLayout } from '../../layout/Layout';
import { checkFeature } from '../../../utils';


const { confirm } = Modal;

const SAMLCredDetails = ({ details, handleCancel, visible }) => {
  const keyTitleMap = {
    cert: 'Certification',
    idpSsoUrl: 'IDP SSO URL',
    idpUrl: 'IDP URL',
    ssoId: 'SSO Id',
  };

  details && details.orgId && delete details.orgId;

  return (
    <Modal
      title="SAML Credentails"
      width={ 800 }
      visible={visible}

      onOk={handleCancel}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
      ]}
    >

      {
      details && Object.keys(details).map((d, k) => (<Row key={k}>
        <Col span={ 10 }>
          <strong>{keyTitleMap[d]}</strong>
        </Col>
        <Col span={ 14 }>
          <pre><code> {details[d]} </code> </pre>
        </Col>
      </Row>))
     }


    </Modal>
  );
};

const SAMLListItem = ({
  cert, idpSsoUrl, idpUrl, ssoId, onSelectCred, onDelete,
}) => (
  <List.Item
    actions={[
      <Button icon='delete' className= 'doc-danger-btn-3' onClick={ async () => await onDelete(ssoId)}>Delete</Button>,
    ]}
  >
    <List.Item.Meta
      avatar={
        <Tooltip title={ _.startCase('SAML Credential') }>
          <Avatar icon="safety-certificate" size="large" style={{ backgroundColor: 'grey' }} />
        </Tooltip>
        }
      title={idpSsoUrl}
      description={ssoId}
      onClick={() => onSelectCred(ssoId)}
    />
  </List.Item>
);

@withContentLayout
class SAMLSettingsPageComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ModalVisible: false,
      samlCredsVisible: false,
      selectedSSOId: '',
      // addModalKey: 0,
      enforceToggleValue: false,
      enforceToggleLoading: true,
    };
  }

  componentDidMount() {
    this.getMFAToggleInfo();
  }

  componentWillUnmount() {
    this.setState({
      enforceToggleValue: false,
      enforceToggleLoading: true,
    });
  }

  getHttpError(data) {
    if (!data._errors) return '';
    return Object.values(data._errors).flat().join('|');
  }

  getMFAToggleInfo = async () => {
    const response = await this.props.getEnforceMFADetails(this.props.organizationId);
    if (response.failed) {
      this.setState({ enforceToggleLoading: false });
      message.error(this.getHttpError(response.data));
      return;
    }
    this.setState({ enforceToggleValue: response.data.totpEnforced, enforceToggleLoading: false });
  }

  handleEnforceToggle = async () => {
    this.setState({ enforceToggleValue: !this.state.enforceToggleValue });
  }

  handleEnforceMFA = async () => {
    if (this.props.orgTotpStatus === this.state.enforceToggleValue) return;

    if (!this.state.enforceToggleValue) {
      confirm({
        title: 'Disable MFA?',
        content: <>Are you sure you want to disable MFA for this organization?</>,
        onOk: async () => {
          await this.enforceMfa();
          this.getMFAToggleInfo();
        },
        onCancel: () => {},
      });
      return;
    }
    confirm({
      title: 'Enable MFA?',
      content: <>After enabling MFA, all your administrators will need to setup accordingly at their next login attempt. You will be redirected to register an MFA device immediately (if you don't already have one)</>,
      onOk: async () => {
        await this.enforceMfa();
        if (this.state.enforceToggleValue === true) this.props.logout();
      },
      onCancel: () => {},
    });
  }

  enforceMfa = async () => {
    this.setState({ enforceToggleLoading: true });
    const response = await this.props.enforceMFA(this.props.organizationId, { totp_enforced: this.state.enforceToggleValue });
    if (!response.failed) {
      this.setState({ enforceToggleValue: response.data.totpEnforced, enforceToggleLoading: false });
    } else {
      this.setState({ enforceToggleLoading: false });
      message.error(this.getHttpError(response.data));
    }
  }

  componentWillMount() {
    this.props.fetchSSOCreds();
  }

  handleAddClick = () => {
    this.setState({ ModalVisible: true });
  }

  handleAddModalCancelClick = () => {
    this.setState({ ModalVisible: false });
  }

  handleAddModalOkClick = () => {
    this.props.fetchSSOCreds();
    this.handleAddModalCancelClick();
  }

  handleSelectCred = ssoId => {
    this.setState({ selectedSSOId: ssoId, samlCredsVisible: true });
  }

  handleDelete = async ssoId => {
    confirm({
      title: 'Do you want to delete this SAML credential?',
      content: <>Are you sure? This action is not reversible.</>,
      onOk: async () => {
        await this.props.deleteSSOCreds(ssoId);
        await this.props.fetchSSOCreds();
      },
      onCancel: () => {},
    });
  }

  featureFlagStatus = () => {
    let status = true;
    if (!checkFeature('mfa') && !this.props.currentUserMfaStatus) status = false;
    return status;
  }

  render() {
    const { fetchSSOCredsItems } = this.props;
    const items = fetchSSOCredsItems ? fetchSSOCredsItems.toJS() : [];

    return (
      <div>
        <BreadcrumbLevel text="Settings" />
        <BreadcrumbLevel text="SAML" />
        <div style={{ display: 'flex' }}>
          <p style={{ flexGrow: 1, paddingRight: '16px' }}>
                  Configure your Organization SAML credentials with Oho to login with sso...
          </p>
          <Button.Group style={{ flexShrink: 1 }}>
            <Button icon="plus" onClick={ this.handleAddClick }>Add</Button>
          </Button.Group>
        </div>
        <Divider />
        <AddModal
          visible={ this.state.ModalVisible }
          onOk={ this.handleAddModalOkClick }
          onCancel={ this.handleAddModalCancelClick }
        />
        <SAMLCredDetails
          visible={this.state.samlCredsVisible}
          details={items.find(({ ssoId }) => ssoId === this.state.selectedSSOId)}
          handleCancel={() => this.setState({ samlCredsVisible: false, selectedSSOId: '' })}
        />
        <List
                  // loading={ '' }
          dataSource={ items }
          renderItem={ item => <SAMLListItem {...item} onSelectCred={this.handleSelectCred} onDelete={this.handleDelete}/> }
        />

        { this.featureFlagStatus()
          ? <>
            <div style={{ display: 'flex' }}>
              <p style={{ flexGrow: 1, paddingRight: '16px' }}>
                  Enforce Multi-factor authentication (MFA)
              </p>
              <Switch loading={this.state.enforceToggleLoading} style={{ marginTop: '7px' }} onChange={this.handleEnforceToggle} checked={this.state.enforceToggleValue} />
            </div>
            <div><Button type='primary' style={{ float: 'right', marginTop: '25px', marginBottom: '25px' }} loading={this.state.enforceToggleLoading} onClick={this.handleEnforceMFA} >Save</Button></div>
            <Divider/>
          </>
          : null
        }
      </div>
    );
  }
}

export const SAMLSettingsPage = withRouter(connect(
  state => ({
    fetchSSOCredsFailed: state.doc.getIn(['http', 'fetchSSOCreds', 'failed'], false),
    fetchSSOCredsItems: state.doc.getIn(['http', 'fetchSSOCreds', 'data', 'items']),
    organizationId: state.doc.getIn(['authMr', 'constituent', 'organization', 'id'], null),
    currentUserMfaStatus: state.doc.getIn(['auth', 'totpEnabled'], false),
    orgTotpStatus: state.doc.getIn(['http', 'getOrgMfaDetails', 'data', 'totpEnforced'], false),
  }),
  actions,
)(SAMLSettingsPageComponent));
