import { Form, Input, Radio } from 'antd';
import React, { PureComponent } from 'react';
import { API_DATE_FS, NON_ALPHA_NUM_SLASH_RE } from './shared';

import getDateOfBirthField from '../../fields/DateOfBirthField';

export class NswWwcFormItems extends PureComponent {
  static slug = 'nswwwc';

  static placeholder = '';

  static identLabel = 'Card number'; // More id names and labels have to be added

  static getSubmissionData(checkData, constituent) {
    return {
      fullName: constituent && constituent.get('fullName'),
      birth_date: (constituent && constituent.get('birthDate')) || (checkData && checkData.birth_date.format(API_DATE_FS)),
      cardType: checkData && checkData.card_type,
      isNew: !constituent.get('birthDate'),
      origin: 'doc',
    };
  }

  static regNumberGetValueFromEvent(evt) {
    return evt.target.value.replace(NON_ALPHA_NUM_SLASH_RE, '');
  }

  constructor(props) {
    super(props);
    this.state = {
      cardType: 'ACC_NO',
      placeholder: 'Accreditation Number',
      identLabel: 'Accreditation Number',
    };
  }

  onToggle = e => {
    let identLabel = 'Accreditation Number';
    let placeholder = 'Accreditation Number';
    let cardType = 'ACC_NO';
    if (e.target.value === 'APP_NO') {
      identLabel = 'Application Number';
      placeholder = 'Application Number';
      cardType = 'APP_NO';
    }
    this.setState({ cardType, placeholder, identLabel });
  }

  render() {
    const { form, disabled, rules, constituent } = this.props;
    const { getFieldDecorator } = form;
    const birthDate = constituent && constituent.get('birthDate');

    const {
      slug,
      regNumberGetValueFromEvent,
    } = this.constructor;

    const {
      placeholder, cardType, identLabel,
    } = this.state;

    return (<div>
      { !this.props.isNSWConfigured
        && <div style={{ paddingBottom: '30px' }}>
          <span style={{ color: 'tomato' }}>**NSW WWC requires configuration for your organisation before it can be used. Please go to the Settings / Provider Settings to complete the relevant setup.</span>
        </div>
      }
      <Form.Item label="Select Accreditation Type" required>
        {getFieldDecorator(`${slug}.card_type`, {
          setFieldsValue: cardType,
          initialValue: cardType,
        })(
          <Radio.Group onChange={this.onToggle}>
            <Radio.Button value={'ACC_NO'}>Accreditation Number</Radio.Button>
            <Radio.Button value={'APP_NO'}>Application Number</Radio.Button>
          </Radio.Group>,
        )}
      </Form.Item>
      { cardType
        && <Form.Item label={identLabel}>
          {getFieldDecorator(`${slug}.identifier`, {
            getValueFromEvent: regNumberGetValueFromEvent,
            rules: [
              {
                required: true,
                whitespace: true,
                message: `${identLabel} is required`,
              },
              ...(rules || []),
            ],
          })(
            <Input
              placeholder={placeholder}
              disabled={disabled}
              size="default"
            />,
          )}
        </Form.Item>
      }
      <Form.Item
        label="Date of Birth"
      >
        {birthDate || (getFieldDecorator(`${slug}.birth_date`, {
          rules: [
            {
              required: true,
              message: 'Date of Birth is required',
            },
            ...(rules || []),
          ],
        }))(getDateOfBirthField({ initialValue: birthDate, yearsToRestrict: 18, disabled }))
        }
      </Form.Item>

    </div>);
  }
}
