/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import {
  Modal, Row, Col, Input, Select, Checkbox, Form, Button, Tooltip, Typography,
} from 'antd';

const { Option } = Select;
const { Text } = Typography;

const generateErrors = errors => {
  const errorComponents = [];
  if (!errors) return errorComponents;
  delete errors.sharingCode;

  for (const error in errors) {
    errors[error].forEach(message => {
      errorComponents.push(
        <Row>
          <Col span={12} offset={6}>
            <Text type='danger'>{message}</Text>
          </Col>
        </Row>,
      );
    });
  }
  return errorComponents;
};

const CreateRequestModal = ({
  visible, handleCancel, relationshipTypes, relationshipAttributes, form: { getFieldDecorator }, tags, inactiveAttributes,
  handleAttributeChange, handleSubmit, handleRoleChange, handleOrganizationNameLookup, sharingCodeOrganizationName,
  serverErrors,
}) => (
  <Modal
    title="Create Request"
    visible={visible}
    onCancel={handleCancel}
    style={{ minWidth: 1200 }}
    footer={[
      <Button key="back" onClick={handleCancel}>
          Cancel
      </Button>,
      <Button key="submit" disabled={!sharingCodeOrganizationName || sharingCodeOrganizationName === 'No organization found'} htmlType="submit" onClick={handleSubmit} type="primary">
          Submit
      </Button>,
    ]}
  >

    <Form.Item label="Related Party Sharing Code:">
      {getFieldDecorator('sharingCode', {
        rules: [
          {
            required: true,
            message: 'Please enter the Sharing Code',
          },
        ],
      })(<Input onBlur={handleOrganizationNameLookup} style={{ maxWidth: 160 }}/>)}
      {sharingCodeOrganizationName ? <span style={{ padding: '10px' }}><b style={sharingCodeOrganizationName === 'No organization found' ? { color: 'red' } : null }>{sharingCodeOrganizationName}</b></span> : null}

    </Form.Item>

    <Form.Item label="Relationship Role:">
      {getFieldDecorator('role', {
        rules: [
          {
            required: true,
            message: 'Please select a Role',
          },
        ],
      })(<Select
        showSearch
        style={{ width: 200 }}
        placeholder="Role"
        onChange={handleRoleChange}
         >
        {relationshipTypes.map(type => <Option value={type._id}>{type.name}</Option>)}
      </Select>)}
    </Form.Item>

    <Form.Item label="Sharing Attributes:">
      {getFieldDecorator('attributes', {
        rules: [
          {
            required: true,
            message: 'Please select atleast one Attribute',
          },
        ],
      })(<Checkbox.Group style={{ width: '100%' }} onChange={handleAttributeChange}>
        <Row>
          {relationshipAttributes.map(attribute => (<Col span={8}>
            <Tooltip placement='topLeft' title={attribute.description}>
              <Checkbox value={attribute._id} disabled={inactiveAttributes.includes(attribute._id)}>{attribute.name}</Checkbox>
            </Tooltip>
          </Col>))}
        </Row>
      </Checkbox.Group>)}
    </Form.Item>

    <Form.Item label="Filtering Tag Name:">
      {getFieldDecorator('tags')(<Select
        style={{ minWidth: 600 }}
        showSearch
        optionFilterProp="children"
        placeholder="select a Tag"
        filterOption
                                 >
        {tags.map(tag => <Option value={tag.id}>{tag.label}</Option>)}
      </Select>)}
    </Form.Item>
    {generateErrors(serverErrors).map(component => component)}

  </Modal>
);

export default CreateRequestModal;
