import React, { PureComponent } from 'react';

import {
  Icon, Input,
} from 'antd';

import { ActionText } from '../../elements';

class FilterSearch extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      sortBy: 'full_name',
      sortOrder: 'asc',
      tagFilterQuery: '',
    };
  }

  handleSearchTerm = evt => {
    this.setState({ searchTerm: evt.target.value });
    if (this.props.allowSearchOnKeyChange) this.props.setQuery(evt.target.value);
  }

  handleClearQuery = evt => {
    this.props.setQuery('');
    this.setState({ searchTerm: '' });
  }

  handleKeyUp = evt => {
    if (evt.key === 'Enter') {
      this.props.setQuery(this.state.searchTerm);
      if (this.props.onEnter) this.props.onEnter(this.state.searchTerm);
    }
  }

  render() {
    const { query } = this.props;
    return (
      <div style={{ position: 'relative', width: '350px' }}>
        <Input
          style={{ width: '100%', borderRadius: '25px' }}
          placeholder="Search..."
          onChange={ this.handleSearchTerm }
          onKeyUp={ this.handleKeyUp }
          value={ this.state.searchTerm }
          ref="query"
        />
        { query
          ? <ActionText
            style={{
              position: 'absolute',
              width: '32px',
              lineHeight: '32px',
              right: 0,
              textAlign: 'center',
              color: 'rgba(0, 0, 0, 0.45)',
            }}
            onClick={ this.handleClearQuery }
            ><Icon type="close-circle" /></ActionText> : null
            }
      </div>
    );
  }
}

export {
  FilterSearch,
};
