import { Form, Alert, message } from 'antd';
import React, { PureComponent, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { GreenPage } from './GreenPageBase';

import * as actions from '../../actions';

class EmailVerificationPageComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { ...EmailVerificationPageComponent.state };
  }

  componentDidMount() {
    this.props.emailVerification(this.props.verificationToken);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.httpSuccess) {
      props.refreshToken();
      props.history.push('/login');
      props.initApp();
      message.success('All done! Your email is verified.');
    }
    if (props.verificationErrors) {
      const verificationErrors = props.verificationErrors.toJS() || {};
      Object.values(verificationErrors).forEach(errors => {
        errors.forEach(error => {
          message.error(<span>{ error }</span>);
        });
      });
    }
    return null;
  }

  render() {
    const { httpFailed } = this.props;
    if (httpFailed) {
      return (
        <GreenPage>
          <span style={{ textAlign: 'left' }}>
            <Alert
              message="Email Verification"
              type="warning"
              showIcon
              description={
                <Fragment>
                  <p>Your email verification has not been successful,
                   please contact support@weareoho.com if you need assistance.</p>
                  <p>
                    <Link to="/login">Back to sign in page</Link>
                  </p>
                </Fragment> }
            /></span>
        </GreenPage>);
    }
    return (<div>Attempting Email Verification...</div>);
  }
}
const EmailVerificationPageForm = Form.create()(EmailVerificationPageComponent);

export const EmailVerificationPage = connect(
  state => ({
    authenticated: state.doc.getIn(['authMr', 'authenticated'], false),
    verificationToken: state.doc.getIn(['authMr', 'verificationToken']),
    httpFailed: state.doc.getIn(['http', 'emailVerification', 'failed'], false),
    httpSuccess: state.doc.getIn(['http', 'emailVerification', 'success'], false),
    verificationErrors: state.doc.getIn(['http', 'emailVerification', 'data', '_errors'], null),
    httpInProgress: state.doc.getIn(['http', 'emailVerification', 'inProgress'], false),
  }),
  actions,
)(EmailVerificationPageForm);
