import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';

import styled from 'styled-components';

import {
  Alert,
  Button,
  Dropdown,
  Icon,
  Layout,
  Menu,
} from 'antd';
import * as actions from '../../actions';

import { ActionText, MaybeLink } from '../elements';
import { SpinUntilUser, withCurrentUser } from '../../whoami';

const HeaderWrap = styled(Layout.Header)`
  display: flex;
`;

const HeaderElement = styled.div`
  flex-grow: 0;
  margin: 0 10px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

class ActivateButton extends PureComponent {
  render() {
    return (
      <Fragment>
        <a href="/billing.html"><Button type='primary' onClick={this.activateSubscription}>Activate my subscription</Button></a>
      </Fragment>
    );
  }
}

class HeaderElements extends PureComponent {
  render() {
    const { organization, constituent, extra } = this.props;

    return (
      <Fragment>
        <HeaderElement style={{ flexGrow: 1 }}>
          <h1>{ organization.name }</h1>
        </HeaderElement>

        { extra.map((el, idx) => (<HeaderElement key={idx}>{el}</HeaderElement>)) }

        <HeaderElement>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item><MaybeLink href={ `/constituents/${constituent.id}/details` }>Profile</MaybeLink></Menu.Item>
                <Menu.Item><MaybeLink href='/logout'>Logout</MaybeLink></Menu.Item>
              </Menu>
              }
            placement="bottomRight"
          >
            <ActionText style={{ color: '#C88242' }}>
              Hello { constituent.fullName.split(' ')[0] } <Icon type="down" />
            </ActionText>
          </Dropdown>
        </HeaderElement>
      </Fragment>
    );
  }
}

@withCurrentUser
@connect(state => ({}), actions)
class HeaderBar extends PureComponent {
  nullClick = evt => evt.preventDefault()

  handleImpersonateDoneClick = async () => {
    await this.props.completeImpersonation(this.props.userId);
    localStorage.docToken = localStorage.docRootToken;
    document.location.reload();
  }

  handleResendEmailClick = async () => {
    await this.props.resendEmailVerification();
  }

  render() {
    const {
      currentUser: {
        constituent,
        organization,
        emailVerified,
      },
    } = this.props;

    const extraElements = [];
    const banners = [];

    if (localStorage.docToken !== localStorage.docRootToken) {
      extraElements.push(
        <Button type="danger" ghost={ true } onClick={ this.handleImpersonateDoneClick }>
          <Icon type="team" /> Done
        </Button>,
      );
    }

    if (organization && organization.freeTrial) {
      extraElements.push(
        <ActivateButton/>,
      );

      const expires = moment(organization.freeTrial.expiry);
      if (moment().isAfter(expires)) {
        banners.push({
          message: 'Your free trial has expired. Accreditation data may not be current.',
          type: 'error',
        });
      } else if (moment().isAfter(expires.clone().subtract(7, 'days'))) {
        banners.push({
          message: `Your free trial expires ${expires.fromNow()}`,
          type: 'warning',
        });
      }
    }

    if (emailVerified != null && !emailVerified) {
      extraElements.push(
        <Button type="danger" ghost={ true } onClick={ this.handleResendEmailClick }>
          <Icon type="mail" /> Resend Email
        </Button>,
      );
      banners.push({
        description: 'Welcome to Oho! Please verify your email to access all features.',
        type: 'warning',
      });
    }

    return (
      <Fragment>
        <HeaderWrap>
          <SpinUntilUser>
            <HeaderElements organization={organization} constituent={constituent} extra={extraElements}/>
          </SpinUntilUser>
        </HeaderWrap>
        { banners.map((banner, idx) => (
          <Alert key={idx} banner {...banner} />
        )) }
      </Fragment>
    );
  }
}

export { HeaderBar };
