import { NON_NUM_RE, RegNumberFormItems } from './shared';

export class QctFormItems extends RegNumberFormItems {
  static slug = 'qct'

  static placeholder = '123456'

  static regNumberGetValueFromEvent(evt) {
    return evt.target.value.substr(0, 6).replace(NON_NUM_RE, '');
  }
}
