import { genericAjaxDispatch, simpleAjaxDispatch } from './http';

const IMPORT_URL_BASE = '/attachments';

export function deleteAttachment(id) {
  return simpleAjaxDispatch(
    `${IMPORT_URL_BASE}/${id}`,
    'deleteAttachment',
    { method: 'DELETE' },
  );
}

export function fetchAttachments() {
  return simpleAjaxDispatch(
    `${IMPORT_URL_BASE}?detail=full`,
    'fetchAttachments',
  );
}

export function submitAttachmentURL(constituentId, accredId, url) {
  return simpleAjaxDispatch(
    IMPORT_URL_BASE,
    'submitAttachmentURL',
    { method: 'POST', json: { constituent_id: constituentId, accreditation_id: accredId, url } },
  );
}

export function uploadAttachment(file, constituentId, accId) {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    genericAjaxDispatch(
      dispatch, getState, IMPORT_URL_BASE,
      'uploadAccredAttachmentAuthorize',
      {
        method: 'POST',
        json: { accreditation_id: accId, constituent_id: constituentId, filename: file.name, type: file.type },
        responseSnake: false,
      },
    ).then(
      authAction => {
        if (authAction.data._errors) return reject(authAction.data._errors)
        const formData = new FormData();
        Object.entries(authAction.data.upload.fields).forEach(
          ([key, value]) => formData.append(key, value),
        );
        formData.append('Content-Disposition', `attachment; filename="${file.name}"`);
        formData.append('Content-Type', file.type);
        formData.append('file', file);

        genericAjaxDispatch(
          dispatch, getState,
          authAction.data.upload.url,
          'uploadAccredAttachment',
          {
            method: 'POST',
            body: formData,
            auth: false,
            meta: authAction.data,
          },
        ).then(resolve, reject);
      }, reject,
    );
  });
}
