import { simpleAjaxDispatch } from './http';

export function postDefineType(json) {
  return simpleAjaxDispatch(
    '/accreditations/definitions',
    'postDefineType',
    { method: 'POST', json },
  );
}

export function fetchDefineTypes() {
  return simpleAjaxDispatch(
    '/accreditations/definitions?order_by=effective_to&seq=asc',
    'fetchDefineTypes',
  );
}

export function fetchSelectedDefineType(typeId) {
  return simpleAjaxDispatch(
    `/accreditations/definitions/${typeId}`,
    'fetchSelectedDefineType',
    { useSeq: true, meta: { typeId } },
  );
}

export function patchDefineType(typeId, json) {
  return simpleAjaxDispatch(
    `/accreditations/definitions/${typeId}`,
    'patchDefineType',
    { method: 'PATCH', meta: { typeId }, json },
  );
}
