import { simpleAjaxDispatch } from './http';
import { checkFeature } from '../utils';

export function fetchRelationships() {
  return simpleAjaxDispatch(
    '/organizations/relationships?active=true',
    'fetchRelationships',
  );
}

export function fetchRelationshipTypes() {
  return simpleAjaxDispatch(
    '/organizations/relationships/types',
    'fetchRelationshipTypes',
  );
}

export function fetchRelationshipAttributes() {
  return simpleAjaxDispatch(
    '/organizations/relationships/attributes',
    'fetchRelationshipAttributes',
  );
}

export function fetchRelationshipKey(relationshipId) {
  return simpleAjaxDispatch(
    `/organizations/relationships/${relationshipId}/key`,
    'fetchRelationshipKey',
  );
}

export function createRelationship(json) {
  return simpleAjaxDispatch(
    '/organizations/relationships',
    'createRelationship',
    { method: 'POST', json },
  );
}

export function patchRelationship(relationshipId, json) {
  return simpleAjaxDispatch(
    `/organizations/relationships/${relationshipId}`,
    'patchRelationship',
    { method: 'PATCH', json },
  );
}

export function endRelationship(relationshipId) {
  return simpleAjaxDispatch(
    `/organizations/relationships/${relationshipId}`,
    'endRelationship',
    { method: 'DELETE' },
  );
}

export function fetchOrganizationBySharingCode(sharingCode) {
  return simpleAjaxDispatch(
    `/organizations/by-sharing-code/${sharingCode}`,
    'fetchOrganizationBySharingCode',
  );
}
