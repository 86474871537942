import { Input } from 'antd';
import React from 'react';

import { getLibPhoneNumber } from '../../asyncModules';
import { formatPhone, parsePhone } from '../../utils';

function validatorWrapper(value, errorsCallback, libphoneCallback) {
  if (!value) { errorsCallback([]); return; }
  getLibPhoneNumber().then(
    libphoneCallback,
    () => errorsCallback([]), // Don't block forever
  );
}

export function phoneNumberValidator(rule, value, callback) {
  validatorWrapper(value, callback, libphone => {
    const regex = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
    if (regex.test(value)) {
      callback([]);
    } else {
      callback(['Not a valid number']);
    }
  });
}

function phoneTypeValidator(rule, value, callback) {
  validatorWrapper(value, callback, libphone => {
    const parsed = parsePhone(libphone, value);
    if (libphone.getNumberType(parsed) === rule.phoneType.toUpperCase()) {
      callback([]);
    } else {
      callback([`Not a ${rule.phoneType.toLowerCase()} number`]);
    }
  });
}

let libphoneCache = null;

export function maybeFormatPhone(value) {
  if (!value) return value;
  if (!libphoneCache) return value;
  return formatPhone(libphoneCache, value);
}

export function phoneGetValueFromEvent(evt) {
  const { value } = evt.target;
  return maybeFormatPhone(value);
}

export function getPhoneNumberField(props) {
  getLibPhoneNumber().then(libphone => {
    libphoneCache = libphone;
  });

  const {
    form, fieldName, fieldText, phoneType, required, rules,
    initialValue,
  } = props;
  const { getFieldDecorator } = form;

  const requiredRules = required ? [
    {
      required: true,
      whitespace: true,
      message: `${fieldText} is required`,
    },
  ] : [];
  const phoneTypeRules = phoneType ? [
    {
      validator: phoneTypeValidator,
      message: `Not a ${phoneType} number`,
      phoneType,
    },
  ] : [];
  const phoneTypeText = phoneType || 'phone';

  return getFieldDecorator(fieldName, {
    initialValue: maybeFormatPhone(initialValue),
    getValueFromEvent: phoneGetValueFromEvent,
    rules: [
      ...requiredRules,
      ...phoneTypeRules,
      ...(rules || []),
    ],
    validate: [{
      trigger: 'onBlur',
      rules: [{
        validator: phoneNumberValidator,
        trigger: 'onBlur',
        message: `Invalid ${phoneTypeText} number`,
      }],
    }],

  })(
    <Input type="tel" />,
  );
}
