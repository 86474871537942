import qs from 'querystringify';
import { simpleAjaxDispatch } from './http';

export function postAccreditationRescan(accreditationId, json) {
  return simpleAjaxDispatch(
    `/accreditations/${accreditationId}/rescan`,
    'postAccreditationRescan',
    { method: 'POST', meta: { accreditationId }, json },
  );
}

export function fetchPcAccreditations(opts) {
  const qsData = {
    ...opts,
    limit: 10,
  };
  const qsFinal = qs.stringify(qsData, true);
  return simpleAjaxDispatch(
    `/accreditations/pc${qsFinal}`,
    'fetchPcAccreditations',
  );
}

export function patchPcAccreditation(id, json) {
  return simpleAjaxDispatch(
    `/accreditations/pc/${id}/authorize`,
    'patchPcAccreditation',
    { method: 'POST', json },
  );
}
