import _ from 'lodash';

import immutable from 'immutable';
import qs from 'querystringify';
import uuid4 from 'uuid/v4';

export const NUMBER_RE = /^\d*$/;

export function unImmutableShallow(obj) {
  if (
    immutable.Map.isMap(obj)
  ) return obj.toObject();
  if (
    immutable.List.isList(obj)
    || immutable.Stack.isStack(obj)
  ) return obj.toArray();
  if (
    immutable.Set.isSet(obj)
  ) return new Set(obj.toArray());
  return obj;
}

export function unImmutable(obj) {
  if (_.isNil(obj)) { return obj; }

  const jsObj = unImmutableShallow(obj);

  if (_.isPlainObject(jsObj)) return _.mapValues(jsObj, unImmutable);
  if (_.isArray(jsObj)) return jsObj.map(unImmutable);
  if (_.isSet(jsObj)) return new Set([...jsObj.values()]);

  return jsObj;
}

export function authHeaderValue(username, password) {
  return `Basic ${btoa(`${username}:${password}`)}`;
}

export function tokenPayload(token) {
  if (!token) return null;
  return JSON.parse(atob(token.split('.')[1]));
}

export function fieldDisplay(field) {
  return _.capitalize(_.lowerCase(field));
}

export function flattenObjProp(root, nextName, valueName = null, init = null) {
  if (init === null) init = [];

  const value = valueName === null
    ? root : root[valueName];
  init.push(value);

  const next = root[nextName];
  if (_.isNil(next)) return init;

  return flattenObjProp(next, nextName, valueName, init);
}

export function flattenObj(obj, prefix = null, others = {}) {
  Object.keys(obj).forEach(key => {
    const value = obj[key];
    const fullKey = prefix ? `${prefix}.${key}` : key;
    if (_.isPlainObject(value)) {
      flattenObj(value, fullKey, others);
    } else {
      others[fullKey] = value;
    }
  });
  return others;
}

export function isPostcode(string) {
  if (!string) return false;
  return /^([0-9]{4}|[89][0-9]{2})$/.test(string);
}

export function isAbn(string) {
  if (!string) return true; // we allow no ABN but need validation when it is provided
  string = string.replace(/[ -]/g, '');

  if (!string.match(/^\d{11}$/)) return false;

  let weightedSum = 0;
  _.zip([...string], isAbn.weight).forEach(([char, weight], idx) => {
    weightedSum += (parseInt(char, 10) - (idx === 0 ? 1 : 0)) * weight;
  });

  return weightedSum % 89 === 0;
}
isAbn.weight = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];

export function parseInt10(value) { return parseInt(value, 10); }

export function deepTransformKeys(obj, func) {
  if (_.isArray(obj)) {
    return obj.map(value => deepTransformKeys(value, func));
  }
  if (_.isObject(obj)) {
    return _.fromPairs(_.toPairs(obj).map(([key, value]) => [func(key), deepTransformKeys(value, func)]));
  }
  return obj;
}

export function camelPreservePrivate(value) {
  if (value.includes('_')) {
    if (value[0] === '_') {
      return `_${_.camelCase(value.substr(1))}`;
    }
    return _.camelCase(value);
  }
  return value;
}

export function checkFeature(name) {
  const value = window.SERVER_DATA.features[name];
  if (_.isNil(value)) return window.SERVER_DATA._defaultFeatureEnable || false;
  return value;
}

export function checkFeatures(...names) {
  return _.fromPairs(_.zip(
    names.map(name => `${name}Enabled`),
    names.map(name => checkFeature(name)),
  ));
}

export function isPhone(value) {
  return /^\+?[0-9 \-()]+$/.exec(value);
}

export function parsePhone(libphone, value) {
  return libphone.parse(value, { country: { default: 'AU' } });
}

export function formatPhone(libphone, value) {
  const formatted = new libphone.AsYouType('AU').input(
    value.replace(/[^0-9*+#]/g, ''),
  );
  if (formatted.substr(formatted.length - 1) === ')') {
    return formatted.substr(1, formatted.length - 2);
  }
  return formatted;
}

export function parseSearch(string) {
  if (string[0] === '?') return parseSearch(string.substr(1));
  return _.fromPairs(string.split('&').map(pair => pair.split('=')));
}

export function getLocale() {
  if (navigator.language) return navigator.language;
  return navigator.languages[0];
}

// TODO: Returns '' for nil objects
function formDataForLeaf(form, fields, leaf) {
  if (_.isObject(leaf)) {
    return _.fromPairs(Object.entries(fields).map(([key, value]) => [key, formDataForLeaf(form, value, leaf[key])]));
  }
  if (_.isNil(leaf)) return '';
  return leaf;
}

export function formData(form, data) {
  const fields = form.getFieldsError();
  return formDataForLeaf(form, fields, data);
}

export function hasFieldsError(fieldsError) {
  return Object.keys(fieldsError).some(field => {
    const value = fieldsError[field];
    if (_.isObject(value)) return hasFieldsError(value);
    return value;
  });
}

export function reduceTagsHierarchy(fromRootTag, includeSelf = true) {
  const options = fromRootTag.children
    ? fromRootTag.children.filter(item => (item.label !== 'Define Types')).reduce((all, tag) => [...all, ...reduceTagsHierarchy(tag)], [])
    : [];
  if (includeSelf) return [fromRootTag, ...options];

  return options;
}
const IS_ROOT_TAG_DEFAULT_OPTS = {
  orgTagIsRoot: true,
};

function isRootTag(tag, opts = {}) {
  opts = { ...IS_ROOT_TAG_DEFAULT_OPTS, ...opts };
  if (_.isNil(tag.parent)) return true;
  if (opts.orgTagIsRoot) return tag.parent.role === 'organization';
  return false;
}
const FULL_TAG_PATH_DEFAULT_OPTS = {
  ignoreRoots: true,
};

export function fullTagPath(tag, opts = {}) {
  opts = { ...FULL_TAG_PATH_DEFAULT_OPTS, ...opts };
  const path = [];
  while (
    !_.isNil(tag)
    && !(opts.ignoreRoots && isRootTag(tag, opts))
  ) {
    path.push(tag.label);
    tag = tag.parent;
  }
  return path.reverse();
}
const FULL_TAG_LABEL_DEFAULT_OPTS = {
  joiner: ' ≫ ',
};

export function fullTagLabel(tag, opts = {}) {
  if (isRootTag(tag)) return tag.label;

  opts = { ...FULL_TAG_LABEL_DEFAULT_OPTS, ...opts };
  return fullTagPath(tag, opts).join(opts.joiner);
}

export function rootTag(tag) {
  if (tag.parent) return rootTag(tag.parent);
  return tag;
}

export function rootTagRole(tag) {
  return rootTag(tag).role;
}

export function parseQsHash(hash) {
  if (!hash || hash.length === 0) return {};
  if (hash.substr(0, 1) === '#') return parseQsHash(hash.substr(1));
  return qs.parse(hash);
}

export function historyPushPath(history, location, path, extraQsData = {}, { replace = false } = {}) {
  const rawQsData = parseQsHash(location.hash);
  Object.assign(rawQsData, extraQsData);
  const qsData = _.omitBy(rawQsData, _.isNil);
  const fn = (replace ? history.replace : history.push);
  fn(`${path}#${qs.stringify(qsData)}`);
}

export function getLatestDateByYear($yearsToRestrict) {
  const dateNow = new Date();
  dateNow.setFullYear(dateNow.getFullYear() - $yearsToRestrict);
  return dateNow;
}

export function stripHints(name) {
  const nameWithoutHints = name.replace(/[[\]"]/g, ' ');
  return nameWithoutHints.replace(/\s+/g, ' ').trim();
}

export function generateUuid() {
  return uuid4();
}

export function dynamicSort(property) {
  let sortOrder = 0;
  return (a, b) => {
    if (a[property] < b[property]) {
      sortOrder = -1;
    } else if (a[property] > b[property]) {
      sortOrder = 1;
    }
    return sortOrder;
  };
}

export function isPCTrackingEnabled(settings) {
  const settingsJSON = settings && settings.toJSON();
  const { paperworkSignedTs: currentNPCStatus } = (settingsJSON && settingsJSON.npc) || {};
  const { enabled: currentIPCStatus } = (settingsJSON && settingsJSON.ipc) || {};
  return currentNPCStatus || currentIPCStatus;
}

export function hasNonEmptyObjectOrArray(obj) {
  return Object.keys(obj).some(key => {
    const value = obj[key];
    if (!value) return false;
    return (Array.isArray(value) && value.length > 0) || (typeof value === 'object' && Object.keys(value).length > 0);
  });
}
