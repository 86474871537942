import React, { PureComponent } from 'react';

import { Spin } from 'antd';
import { tokenPayload } from './utils';

const CurrentUser = React.createContext(null);

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

const withCurrentUser = WrappedComponent => {
  class WithCurrentUser extends PureComponent {
    static contextType = CurrentUser;

    render() {
      return (
        <WrappedComponent currentUser={this.context} {...this.props} />
      );
    }
  }
  WithCurrentUser.displayName = `WithCurrentUser(${getDisplayName(WrappedComponent)})`;
  return WithCurrentUser;
};

@withCurrentUser
class SpinUntilUser extends PureComponent {
  render() {
    const { currentUser } = this.props;
    if (!currentUser.organization) {
      return <div style={{ textAlign: 'center', width: '100%' }}><Spin/></div>;
    }
    return this.props.children;
  }
}

const formatWhoami = ({ auth: { constituent, emailVerified }, tokenString }) => {
  if (!constituent) {
    return {};
  }

  let tokenData = {};
  if (tokenString) {
    try {
      tokenData = tokenPayload(tokenString);
    } catch (err) {
      console.error('Token parse error:', err);
    }
  }

  return {
    isAdmin: tokenData.admin || false,
    emailVerified: emailVerified || false,
    tokenString,
    managedBilling: ['free_trial', 'stripe', 'xero_invoice'].indexOf(constituent.organization.subscription.subscriptionType) > -1,
    constituent: {
      fullName: constituent.fullName,
      id: constituent.id,
    },
    organization: {
      id: constituent.organization.id,
      name: constituent.organization.name,
      freeTrial: constituent.organization.subscription.subscriptionType === 'free_trial' ? {
        expiry: constituent.organization.subscription.expiry,
      } : null,
    },
  };
};

class CurrentUserProvider extends PureComponent {
  // Comparing context uses reference, this compares the actual
  // values
  constructor(props) {
    super(props);
    this.state = {
      auth: null,
      token: null,
      formatted: {},
    };
  }

  componentDidUpdate() {
    this.check(this.props);
  }

  format() {
    return formatWhoami({
      auth: this.props.auth.toJS(),
      tokenString: this.props.token,
    });
  }

  check(props) {
    if (props.auth.equals(this.state.auth) && props.token === this.state.token) {
      return;
    }
    this.setState({
      auth: props.auth,
      token: props.token,
      formatted: this.format(),
    });
  }

  render() {
    return (<CurrentUser.Provider value={this.state.formatted}>
      {this.props.children}
    </CurrentUser.Provider>);
  }
}

export {
  CurrentUserProvider,
  withCurrentUser,
  SpinUntilUser,
};
