import {
  Form, Input, Radio, Select, Modal,
  Icon,
} from 'antd';
import React, { PureComponent } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { NON_ALPHA_NUM_SLASH_RE } from './shared';

export class NpcFormItems extends PureComponent {
  constructor(props) {
    super(props);
    this.initialCheckPurposePresets = _.get(props, 'institutionsCredSettings.npc.checkPurposePresets') || [];
    this.initialPurposeStatePresets = _.get(props, 'institutionsCredSettings.npc.purposeStatePresets') || [];
    this.initialPurposeLocationPresets = _.get(props, 'institutionsCredSettings.npc.purposeLocationPresets') || [];
    this.state = {
      checkPurposePresets: this.initialCheckPurposePresets,
      purposeStatePresets: this.initialPurposeStatePresets,
      purposeLocationPresets: this.initialPurposeLocationPresets,
      currentValue: null,
    };
  }

  static slug = 'npc';

  static placeholder = '';

  static identLabel = 'Clearance Notice No:';

  static getSubmissionData(checkData, constituent) {
    return {
      fullName: constituent && constituent.get('fullName'),
      email: (constituent && constituent.get('email')) || (checkData && checkData.email),
      origin: 'doc',
    };
  }

  static showWarnings(opts) {
    const { constituent } = opts;
    const constituentData = constituent ? constituent.toJS() : null;
    const constituentClaims = constituentData ? _.get(constituentData, 'claims', []) : [];
    const activeNpcClaims = constituentClaims.filter(i => i.active && _.get(i, 'accreditation.type') === 'npc' && _.get(i, 'statusColor') === 'green' && moment(_.get(i, 'accreditation.expiry')).isAfter(moment()) && _.get(i, 'accreditation.registryResponse.response') === 'Suitable');
    return new Promise(resolve => {
      if (!activeNpcClaims.length) resolve('OK');
      else {
        Modal.confirm({
          title: 'Confirm new NPC request',
          content: <span>
          You already have a current NPC marked <b>Suitable</b> for this person. Are you sure you want to request another one ?
          </span>,
          onOk: () => {
            resolve('OK');
          },
          onCancel: () => {
            resolve('Canceled');
          },
        });
      }
    });
  }

  static regNumberGetValueFromEvent(evt) {
    return evt.target.value.replace(NON_ALPHA_NUM_SLASH_RE, '');
  }

  static disabledDate(current) {
    return current && current.valueOf() < Date.now();
  }

  handleSearch = value => {
    const { checkPurposePresets } = this.state;
    if (checkPurposePresets.length > 0) {
      for (let i = 0; i < checkPurposePresets.length; i++) {
        const isSearchValueInState = new RegExp(value).test(checkPurposePresets[i]);
        if (!isSearchValueInState) {
          this.setState({
            checkPurposePresets: [...this.initialCheckPurposePresets, value],
            currentValue: value,
          });
          break;
        }
      }
    } else {
      this.setState({
        checkPurposePresets: [value],
        currentValue: value,
      });
    }
  };

  handlePurposeStateSearch = value => {
    const { purposeStatePresets } = this.state;
    if (purposeStatePresets.length > 0) {
      for (let i = 0; i < purposeStatePresets.length; i++) {
        const isSearchValueInState = new RegExp(value).test(purposeStatePresets[i]);
        if (!isSearchValueInState) {
          this.setState({
            purposeStatePresets: [...this.initialPurposeStatePresets, value],
            currentValue: value,
          });
          break;
        }
      }
    } else {
      this.setState({
        purposeStatePresets: [value],
        currentValue: value,
      });
    }
  };

  handlePurposeLocationSearch = value => {
    const { purposeLocationPresets } = this.state;
    if (purposeLocationPresets.length > 0) {
      for (let i = 0; i < purposeLocationPresets.length; i++) {
        const isSearchValueInState = new RegExp(value).test(purposeLocationPresets[i]);
        if (!isSearchValueInState) {
          this.setState({
            purposeLocationPresets: [...this.initialPurposeLocationPresets, value],
            currentValue: value,
          });
          break;
        }
      }
    } else {
      this.setState({
        purposeLocationPresets: [value],
        currentValue: value,
      });
    }
  };

handleChange = value => {
  this.setState(prev => ({ ...prev, currentValue: value }));
}

render() {
  const {
    form, disabled, rules, constituent, institutionsCredSettings,
  } = this.props;
  const npcSettings = this.props.isNPCConfigured && institutionsCredSettings.npc || {};

  const { getFieldDecorator } = form;
  const email = constituent && constituent.get('email');

  const reasonType = !npcSettings.personPay ? 'commercial' : 'volunteer';

  const {
    slug,
  } = this.constructor;

  const { checkPurposePresets } = this.state;
  const { purposeStatePresets } = this.state;
  const { purposeLocationPresets } = this.state;


  return (<div>
    { !this.props.isNPCConfigured
        && <div style={{ paddingBottom: '30px' }}>
          <span style={{ color: 'tomato' }}>**NPC requires configuration for your organization before it can be used. Please go to the Settings / Police Check to complete the relevant setup.</span>
        </div>
      }
    <Form.Item label="Select Reason Type" required>
      {getFieldDecorator(`${slug}.reason_type`, {
        setFieldsValue: reasonType,
        initialValue: reasonType,
      })(
        <Radio.Group>
          <Radio.Button value={'volunteer'}>Volunteer</Radio.Button>
          <Radio.Button value={'commercial'}>Commercial</Radio.Button>
        </Radio.Group>,
      )}
    </Form.Item>
    <Form.Item label="Email Address">
      { email || getFieldDecorator(`${slug}.email`, {
        rules: [
          {
            type: 'email',
            required: true,
            whitespace: true,
            message: 'Email address is invalid',
          },
          ...(rules || []),
        ],
      })(
        <Input
          placeholder="Email Address"
          disabled={disabled}
        />,
      )
        }
    </Form.Item>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Icon
        type="exclamation-circle"
        style={{ marginRight: '10px', fontSize: '18px' }}
      />
      Can't see the items you need? Type them to add for this request, or update default lists by clicking <a style={{ marginLeft: '5px' }} href='/npcSettings' >here</a>
    </div>
    <Form.Item label="Check Purpose/Role">
      {getFieldDecorator(`${slug}.check_purpose`, {
        rules: [
          {
            required: true,
            whitespace: true,
            message: 'check purpose is required',
          },
        ],
      })(
        <Select
          showSearch
          filterOption={true}
          onSearch={this.handleSearch}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          style={{ width: '100%' }}
        >
          {checkPurposePresets.map(preset => (
            <Select.Option value={preset} key={preset}>{preset}</Select.Option>
          ))}
        </Select>,
      )}
    </Form.Item>
    <Form.Item label="Purpose Location">
      {getFieldDecorator(`${slug}.purpose_location`, {
        rules: [
          {
            required: true,
            whitespace: true,
            message: 'Purpose location is required with minimum 3 characters',
            min: 3,
          },
        ],
      })(
        <Select
          showSearch
          filterOption={true}
          onSearch={this.handlePurposeLocationSearch}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          style={{ width: '100%' }}
        >
          {purposeLocationPresets.map(preset => (
            <Select.Option value={preset} key={preset}>{preset}</Select.Option>
          ))}
        </Select>,
      )}
    </Form.Item>
    <Form.Item label="State">
      {getFieldDecorator(`${slug}.purpose_state`, {
        rules: [
          {
            required: true,
            whitespace: true,
            message: 'State is invalid, only supports NSW,QLD,NT,SA,ACT,WA,TAS,VIC',
            validator: (rule, value, cb) => (['NSW', 'QLD', 'NT', 'SA', 'ACT', 'WA', 'TAS', 'VIC'].includes(value) ? cb() : cb(true)),
          },
        ],
      })(
        <Select
          showSearch
          filterOption={true}
          onSearch={this.handlePurposeStateSearch}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          style={{ width: '100%' }}
        >
          {purposeStatePresets.map(preset => (
            <Select.Option value={preset} key={preset}>{preset}</Select.Option>
          ))}
        </Select>,
      )}
    </Form.Item>
    <Form.Item label="Charge Code">
      {getFieldDecorator(`${slug}.charge_code`, {
        rules: [
          {
            required: false,
            whitespace: true,
          },
        ],
      })(
        <Input
          placeholder='Charge code'
          disabled={disabled}
          size="default"
        />,
      )}
    </Form.Item>
  </div>);
}
}
