import _ from 'lodash';
import moment from 'moment';

import {
  Badge,
  Row,
  Col,
  Button,
  Card,
  Collapse,
  Divider,
  Icon,
  List,
  Spin,
  Tag,
  Tooltip,
  message,
  Tabs,
  Modal,
  Typography,
} from 'antd';

import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { checkFeature } from '../../../utils';

import { AttachmentModal } from './AttachmentModal';
import * as actions from '../../../actions';
import { LiveDate } from '../../LiveDate';
import { ActionText, DefinedTerm, PortalChild } from '../../elements';

const { Text } = Typography;
const { confirm } = Modal;
const STATUS_NA = 'STATUS_NA';

const NON_REVALIDATION_ACCRED_TYPES = ['npc', 'ipc'];
const EXPIRY_NOT_PROVIDED = ['wawwc', 'nesa'];

const ahpraPayloadTable = (payload, visible, handleCancel) => {
  const data = [];
  const payloadJS = payload.toJS();

  const { TabPane } = Tabs;

  Object.keys(payloadJS).forEach((key, index) => data.push({
    category: key,
    key: index,
    details: Array.isArray(payloadJS[key]) ? payloadJS[key].join(', ') : payloadJS[key],
  }));

  const splitCamelCaseWithAbbreviations = s => {
    const terms = s.split(/([A-Z][a-z]+)/).filter(e => e); // filter removes empty strings
    return terms.map(t => t.charAt(0).toUpperCase() + t.slice(1)).join(' ');
  };

  return (
    <Modal
      destroyOnClose={true}
      style={{ minWidth: 750 }}
      visible={visible}
      title="AHPRA Details"
      onOk={handleCancel}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
      ]}
    >
      <Tabs defaultActiveKey="0">
        {payloadJS.sections && payloadJS.sections.map((section, idx) => (
          <TabPane tab={section.label} key={idx}>
            {section && Object.keys(section).filter(key => key !== 'label').map((key, idx) => (
              <div style={{ width: '80%', margin: 'auto', marginBottom: '1rem' }}>
                {idx > 0 && <Divider />}
                <h4>{splitCamelCaseWithAbbreviations(key)}</h4>
                <p style={{ display: 'inline' }}>{section[key]}</p>
              </div>
            ))}
          </TabPane>
        ))}
      </Tabs>
    </Modal>
  );
};

const vevoPayloadTable = (payload, visible, handleCancel) => {
  const data = [];
  const payloadJS = payload.toJS();

  const { TabPane } = Tabs;

  Object.keys(payloadJS).forEach((key, index) => data.push({
    category: key,
    key: index,
    details: Array.isArray(payloadJS[key]) ? payloadJS[key].join(', ') : payloadJS[key],
  }));

  const splitCamelCaseWithAbbreviations = s => {
    const terms = s.split(/([A-Z][a-z]+)/).filter(e => e); // filter removes empty strings
    return terms.map(t => t.charAt(0).toUpperCase() + t.slice(1)).join(' ');
  };

  return (
    <Modal
      destroyOnClose={true}
      style={{ minWidth: 750 }}
      visible={visible}
      title="VEVO Details"
      onOk={handleCancel}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Close
        </Button>,
      ]}
    >
      <Tabs defaultActiveKey="0">
        {payloadJS.sections && payloadJS.sections.map((section, idx) => (
          <TabPane tab={section.label} key={idx}>
            {section && Object.keys(section).filter(key => key !== 'label').map((key, idx) => (
              <div style={{ width: '80%', margin: 'auto', marginBottom: '1rem' }}>
                {idx > 0 && <Divider />}
                <h4>{splitCamelCaseWithAbbreviations(key)}</h4>
                <p style={{ display: 'inline' }}>{parse(section[key])}</p>
              </div>
            ))}
          </TabPane>
        ))}
      </Tabs>
    </Modal>
  );
};

class AccreditationFieldStatus extends PureComponent {
  render() {
    if (_.isNil(this.props.okay)) return <Spin size="small" style={{ paddingRight: '4px' }} />;

    const { color, icon, msg } = {
      true: {
        color: 'green',
        icon: 'check-circle',
        msg: this.props.goodMsg,
      },
      false: {
        color: 'red',
        icon: 'exclamation-circle',
        msg: this.props.badMsg,
      },
      warn: {
        color: 'orange',
        icon: 'info-circle',
        msg: this.props.warnMsg,
      },
      notFound: {
        color: 'red',
        icon: 'exclamation-circle',
        msg: 'Card Not Found',
      },
      STATUS_NA: {
        color: 'grey',
        icon: 'exclamation-circle',
        msg: this.props.naMsg || this.props.badMsg,
      },
      with_conditions: {
        color: 'orange',
        icon: 'check-circle',
        msg: this.props.badMsg,
      },
      without_conditions: {
        color: 'green',
        icon: 'check-circle',
        msg: this.props.goodMsg,
      },
      not_current: {
        color: 'red',
        icon: 'exclamation-circle',
        msg: this.props.badMsg,
      },
      eligible: {
        color: 'green',
        icon: 'check-circle',
        msg: this.props.goodMsg,
      },
      not_eligible: {
        color: 'red',
        icon: 'exclamation-circle',
        msg: this.props.badMsg,
      },
      must_not_engage: {
        color: 'red',
        icon: 'exclamation-circle',
        msg: this.props.badMsg,
      },
      may_engage: {
        color: 'green',
        icon: 'check-circle',
        msg: this.props.goodMsg,
      },
    }[this.props.okay];

    return (<Tooltip title={msg}>
      <Icon type={icon} style={{ color, paddingRight: '4px' }} />
    </Tooltip>);
  }
}

const ACCREDITATION_CLAIM_DETAIL_ROWS = [
  // VicWWC new card
  claim => {
    const show = claim.getIn(['meta', 'vicwwc', 'isNewCard'], false);
    if (!show) {
      return null;
    }
    return (
      <div key="vicwwc-is-new-card">
        <AccreditationFieldStatus
          okay={'warn'}
          warnMsg='Contact person for details of their new card'
        />
        New card details available
      </div>
    );
  },

  claim => {
    const statusFlags = claim.getIn(['statusFlags'], []).toJS();
    const show = statusFlags && statusFlags.includes('is_application');
    if (!show) {
      return null;
    }
    return (
      <div key="is-application">
        <AccreditationFieldStatus
          okay={'warn'}
          warnMsg={'Person must not engage in child related work while the application is being processed.'}
        />
        Application is in progress
      </div>
    );
  },
];

function checkGeneric(scanTypes, accred) {
  if (!accred) {
    return null;
  }
  const type = accred.get('type');
  const scanTypesJs = scanTypes && scanTypes.toObject ? scanTypes.toJS() : [];
  const itemFound = scanTypesJs.find(item => (item.typeCode === type));
  return itemFound && (!itemFound.scanner || itemFound.scanner === '');
}

function getAccredMaxAttachments(scanTypes, accred) {
  if (!accred) {
    return null;
  }
  const type = accred.get('type');
  const scanTypesJs = scanTypes && scanTypes.toObject ? scanTypes.toJS() : [];
  const itemFound = scanTypesJs.find(item => item.typeCode === type);
  const maxAttachments = itemFound && itemFound.maxAttachments || '';
  return maxAttachments && maxAttachments !== '' ? maxAttachments : 0;
}


const statusRules = {
  conditional: obj => ({
    fieldStatus: 'with_conditions',
    negativeMsg: 'Accreditation is conditional or limited',
    positiveMsg: '',
  }),
  engage: obj => {
    if (obj.mayEngage) {
      return {
        fieldStatus: 'may_engage',
        negativeMsg: '',
        positiveMsg: `Accreditation is ${obj.response || 'current'}`,
      };
    }
    return {
      fieldStatus: 'must_not_engage',
      negativeMsg: `Accreditation is ${obj.response || 'not current'}`,
      positiveMsg: '',
    };
  },
  response: {
    'not current': obj => ({
      fieldStatus: 'not_current',
      negativeMsg: 'Accreditation is not current',
      positiveMsg: 'Response is not current',
    }),
    'not found': obj => ({
      fieldStatus: 'false',
      negativeMsg: 'Accreditation is not found',
      positiveMsg: 'Response is not current',
    }),
    'not eligible to work': obj => ({
      fieldStatus: 'not_eligible',
      negativeMsg: 'Not eligible to work',
      positiveMsg: '',
    }),
    incomplete: obj => ({
      fieldStatus: 'not_eligible',
      negativeMsg: 'Not eligible to work',
      positiveMsg: '',
    }),
    'under assessment': obj => ({
      fieldStatus: 'not_eligible',
      negativeMsg: 'Not eligible to work',
      positiveMsg: '',
    }),
    'eligible to work': obj => ({
      fieldStatus: 'not_current',
      negativeMsg: 'Accreditation is not current',
      positiveMsg: 'Response is not current',
    }),
    'no work rights': obj => ({
      fieldStatus: 'not_current',
      negativeMsg: obj.supplement,
      positiveMsg: obj.supplement,
    }),
    'limited work rights': obj => ({
      fieldStatus: 'with_conditions',
      negativeMsg: obj.supplement,
      positiveMsg: obj.supplement,
    }),
    'unlimited work rights': obj => ({
      fieldStatus: 'true',
      negativeMsg: obj.supplement,
      positiveMsg: obj.supplement,
    }),
  },
};

const getStatusFromRegistryResponse = registryResponse => {
  const registryResponseObj = registryResponse && registryResponse.toJS();
  const { isConditional, response, mayEngage } = registryResponseObj || {};
  const result = {
    fieldStatus: 'true',
    negativeMsg: '',
    positiveMsg: '',
  };

  // Check conditions
  if (isConditional && statusRules.conditional) {
    Object.assign(result, statusRules.conditional(isConditional));
  }

  // Check response
  if (typeof response === 'string') {
    const normalizedResponse = response.trim().toLowerCase();
    if (statusRules.response[normalizedResponse]) {
      Object.assign(result, statusRules.response[normalizedResponse](registryResponseObj));
    }
  }

  if (typeof mayEngage === 'boolean') {
    Object.assign(result, statusRules.engage(registryResponseObj));
  }

  return result;
};

@connect(
  (state, ownProps) => ({
    isGeneric: checkGeneric(
      state.doc.getIn(['http', 'scanTypes', 'data']),
      ownProps.accreditation,
    ),
    accreditationMaxAttachments: getAccredMaxAttachments(
      state.doc.getIn(['http', 'scanTypes', 'data']),
      ownProps.accreditation,
    ),
  }),
)

class AccreditationListClaimBody extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      moreDetailsIsVisible: false,
      attchmentModalVisible: false,
    };
  }

  renderExpiry(accType, value, type = 'expiry') {
    if (!value && accType && EXPIRY_NOT_PROVIDED.includes(accType)) return <DefinedTerm definition='This card expiry is not managed'>Not Provided</DefinedTerm>;
    if (!value) return <DefinedTerm definition='This card type does not expire'>Unknown</DefinedTerm>;
    const m = moment(value);
    const expiryTerm = moment().diff(m) >= 0 ? 'Expired' : 'Expires';
    return <Fragment>{type === 'attainment' ? 'Attained' : expiryTerm} <DefinedTerm definition={m.format('Do MMM YYYY')}>{m.fromNow()}</DefinedTerm></Fragment>;
  }

  handleRevalidateClick = async () => {
    this.setState({ rescanSubmitting: true });
    const resp = await this.props.postAccreditationRescan(this.props.accreditation.get('id'));
    this.setState({ rescanSubmitting: false });
    if (resp.success) message.success('Submitted revalidation. Your accreditation will be revalidated soon.');
    if (resp.failed) message.success('There was a problem submitting your revalidation request.');
  }

  handleDeleteAttachment = (id, index) => {
    const that = this;
    const constituentId = this.props.constituent.get('id');
    confirm({
      title: 'Do you want to delete the attachment request?',
      content: <>This will result in deletion of <b>Attachment{index + 1}</b></>,
      async onOk() {
        await that.props.deleteAttachment(id);
        if (constituentId) await that.props.getConstituent(constituentId);
      },
      onCancel() {

      },
    });
  }

  render() {
    const expiry = this.props.accreditation.get('expiry');
    const { isGeneric } = this.props;
    const attachments = this.props.attachments ? this.props.attachments.toJS() : null;
    const activeAttachments = attachments.filter(a => a.active === true);
    const activeGoodMsg = (<span>
      Accreditation is valid (checked <LiveDate date={this.props.accreditation.getIn(['latestVerificationEvent', 'eventTs'])} />)
    </span>);
    const activeBadMsg = (
      <span>
        Accreditation may have been revoked (checked <LiveDate date={this.props.accreditation.getIn(['latestVerificationEvent', 'eventTs'])} />)
      </span>
    );
    const accredType = this.props.accreditation.get('type');
    const nswNotFound = this.props.accreditation.getIn(['meta', 'nswwwc', 'reviewRequired']);
    const registryResponse = this.props.accreditation.getIn(['registryResponse'], null);
    const type = this.props.accreditation.getIn(['registryResponse', 'type'], null);
    const response = this.props.accreditation.getIn(['registryResponse', 'response'], null);
    const status = this.props.accreditation.getIn(['registryResponse', 'status'], null);
    const profession = this.props.accreditation.getIn(['registryResponse', 'profession'], null);
    const supplement = this.props.accreditation.getIn(['registryResponse', 'supplement'], null);
    const isConditional = this.props.accreditation.getIn(['registryResponse', 'isConditional'], null);
    const attained = this.props.accreditation.getIn(['attained'], null);
    const messages = this.props.accreditation.getIn(['meta', 'status', 'messages'], null) ? this.props.accreditation.getIn(['meta', 'status', 'messages'], null).toJS() : [];
    const ahpra = this.props.accreditation.getIn(['meta', 'ahpra'], null);
    const vevo = this.props.accreditation.getIn(['meta', 'vevo'], null);
    const renderObj = obj => (obj && typeof obj !== 'string' ? `${obj.toJS().join(', ')}` : obj);
    const renderType = renderObj(type);
    const renderResponse = renderObj(response);
    const attachmentButton = this.props.accreditationMaxAttachments > 0 && activeAttachments.length < this.props.accreditationMaxAttachments && (
      <div style={{ paddingTop: '1em' }}>
        <Button icon="paper-clip" size="small" type="secondary" onClick={() => this.setState({ attchmentModalVisible: true })}>Attachment</Button>
      </div>
    );

    const accred = this.props.accreditations
      && this.props.accreditations.find(accred => accred.id === this.props.accreditation.get('id'));
    let linked = null;
    if (
      accred
      && accred.statusDetail
      && accred.statusDetail.linkage
      && accred.statusDetail.linkage.linked
    ) {
      linked = (
        <Tooltip title={`This accreditation has been linked to your ${accred.statusDetail.linkage.typeName} account.`}>
          <Icon type='link' style={{ color: 'green' }} />
        </Tooltip>
      );
    }

    const { fieldStatus, positiveMsg, negativeMsg } = getStatusFromRegistryResponse(registryResponse);

    return (
      <>
        <div>
          <Row>
            <Col span={16}>
              <div>
                <AccreditationFieldStatus
                  okay={nswNotFound ? 'notFound' : this.props.nameMatch}
                  goodMsg='Person name matches'
                  badMsg="Person name doesn't match"
                />
                {this.props.accreditation.get('name')}
              </div>
              {response
                ? <div>
                  <AccreditationFieldStatus
                    okay={fieldStatus}
                    goodMsg={positiveMsg}
                    badMsg={negativeMsg}
                  />
                  {vevo ? (
                    <>
                      {`${response} `}
                      <Text style={{ marginLeft: 10, color: '#1890ff', cursor: 'pointer' }} onClick={() => this.setState({ moreDetailsIsVisible: true })}>More..</Text>
                      {vevoPayloadTable(vevo, this.state.moreDetailsIsVisible, () => this.setState({ moreDetailsIsVisible: false }))}
                    </>
                  )
                    : renderResponse}
                </div>
                : renderType}
              {ahpra ? <div>
                <AccreditationFieldStatus
                  okay={isConditional ? 'with_conditions' : 'without_conditions'}
                  goodMsg={'Without Conditions'}
                  badMsg={isConditional && supplement}
                />
                {`${status} `}{profession}
              </div> : null}
              <div>
                <AccreditationFieldStatus
                  okay={nswNotFound ? 'notFound' : this.props.accreditation.getIn(['meta', 'status', 'current'], false)}
                  goodMsg={activeGoodMsg}
                  badMsg={activeBadMsg}
                />
                {ahpra
                  ? <>

                    {this.props.accreditation.get('identifier')}
                    <Text style={{ marginLeft: 10, color: '#1890ff', cursor: 'pointer' }} onClick={() => this.setState({ moreDetailsIsVisible: true })
                    }
                    >More..</Text>
                    {ahpraPayloadTable(ahpra, this.state.moreDetailsIsVisible, () => this.setState({ moreDetailsIsVisible: false }))}
                  </>
                  : <Tooltip title={messages.length > 0 ? messages.join(',') : null}>
                    {this.props.accreditation.get('identifier')}
                  </Tooltip>} {linked}
              </div>
              <div>
                {attained
                  ? <div>
                    <AccreditationFieldStatus
                      okay={!!attained}
                      goodMsg='Accreditation attainment date'
                      badMsg='Accreditation does not have an attainment date'
                    />
                    {this.renderExpiry(accredType, attained, 'attainment')}
                  </div>
                  : null}
              </div>
              <div>
                <AccreditationFieldStatus
                  okay={
                    expiry ? moment(expiry).isAfter(moment()) : STATUS_NA
                  }
                  goodMsg='Accreditation has not expired'
                  badMsg='Accreditation has expired'
                  naMsg='Accreditation does not have an expiry'
                />
                {this.renderExpiry(accredType, this.props.accreditation.get('expiry'))}
              </div>
              {ACCREDITATION_CLAIM_DETAIL_ROWS.map(renderer => renderer(this.props.accreditation))}

              <AttachmentModal
                visible={this.state.attchmentModalVisible}
                constituentId={this.props.constituent.get('id')}
                accreditationId={this.props.accreditation.get('id')}
                handleCancel={() => {
                  this.setState({ attchmentModalVisible: false });
                  this.props.getConstituent(this.props.constituent.get('id'));
                }}
              />
            </Col>
            <Col span={8}>
              {attachments && attachments.length > 0 ? attachments.filter(a => a.active === true).map((a, index) => (<Row>
                <Col span={20}>
                  <a href={a.download} title={(a.filename && a.filename.length > 20) ? a.filename : ''} target='_blank' rel="noopener noreferrer">{
                    a.filename && a.filename.length < 20
                      ? a.filename
                      : `Attachment${index + 1}`
                  }</a>
                </Col>
                <Col span={4}>
                  {' '}<Icon type="close-circle" onClick={() => this.handleDeleteAttachment(a.id, index)} />
                </Col>
              </Row>)) : null}
            </Col>
          </Row>
          <Row>
            {(!NON_REVALIDATION_ACCRED_TYPES.includes(accredType) && !isGeneric)
              && <>
                <Col span={10}>
                  <div style={{ paddingTop: '1em' }}>
                    <Button icon="reload" size="small" type="primary" loading={this.state.rescanSubmitting} onClick={this.handleRevalidateClick}>Revalidate now</Button>
                  </div>
                </Col>
                <Col span={6} />
              </>
            }
            <Col span={8}>
              {checkFeature('attachments') ? attachmentButton : null}
            </Col>
          </Row>
        </div>
      </>);
  }
}

function getAccredName(scanTypes, accred) {
  if (!accred) {
    return null;
  }
  const type = accred.get('type');
  const scanTypesJs = scanTypes && scanTypes.toObject ? scanTypes.toJS() : [];
  const itemFound = scanTypesJs.find(item => item.typeCode === type);
  const label = itemFound && itemFound.typeName || '';
  return label;
}

const getNSWWWCCardType = accreditation => {
  const isPendingLoad = accreditation.getIn(['meta', 'nswwwc', 'pendingLoad'], '');
  if (isPendingLoad) return '';

  let cardType;
  cardType = accreditation.getIn(['meta', 'nswwwc', 'cardType'], null);

  if (cardType) return cardType.substring(0, 1);

  const identifier = accreditation.getIn(['identifier'], '');
  const lastChar = identifier.toUpperCase().slice(-1);
  cardType = lastChar === 'E' || lastChar === 'V' ? lastChar : '';
  return cardType;
};

@connect(
  (state, ownProps) => ({
    accreditationName: getAccredName(
      state.doc.getIn(['http', 'scanTypes', 'data']),
      ownProps.accreditation,
    ),
  }),
)
class AccreditationListClaimTitle extends PureComponent {
  render() {
    const { accreditation, accreditationName, statusColor } = this.props;
    if (!accreditation) {
      return null;
    }
    const vicwwcCardType = accreditation.getIn(['meta', 'vicwwc', 'cardType'], null);
    const taswwcCardType = accreditation.getIn(['meta', 'taswwc', 'type'], null);
    const nswwcCardType = getNSWWWCCardType(accreditation);

    return (<span >
      <Badge color={statusColor} />
      {accreditationName}
      {vicwwcCardType
        && <span> ({vicwwcCardType.substring(0, 1)})</span>
      }
      {taswwcCardType
        && <span> ({taswwcCardType.substring(0, 1)})</span>
      }
      {nswwcCardType
        && <span> ({nswwcCardType})</span>
      }
    </span>);
  }
}

@connect(state => ({}), actions)
class AccreditationsListClaimCard extends PureComponent {
  handleToggleActiveClick = ev => {
    ev.preventDefault();
    this.props.patchAccreditationClaim(
      this.props.constituent.get('id'),
      this.props.accreditation.get('id'),
      { active: !this.props.active },
    ).then(action => {
      if (action.data._errors) {
        Object.values(action.data._errors).forEach(errors => {
          errors.forEach(error => {
            message.error(<span>{error}</span>);
          });
        });
      }
    });
  }

  render() {
    const icon = `${this.props.active ? 'close' : 'plus'}-circle`;
    const text = this.props.active ? 'Deactivate' : 'Activate';
    const genacc = this.props.accreditation.getIn(['meta', 'genacc'], null);
    return (
      <Spin spinning={this.props.patching}>
        <Card
          headStyle={!genacc ? { backgroundColor: '#DCBFA6' } : null}
          title={
            <AccreditationListClaimTitle
              statusColor={this.props.statusColor}
              accreditation={this.props.accreditation}
            />
          }
          type="inner"
          extra={
            <DefinedTerm description={`${text} accreditation`}>
              <ActionText onClick={this.handleToggleActiveClick}>
                <Icon type={icon} />
              </ActionText>
            </DefinedTerm>
          }
        >
          <AccreditationListClaimBody {...this.props} />
        </Card>
      </Spin>
    );
  }
}

class AccreditationsListComponent extends PureComponent {
  componentDidMount() {
    // temporary solution for loader for accreditation claim
    const that = this;
    const { accreditationId, constituentId, type } = that.props.accreditationCurrent;
    setTimeout(() => {
      const currentConstituentId = that.props.constituent && that.props.constituent.get('id');
      if (accreditationId && constituentId && constituentId === currentConstituentId) {
        that.props.getConstituent(currentConstituentId);
        that.props.setConstituentAccreditationIdAction();
      } else if (accreditationId && constituentId && constituentId !== currentConstituentId) {
        that.props.setConstituentAccreditationIdAction();
      }
    }, type !== 'genacc' ? window.SERVER_DATA.accreditationTimeOut : 2000);
  }

  componentWillReceiveProps = nextProps => {
    if (!this.props.patchSuccess && nextProps.patchSuccess) nextProps.getConstituent(nextProps.constituent.get('id'));
  }

  renderTypeCell = text => (
    <span style={{
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }}
    ><em>{text}</em></span>
  )

  renderClaim = data => {
    const dataJs = data.toObject ? data.toObject() : data;
    const role = dataJs.role || 'accreditation';
    const accreditations = this.props.accreditations && this.props.accreditations.toJS();

    return (<List.Item>
      {role === 'accreditation'
        && <AccreditationsListClaimCard
          constituent={this.props.constituent}
          accreditations={accreditations || null}
          {...dataJs}
           />
      }
      {role === 'new'
        && <div style={{ opacity: 0.5 }}>
          <Link to={`/constituents/${this.props.constituent.get('id')}/addAccreditation`}>
            <Card
              style={{ cursor: 'pointer' }}
              role="button"
              aria-label="Add accreditation"
            >
              <div
                style={{
                  fontSize: '60px',
                  textAlign: 'center',
                }}
              >
                <Icon type="plus-circle-o" />
              </div>
            </Card>
          </Link>
        </div>
      }
      {role === 'loading' && <Card loading={true}>Loading</Card>}
    </List.Item>);
  }

  render() {
    const {
      constituent,
      patchInProgress,
      patchAccreditationId,
      onCancel,
      accreditationCurrent,
    } = this.props;
    const constituentClaims = constituent ? constituent.get('claims', null) : null;
    const claims = constituentClaims
      ? constituentClaims
        .toArray()
        .map(claim => claim.set('patching',
          patchInProgress
          && claim.getIn(['accreditation', 'id']) === patchAccreditationId))
      : [];
    const { true: activeClaims, false: inactiveClaims } = _.groupBy(
      claims, claim => claim.get('active'),
    );

    const finalActiveClaims = activeClaims || [];
    const finalInactiveClaims = inactiveClaims || [];

    const activeClaimsCount = finalActiveClaims.length;
    const { accreditationId } = accreditationCurrent;
    if (accreditationId) finalActiveClaims.push({ role: 'loading' });
    const inactiveClaimsCount = finalInactiveClaims.length;

    if (!constituent) {
      finalActiveClaims.push({ role: 'loading' });
    } else {
      finalActiveClaims.push({ role: 'new' });
    }

    return (<Fragment>
      <Collapse bordered={false} defaultActiveKey={['active']}>
        <Collapse.Panel
          header={<span>Active accreditations <Tag>{activeClaimsCount}</Tag></span>}
          key="active"
        >
          <List
            dataSource={finalActiveClaims}
            renderItem={this.renderClaim}
            grid={{
              gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2,
            }}
            locale={{ emptyText: 'No active accreditations' }}
          />
        </Collapse.Panel>
        <Collapse.Panel
          header={<span>Inactive accreditations <Tag>{inactiveClaimsCount}</Tag></span>}
          key="inactive"
        >
          <List
            dataSource={finalInactiveClaims}
            renderItem={this.renderClaim}
            grid={{
              gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2,
            }}
            locale={{ emptyText: 'No inactive accreditations' }}
          />
        </Collapse.Panel>
      </Collapse>
      <PortalChild portal={this.props.footerRef}>
        <Button onClick={onCancel}>Close</Button>
      </PortalChild>
    </Fragment>);
  }
}

const AccreditationsList = withRouter(connect(
  state => ({
    patchInProgress: state.doc.getIn(['http', 'patchAccreditationClaim', 'inProgress'], false),
    patchSuccess: state.doc.getIn(['http', 'patchAccreditationClaim', 'success'], false),
    patchConstituentId: state.doc.getIn(['http', 'patchAccreditationClaim', 'meta', 'constituentId']),
    patchAccreditationId: state.doc.getIn(['http', 'patchAccreditationClaim', 'meta', 'accreditationId']),
    accreditationCurrent: state.doc.getIn(['accreditationMr', 'current']),
    accreditations: state.doc.getIn(['http', 'getConstituent', 'data', 'accreditations']),
    getConstituentInProgress: state.doc.getIn(['http', 'getConstituent', 'inProgress']),
    getConstituentFailed: state.doc.getIn(['http', 'getConstituent', 'failed']),
    getConstituent: state.doc.getIn(['http', 'getConstituent', 'data']),
  }),
  actions,
)(AccreditationsListComponent));

export {
  AccreditationsList,
  AccreditationFieldStatus,
};
