import { simpleAjaxDispatch } from './http';

export function registerTotp() {
  return simpleAjaxDispatch(
    '/totp/register',
    'regTotp',
    { method: 'POST' },
  );
}

export function verifyTotp(json) {
  return (dispatch, getState) => simpleAjaxDispatch(
    '/totp/verify',
    'verifyTotp',
    { method: 'POST', json },
  )(dispatch, getState).then(action => {
    if (action.success) {
      dispatch({
        type: 'SET_TOKEN',
        token: action.data.token,
      });
    }
    return action;
  });
}

export function enforceMFA(orgId, json) {
  return (dispatch, getState) => simpleAjaxDispatch(
    `/organizations/${orgId}`,
    'enforceMFA',
    { method: 'PATCH', json },
  )(dispatch, getState).then(action => {
    return action;
  });
}

export function getEnforceMFADetails(orgId) {
  return (dispatch, getState) => simpleAjaxDispatch(
    `/organizations/${orgId}`,
    'getOrgMfaDetails',
    { method: 'GET' },
  )(dispatch, getState).then(action => {
    return action;
  });
}

export function disableMFA(userID, json = {}) {
  return (dispatch, getState) => simpleAjaxDispatch(
    `/totp/disable/${userID}`,
    'disableMFA',
    { method: 'POST', json },
  )(dispatch, getState).then(action => {
    return action;
  });
}

export function getCurrentUserInfo(userId) {
  return (dispatch, getState) => simpleAjaxDispatch(
    `/users/${userId}`,
    'mfaUserInfo',
    { method: 'GET' },
  )(dispatch, getState).then(action => {
    return action;
  });
}