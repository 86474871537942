import _ from 'lodash';
import React, { Component, PureComponent } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { fromJS } from 'immutable';

import * as actions from '../../../actions';

import { BreadcrumbLevel } from '../../BreadcrumbLevel';

import { parseQsHash, historyPushPath, checkFeature } from '../../../utils';

import { ConstituentsList } from './ConstituentsList';
import { GlobalActions } from './GlobalActions';
import { ConstituentImportRoute } from './Import';
import { ConstituentPopupRoute } from '../ConstituentPage';
import { NdwsSyncRoute } from './NdwsSyncImport';
import { QldblueImportRoute } from './QldblueImport';
import { FilterTagList } from './FilterTagList';
import { FilterSearch } from './FilterSearch';
import { ListSort } from './ListSort';
import { withContentLayout } from '../../layout/Layout';


@withContentLayout
class ConstituentListComponent extends PureComponent {
  constructor(props) {
    super(props);

    const defaultTag = props.tagsByIdent.get('smart.active.active') && props.tagsByIdent.get('smart.active.active').toJS();
    this.state = {
      query: '',
      sortBy: 'full_name',
      sortOrder: 'asc',
      offset: 0,
      tags: defaultTag ? [{ with_: true, ...defaultTag }] : [],
      ...this.parseLocationHash(props.location.hash, props),
    };
  }

  componentDidMount() {
    // Pushes the location build from state in the constructor
    const fromState = this.buildLocationHash(this.state);
    historyPushPath(
      this.props.history,
      this.props.location,
      this.props.location.pathname,
      // location.pathname instead of match.path, to retain the constituent
      // detail page, or any other sub page of the constituents list
      fromState,
      {
        replace: true,
      },
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const oldHash = this.buildLocationHash(prevState);
    const newHash = this.buildLocationHash(this.state);
    if (_.isEqual(oldHash, newHash)) {
      return;
    }
    historyPushPath(
      this.props.history,
      this.props.location,
      this.props.match.path,
      newHash,
      {
        replace: true,
      },
    );
  }

  parseLocationHash = (hash, props = this.props) => {
    const parsed = parseQsHash(hash);
    const out = {};
    if (parsed.orgSmartTag) {
      out.orgSmartTag = props.tagsByIdent.get(parsed.orgSmartTag, fromJS({ role: parsed.orgSmartTag })).toJS();
    }

    if (parsed.dashboardType) {
      out.dashboardType = parsed.dashboardType;
    }

    if (parsed.organizationId) {
      out.organizationId = parsed.organizationId;
    }

    if (parsed.filters) {
      out.tags = parsed.filters.split(',')
        .map(str => {
          const strWith = str[0] !== '!';
          const strId = strWith ? str : str.substr(1);
          const tag = props.tagsByIdent.get(strId, fromJS({ role: strId })).toJS();
          return { with_: strWith, ...tag };
        });
    }

    return out;
  }

  buildLocationHash = state => ({
    filters: state.tags.map(tag => (tag.with_ ? '' : '!') + (tag.role ? tag.role : tag.id)),
  })

  setQuery = query => {
    this.setState({ query });
  }

  setFilterTags = tags => {
    this.setState({ tags });
  }

  setSort = (sortBy, sortOrder) => {
    if (sortBy === null) {
      sortBy = 'full_name';
      sortOrder = 'asc';
    }
    this.setState({ sortBy, sortOrder });
  }

  setOffset = offset => {
    this.setState({ offset });
  }

  render() {
    const {
      sortBy, sortOrder, tags, query, orgSmartTag, dashboardType, organizationId,
    } = this.state;

    return (
      <div>
        <BreadcrumbLevel text="People" />
        <Switch>
          <Route path={`${this.props.match.path}/ndws-sync`} component={NdwsSyncRoute} />
          <Route path={`${this.props.match.path}/qldblue-import`} component={QldblueImportRoute} />
          <Route path={`${this.props.match.path}/import`} component={ConstituentImportRoute} />
          <Route path={`${this.props.match.path}/:id`} component={ConstituentPopupRoute} />
        </Switch>

        <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '1em' }}>
          <div>
            <div style={{ position: 'relative', marginBottom: '5px' }}>
              <FilterSearch query={this.state.query} setQuery={this.setQuery} />
            </div>
            <div>
              { checkFeature('filterTags') && <FilterTagList tags={this.state.tags} setTags={this.setFilterTags} /> }
            </div>
            <div style={{ paddingTop: '1em' }}>
              <ListSort sortBy={this.state.sortBy} sortOrder={this.state.sortOrder} setSort={this.setSort} />
            </div>
          </div>
          <div>
            <GlobalActions tags={this.state.tags} query={this.state.query}/>
          </div>
        </div>
        <ConstituentsList handleOffset={this.setOffset} offset={this.state.offset} organizationId={organizationId} pageOptions={
          {
            sortBy, sortOrder, tags, query, orgSmartTag, dashboardType, organizationId,
          }}
        />
      </div>
    );
  }
}

@withRouter
@connect(state => ({
  tagsByIdent: state.doc.getIn(['tagsMr', 'byIdent']),
}), actions)
class ConstituentListPage extends Component {
  componentDidMount() {
    this.props.lazyTags();
  }

  render() {
    if (!this.props.tagsByIdent) {
      return <Spin />;
    }
    return <ConstituentListComponent {...this.props} />;
  }
}

export {
  ConstituentListPage,
};
