import { message } from 'antd';
import { simpleAjaxDispatch } from './http';

export function getOrganizationUsers(organizationId) {
  return simpleAjaxDispatch(
    `/users?organization_id=${organizationId}&detail=full`,
    'getOrganizationUsers',
  );
}

export function makeConstituentUser(constituentId, password) {
  return simpleAjaxDispatch(
    '/users',
    'addUser',
    {
      method: 'POST',
      meta: { constituentId },
      json: {
        constituent: { id: constituentId },
        password,
      },
    },
  );
}

export function removeConstituentUser(constituentId) {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.doc
      .getIn(['constituentsMr', 'byIdFull', constituentId, 'user', 'id'], null);

    return simpleAjaxDispatch(
      `/users/${userId}`,
      'deleteUser',
      { method: 'DELETE', meta: { userId, constituentId } },
    )(dispatch, getState);
  };
}

export function patchUser(userId, json) {
  return (dispatch, getState) => {
    const state = getState();
    const resetToken = state.doc.getIn(['authMr', 'resetToken']);
    const authOpts = resetToken
      ? { auth: `Bearer ${resetToken}` }
      : {};
    return simpleAjaxDispatch(
      `/users/${userId}`,
      'patchUser',
      { method: 'PATCH', json, meta: { userId, json }, ...authOpts },
    )(dispatch, getState);
  };
}

export function patchUserPassword(userId, json) {
  return (dispatch, getState) => {
    const state = getState();
    const resetToken = state.doc.getIn(['authMr', 'resetToken']);
    const authOpts = resetToken
      ? { auth: `Bearer ${resetToken}` }
      : {};
    return simpleAjaxDispatch(
      `/users/${userId}/password_reset`,
      'patchUserPassword',
      { method: 'PATCH', json, meta: { userId, json }, ...authOpts },
    )(dispatch, getState).then(action => {
      if (!action.data.okay && action.response.status === 401) {
        message.error('Cannot reset password because the token has expired');
      }
    });
  };
}

export function getUser(userId) {
  return simpleAjaxDispatch(
    `/users/${userId}`,
    'getUser',
  );
}

export function clearPasswordResetToken() {
  return dispatch => dispatch({ type: 'CLEAR_PASSWORD_RESET_TOKEN' });
}
