/* eslint prefer-promise-reject-errors: ["off"] */
import uuid4 from 'uuid/v4';

import { UNKNOWN_HTTP_ERROR_MESSAGE } from '../defaults';

import { simpleAjaxDispatch } from './http';
import { watchCorrelationId } from './longpoller';

export function submitLinkage() {
  const allData = { correlationId: uuid4() };

  let finalValue = null;
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const watchProm = watchCorrelationId(allData.correlationId, (err, value) => {
      if (err) return;

      const event = value.routingKey.split('.')[3];
      if (event === 'complete') {
        if (finalValue) {
          if (finalValue.event === 'value') {
            resolve(JSON.parse(finalValue.content));
          } else if (finalValue.event === 'error') {
            reject(JSON.parse(finalValue.content));
          } else {
            reject({ _errors: [UNKNOWN_HTTP_ERROR_MESSAGE] });
          }
        } else {
          reject({ _errors: [UNKNOWN_HTTP_ERROR_MESSAGE] });
        }
      } else {
        finalValue = value;
        finalValue.event = event;
      }
    })
      .then(() => {
        const url = '/organizations/me';
        simpleAjaxDispatch(url, 'linkage', {
          method: 'GET',
        })(dispatch, getState)
          .then(action => {
            if (action.failed) {
              reject(action.data);
            }
          }).catch(err => reject({ _errors: [err] })).finally(() => watchProm.cancel());
      })
      .catch(err => { console.error(err); });
  });
}
