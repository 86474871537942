import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Modal, Input, Form, Button, Upload, Icon, message,
} from 'antd';

import * as actions from '../../../actions';
import { checkFeature } from '../../../utils';

const { Dragger } = Upload;
@Form.create()
@connect(state => ({
  urlServerErrors: state.doc.getIn(['http', 'submitAttachmentURL', 'data', '_errors'], null),
}), actions)
class AttachmentModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      uploaded: false,
    };
  }

  handleOk = ev => {
    const { submitAttachmentURL, handleCancel, form: { validateFields } } = this.props;
    validateFields(async (errs, values) => {
      if (errs) {
        return;
      }

      if (!values.link) {
        if (!this.state.uploaded) {
          message.error('required to add evidence in at least one method!');
          return;
        }
        handleCancel();
        return;
      }

      try {
        const resp = await submitAttachmentURL(
          this.props.constituentId,
          this.props.accreditationId,
          values.link,
        );
        const { success } = resp;

        if (success) {
          handleCancel();
        } else {
          /* eslint no-unused-expressions: ["error", { "allowTernary": true }] */
          this.props.urlServerErrors
            ? message.error(this.props.urlServerErrors.toJS().claim[0])
            : message.error('unhandled error');
        }
      } catch (err) {
        message.error('unhandled exception');
      }
    });
  }

    customRequest = ({ file, onError, onProgress, onSuccess }) => {
      this.props.uploadAttachment(file, this.props.constituentId, this.props.accreditationId).then(
        action => {
          if (action.success) {
            onSuccess(action.response.body);
            this.setState({ uploaded: true });
            if (this.props.onSaved) this.props.onSaved(action);
          } else {
            message.error("We're having trouble uploading your file right now.");
            onError(action.response, action.response.body);
            if (this.props.onSaveFailed) this.props.onSaveFailed(action);
          }
        },
      ).catch(err => {
        Object.entries(err).forEach(
          ([_, value]) => message.error(value),
        );
        onError();
      });
    }

    render() {
      const { visible, form, handleCancel } = this.props;
      const { getFieldDecorator } = form;

      return (
        <Modal
          title="Add Evidence"
          visible={visible}
          onCancel={handleCancel}
          destroyOnClose={true}
          onOk={this.handleOk}
          style={{ minWidth: 800 }}
        >

          <Form layout="vertical">
            <Form.Item label="Add Link">
              {getFieldDecorator('link', {
                rules: [
                  {
                    type: 'url',
                    message: 'Invalid url',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            {checkFeature('attachmentRequest') && <Form.Item>
              Or Request Attachment from a person {'    '}
              <Button size="small" type="primary">Request</Button>
            </Form.Item>}
            <Form.Item>
              Or Add Attachment
              <Dragger
                style={{ padding: '16px' }}
                accept="application/pdf,image/jpeg,image/gif,image/png,image/tiff,image/bmp,image/heic,image/heif"
                multiple={ false }
                customRequest={ this.customRequest }
              >
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
              </Dragger>
            </Form.Item>
          </Form>
        </Modal>
      );
    }
}

export { AttachmentModal };
