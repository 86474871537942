import {
  Form, Steps, Avatar, Popover, Skeleton, Icon, Result,
} from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PinInput from 'react-pin-input';
import * as actions from '../../../actions';

const { Step } = Steps;

class TotpRegisterPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showQRCode: false,
      showSecret: false,
      totpRegisterError: [],
    };
  }

  inputRef = React.createRef();

  fetchRegisterDetails = async () => {
    const { failed, data, success } = await this.props.registerTotp();
    if (failed) this.setState({ totpRegisterError: this.getHttpError(data) });
    if (success) this.inputRef.current.focus();
  }

  getHttpError(data) {
    if (!data._errors) return '';
    return Object.values(data._errors).flat().join('|');
  }

  componentDidMount() {
    // Fetching reg data , barcode and uri
    this.fetchRegisterDetails();
  }

  componentWillUnmount() {
    // Clean up
    this.setState({ saving: false,
      showQRCode: false,
      showSecret: false,
      totpRegisterError: [],
    });
  }

  getSecret(url) {
    let secretValue = '';
    if (!url) return secretValue;
    const values = url.split('&');
    for (const pair of values) {
      const [key, value] = pair.split('=');
      if (key !== 'secret') continue;
      secretValue = value;
      break;
    }
    return secretValue;
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <>
        { this.props.regTotpFailed
          ? <Result
            title={<p style={{ fontSize: '16px' }}>Failed to load MFA registration details</p>}
            subTitle={this.state.totpRegisterError}
            icon={<Icon style={{ color: '#C88242' }} type="frown" />}
            />
          : <>
            <p style={{ fontWeight: '600' }}>Set up your authenticator application</p>
            <Steps direction="vertical" className='mfa-steps'>
              <Step status='process' title="Install a compatible application." description='(Eg: Google Authenticator, Microsoft Authenticator)' />
              <Step status='process' style={{ minHeight: '180px' }} description={
                <Skeleton loading={this.props.regTotpInProgress || !this.props.regTotpBarcode}>
                  <div style={{ marginTop: '10px', display: 'flex' }}>
                    <div>
                      {this.state.showQRCode
                        ? <Popover placement='rightBottom' content={<span className='verify-totp-underline-on-hover' onClick={() => { this.setState({ showQRCode: false }); this.inputRef.current.focus(); }}>Hide</span>}>
                          <Avatar src={`data:image/png;base64, ${this.props.regTotpBarcode}`} shape="square" size={150} style={{ border: '2px solid gray' }} />
                        </Popover>
                        : <Avatar onClick={() => { this.setState({ showQRCode: !this.showQRCode }); this.inputRef.current.focus(); }} shape='square' size={150} style={{ color: '#82412C', fontSize: '12px', cursor: 'pointer', border: '2px solid #82412C' }}><span className='verify-totp-underline-on-hover'>Show QR code</span></Avatar>
                      }
                    </div>
                    <div style={{ marginLeft: '20px', paddingRight: '50px' }}>
                      <p>Open your authenticator app, Then use the app to scan the code.<span className='verify-totp-underline-on-hover' onClick={() => { this.setState({ showSecret: !this.state.showSecret }) }}>(show secret key)</span> </p>
                      <Popover trigger='click' placement='right' content={<span className='verify-totp-underline-on-hover' onClick={() => { this.setState({ showQRCode: false }); }} style={{ color: 'green' }}><Icon type="check-circle" /> Copied</span>}>
                        { this.state.showSecret
                          ? <p style={{ backgroundColor: '#f5f5f5', display: 'inline-block', padding: '10px', fontSize: '12px', marginTop: '5px' }} >{this.getSecret(this.props.regTotpUrl)} <a><Icon style={{ fontSize: '15px', marginLeft: '5px' }} onClick={() => { navigator.clipboard.writeText(this.getSecret(this.props.regTotpUrl)); }} type="switcher" /></a></p>
                          : null
                        }
                      </Popover>
                    </div>
                  </div>
                </Skeleton>
                }
              />
              <Step status='process' title="Please enter the six digit code from your authenticator app." description={
                <>
                  <Form>
                    <Form.Item validateStatus={this.props.verifyInputStatus} help={this.props.verifyingHelp} >
                      { getFieldDecorator('pininput', {
                        rules: [
                          { required: true, message: 'MFA code is required' },
                        ],
                      })(
                        <PinInput
                          length={6}
                          initialValue=""
                          onChange={(value, index) => { this.props.setParentState(value) }}
                          type="numeric"
                          inputMode="number"
                          style={{ fontSize: '16px', marginBottom: '5px', marginTop: '10px' }}
                          inputStyle={{ borderColor: 'black', height: '30px', width: '30px', borderRadius: '10px' }}
                          inputFocusStyle={{ borderColor: '#C88242' }}
                          onComplete={(value, index) => { this.props.setParentState(value); }}
                          autoSelect={true}
                          ref={this.inputRef}
                        />,
                      ) }
                    </Form.Item>
                  </Form>
                </>
            }
              />
            </Steps>
          </>
        }
      </>);
  }
}

export const TotpRegisterForm = Form.create()(TotpRegisterPage);

export const TotpRegisterComponent = connect(
  state => ({
    organizationId: state.doc.getIn(['authMr', 'organizationId']),
    invalidSSOIdError: state.doc.getIn(['http', 'createSSOCreds', 'data', '_errors', 'organization', 'ssoId'], null),
    regTotpInProgress: state.doc.getIn(['http', 'regTotp', 'inProgress'], true),
    regTotpFailed: state.doc.getIn(['http', 'regTotp', 'failed'], false),
    regTotpBarcode: state.doc.getIn(['http', 'regTotp', 'data', 'barcode'], null),
    regTotpUrl: state.doc.getIn(['http', 'regTotp', 'data', 'url'], null),
  }),
  actions,
)(TotpRegisterForm);
