import _ from 'lodash';
import {
  Alert, Badge, Button, Col, Form, Input, Row, Spin, Switch, Tree
} from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { BreadcrumbLevel } from '../BreadcrumbLevel';
import * as actions from '../../actions';
import { DefinedTerm } from '../elements';
import { withContentLayout } from '../layout/Layout';


const _getTag = (tags, predicate) => (tags ? tags.find(predicate) : null);
const NOTIFICATION_CATS_BASE = [
  {
    fieldSuffix: 'user',
    trueValue: 'organization',
    getTag: tags => _getTag(tags, tag => tag.get('role') === 'smart.user'),
  },
  {
    fieldSuffix: 'constituent',
    trueValue: 'own',
    getTag: tags => _getTag(tags, tag => tag.getIn(['parent', 'role']) === 'organization'),
  },
];

const EXPIRY_UPGRADE_KEYS = [
  'accreditationUpgradeExpiring',
  'accreditationUpgradeExpiredUnconfirmed',
  'accreditationUpgradeExpiredConfirmed',
];

const EXPIRING_DOWNGRADE_KEYS = [
  'accreditationDowngradeExpiring',
  'accreditationInitialExpiring',
];

const EXPIRY_UPGRADE_KEY = 'accreditationUpgradeExpiry';

const EXPIRING_DOWNGRADE_KEY = 'accreditationDowngradeExpiring';


@withContentLayout
class OrgSettingsPageComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.footerRef = React.createRef();
    this.state = {
      showOCG: false,
      showSA: false,
      showQLDBLUECreds: false,
      showQLDBLUEDefaults: false,
      currentType: null,
      OCGCredsSaveClicked: false,
      SACredsSaveClicked: false,
      QLDBLUECredsSaveClicked: false,
      QLDBLUEDefaultsSaveClicked: false,
      QLDBLUEDefaultEmpType: null,
      QLDBLUEDefaultAppType: null,
      QLDBLUEDefaultStateSet: false,
    };
  }

  componentWillMount() {
    if (this.props.organizationId) {
      this.props.fetchOrganization(this.props.organizationId);
      this.props.fetchOrganizationEmail(this.props.organizationId);
      this.props.fetchTags(this.props.organizationId);
    }
  }

  getCategories = () => NOTIFICATION_CATS_BASE.map(base => ({
    ...base,
    tag: base.getTag(this.props.tagsItems),
  }))

  componentDidUpdate(prevProps) {
    if (prevProps.organizationId !== this.props.organizationId) {
      this.props.fetchOrganization(this.props.organizationId);
      this.props.fetchOrganizationEmail(this.props.organizationId);
      this.props.fetchTags(this.props.organizationId);
    }
    if (prevProps.organizationData !== this.props.organizationData) {
      const orgData = this.props.organizationData.toJSON();
      this.props.form.setFieldsValue({
        abn: orgData.abn,
        externalIdEnabled: orgData.externalIdEnabled,
        managerEmailEnabled: orgData.managerEmailEnabled,
        ndwsSyncEnabled: !!orgData.ndwsSyncEnabled,
        externalLinkTpl: orgData.externalLinkTpl,
        genaccNotificationEnabled: orgData.genaccNotificationEnabled,
        phoneNumber: orgData.phoneNumber,
        institutionsCredentials: orgData.institutionsCredentials || {},
        saSync: _.get(orgData, 'institutionsCredentials.sa.saSync', false),
      });
    }
    if (prevProps.tagsItems !== this.props.tagsItems) {
      const categories = this.getCategories();
      if (categories.every(cat => cat.tag)) {
        const notificationValues = _.fromPairs(categories.map(({ fieldSuffix, tag, trueValue }) => [
          `notify.${fieldSuffix}`,
          Object.entries(tag.get('notifications').toJS())
            .filter(([apiKey, value]) => value === trueValue)
            .map(([apiKey, value]) => {
              if (EXPIRY_UPGRADE_KEYS.indexOf(apiKey) !== -1) {
                return EXPIRY_UPGRADE_KEY;
              }
              if (EXPIRING_DOWNGRADE_KEYS.indexOf(apiKey) !== -1) {
                return EXPIRING_DOWNGRADE_KEY;
              }
              return apiKey;
            })
            .filter(apiKey => apiKey),
        ]));
        this.props.form.setFieldsValue(notificationValues);
      }
    }
  }

  handleSubmit = ev => {
    ev.preventDefault();
    this.props.form.validateFields((errors, values) => {
      if (errors) return;

      this.props.patchOrganization(this.props.organizationId, _.omit(values, ['notify']));

      for (const { fieldSuffix, tag, trueValue } of this.getCategories()) {
        const fieldValues = values.notify[fieldSuffix];
        const notifications = _.mapValues(
          tag.get('notifications').toJS(),
          (v, k) => (
            fieldValues.indexOf(k) === -1
              ? null
              : trueValue
          ),
        );

        // Expand the single expiry value in the UI into the multiple values
        // that represent expiry (confirmed, unconfirmed, soon)
        const expiryUpgradeIdx = fieldValues.indexOf(EXPIRY_UPGRADE_KEY);
        if (expiryUpgradeIdx !== -1) {
          for (const key of EXPIRY_UPGRADE_KEYS) {
            notifications[key] = trueValue;
          }
        }
        const expiringDowngradeIdx = fieldValues.indexOf(EXPIRING_DOWNGRADE_KEY);
        if (expiringDowngradeIdx !== -1) {
          for (const key of EXPIRING_DOWNGRADE_KEYS) {
            notifications[key] = trueValue;
          }
        }

        this.props.patchTag(tag.get('id'), { notifications });
      }

      const doneEmail = window.SERVER_DATA.allowedStatesForOrgEmails.filter(item => {
        if (values && values[item.type]) {
          return this.props.postOrganizationEmail(this.props.organizationId, { type: item.type });
        }
        return null;
      });

      if (doneEmail) {
        this.props.fetchOrganizationEmail(this.props.organizationId);
      }
    });
  }

  renderNotificationsTree = () => (
    <Tree
      selectable={false}
      checkable
    >
      <Tree.TreeNode title={<strong>New issue present</strong>} key="accreditationDowngrade" checkable={false} />
      <Tree.TreeNode title={<Badge color="yellow" text="New warning" />} key="accreditationDowngradeYellow">
        <Tree.TreeNode title="Expiring soon" key={EXPIRING_DOWNGRADE_KEY} />
        <Tree.TreeNode title="Expired (unconfirmed)" key="accreditationDowngradeExpiredUnconfirmed" />
        <Tree.TreeNode title="Victorian Working With Children" key="accreditationDowngradeVicwwc">
          <Tree.TreeNode title="New card details" key="accreditationDowngradeVicwwcIsNewCard" />
        </Tree.TreeNode>
        <Tree.TreeNode title="Conditional" key="accreditationDowngradeIsConditional" />
      </Tree.TreeNode>
      <Tree.TreeNode title={<Badge color="red" text="New critical issue" />} key="accreditationDowngradeRed">
        <Tree.TreeNode title="No longer current (revoked, expired)" key="accreditationDowngradeNotCurrent" />
        <Tree.TreeNode title="Not valid on first check" key="accreditationInitialNotCurrent" />
        <Tree.TreeNode title="Name mismatch on first check" key="accreditationInitialNameMismatch" />
      </Tree.TreeNode>
      <Tree.TreeNode title={<strong>Issue resolved</strong>} key="accreditationUpgrade" checkable={false} />
      <Tree.TreeNode title={<Badge color="yellow" text="Warning resolved" />} key="accreditationUpgradeYellow">
        <Tree.TreeNode title="Conditional" key="accreditationUpgradeIsConditional" />
        <Tree.TreeNode title="Renewed expiry" key={EXPIRY_UPGRADE_KEY} />
        <Tree.TreeNode title="Victorian Working With Children" key="accreditationUpgradeVicwwc">
          <Tree.TreeNode title="Application approved" key="accreditationUpgradeVicwwcIsApplication" />
        </Tree.TreeNode>
      </Tree.TreeNode>
      <Tree.TreeNode title={<Badge color="red" text="Critical issue resolved" />} key="accreditationUpgradeRed">
        <Tree.TreeNode title="Currency issue resolved" key="accreditationUpgradeNotCurrent" />
      </Tree.TreeNode>
    </Tree>
  )

  render() {
    const {
      userId,
      form,
      fetchOrganizationInProgress, fetchOrganizationFailed,
      patchOrganizationInProgress, patchOrganizationFailed,
      tagsItems,
      institutionsCredentials: inCreds,
    } = this.props;
    const { getFieldDecorator } = form;
    const externalIdEnabled = form.getFieldValue('externalIdEnabled');
    const institutionsCredentials = form.getFieldValue('institutionsCredentials') || (inCreds && inCreds.toJSON()) || {};

    if (institutionsCredentials.qld && institutionsCredentials.qld[userId]) {
      institutionsCredentials.qldblue = institutionsCredentials.qld[userId];
    }
    return (<Spin spinning={fetchOrganizationInProgress}>
      <BreadcrumbLevel text="Settings" />
      <BreadcrumbLevel text="General" />
      {fetchOrganizationFailed
        ? <Alert message="Couldn't get your organisation's settings right now!" /> : null}
      {patchOrganizationFailed
        ? <Alert message="Couldn't save your organisation's settings right now!" closable /> : null}
      <Form onSubmit={this.handleSubmit}>

        <h2>Notifications</h2>
        <Spin spinning={!tagsItems}>
          <h3>
            <DefinedTerm definition="An admin user is a person with a login to weareoho.com">
              Admin Users
            </DefinedTerm> receive notifications when <em>any accreditation in this organisation</em>
          </h3>
          <Form.Item>
            {getFieldDecorator('notify.user', {
              valuePropName: 'checkedKeys',
              trigger: 'onCheck',
              getValueFromEvent: value => value,
              initialValue: [],
            })(
              this.renderNotificationsTree(),
            )
            }
          </Form.Item>
          <h3>
            <DefinedTerm definition="A person is anyone who your organisation has added to weareoho.com">
              People
            </DefinedTerm> receive notifications when <em>one of their own accreditations</em>
          </h3>
          <Form.Item>
            {getFieldDecorator('notify.constituent', {
              valuePropName: 'checkedKeys',
              trigger: 'onCheck',
              getValueFromEvent: value => value,
              initialValue: [],
            })(
              this.renderNotificationsTree(),
            )
            }
          </Form.Item>
        </Spin>
        <h2>General Expiry Management</h2>
        <p>
          Enabling this feature allows you to receive expiry notifications for general accreditations.
        </p>
        <Form.Item labelCol={{ span: 6 }} label="General Expiry Management" required>
          {getFieldDecorator('genaccNotificationEnabled', {
            valuePropName: 'checked',
          })(
            <Switch />,
          )
          }
        </Form.Item>

        <h2>Display manager's email</h2>
        <p>
          Enabling this feature allows you to assign a manager's email.
        </p>
        <Form.Item labelCol={{ span: 6 }} label="Manager's email field enabled" required>
          {getFieldDecorator('managerEmailEnabled', {
            valuePropName: 'checked',
          })(
            <Switch />,
          )
          }
        </Form.Item>

        <h2>External system links</h2>
        <p>
          Enabling this feature allows you to track a primary key in an external system.
        </p>
        <Form.Item labelCol={{ span: 6 }} label="External ID field enabled" required>
          {getFieldDecorator('externalIdEnabled', {
            valuePropName: 'checked',
          })(
            <Switch />,
          )
          }
        </Form.Item>
        <Form.Item
          label="External system URL template"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          style={{ visibility: externalIdEnabled ? 'visible' : 'hidden' }}
          extra={<span>
            We will generate links in person profiles to this URL,
            replacing <code>[ID]</code> with the person's external ID.
            You may leave this field blank if you don't want to use this
            feature.
          </span>}
        >
          {getFieldDecorator('externalLinkTpl', {
          })(
            <Input />,
          )
          }
        </Form.Item>

        <Row justify="end" type="flex">
          <Col>
            <Button
              style={{ marginTop: '16px' }}
              loading={patchOrganizationInProgress}
              type="primary"
              htmlType="submit"
              size="large"
            >Save</Button>
          </Col>
        </Row>
      </Form>
    </Spin>);
  }
}

export const OrgSettingsPageForm = Form.create()(OrgSettingsPageComponent);

export const OrgSettingsPage = connect(
  state => ({
    userId: state.doc.getIn(['authMr', 'id'], null),
    organizationData: state.doc.getIn(['authMr', 'constituent', 'organization'], null),
    tagsItems: state.doc.getIn(['tagsMr', 'items'], null),
    fetchOrganizationInProgress: state.doc.getIn(['http', 'fetchOrganization', 'inProgress'], true),
    fetchOrganizationFailed: state.doc.getIn(['http', 'fetchOrganization', 'failed'], false),
    fetchOrganizationEmailInProgress: state.doc.getIn(['http', 'fetchOrganizationEmail', 'inProgress'], true),
    fetchOrganizationEmailFailed: state.doc.getIn(['http', 'fetchOrganizationEmail', 'failed'], false),
    patchOrganizationInProgress: state.doc.getIn(['http', 'patchOrganization', 'inProgress'], false),
    patchOrganizationFailed: state.doc.getIn(['http', 'patchOrganization', 'failed'], false),
    organizationId: state.doc.getIn(['authMr', 'organizationId'], null),
    postOrganizationEmailInProgress: state.doc.getIn(['http', 'postOrganizationEmail', 'inProgress'], true),
    postOrganizationEmailFailed: state.doc.getIn(['http', 'postOrganizationEmail', 'failed'], false),
    postOrganizationCredentialsFailed: state.doc.getIn(['http', 'postOrganizationCredentials', 'failed'], false),
    organizationEmails: state.doc.getIn(['authMr', 'constituent', 'organization', 'orgEmails'], null),
    institutionsCredentials: state.doc.getIn(['authMr', 'constituent', 'organization', 'institutionsCredentials'], null),
  }),
  actions,
)(OrgSettingsPageForm);
