import moment from 'moment';
import { fromJS } from 'immutable';
import {
  Button, Checkbox, Form, Icon, Input, Modal, Table, DatePicker, Switch, Radio, Row, Col, message,
} from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { BreadcrumbLevel } from '../BreadcrumbLevel';
import * as actions from '../../actions';
import { withContentLayout } from '../layout/Layout';


const EMPTY_LIST = fromJS([]);
const FORM_LAYOUT = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};
const formItemPropsSingleColumn = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
  labelAlign: 'left',
};
const formItemPropsTwoColumn = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
  labelAlign: 'left',
};

const formItemPropsThreeColumn = {
  labelCol: { span: 10 },
  wrapperCol: { span: 10 },
  labelAlign: 'left',
};
const dateTypes = [{ label: 'Expiry', value: 'expiry' }, { label: 'Attainment', value: 'attainment' }];
const RECEIVABLE_DATE_FORMATS = ['YYYY-MM-DD', 'DD-MM-YYYY', 'DD/MM/YYYY'];
const OUTPUT_DATE_FORMAT = 'YYYY-MM-DD';
const INITIAL_STATES = {
  showAddNewForm: false,
  dateType: dateTypes[0].value,
  selectedId: null,
  effectiveFrom: null,
  switches: null,
  showInactive: false,
};

const getProcessDefineTypes = (defineTypes, showInactive) => {
  const types = showInactive ? defineTypes : defineTypes.filter(t => new Date(t.effectiveTo) >= Date.now());
  return types.map(
    item => ({ ...item, validityPeriod: item.effectiveFrom ? `${item.effectiveFrom} - ${item.effectiveTo}` : 'Non expiry' }),
  );
};

const getActiveStatus = (id, items) => {
  const foundItem = items.find(item => item.typeId === id).active;
  return foundItem;
};
const AddDefineTypes = ({
  handleSubmit,
  handleReset,
  getFieldDecorator,
  dateTypes,
  handleDateType,
  selectedDateType,
  selectedItem,
  rules,
  effectiveFrom,
  handleEffectiveFrom,
  handleSwitch,
  switches,
}) => {
  let initialValues = {
    effectiveFrom: moment().startOf('day'),
    effectiveTo: moment().add(1, 'days'),
    identifierMandatory: true,
    boardReportable: true,
    dateType: selectedDateType,
    attainmentValidity: 0,
    manageExpiry: true,
    expiryDatePatchable: false,
    gracePeriod: 0,
    relatedArtefactUrlBase: null,
    maxAttachments: 0,
    smartTagName: null,
  };

  const editedValues = {};

  if (selectedItem) {
    selectedItem.effectiveFrom = moment(selectedItem.effectiveFrom);
    selectedItem.effectiveTo = moment(selectedItem.effectiveTo);
  }

  if (effectiveFrom) {
    editedValues.effectiveFrom = moment(effectiveFrom);
    editedValues.effectiveTo = moment(effectiveFrom).add(1, 'days');
    if (selectedItem && selectedItem.effectiveTo >= editedValues.effectiveTo) {
      editedValues.effectiveTo = selectedItem.effectiveTo;
    }
  }

  initialValues = { ...initialValues, ...selectedItem, ...editedValues, ...switches };

  return (<Form onSubmit={ev => handleSubmit(ev)} onReset={ev => handleReset(ev)} {...FORM_LAYOUT} style={{ alignSelf: 'start', textAlign: 'left' }}>

    <Row>
      <Col span={24}>
        <Form.Item
          label="Type"
          key="typeName"
          {...formItemPropsSingleColumn}
        >
          {getFieldDecorator('typeName', {
            initialValue: initialValues.typeName,
            rules: [
              {
                required: true,
                message: 'Type name is required',
              },
            ],
          })(
            <Input
              placeholder="Name of accreditation type. eg: First Aid Treatment"
            />,
          )
          }

        </Form.Item>
        <Form.Item
          key="typeId"
          style={{ display: 'none' }}
          {...formItemPropsSingleColumn}
        >
          {getFieldDecorator('typeId', {
            initialValue: initialValues.typeId,
          })(
            <Input
              type="hidden"
            />,
          )
          }
        </Form.Item>

      </Col>

    </Row>
    <Row>
      <Col span={24}>
        <Form.Item
          label="Issued By"
          key="issuedBy"
          {...formItemPropsSingleColumn}
        >
          {getFieldDecorator('issuedBy', {
            initialValue: initialValues.issuedBy,
          })(
            <Input
              placeholder="Issued Organization Name"
            />,
          )
          }
        </Form.Item>
      </Col>
    </Row>

    <Row>
      <Col span={6}>
        <Form.Item
          label="Code"
          key="typeCode"
          {...formItemPropsThreeColumn}
        >
          {getFieldDecorator('typeCode', {
            initialValue: initialValues.typeCode,
            rules: [
              {
                required: true,
                message: 'Type code is required',
              },
              ...(rules || []),
            ],
          })(
            <Input
              placeholder="eg: stjafac"
              disabled={initialValues.typeId && initialValues.typeId > 0}
            />,
          )
          }
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Effective From"
          key="effectiveFrom"
          {...formItemPropsThreeColumn}
        >
          {getFieldDecorator('effectiveFrom', {
            initialValue: initialValues.effectiveFrom,
            rules: [
              {
                required: true,
                message: 'From date is required',
              },
            ],
          })(<DatePicker
            format="YYYY-MM-DD"
            onChange={(dateMoment, dateString) => handleEffectiveFrom({ dateMoment, dateString })}
             />)
          }
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Effective To"
          key="effectiveTo"
          {...formItemPropsThreeColumn}
        >
          {getFieldDecorator('effectiveTo', {
            initialValue: initialValues.effectiveTo,
          })(<DatePicker
            format="YYYY-MM-DD"
            disabledDate={current => current && current <= initialValues.effectiveFrom}
             />)
          }
        </Form.Item>
      </Col>
    </Row>
    <Row>

      <Col span={10}>
        <Form.Item
          label="Identifier mandatory"
          key="identifierMandatory"
          {...formItemPropsTwoColumn}
        >
          {getFieldDecorator('identifierMandatory', {
            defaultChecked: initialValues.identifierMandatory,
          })(
            <Switch checked={initialValues.identifierMandatory} onChange={value => handleSwitch({ label: 'identifierMandatory', value })} />,
          )
          }
        </Form.Item>
      </Col>
      <Col span={11}>
        <Form.Item
          label="Board Reportable"
          key="boardReportable"
          {...formItemPropsTwoColumn}
        >
          {getFieldDecorator('boardReportable', {
            initialValue: initialValues.boardReportable,
          })(
            <Col pull={4}>
              <Switch checked={initialValues.boardReportable} onChange={value => handleSwitch({ label: 'boardReportable', value })} />
            </Col>,
          )
          }
        </Form.Item>
      </Col>
    </Row>

    <Row>
      <Col span={11}>
        <Form.Item
          label="Date Type"
          key="dateType"
          {...formItemPropsTwoColumn}
        >
          {getFieldDecorator('dateType', {
            initialValue: initialValues.dateType,
            rules: [
              { required: true },
            ],

          })(
            <Radio.Group buttonStyle="solid" onChange={e => handleDateType(e)}>
              <Radio.Button value={dateTypes[0].value}>{dateTypes[0].label}</Radio.Button>
              <Radio.Button value={dateTypes[1].value}>{dateTypes[1].label}</Radio.Button>
            </Radio.Group>,
          )
          }
        </Form.Item>
      </Col>
      <Col span={11}>

        <Form.Item
          label="Attainment validity"
          key="attainmentValidity"
          {...formItemPropsTwoColumn}
        >
          {getFieldDecorator('attainmentValidity', {
            initialValue: initialValues.attainmentValidity,
            rules: [
              { required: initialValues.dateType === 'attainment' },
            ],
          })(
            <Input
              placeholder="90"
              disabled={selectedDateType === dateTypes[0].value}
            />,
          )
          }
        </Form.Item>
      </Col>
    </Row>
    <Row>
      <Col span={10}>
        <Form.Item
          label="Manage Expiry"
          key="manageExpiry"
          {...formItemPropsTwoColumn}
        >
          {getFieldDecorator('manageExpiry', {
            initialValue: initialValues.manageExpiry,
          })(
            <Switch checked={initialValues.manageExpiry} onChange={value => handleSwitch({ label: 'manageExpiry', value })} />,
          )
          }
        </Form.Item>
      </Col>

      <Col span={11}>
        <Form.Item
          label="Expiry Updatable"
          key="expiryDatePatchable"
          {...formItemPropsTwoColumn}
        >
          {getFieldDecorator('expiryDatePatchable', {
            initialValue: initialValues.expiryDatePatchable,
          })(
            <Col pull={4}>
              <Switch checked={initialValues.expiryDatePatchable} onChange={value => handleSwitch({ label: 'expiryDatePatchable', value })} />
            </Col>,
          )
          }
        </Form.Item>
      </Col>
    </Row>

    <Row justify="start">
      <Col span={24}>
        <Form.Item
          label="Grace Period"
          key="gracePeriod"
          {...formItemPropsSingleColumn}
        >
          {getFieldDecorator('gracePeriod', {
            initialValue: initialValues.gracePeriod,
          })(
            <Input
              placeholder="0"
            />,
          )
          }
        </Form.Item>
      </Col>
    </Row>
    <Row justify="start">
      <Col span={24}>
        <Form.Item
          label="Attachment Base"
          key="relatedArtefactUrlBase"
          {...formItemPropsSingleColumn}
        >
          {getFieldDecorator('relatedArtefactUrlBase', {
            initialValue: initialValues.relatedArtefactUrlBase,
          })(
            <Input />,
          )
          }
        </Form.Item>
      </Col>
    </Row>
    <Row justify="start">
      <Col span={24}>
        <Form.Item
          label="Max Attachments"
          key="maxAttachments"
          {...formItemPropsSingleColumn}
        >
          {getFieldDecorator('maxAttachments', {
            initialValue: initialValues.maxAttachments,
          })(
            <Input />,
          )
          }
        </Form.Item>
      </Col>
    </Row>
    <Row justify="start">
      <Col span={24}>
        <Form.Item
          label="Tag Name"
          key="smartTagName"
          {...formItemPropsSingleColumn}
        >
          {getFieldDecorator('smartTagName', {
            initialValue: initialValues.smartTagName,
            rules: [
              { required: false },
            ],
          })(
            <Input />,
          )
          }
        </Form.Item>
      </Col>
    </Row>

    <Row justify="end" type="flex">

      <Col span={8}>
        <Button
          style={{ marginTop: '16px' }}
          type="secondary"
          htmlType="reset"
          size="large"
        >Cancel</Button>
        <Button
          style={{ marginTop: '16px', marginLeft: '23px' }}
          type="primary"
          htmlType="submit"
          size="large"
        >Save Definition</Button>
      </Col>
    </Row>
  </Form>);
};

const RequestFormToAddDefineTypes = ({
  maxDefinedTypes, definedTypesUpgradeRequested, definedTypesUpgradeRequestor, handleUpgradeRequest, reachedMax, definedTypesUpgradeRequestorName,
}) => {
  const italicH2 = { padding: '10px', fontStyle: 'italic', color: '#ccc' };
  return (
    <div style={{ textAlign: 'center' }}>
      { (!maxDefinedTypes) && <h2 style={italicH2}>Your subscription level does not currently allow <br />you to define your own accreditation types</h2>}
      { (maxDefinedTypes > 0) && reachedMax && <h2 style={italicH2}>You've reached your maximum defined types for your subscription.<br />Please request upgrade if you require more</h2>}
      { !definedTypesUpgradeRequested && reachedMax && <Button type="primary" size="large" onClick={ev => handleUpgradeRequest(ev)}>Request Upgrade</Button>}
      { definedTypesUpgradeRequested && definedTypesUpgradeRequestor && (
        <h2 style={italicH2}>Upgrade requested on {moment(definedTypesUpgradeRequested).format('YYYY-MM-DD hh:mm:ss')} by {definedTypesUpgradeRequestorName}</h2>
      ) }
    </div>
  );
};

@withContentLayout
class DefineTypeSettingsPageComponent extends PureComponent {
  state = { ...INITIAL_STATES }

  componentWillMount() {
    if (this.props.organizationId) {
      this.props.fetchDefineTypes();
      this.props.fetchOrganization(this.props.organizationId);
      if (this.props.organization) {
        const { definedTypesUpgradeRequestor } = this.props.organization.toJS();
        if (definedTypesUpgradeRequestor) this.props.getUser(definedTypesUpgradeRequestor);
      }
    }
  }

  handleSubmit = async ev => {
    ev.preventDefault();
    this.props.form.validateFields(async (errors, values) => {
      if (!errors) {
        values.effectiveFrom = moment(values.effectiveFrom, RECEIVABLE_DATE_FORMATS).format(OUTPUT_DATE_FORMAT);
        values.effectiveTo = moment(values.effectiveTo, RECEIVABLE_DATE_FORMATS).format(OUTPUT_DATE_FORMAT);
        try {
          const response = values.typeId ? await this.props.patchDefineType(values.typeId, values) : await this.props.postDefineType(values);
          if (response.data._errors) {
            Object.keys(response.data._errors).forEach(error => {
              const fieldValue = this.props.form.getFieldValue(error);
              if (fieldValue) {
                this.props.form.setFields({
                  [error]: {
                    value: fieldValue,
                    errors: [new Error(response.data._errors[error])],
                  },
                });
              } else {
                throw new Error("Field doesn't exists");
              }
            });
          } else {
            this.setState({ ...INITIAL_STATES });
            this.props.fetchDefineTypes();
          }
        } catch (err) {
          console.error(err);
          message.error('Unable to process the request. Please try again!');
        }
        await this.props.initApp();
        await this.props.fetchOrganization(this.props.organizationId);
      }
    });
  }

  handleDateType = e => {
    if (e.target.value === dateTypes[0].value) {
      this.props.form.setFieldsValue({
        attainment_validity: 0,
      });
    }

    this.setState({ dateType: e.target.value });
  }

  toggleInactive = e => {
    this.setState({ showInactive: !this.state.showInactive });
  }

  handleEffectiveFrom = e => {
    this.setState({ effectiveFrom: e.dateString });
  }

  showAddForm = () => {
    this.setState({ showAddNewForm: true });
  }

  handleSwitch = ({ label, value }) => {
    let { switches } = this.state;
    if (switches) {
      switches[label] = value;
    } else {
      switches = { [label]: value };
    }
    this.setState({ switches });
  }

  handleUpgradeRequest = async ev => {
    ev.preventDefault();
    await this.props.patchDefineTypeRequestForOrg(this.props.organizationId);
    await this.props.fetchOrganization(this.props.organizationId);
    const { definedTypesUpgradeRequestor } = this.props.organization.toJS();
    if (definedTypesUpgradeRequestor) await this.props.getUser(definedTypesUpgradeRequestor);
  }

  handleEditForm = async obj => {
    this.setState({ selectedId: obj.typeId });
    await this.props.fetchSelectedDefineType(obj.typeId);
    if (!this.props.fetchDefineTypesFailed) {
      this.setState({ showAddNewForm: true });
    }
  }

  closeModal = () => {
    this.setState({ ...INITIAL_STATES });
  }

  render() {
    const {
      defineTypes, selectedDefineType, form, rules, organization, definedTypesUpgradeRequestorName,
    } = this.props;
    const { maxDefinedTypes, definedTypesUpgradeRequested, definedTypesUpgradeRequestor } = organization && organization.toJS() || {};
    const { getFieldDecorator } = form;
    const items = (defineTypes && defineTypes.toJS()) || [];
    const selectedItem = selectedDefineType && selectedDefineType.toJS() || null;
    const reachedMax = items.filter(i => i.active).length >= maxDefinedTypes;

    return (<div style={{ textAlign: 'start' }}>
      <BreadcrumbLevel text="Settings" />
      <BreadcrumbLevel text="Define Type" />

      { (!maxDefinedTypes || items.length >= maxDefinedTypes) && (
        <RequestFormToAddDefineTypes
          maxDefinedTypes={maxDefinedTypes}
          definedTypesUpgradeRequested={definedTypesUpgradeRequested}
          definedTypesUpgradeRequestor={definedTypesUpgradeRequestor}
          definedTypesUpgradeRequestorName = {definedTypesUpgradeRequestorName}
          reachedMax={reachedMax}
          handleUpgradeRequest={ev => this.handleUpgradeRequest(ev)}
        />
      )}

      { maxDefinedTypes > 0 && (
        <div>
          <div style={{ float: 'right' }}>
            <Checkbox onChange={this.toggleInactive}>Show Inactive Types</Checkbox>
            <Button onClick={this.showAddForm} disabled={reachedMax}><Icon type="plus" /></Button>
          </div>
          <h2 style={{ paddingBottom: '20px' }}>Define Types</h2>

          <Table dataSource={getProcessDefineTypes(items, this.state.showInactive)} pagination={false}>
            <Table.Column title="Type Name" dataIndex="typeName" />
            <Table.Column title="Type Code" dataIndex="typeCode" />
            <Table.Column title="Validity Period" dataIndex="validityPeriod" />
            <Table.Column title="Actions" dataIndex="typeId"
              onCellClick={e => this.handleEditForm(e)}
              render={rowKey => <div>{getActiveStatus(rowKey, items) && <Icon type="edit" style={{ color: '#1890ff', cursor: 'pointer' }} />}</div>}
            >
            </Table.Column>
          </Table>
        </div>
      )
      }

      {this.state.showAddNewForm
        && <Modal
          visible={true}
          title={this.state.selectedId ? 'Edit define type' : 'Add new define type'}
          width='70%'

          footer={null}
          onCancel={this.closeModal}
           >
          <AddDefineTypes
            getFieldDecorator={getFieldDecorator}
            dateTypes={dateTypes}
            handleDateType={ev => this.handleDateType(ev)}
            handleSubmit={ev => this.handleSubmit(ev)}
            effectiveFrom={this.state.effectiveFrom}
            handleEffectiveFrom={ev => this.handleEffectiveFrom(ev)}
            handleReset={() => this.closeModal()}
            selectedDateType={this.state.dateType}
            selectedItem={selectedItem && this.state.selectedId && selectedItem[this.state.selectedId]}
            rules={rules}
            handleSwitch={ev => this.handleSwitch(ev)}
            switches={this.state.switches}
          />
        </Modal>
      }

    </div>);
  }
}

export const DefineTypeSettingsPageForm = Form.create()(DefineTypeSettingsPageComponent);

export const DefineTypeSettingsPage = connect(
  state => ({
    fetchDefineTypesInProgress: state.doc.getIn(['http', 'fetchDefineTypes', 'inProgress'], true),
    fetchDefineTypesFailed: state.doc.getIn(['http', 'fetchDefineTypes', 'failed'], false),
    postDefineTypeInProgress: state.doc.getIn(['http', 'postDefineType', 'inProgress'], true),
    postDefineTypeFailed: state.doc.getIn(['http', 'postDefineType', 'failed'], true),
    patchDefineTypeInProgress: state.doc.getIn(['http', 'patchDefineType', 'inProgress'], true),
    patchDefineTypeFailed: state.doc.getIn(['http', 'patchDefineType', 'failed'], true),
    fetchSelectedDefineTypeFailed: state.doc.getIn(['http', 'fetchSelectedDefineType', 'failed'], true),
    fetchSelectedDefineTypeInProgress: state.doc.getIn(['http', 'fetchSelectedDefineType', 'inProgress'], true),
    fetchOrganizationInProgress: state.doc.getIn(['http', 'fetchOrganization', 'inProgress'], true),
    fetchOrganizationFailed: state.doc.getIn(['http', 'fetchOrganization', 'failed'], false),
    patchDefineTypeRequestForOrgInProgress: state.doc.getIn(['http', 'patchDefineType', 'inProgress'], true),
    patchDefineTypeRequestForOrgFailed: state.doc.getIn(['http', 'patchDefineType', 'failed'], true),
    defineTypes: state.doc.getIn(['defineTypesMr', 'items'], EMPTY_LIST),
    selectedDefineType: state.doc.getIn(['defineTypesMr', 'byId'], null),
    organizationId: state.doc.getIn(['authMr', 'constituent', 'organization', 'id'], null),
    organizationName: state.doc.getIn(['authMr', 'constituent', 'organization', 'name'], null),
    organization: state.doc.getIn(['authMr', 'constituent', 'organization'], null),
    definedTypesUpgradeRequestorName: state.doc.getIn(['http', 'getUser', 'data', 'constituent', 'fullName'], null),

  }),
  actions,
)(DefineTypeSettingsPageForm);
