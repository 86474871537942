import { Form, Button } from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PinInput from 'react-pin-input';
import * as actions from '../../actions';
import logo from '../../logos/logo_dark.svg';
import { Svg } from '../Svg';

class FrontTotpComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pinInput1: '',
      saving: false,
      verifyingHelp: undefined,
      verifyInputStatus: undefined,
    };
  }

  componentWillUnmount() {
    this.setState({
      pinInput1: '',
      saving: false,
      verifyingHelp: undefined,
      verifyInputStatus: undefined,
    });
  }

  getHttpError(data) {
    if (!data._errors) return '';
    return Object.values(data._errors).flat().join('|');
  }

  handleOk = async evt => {
    this.setState({ saving: true });
    this.props.form.validateFields(async (err, values) => {
      if (err || this.state.pinInput1.split('').length !== 6) {
        this.setState({
          verifyingHelp: 'MFA code requires 6 digit code',
          verifyInputStatus: 'error',
          saving: false,
        });
        return;
      }
      const res = await this.props.verifyTotp({
        code: Number(this.state.pinInput1),
      });
      if (res.failed) {
        this.setState({
          verifyingHelp: this.getHttpError(res.data),
          verifyInputStatus: 'error',
          saving: false,
        });
      }
      if (res.success) {
        this.setState({ saving: false });
        this.props.onVerifySuccess();
      }
    });
  };

  render() {
    return (
      <div style={{
        display: 'flex', backgroundColor: '#CAD6DE', minHeight: '100vh', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <div style={{
          display: 'flex', flexDirection: 'column', height: 'fit-content', alignItems: 'center', backgroundColor: 'white', padding: '30px', borderRadius: '15px',
        }}
        >
          <div>
            <Svg
              url={ logo }
              style={{
                width: '180px',
                position: 'relative',
              }}
            />
          </div>

          <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '25px',
          }}
          >
            <p style={{ fontWeight: '600', fontSize: '16px' }}>Additional verification required</p>
            <p>Please enter the six digit code from your authenticator app.</p>
            <Form style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <Form.Item style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <PinInput
                  focus
                  length={6}
                  initialValue=""
                  onChange={(value, index) => { this.setState({ pinInput1: value }); }}
                  type="numeric"
                  inputMode="number"
                  style={{ fontSize: '16px', marginBottom: '5px', marginTop: '15px' }}
                  inputStyle={{ borderColor: 'black', borderRadius: '10px', height: '30px', width: '30px' }}
                  inputFocusStyle={{ borderColor: '#C88242' }}
                  onComplete={(value, index) => {
                    this.setState({ pinInput1: value });
                    this.handleOk();
                  }}
                  autoSelect={true}
                />
              </Form.Item>
              { this.state.verifyInputStatus
                ? <div style={{
                  display: 'flex', justifyContent: 'center', color: 'red', marginTop: '-25px', width: '100%',
                }}>
                  <p>{this.state.verifyingHelp}</p>
                </div>
                : null
              }
            </Form>
            <Button type="primary" style={{ marginTop: '15px' }} loading={this.state.saving} onClick={ this.handleOk }>Sign in</Button>
          </div>
        </div>
      </div>);
  }
}

export const FrontTotpForm = Form.create()(FrontTotpComponent);

export const FrontTotpPage = connect(
  state => ({
    organizationId: state.doc.getIn(['authMr', 'organizationId']),
  }),
  actions,
)(FrontTotpForm);
