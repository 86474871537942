import qs from 'querystringify';
import { simpleAjaxDispatch } from './http';

export function fetchInfoCaptureRequests(opts) {
  const { organizationId, ...queryOpts } = opts;
  const qsData = {
    ...queryOpts,
    org_id: organizationId,
    detail: 'full',
    limit: 10,
  };
  const qsFinal = qs.stringify(qsData, true);
  return simpleAjaxDispatch(
    `/info_capture_request${qsFinal}`,
    'fetchInfoCaptureRequests',
  );
}

export function infoCaptureRequestAction(opts) {
  const { icrId } = opts;
  const qsData = {
    action: opts.action,
    organization_id: opts.organizationId,
    icr_id: icrId,
    constituent_id: opts.constituentId,
    ...(opts.onboardingOrganizationId) && { onboarding_organization_id: opts.onboardingOrganizationId },
  };
  const qsFinal = qs.stringify(qsData, true);
  return simpleAjaxDispatch(
    `/info_capture_request/${icrId}/action${qsFinal}`,
    'infoCaptureRequestAction',
    { method: 'POST' },
  );
}
