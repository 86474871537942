import React, { Component, Fragment } from 'react';

import {
  Form, Icon, Button, Col, Row,
} from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';

export class DynamicFields extends Component {
  id = this.props.customValues ? this.props.customValues.length : 1;

  add = () => {
    const { getFieldValue, setFieldsValue, name } = this.props;
    const keys = getFieldValue(`${name}List`);
    const nextKeys = keys.concat(this.id++);

    setFieldsValue({
      [`${name}List`]: nextKeys,
    });
  };

  remove = k => () => {
    const { getFieldValue, setFieldsValue, name } = this.props;
    const keys = getFieldValue(`${name}List`);

    if (keys.length === 1) return;
    setFieldsValue({
      [`${name}List`]: keys.filter(key => key !== k),
    });
  };

  defaultValidation = name => ({
    validateTrigger: ['onChange', 'onBlur'],
    rules: [
      {
        required: true,
        whitespace: true,
        message: `Please input ${name}.`,
      },
    ],
  });

  addSingleField = () => {
    const {
      getFieldDecorator, getFieldValue, fields: obj, name, initialValues,
    } = this.props;
    getFieldDecorator(`${name}List`, { initialValue: [...initialValues.keys()] });

    const fieldCounter = getFieldValue(`${name}List`);
    return fieldCounter.map(k => (
      <Form.Item key={k}>
        {getFieldDecorator(
          `${name}[${k}]`,
          {
            ...obj.validation || this.defaultValidation(name),
            initialValue: initialValues[k],
          },
        )(obj.field())}
        {fieldCounter.length > 1 ? (
          <Icon
            type="delete"
            style={{
              fontSize: '24px', marginLeft: '20px',
            }}
            onClick={this.remove(k)}
          />
        ) : null}
      </Form.Item>
    ));
  };

  addMultipleFields = () => {
    const {
      getFieldDecorator, getFieldValue, fields, name, customValues,
    } = this.props;
    const initValCount = customValues ? [...customValues.keys()] : [0];
    getFieldDecorator(`${name}List`, { initialValue: initValCount });
    const fieldCounter = getFieldValue(`${name}List`);

    return fieldCounter.reduce((preResult, k) => {
      const row = fields.map((obj, i) => (
        <Col span={12} key={`${name}[${k}][${obj.name}]`}>
          <Form.Item key={`${k}${obj.name}`}>
            {getFieldDecorator(
              `${name}[${k}][${obj.name}]`,
              {
                ...obj.validation || this.defaultValidation(name),
                initialValue: _.get(customValues, [k, obj.name]),
              },

            )(obj.field())}
            {fieldCounter.length > 1 && fields.length - 1 === i ? (
              <Icon
                type="delete"
                style={{ fontSize: '20px', marginLeft: '24px' }}
                onClick={this.remove(k)}
              />
            ) : null}
          </Form.Item>
        </Col>
      ));
      return [...preResult, <Row key={k}>{row}</Row>];
    }, []);
  };

  render() {
    const { fields } = this.props;
    return (
      <Fragment>
        {Array.isArray(fields)
          ? this.addMultipleFields()
          : this.addSingleField()}
        <Form.Item>
          <Button shape="circle" icon="plus" style={{ minWidth: '50px' }} onClick={this.add}>
          </Button>
        </Form.Item>
      </Fragment>
    );
  }
}

DynamicFields.propTypes = {
  name: PropTypes.string.isRequired,
  fields: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  getFieldValue: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
};
