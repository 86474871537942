import { NON_NUM_RE, RegNumberFormItems } from './shared';

export class TasTrbFormItems extends RegNumberFormItems {
  static slug = 'tastrb'

  static placeholder = '12345'

  static identLabel = 'TRB number'

  static regNumberGetValueFromEvent(evt) {
    return evt.target.value.substr(0, 5).replace(NON_NUM_RE, '');
  }
}
