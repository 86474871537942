import { simpleAjaxDispatch } from './http';
import { checkFeature } from '../utils';

export function fetchAccreditationsSummary(organizationId) {
  const useLibraryFlag = checkFeature('filterTagLibrary') ? '&use_library=true' : '';
  const url = `/dashboard/accreditations_summary?organization_id=${organizationId}${useLibraryFlag}`;
  return simpleAjaxDispatch(
    url,
    'accreditationsSummary',
  );
}

export function fetchAccreditationsSummaryControl(organizationId) {
  const url = `/dashboard/accreditations_summary/control?organization_id=${organizationId}`;
  return simpleAjaxDispatch(
    url,
    'accreditationsSummaryControl',
  );
}

export function fetchOrgStats() {
  const url = '/dashboard/org_stats';
  return simpleAjaxDispatch(
    url,
    'orgStats',
  );
}

export function fetchReportsSummary(organizationId) {
  const url = `/dashboard/reports?organization_id=${organizationId}`;
  return simpleAjaxDispatch(
    url,
    'reportsSummary',
  );
}

export function fetchQldBlueLinkageTileStatus() {
  return simpleAjaxDispatch(
    '/dashboard/qldblue/to_link',
    'qldblueLinkageCount',
  );
}

export function fetchNDWSLinkageTileStatus() {
  return simpleAjaxDispatch(
    '/ndws/linkage',
    'ndwsLinkageCount',
  );
}

export function fetchTwoFactorCode() {
  return simpleAjaxDispatch(
    '/twofactor',
    'twoFactorCode',
  );
}

export function fetchQldBlueLinkageStatus() {
  return simpleAjaxDispatch(
    '/dashboard/qldblue/linkage/status',
    'qldblueLinkageStatus',
  );
}
