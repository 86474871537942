import _ from 'lodash';

import { fromJS } from 'immutable';

export const VERSION = window.DOC_IMAGE_TAG ? window.DOC_IMAGE_TAG : 'development';

export const SHORT_VERSION = VERSION.substr(0, 10);

export const HTTP_GROUP = { inProgress: false, success: false, failed: false, seq: null };

export const IMPORT_HELP_LINK = 'https://dutyofcare.supporthero.io/article/show/88807-how-do-i-prepare-the-csv-file-for-bulk-upload';

export const NDWS_SYNC_IMPORT_HELP_LINK = 'https://help.weareoho.com/article/show/169262-processing-a-ndws-sync-file';

export const QLD_SYNC_IMPORT_HELP_LINK = 'https://help.weareoho.com/article/show/169263-processing-a-qld-blue-portal-sync-file';

export const IMPORT_TEMPLATE_LINK = 'https://s3.eu-west-1.amazonaws.com/cdn.supporthero.io/article/4197/Oho%20General%20Import%20Template%20with%20DOB.csv';

export const JWT_BEST_BEFORE = 1; // Best before token expiry in days

export const DEFAULT_QLDBLUE_LINKAGE_POLLING_DELAY = 60000;

export const QLDBLUE_LINKAGE_INITIATED_POLLING_DELAY = 3000;

const STATE_JS = {
  auth: {
    username: null,
    password: null,
    loginError: null,
  },
  authMr: {
    authenticated: null,
  },
  constituentsMr: {
    byIdFull: {},
    byIdPartial: {},
  },
  accreditationMr: {
    current: {},
  },
  import: {},
  importNdws: {},
  ndwsSync: {},
  ndwsLinkage: {},
  importQldblue: {},
  linkage: {
    error: null,
    qld: null,
  },
  linkageError: {
    ndws: null,
  },
  organization: {
    organizations: {},
  },
  navigation: {
    breadcrumbs: [],
  },
  http: {
    getMe: HTTP_GROUP,
    checkUniqueEmail: HTTP_GROUP,
  },
  sso: {},
};
_.assign(STATE_JS.authMr, STATE_JS.auth);
_.assign(STATE_JS.constituentMr, STATE_JS.constituent);
_.assign(STATE_JS.constituentsMr, STATE_JS.constituents);
_.assign(STATE_JS.organizationMr, STATE_JS.organization);

export const STATE = fromJS(STATE_JS);

export const UNKNOWN_HTTP_ERROR_MESSAGE = "Oops! Something's wrong. You might need to try again later";

export const PHONE_HELP_MESSAGE = 'Including the area code';

export const TRIAL_DAYS = 7;

export const COMPLIANCE_STANDARDS = [
  'Child safety is embedded in institutional leadership, governance and culture',
  'Children participate in decisions affecting them and are taken seriously',
  'Families and communities are informed and involved',
  'Equity is promoted and diversity respected',
  'People working with children are suitable and supported',
  'Processes to respond to complaints of child sexual abuse are child focussed',
  'Staff are equipped with the knowledge, skills and awareness to keep children safe through continual education and training',
  'Physical and online environments minimise the opportunity for abuse to occur',
  'Implementation of child safe standards is continuously reviewed and improved',
  'Policies and procedures document how the institution is child safe',
];

export const IMPORTANT_TAG_COLORS = {
  'smart.accreditation_invalid': 'red',
  'smart.accreditation.nswwwc.ocg_barred': 'red',
  'smart.accreditation.nswwwc.pending_load': 'red',
  'smart.accreditation.nswwwc.pending_response': 'red',
  'smart.accreditation.nswwwc.review_required': 'red',
  'smart.accreditation.ntwwc.pending_load': 'red',
  'smart.accreditation.ntwwc.review_required': 'red',
  'smart.accreditation.sawwc.pending_load': 'red',
  'smart.accreditation.sawwc.review_required': 'red',
  'smart.accreditation.npc.pending_response': 'red',
  'smart.accreditation.npc.review_required': 'red',
  'smart.accreditation.npc.not_suitable': 'red',
  'smart.accreditation.ipc.pending_response': 'red',
  'smart.accreditation.ipc.not_suitable': 'red',
  'smart.accreditation.taswwc.pending_load': 'red',
  'smart.accreditation.taswwc.review_required': 'red',
  'smart.accreditation.vicwwc.pending_load': 'red',
  'smart.accreditation.vicwwc.review_required': 'red',
  'smart.name_structure_incomplete': 'red',
  'smart.accreditation_review_required': 'red',
  'smart.accreditation_in_progress': 'red',
  'smart.accreditation_missing_dob_information': 'red',
  'smart.accreditation_not_linked': 'red',
};

export const WARNING_TAG_COLORS = {
  'smart.accreditation_expiring': 'orange',
  'smart.accreditation.npc.pending_acknowledgement': 'orange',
  'smart.accreditation.ipc.pending_acknowledgement': 'orange',
  'smart.accreditation.vicwwc.is_new_card': 'orange',
  'smart.accreditation.vicwwc.is_application': 'orange',
  'smart.accreditation_is_application': 'orange',
};


export const TAG_COLORS = {
  smart: '#E4EBEF',
  organization: '#E6D9D5',
  ...IMPORTANT_TAG_COLORS,
  ...WARNING_TAG_COLORS,
};

export const SCAN_TYPES = [
  {
    key: 'ahpra',
    full: 'AHPRA Medical Registration Number',
    short: 'AHPRA',
  },
  {
    key: 'nttrb',
    full: 'Northern Territory Teachers Registration',
    short: 'N.T. Teachers',
  },
  {
    key: 'qct',
    full: 'Queensland College of Teachers',
    short: 'QCT',
  },
  {
    key: 'qldblue',
    full: 'Queensland Blue Card',
    short: 'Blue Card',
  },
  {
    key: 'qldblueex',
    full: 'Queensland Blue Card Exemption',
    short: 'Blue Card Exemption',
  },
  {
    key: 'qldblueall',
    full: 'Queensland Blue Card or Exemption',
    short: 'Blue Card',
  },
  {
    key: 'taswwc',
    full: 'Tasmanian Working With Children Check',
    short: 'Tas WWCC',
  },
  {
    key: 'tastrb',
    full: 'Tasmanian Teachers Registration',
    short: 'Tas Teachers',
  },
  {
    key: 'vicwwc',
    full: 'Victorian Working With Children Check',
    short: 'Vic WWCC',
  },
  {
    key: 'virtual.qld',
    full: 'Virtual validator for Queensland accreditations',
  },
  {
    key: 'vit',
    full: 'Victorian Institute of Teaching',
    short: 'VIT',
  },
  {
    key: 'wawwc',
    full: 'Western Australian Working With Children Check',
    short: 'W.A. WWC',
  }, {
    key: 'watrb',
    full: 'Western Australian Teachers Registration',
    short: 'W.A. Teachers',
  },
  {
    key: 'nswwwc',
    full: 'New South Wales Working With Children Check',
    short: 'NSW WWCC',
  },
  {
    key: 'sawwc',
    full: 'South Australia Working With Children Check',
    short: 'SA WWCC',
  },
];
