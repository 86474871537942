import _ from 'lodash';

import { fromJS } from 'immutable';

import { Reducer } from './base';

export class HttpReducer extends Reducer {
  statePath = ['http']

  handleHttpUpdatedAction(prevState, state, action) {
    // eslint-disable-next-line
    const { type, group, ...props } = action
    const { failed, success, inProgress, seq } = props;

    if (!_.isNil(seq)) {
      const oldSeq = state.getIn([group, 'seq'], null);
      if (!_.isNil(oldSeq) && seq < oldSeq) return state;
    }

    const completeProps = _.defaults(props, {
      failed: _.isNil(success) ? false : !inProgress && !success,
      success: _.isNil(failed) ? false : !inProgress && !failed,
    });

    return state.mergeIn([group], fromJS(completeProps));
  }
}
