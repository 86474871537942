import React, { PureComponent, Component } from 'react';
import { connect } from 'react-redux';
import {
  Alert, Modal, Tree, Popover, Button, Input,
} from 'antd';

import styled from 'styled-components';
import * as actions from '../../../actions';

import { ActionText, IconButton } from '../../elements';

const TagListStyle = styled.div`
  ul {
    list-style: none;
    margin: 5px;
    padding: 0;
  }

  li {
    margin: 5px;
    padding: 0;
  }
`;

@connect(state => ({
  orgRootTag: state.doc.getIn(['tagsMr', 'tree', 'organization'], null),
  organizationId: state.doc.getIn(['authMr', 'organizationId'], null),
}), actions)
class BulkActionBar extends PureComponent {
  state = {
    tagsOpen: false,
    tagsSelected: [],
  }

  handleCancelClick = evt => {
    evt.preventDefault();
    this.props.onFinally();
  }

  handleDeactivateClick = evt => {
    const { constituentIds, bulkPatchConstituents } = this.props;

    Modal.confirm({
      title: `Deactivate ${constituentIds.length} ${constituentIds.length > 1 ? 'people' : 'person'}`,
      content: 'Deactivating the selected people will mean that their accreditation(s) are no longer checked on a weekly basis. Do you want to proceed?',
      onOk: async () => {
        await bulkPatchConstituents(
          constituentIds,
          { active: false },
        );
        this.props.onFinally();
      },
      onCancel() {},
    });
  }

  handleExportClick = evt => {
    const { constituentIds, constituentExport } = this.props;
    const options = {
      constituent_ids: constituentIds.join(','),
    };
    Modal.confirm({
      title: `Download ${constituentIds.length} ${constituentIds.length > 1 ? 'people' : 'person'} records`,
      content: 'The selected people records will be downloaded as a CSV file. Do you want to proceed?',
      onOk: async () => {
        await constituentExport(
          options,
          'constituentExport',
        );
        this.props.onFinally();
      },
      onCancel() {},
    });
  }

  handleSaveTags = () => {
    const { constituentIds, bulkPatchConstituents } = this.props;
    const { tagsSelected } = this.state;

    Modal.confirm({
      title: `Apply tags to ${constituentIds.length} ${constituentIds.length > 1 ? 'people' : 'person'}`,
      content: 'Will add the selected tags to all selected people',
      onOk: async () => {
        await bulkPatchConstituents(
          constituentIds,
          { directTags: tagsSelected.map(id => ({ id })) },
        );
        this.props.onFinally();
        this.setState({
          tagsSelected: [],
          tagsOpen: false,
        });
      },
      onCancel() {},
    });
  }

  handleClickTags = evt => {
    evt.preventDefault();
    const { tagsOpen } = this.state;
    this.setState({ tagsOpen: !tagsOpen });
  }

  setSelectedTags = tagsSelected => {
    this.setState({
      tagsSelected,
    });
  }

  render() {
    const { constituentIds, organizationId } = this.props;
    const { tagsOpen, tagsSelected } = this.state;
    const orgRootTag = this.props.orgRootTag ? this.props.orgRootTag.toJS() : null;
    const orgTag = ((orgRootTag || {}).children || []).find(
      tag => tag.role === `organization.${organizationId}`,
    );
    orgTag.children = orgTag.children.filter(item => item.label !== 'Define Types');

    const content = (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ lineHeight: '25px' }}>
          <span>
            <strong>{constituentIds.length} </strong>
            {constituentIds.length > 1 ? 'people are' : 'person is'} selected
          </span>
          <ActionText
            style={{ marginLeft: 20 }}
            onClick={this.handleCancelClick}
          >
            CANCEL
          </ActionText>
        </div>
        <div>
          <Popover
            overlayStyle={{ zIndex: 999 }}
            placement="bottomRight"
            title="Add Tags"
            visible={tagsOpen}
            content={
              <TagTree
                orgTag={orgTag}
                selectedIds={tagsSelected}
                setSelectedTags={this.setSelectedTags}
                onSave={this.handleSaveTags}
              />
            }
          >
            <IconButton
              tooltip="Add Tags"
              iconType="tags"
              onClick={this.handleClickTags}
              style={{ fontSize: '25px', marginRight: '20px' }}
            />

          </Popover>

          <IconButton
            tooltip="Deactivate people"
            iconType="usergroup-delete"
            onClick={this.handleDeactivateClick}
            style={{ fontSize: '25px', marginRight: '20px' }}
          />
          <IconButton
            tooltip="Export people"
            iconType="download"
            onClick={this.handleExportClick}
            style={{ fontSize: '25px' }}
          />
        </div>
      </div>
    );
    return (
      <Alert type="info" message={content}/>
    );
  }
}

class TagTree extends Component {
  state = {
    searchTerm: '',
    expandedKeys: [],
    autoExpandParent: false,
  }

  handleSaveClick = evt => {
    evt.preventDefault();
    this.props.onSave();
  }

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };

  onSearchChange = e => {
    const { value } = e.target;
    const searchTermLower = value.toLowerCase();
    const expandedKeys = [];

    const addMatches = tag => {
      if (tag.label.toLowerCase().indexOf(searchTermLower) > -1) {
        expandedKeys.push(String(tag.id));
      }
      if (tag.children) {
        tag.children.forEach(addMatches);
      }
    };
    this.props.orgTag.children.forEach(addMatches);

    this.setState({
      searchTerm: value,
      expandedKeys,
      autoExpandParent: true,
    });
  }

  onCheck = ({ checked }) => {
    this.props.setSelectedTags(checked);
  }

  render() {
    const { autoExpandParent, searchTerm, expandedKeys } = this.state;
    const { selectedIds, orgTag } = this.props;
    const searchTermLower = searchTerm.toLowerCase();

    const renderNode = tag => {
      const { label } = tag;
      const index = label.toLowerCase().indexOf(searchTermLower);
      const beforeStr = label.substr(0, index);
      const matchStr = label.substr(index, searchTerm.length);
      const afterStr = label.substr(index + searchTerm.length);
      const title = index > -1 ? (
        <span>
          {beforeStr}
          <span style={{ color: '#f50' }}>{matchStr}</span>
          {afterStr}
        </span>
      ) : (
        <span>{label}</span>
      );
      return (
        <Tree.TreeNode key={tag.id} title={title}>
          { tag.children ? tag.children.map(renderNode) : null }
        </Tree.TreeNode>
      );
    };

    return (
      <TagListStyle>
        <Input.Search
          style={{ marginBottom: 8 }}
          placeholder="Search"
          onChange={this.onSearchChange}
        />
        <Tree
          checkable
          checkStrictly // Parents and Children are not associated
          checkedKeys={selectedIds}
          onCheck={this.onCheck}

          // Control expanded based on clicks and search matches
          onExpand={this.onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
        >
          { orgTag.children.map(renderNode) }
        </Tree>
        <div style={{ textAlign: 'right' }}>
          <Button disabled={selectedIds.length < 1} type="primary" onClick={this.handleSaveClick}>Save</Button>
        </div>
      </TagListStyle>
    );
  }
}

export { BulkActionBar };
