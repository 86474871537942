import React, { PureComponent, memo } from 'react';
import { Button, Icon, Tooltip } from 'antd';
import styled from 'styled-components';
import ReactDOM from 'react-dom';

export { ReactRouterTabs } from './ReactRouterTabs';

export { MaybeLink } from './MaybeLink';

export const CalloutText = styled.span`
  color: #1890ff;
  cursor: pointer;
`;

const InteractText = styled(CalloutText)`
  transition: color 0.3s;
  &:hover {
    color: #40a9ff;
  }
`;

export const DefinedTerm = memo(({ children, definition }) => (
  <Tooltip title={definition}>
    <CalloutText>{children}</CalloutText>
  </Tooltip>
));

export const IconDefinedTerm = memo(({ children, definition }) => (
  <Tooltip title={definition}>
    {children} <Icon type="info-circle"/>
  </Tooltip>
));

// TODO: Keyboard Action
export const ActionText = memo(({ onClick, children, ...remainder }) => (
  <InteractText
    onClick={onClick}
    tabIndex="0"
    {...remainder}
  >
    {children}
  </InteractText>
));

// TODO: Keyboard Action
export const IconButton = memo(({ tooltip, iconType, onClick, ...remainder }) => (
  <Tooltip title={tooltip}>
    <Icon tabIndex="0" type={iconType} onClick={onClick} {...remainder}/>
  </Tooltip>
));

export const ButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
  > * {
    margin-left: 10px;
  }
`;

const dkOrange = '#e07a5f';
const ltOrange = '#e39b88';

export const OrangeButton = styled(Button)`
  background-color: ${dkOrange};
  border-color: ${dkOrange};
  color: #fff;
  &:hover, &:active, &:focus {
    background-color: ${ltOrange};
    border-color: ${ltOrange};
    color: #fff;
  }
`;

export class PortalChild extends PureComponent {
  render() {
    if (!this.props.portal) {
      return this.props.children || null;
    }
    if (!this.props.portal.current) {
      return null;
    }
    return ReactDOM.createPortal(this.props.children, this.props.portal.current);
  }
}
