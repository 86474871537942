import { simpleAjaxDispatch } from './http';

export function fetchEmailBlocks(query) {
  return simpleAjaxDispatch(
    `/email_blocks?email=${query}`,
    'fetchEmailBlocks',
  );
}

export function putEmailBlock(email, reason) {
  return simpleAjaxDispatch(
    `/email_blocks/${email}`,
    'putEmailBlock',
    { method: 'PUT', json: { reason } },
  );
}

export function deleteEmailBlock(email) {
  return simpleAjaxDispatch(
    `/email_blocks/${email}`,
    'deleteEmailBlock',
    { method: 'DELETE' },
  );
}
