import React, { PureComponent } from 'react';
import {
  Table, Row, Col, Button, Tag, Icon, Tooltip, Form, Modal,
} from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';

import CreateRequestModal from './CreateRequestModal';
import AcceptRequestModal from './AcceptRequestModal';
import genericConfirmationModal from './GenericConfirmationModal';

import * as actions from '../../../actions';
import { fullTagLabel } from '../../../utils';
import { withContentLayout } from '../../layout/Layout';


const getIncompitableAttrIds = attribute => attribute.incompatibleWith.map(incomp => incomp._id);
const { confirm } = Modal;

@withContentLayout
class DataSharingPageComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      createRequestVisible: false,
      inactiveAttributes: [],
      acceptRequestVisible: false,
      sharingCodeVisible: false,
      copied: false,
      sharingCodeOrganizationName: null,
      loading: true,
    };
  }

  static statuses = {
    accepted: 'Accepted',
    cancelled: 'Cancelled',
    declined: 'Declined',
    ended: 'Ended',
    pending: 'Pending',
    pending_change: 'Pending Change',
    pending_end: 'Pending End',
  }

  componentWillMount() {
    this.props.fetchRelationships();
    this.props.fetchRelationshipTypes();
    this.props.fetchRelationshipAttributes();
    this.props.fetchCurrentOrganization();
    if (this.props.organizationId) this.props.fetchTags(this.props.organizationId, true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.organizationId !== prevProps.organizationId) {
      this.props.fetchTags(this.props.organizationId);
    }
    if (this.props.relationships !== prevProps.relationships) this.setState({ loading: false });
  }

  handleAcceptRequestCancel = () => {
    this.setState({ acceptRequestVisible: false });
  }

  handleCancel = () => {
    this.props.form.resetFields();
    this.setState({
      createRequestVisible: false,
      inactiveAttributes: [],
      sharingCodeOrganizationName: null,
      relationshipExists: false,
    });
  }

  handleCopySharingKey = evt => {
    evt.preventDefault();
    navigator.clipboard.writeText(evt.target.getAttribute('data-sharing-key'));
    this.setState({
      ...this.state,
      copied: true,
    });
  }

  handleRetrieveSharingKey = async relationshipId => {
    const keyResponse = await this.props.fetchRelationshipKey(relationshipId);
    if (keyResponse.data.sharingKey) {
      this.setState({
        ...this.state,
        copied: false,
        sharingKeys: {
          [relationshipId]: keyResponse.data.sharingKey,
        },
      });
    }
  };

  handleCopyFocus = () => {
    this.setState({
      ...this.state,
      copied: false,
    });
  };

  handleAttributeChange = checkedAttributes => {
    const inactiveAttr = new Set();
    const relationshipTypes = this.props.relationshipTypes ? this.props.relationshipTypes.toJS() : [];
    let customRoleId = null;
    let roleId = null;
    relationshipTypes.every(type => {
      if (_.isEqual(type.attributes.map(attri => attri._id).sort(), checkedAttributes.sort())) {
        roleId = type._id;
        return false;
      } if (type.name === 'Custom') {
        customRoleId = type._id;
      }
      return true;
    });
    const attributes = this.props.relationshipAttributes ? this.props.relationshipAttributes.toJS() : [];
    checkedAttributes.forEach(id => {
      getIncompitableAttrIds(attributes.find(attri => attri._id === id)).forEach(inactiveId => inactiveAttr.add(inactiveId));
    });
    this.props.form.setFields({
      role: {
        value: roleId !== null ? roleId : customRoleId,
      },
    });
    this.setState({ inactiveAttributes: [...inactiveAttr] });
  }

  handleRelationshipRequestSubmit = async e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const attributes = this.props.relationshipAttributes ? this.props.relationshipAttributes.toJS() : [];
        const relationshipTypes = this.props.relationshipTypes ? this.props.relationshipTypes.toJS() : [];
        const tagItems = this.props.tagItems ? this.props.tagItems.toJS() : [];
        const tag = tagItems.find(tag => tag.id === values.tags);
        const tagLabel = tag ? fullTagLabel(tag) : null;
        const content = (<><p>This would create a <b>{relationshipTypes.find(type => type._id === values.role).name} </b> relationship
          where, if accepted, <b>{this.state.sharingCodeOrganizationName}</b> will have the following permissions within your organization: <b>{attributes.filter(attri => values.attributes.includes(attri._id)).map(({ name }) => name).join(', ')}</b>.</p>
          <p><b>{this.state.sharingCodeOrganizationName}</b> will have this permission for <b>all</b> of your {tagLabel ? <><b>{tagLabel}</b> tagged</> : null} people.</p>
        </>);

        const handleOk = async () => {
          await this.props.createRelationship({
            attribute_ids: values.attributes,
            filter_tag_ids: [typeof values.tags !== 'undefined' && values.tags],
            sharing_code: values.sharingCode,
            type_id: values.role,
          });
          this.setState((state, props) => {
            const createRequestVisible = props.createRelationshipError;


            if (props.createRelationshipError) {
              const createRelationshipError = props.createRelationshipError.toJS();
              if (createRelationshipError.sharingCode) {
                this.props.form.setFields({
                  sharingCode: {
                    errors: [new Error('Invalid Sharing Code')],
                  },
                });
              }
            }
            props.form.resetFields();
            props.fetchRelationships();
            return { createRequestVisible, inactiveAttributes: !createRequestVisible ? [] : [...state.inactiveAttributes] };
          });
        };
        const handleCancel = () => {
          this.setState((state, props) => {
            props.form.resetFields();
            state.sharingCodeOrganizationName = null;
          });
        };
        confirm({
          title: 'Do you want to send this relationship request?',
          content,
          onOk() {
            handleOk();
          },
          onCancel() {
            handleCancel();
          },
        });
      }
    });
  };

  handleAcceptRequestSubmit = async (acceptForm, relationshipId) => {
    acceptForm.validateFields(async (err, values) => {
      if (!err) {
        await this.props.patchRelationship(relationshipId, {
          decision: 'accepted',
          sharing_key: values.sharingKey,
        });

        this.setState((state, props) => {
          const acceptRequestVisible = props.patchRelationshipError;
          if (!acceptRequestVisible) {
            acceptForm.resetFields();
            props.fetchRelationships();
          } else {
            acceptForm.setFields({
              sharingKey: {
                errors: [new Error('Invalid Sharing Key')],
              },
            });
          }

          return { acceptRequestVisible };
        });
      }
    });
  }

  handleDeclineRequest = async relationshipId => {
    await this.props.patchRelationship(relationshipId, {
      decision: 'declined',
    });
    this.props.fetchRelationships();
  }

  handleEndRelationship = async (relationshipId, isRequestor, status) => {
    if (isRequestor) {
      if (status === 'accepted') {
        await this.props.endRelationship(relationshipId);
      } else if (status === 'pending') {
        await this.props.patchRelationship(relationshipId, {
          decision: 'cancelled',
        });
      }
    } else { await this.props.endRelationship(relationshipId); }

    this.props.fetchRelationships();
  }

  handleAcceptEndRequest = async relationshipId => {
    await this.props.patchRelationship(relationshipId, {
      decision: 'accepted',
    });
    this.props.fetchRelationships();
  }

  handleRoleChange = role => {
    const incompatibleAttr = new Set();
    const types = this.props.relationshipTypes ? this.props.relationshipTypes.toJS() : [];
    const attributes = this.props.relationshipAttributes ? this.props.relationshipAttributes.toJS() : [];
    const selectedAttributes = types.find(type => type._id === role).attributes.map(attri => attri._id);
    selectedAttributes.forEach(attri => {
      const incompIds = getIncompitableAttrIds(attributes.find(a => a._id === attri));
      incompIds.forEach(incompId => incompatibleAttr.add(incompId));
    });

    this.props.form.setFields({
      attributes: {
        value: selectedAttributes,
      },
    });
    this.setState({ inactiveAttributes: [...incompatibleAttr] });
  }

  renderKeyField = keyData => {
    if (!keyData.isRequestor) return null;
    const tooltipCopy = <><Icon style={{ display: 'inline' }} type="copy" /> <span>{this.state.copied ? 'Copied!' : 'Click to copy'}</span></>;
    const key = this.state.sharingKeys && this.state.sharingKeys[keyData.id];
    const keyField = key ? (
      <Tooltip placement="top" title={tooltipCopy}>
        <Button data-sharing-key={key} onClick={this.handleCopySharingKey} onBlur={this.handleCopyFocus}>{key}</Button>
      </Tooltip>) : (
        <Tooltip placement="top" title={<><Icon type="key" style={{ display: 'inline' }} /> <span>Click to show sharing key</span></>}>
          <Button onClick={() => this.handleRetrieveSharingKey(keyData.id)}>
            <span>Reveal Key</span>
          </Button>
        </Tooltip>
    );
    return keyField;
  }

  handleOrganizationNameLookup = async evt => {
    const org = await this.props.fetchOrganizationBySharingCode(evt.target.value);
    this.setState({
      sharingCodeOrganizationName: org.failed ? 'No organization found' : org.data.name,
    });
  };

  getTagLabel = (tagItems, tag) => {
    tagItems.push(tag);
    let foundTag = null;
    let foundTagLabel = null;
    if (tagItems.length > 0) {
      foundTag = tagItems.find(tagItem => tagItem.id === tag.tagId);
    }
    if (foundTag) {
      foundTagLabel = fullTagLabel(foundTag);
    } else {
      foundTagLabel = tag.tagLabel;
    }
    return foundTagLabel;
  };

  sharingDirectionAltText = details => {
    const tagFilterDesc = details.tags.length > 0 ? <> where {details.isRequestor ? 'your' : 'their'} people have tag: <b>{details.tags.join(',')}</b></> : null;
    const requestorTooltipText = (<>
      <p>Sharing data to <b>{details.relatedParty}</b>{tagFilterDesc}.</p>
      <p><b>{details.relatedParty}</b> has a {<b>{details.type}</b>} role, with permissions: {<b>{details.attributes.join(', ')}</b>} over your person data.</p>
    </>);
    const responderTooltipText = (<>
      <p>Receiving data from <b>{details.relatedParty}</b>{tagFilterDesc}.</p>
      <p>Your organization has a {<b>{details.type}</b>} role, with permissions: {<b>{details.attributes.join(', ')}</b>} over <b>{details.relatedParty}</b>'s person data.</p>
    </>);

    return (
      <Tooltip mouseEnterDelay="0.3" placement="top" title={details.isRequestor ? requestorTooltipText : responderTooltipText}>
        <Icon key={''} type={details.isRequestor ? 'import' : 'export'} style={{ fontSize: '30px', color: '#C88242' }} />
      </Tooltip>
    );
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const dataSource = [];
    const { organizationId } = this.props;
    const tagItems = this.props.tagItems ? this.props.tagItems.toJS() : [];
    const applyableTagFilters = tagItems.filter(tag => {
      if (tag.role === 'smart') return false;
      if (tag.role === 'smart.active') return false;
      if (tag.role === 'smart.active.inactive') return false;
      if (tag.role === 'smart.email') return false;
      if (tag.role === 'smart.email.blank') return false;
      if (tag.role === 'smart.mobile_number') return false;
      if (tag.role === 'smart.mobile_number.blank') return false;
      if (tag.role === 'fixture') return false;
      if (tag.role && tag.role.startsWith('organization')) return false;
      const fullLabel = fullTagLabel(tag);
      if (!fullLabel) return false;
      return true;
    });

    applyableTagFilters.sort((left, right) => {
      if (left.role && !right.role) return 1;
      if (!left.role && right.role) return -1;
      if (left.role && right.role) {
        if (left.role > right.role) return 1;
        if (left.role < right.role) return -1;
        return 0;
      }
      if (left.label > right.label) return 1;
      if (left.label < right.label) return -1;
      return 0;
    });

    const fullTagLabelsWithIds = applyableTagFilters.map(tag => ({ id: tag.id, label: fullTagLabel(tag) }));
    const relationships = this.props.relationships ? this.props.relationships.toJS() : [];
    for (const relationship of relationships) {
      const isRequestor = relationship.organizationRelationship.requestor._id === organizationId;
      const relatedParty = isRequestor ? relationship.organizationRelationship.responder.name : relationship.organizationRelationship.requestor.name;
      const type = relationship.type.name;
      // eslint-disable-next-line no-loop-func
      const tags = relationship.tagFilter.map(tag => this.getTagLabel(tagItems, tag));
      const details = relationship.attributes.map(attr => attr.definition.name);
      const lastUpdatedByOrg = relationship.modifiedUser.constituent.organization.id;
      dataSource.push({
        relatedParty,
        sharingDirection: {
          relatedParty, isRequestor, tags, type, attributes: details,
        },
        sharingKey: { isRequestor, id: relationship._id },
        status: relationship.status,
        type,
        action: {
          type, relatedParty, relationshipId: relationship._id, isRequestor, status: relationship.status, tags, lastUpdatedByOrg, myOrganizationId: organizationId,
        },
        details,
      });
    }

    return (
      <div>
        <Row style={{ marginBottom: 25 }} type='flex' justify='center'>
          <Col span={5}>
            <h3 style={{ marginTop: 7 }}>Sharing code: {this.state.sharingCodeVisible ? this.props.sharingCode : 'XXXXXXXXX'}</h3>
          </Col>
          <Col span={2}>
            <Icon style={{ fontSize: 30, marginTop: 6, cursor: 'pointer' }} type={!this.state.sharingCodeVisible ? 'eye' : 'eye-invisible'} onClick={() => this.setState({ sharingCodeVisible: !this.state.sharingCodeVisible })}/>
          </Col>
          <Col span={6}>
            <Button onClick={e => this.setState({ createRequestVisible: !this.state.createRequestVisible })}>Request New</Button>
          </Col>
        </Row>
        <Table dataSource={dataSource} pagination={false} loading={this.state.loading}>
          <Table.Column title="Related Party" dataIndex="relatedParty" />
          <Table.Column title="Sharing Direction" dataIndex="sharingDirection" render={details => this.sharingDirectionAltText(details)} align="center"/>
          <Table.Column title="Type" dataIndex="type" />
          <Table.Column title="Status" dataIndex="status" render={status => <Tag color="green">{DataSharingPageComponent.statuses[status]}</Tag>}/>
          <Table.Column title="Details" dataIndex="details" render={names => names.map(name => <Tag color="#C88242">{name}</Tag>)}/>
          <Table.Column title="Key" dataIndex="sharingKey" render={keyData => this.renderKeyField(keyData)}/>
          <Table.Column title="Action" dataIndex="action" render={({
            type, relatedParty, isRequestor, status, tags, relationshipId, lastUpdatedByOrg, myOrganizationId,
          }) => {
            let actions = null;
            if (status === 'pending_end' && lastUpdatedByOrg !== myOrganizationId) {
              actions = (<Button onClick={() => genericConfirmationModal({
                isRequestor,
                title: 'Do you want to end the relationship?',
                content: <>Are you sure? This will end your current data sharing relationship with <b>{relatedParty}</b>.</>,
                handleSubmit: this.handleAcceptEndRequest,
                relationshipId,
              })}
                         >Accept End Request</Button>);
            } else if (status === 'accepted') {
              actions = (<Button onClick={() => genericConfirmationModal({
                isRequestor,
                title: 'Do you want to end the relationship?',
                content: <>Are you sure? This will send a request to end your data sharing relationship with <b>{relatedParty}</b>. This will need to be confirmed by <b>{relatedParty}</b> before it is ended. </>,
                handleSubmit: () => this.handleEndRelationship(relationshipId, isRequestor, status),
                relationshipId,
                status,
              })}
                         >Request End</Button>);
            } else if (status !== 'pending_end' && isRequestor) {
              actions = (<>
                <Button onClick={() => genericConfirmationModal({
                  title: 'Do you want to decline this request?',
                  content: <>Are you sure? This will cancel the relationship request sent to <b>{relatedParty}</b></>,
                  handleSubmit: () => this.handleEndRelationship(relationshipId, isRequestor, status),
                  relationshipId,
                })}
                >Cancel Request</Button>
              </>);
            } else if (status !== 'pending_end' && !isRequestor) {
              actions = (<>
                <Button type="primary" style={{ marginRight: 10 }} onClick={() => this.setState({ acceptRequestVisible: true, relatedParty })}>Accept</Button>
                <Button onClick={() => genericConfirmationModal({
                  title: 'Do you want to decline this request?',
                  content: <>Are you sure? This will decline the relationship request made by <b>{relatedParty}</b></>,
                  handleSubmit: this.handleDeclineRequest,
                  relationshipId,
                })}
                >Decline</Button>
                <AcceptRequestModal
                  tags={tags}
                  role={type}
                  visible={this.state.acceptRequestVisible}
                  handleCancel={this.handleAcceptRequestCancel}
                  handleSubmit={this.handleAcceptRequestSubmit}
                  relationshipId={relationshipId}
                  organizationName={relatedParty}
                />
              </>);
            }
            return actions;
          }}
          >
          </Table.Column>
        </Table>
        <Form {...formItemLayout} onSubmit={this.handleRelationshipRequestSubmit}>
          <CreateRequestModal
            form={this.props.form}
            relationshipTypes={this.props.relationshipTypes ? this.props.relationshipTypes.toJS() : []}
            handleCancel={this.handleCancel}
            visible={this.state.createRequestVisible}
            relationshipAttributes={this.props.relationshipAttributes ? this.props.relationshipAttributes.toJS() : []}
            tags={fullTagLabelsWithIds}
            handleAttributeChange={this.handleAttributeChange}
            inactiveAttributes={this.state.inactiveAttributes}
            handleSubmit={this.handleRelationshipRequestSubmit}
            handleRoleChange={this.handleRoleChange}
            handleOrganizationNameLookup={this.handleOrganizationNameLookup}
            sharingCodeOrganizationName={this.state.sharingCodeOrganizationName}
            createRequestError={this.props.createRelationshipError}
            serverErrors={this.props.createRelationshipError !== null ? this.props.createRelationshipError.toJS() : null}
          />
        </Form>
      </div>
    );
  }
}

export const DataSharingPageForm = Form.create()(DataSharingPageComponent);

export const DataSharingPage = connect(
  state => ({
    authConstituentId: state.doc.getIn(['authMr', 'constituent', 'id'], null),
    relationships: state.doc.getIn(['http', 'fetchRelationships', 'data', 'items'], null),
    organizationBySharingCode: state.doc.getIn(['http', 'fetchOrganizationBySharingCode'], null),
    organizationId: state.doc.getIn(['authMr', 'organizationId'], null),
    relationshipTypes: state.doc.getIn(['http', 'fetchRelationshipTypes', 'data', 'items'], null),
    relationshipAttributes: state.doc.getIn(['http', 'fetchRelationshipAttributes', 'data', 'items'], null),
    sharingCode: state.doc.getIn(['http', 'fetchCurrentOrganization', 'data', 'sharingCode'], null),
    sharingKey: state.doc.getIn(['http', 'fetchRelationshipKey', 'data', 'sharingKey'], null),
    tagItems: state.doc.getIn(['tagsMr', 'items'], null),
    createRelationshipError: state.doc.getIn(['http', 'createRelationship', 'data', '_errors'], null),
    createRelationshipFailed: state.doc.getIn(['http', 'createRelationship', 'failed'], null),
    patchRelationshipError: state.doc.getIn(['http', 'patchRelationship', 'data', '_errors'], null),
  }),
  actions,
)(DataSharingPageForm);
