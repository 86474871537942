import { Form, Icon, Input, Button, Spin } from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as actions from '../../actions';

import { GreenPage } from './GreenPageBase';
import { TRIAL_DAYS } from '../../defaults';
import { FrontTotpPage } from './FrontTotpPage';
import { FrontTotpRegisterPage } from './FrontTotpRegisterPage';

const FormItem = Form.Item;

class LoginPageComponent extends PureComponent {
  static state = {
    loginHelp: undefined,
    loginStatus: undefined,
    sso: false,
    showMfa: false,
    mountRegisterModal: false,
  }

  constructor(props) {
    super(props);
    this.state = { ...LoginPageComponent.state };
    if (localStorage.docUsername && localStorage.docPassword) this.props.login(localStorage.docUsername, localStorage.docPassword);
  }

  manageRedirection = (nextProps = this.props) => {
    if (this.props.ssoUrl.authMethod || nextProps.ssoUrl.authMethod) {
      window.location.href = this.props.ssoUrl.authMethod || nextProps.ssoUrl.authMethod;
    }
    if (nextProps.authenticated) {
      const { state: locState } = nextProps.history.location;
      if (locState && locState.referrer && locState.referrer.path !== '/logout') {
        nextProps.history.push(
          `${locState.referrer.path}${locState.referrer.search}`,
        );
      } else {
        nextProps.history.push('/');
      }
      this.props.initApp();
    } else {
      this.setState({ loginHelp: undefined, loginStatus: undefined });
    }
  }

  componentWillUnmount() {
    this.setState({
      loginHelp: undefined,
      loginStatus: undefined,
      sso: false,
      showMfa: false,
      mountRegisterModal: false,
    });
  }

  getHttpError(data) {
    if (!data._errors) return '';
    return Object.values(data._errors).flat().join('|');
  }

  handleLoginSubmit = evt => {
    evt.preventDefault();
    if (this.props.loginInProgress) return;
    this.props.form.validateFields((error, values) => {
      if (error) return;
      this.props.login(values.email, values.password).then(async response => {
        if (!response.success) {
          this.setState({ loginHelp: 'Incorrect username or password, or login via SSO is required', loginStatus: 'error' });
          return;
        }
        const { success, response: resp, data } = await this.props.checkRedirectionDetails();
        if (success && resp.status === 200) {
          this.manageRedirection();
          return;
        }
        if (!success && resp.status === 401) {
          const err = this.getHttpError(data);
          if (err === 'TOTP Verification Required') this.setState({ showMfa: true });
          else if (err === 'TOTP Registration Required') this.setState({ mountRegisterModal: true });
        }
      });
    });
  }

  componentWillReceiveProps() {
    this.setState({ loginHelp: undefined, loginStatus: undefined });
  }

  handleSSOLoginSubmit = evt => {
    evt.preventDefault();
    if (this.props.loginInProgress) return;
    this.props.form.validateFields(async (error, values) => {
      if (error) return;
      const { success } = await this.props.ssoLogin(values.email.toLowerCase());
      if (success) this.manageRedirection();
    });
  }

  handleChangeToSSO = evt => {
    evt.preventDefault();
    this.setState({ sso: true, loginHelp: undefined, loginStatus: undefined });
  }

  handleChangeToStandard = evt => {
    evt.preventDefault();
    this.setState({ sso: false, loginHelp: undefined, loginStatus: undefined });
  }

  render() {
    const { loginHelp, loginStatus, sso } = this.state;
    const { loginInProgress, form } = this.props;
    const { getFieldDecorator } = form;

    if (sso) {
      return (
        <GreenPage title="Welcome to Oho" subtitle="Sign in below.">
          <Form onSubmit={this.handleLoginSubmit}>
            <FormItem validateStatus={loginStatus} help={loginHelp} style={{ marginBottom: '10px' }}>
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    message: 'We need your username!',
                  },
                ],
              })(
                <Input
                  prefix={<Icon type="mail" />}
                  placeholder="Username"
                  disabled={loginInProgress}
                />,
              )}
            </FormItem>
            <button type="submit" style={{ display: 'none' }} />
          </Form>
          <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
            <Button type='primary' onClick={this.handleSSOLoginSubmit} style={{ height: '40px', fontWeight: '500' }} loading={loginInProgress}>Sign in with SSO</Button>
          </div>
          <div style={{ marginBottom: '20px', display: 'flex' }}>
            <Link onClick={this.handleChangeToStandard} style={{ color: '#82412C' }} to="/login">{'< Back'}</Link>
          </div>
        </GreenPage>
      );
    }

    if (this.state.showMfa) {
      return (<FrontTotpPage onVerifySuccess={() => { this.manageRedirection(); }} />);
    }

    if (this.state.mountRegisterModal) {
      return (<FrontTotpRegisterPage onVerifySuccess={() => { this.manageRedirection(); }} />);
    }

    if (!this.state.showMfa) {
      return (
        <GreenPage title="Welcome to Oho" subtitle="Sign in below.">
          <p style={{ marginBottom: '30px' }}>
           No account? Give Oho a try for {TRIAL_DAYS} days<br />
           No credit card required.<br />
          </p>
          <Form onSubmit={this.handleLoginSubmit}>
            <FormItem validateStatus={loginStatus} style={{ marginBottom: '10px' }}>
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    message: 'We need your username!',
                  },
                ],
              })(
                <Input
                  prefix={<Icon type="mail" />}
                  placeholder="Username"
                  disabled={loginInProgress}
                />,
              )}
            </FormItem>
            <FormItem validateStatus={loginStatus} help={loginHelp} style={{ marginBottom: '40px' }}>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'We need your password!',
                  },
                ],
              })(
                <Input
                  prefix={<Icon type="lock" />}
                  placeholder="Password"
                  type="password"
                  disabled={loginInProgress}
                />,
              )}
            </FormItem>
            <button type="submit" style={{ display: 'none' }} />
          </Form>
          <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
            <Button type='primary' onClick={this.handleLoginSubmit} style={{ height: '40px', width: '60px', marginRight: '20px', fontWeight: '500' }} loading={loginInProgress}>Sign in</Button>
            <Button onClick={() => {
              document.location = 'https://weareoho.com/?show-signup=1';
            }} style={{ height: '40px', width: '60px', fontWeight: '500' }} loading={loginInProgress}
            >Sign up</Button>
          </div>
          <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
            <Button onClick={this.handleChangeToSSO} style={{ height: '40px', width: '60px', fontWeight: '500' }} loading={loginInProgress}>SSO</Button>
          </div>
          <p style={{ color: '#82412C', fontSize: '14px', fontWeight: 500 }}>
            Forgot your password?&nbsp;
            <Link style={{ textDecoration: 'underline' }} to="/passwordReset">Request a reset</Link>
          </p>
          <p style={{ fontSize: '14px' }}>By signing up, you are agreeing to our Terms and Privacy Policy. </p>
        </GreenPage>
      );
    }

    return (
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'green', minHeight: '100vh',
      }}
      >
        <Spin loading />
      </div>
    );
  }
}
const LoginPageForm = Form.create()(LoginPageComponent);

export const LoginPage = connect(
  state => ({
    authenticated: state.doc.getIn(['authMr', 'authenticated'], false),
    loginFailed: state.doc.getIn(['http', 'createToken', 'failed'], false),
    loginInProgress: state.doc.getIn(['http', 'createToken', 'inProgress'], false),
    ssoUrl: state.doc.getIn(['sso'], null),
  }),
  actions,
)(LoginPageForm);
