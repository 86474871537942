import qs from 'querystringify';
import uuid4 from 'uuid/v4';
import _ from 'lodash';
import { message } from 'antd';

import { genericAjaxDispatch, simpleAjaxDispatch } from './http';
import { watchCorrelationId } from './longpoller';
import { checkFeature } from '../utils';

export function getOrganizationConstituentsFirst(qsData, namespace) {
  return (dispatch, getState) => {
    const state = getState();
    const qsDataFinal = {
      org_smart: 0,
      ...qsData,
      organization_id: qsData.organizationId || state.doc.getIn(['authMr', 'organizationId']),
      detail: 'full',
      limit: 50,
    };
    const currentFilter = state.doc.getIn(['constituentsMr', 'lists', namespace, 'filter'], null);
    if (currentFilter && _.isEqual(qsDataFinal, currentFilter.toJS())) {
      return null;
    }
    const qsString = qs.stringify(qsDataFinal, true);

    dispatch({
      type: 'SET_CONSTITUENTS_FILTER',
      filter: qsDataFinal,
      namespace,
      filterHash: qsString,
    });

    const useLibraryFlag = checkFeature('filterTagLibrary') ? '&use_library=true' : '';
    return simpleAjaxDispatch(
      `/constituents${qsString}${useLibraryFlag}`,
      'getOrganizationConstituentsPage',
      { useSeq: true, meta: { namespace, filterHash: qsString } },
    )(dispatch, getState);
  };
}

export function getOrganizationConstituentsNext(namespace, offset = 50) {
  return (dispatch, getState) => {
    const state = getState();
    const { next, filterHash } = state.doc
      .getIn(['constituentsMr', 'lists', namespace]).toJS();

    const qsData = qs.parse(filterHash);
    const qsFinal = {
      ...qsData,
      offset,
      limit: 50,
    };
    const queryString = qs.stringify(qsFinal, true);
    const useLibraryFlag = checkFeature('filterTagLibrary') ? '&use_library=true' : '';
    return simpleAjaxDispatch(
      `/constituents${queryString}${useLibraryFlag}`, 'getOrganizationConstituentsPage',
      { useSeq: true, meta: { namespace, filterHash } },
    )(dispatch, getState);
  };
}

export function setConstituentIdAction(constituentId) {
  return { type: 'SET_CONSTITUENT_ID', constituentId };
}

export function setConstituentId(constituentId) {
  return dispatch => dispatch(setConstituentIdAction(constituentId));
}

export function setConstituentAccreditationIdAction(accreditationId = null, constituentId = null, type = null) {
  return { type: 'SET_ACCREDITATION_ID', accreditation: { accreditationId, constituentId, type } };
}

export function getConstituent(constituentId) {
  return simpleAjaxDispatch(
    `/constituents/${constituentId}`,
    'getConstituent',
    { useSeq: true, meta: { constituentId } },
  );
}

export function setMfaOwner(mfaOwnerId) {
  return dispatch => dispatch({ type: 'SET_OWNER', id: mfaOwnerId });
}

export function patchConstituent(constituentId, json) {
  return simpleAjaxDispatch(
    `/constituents/${constituentId}`,
    'patchConstituent',
    { method: 'PATCH', json, meta: { constituentId } },
  );
}

export function bulkPatchConstituents(constituentIds, patch) {
  return simpleAjaxDispatch(
    '/constituents/bulk',
    'bulkPatchConstituents',
    { method: 'PATCH', json: { constituentIds, patch }, meta: { constituentIds } },
  );
}

export function addConstituent(json) {
  return simpleAjaxDispatch(
    '/constituents',
    'addConstituent',
    { method: 'POST', json },
  );
}

const IMPORT_URL_BASE = '/upload/bulk_import/constituents';
const NDWS_IMPORT_URL = '/upload/ndws_import/constituents';
const QLDBLUE_IMPORT_URL = '/upload/qldblue_import/constituents';

export function uploadConstituentImport(file) {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    genericAjaxDispatch(
      dispatch, getState, IMPORT_URL_BASE,
      'uploadConstituentImportAuthorize',
      {
        method: 'POST',
        json: { originalFn: file.name },
      },
    ).then(
      authAction => {
        const formData = new FormData();
        Object.entries(authAction.data.params.fields).forEach(
          ([key, value]) => formData.append(key, value),
        );
        formData.append('file', file);

        genericAjaxDispatch(
          dispatch, getState,
          authAction.data.params.url,
          'uploadConstituentImport',
          {
            method: 'POST',
            body: formData,
            auth: false,
            meta: authAction.data,
          },
        ).then(resolve, reject);
      }, reject,
    );
  });
}

export function QldblueConstituentImport(file) {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    genericAjaxDispatch(
      dispatch, getState, QLDBLUE_IMPORT_URL,
      'qldblueConstituentImportAuthorize',
      {
        method: 'POST',
        json: { originalFn: file.name },
      },
    ).then(
      authAction => {
        const formData = new FormData();
        Object.entries(authAction.data.params.fields).forEach(
          ([key, value]) => formData.append(key, value),
        );
        formData.append('file', file);

        genericAjaxDispatch(
          dispatch, getState,
          authAction.data.params.url,
          'qldblueConstituentImport',
          {
            method: 'POST',
            body: formData,
            auth: false,
            meta: authAction.data,
          },
        ).then(resolve, reject);
      }, reject,
    );
  });
}

export function clearConstituentImportAction() {
  return { type: 'CLEAR_CONSTITUENT_IMPORT' };
}

export function clearConstituentImport() {
  return dispatch => dispatch(clearConstituentImportAction());
}

export function updateConstituentImportStatus(correlationId, data) {
  return { type: 'UPDATE_CONSTITUENT_IMPORT_STATUS', correlationId, ...data };
}

export function addConstituentImportError(correlationId, error) {
  return { type: 'ADD_CONSTITUENT_IMPORT_ERROR', correlationId, error };
}

export function pushConstituentImportCompletion(correlationId) {
  return { type: 'PUSH_CONSTITUENT_IMPORT_COMPLETION', correlationId };
}

export function completeConstituentImport(fileId) {
  const correlationId = uuid4();
  const completedUrl = `${IMPORT_URL_BASE}/${fileId}/complete`;
  return (dispatch, getState) => {
    dispatch(updateConstituentImportStatus(correlationId, {
      state: 'starting',
      errors: [],
      done: 0,
    }));
    return new Promise((resolve, reject) => {
      watchCorrelationId(correlationId, (err, value) => {
        if (err) return; // TODO
        // routingKey is like event.rpc.result.<ev>.<qualName>
        const event = value.routingKey.split('.')[3];
        if (event === 'complete') {
          dispatch(updateConstituentImportStatus(correlationId, {
            state: 'complete',
          }));
          resolve();
        } else if (event === 'start') {
          dispatch(updateConstituentImportStatus(correlationId, {
            state: 'running',
          }));
        } else {
          const content = JSON.parse(value.content);
          if (event === 'error') {
            dispatch(addConstituentImportError(correlationId, content));
          } else if (content.type === '_count') {
            dispatch(updateConstituentImportStatus(correlationId, { total: content.count }));
          } else {
            dispatch(pushConstituentImportCompletion(correlationId));
          }
        }
      })
        .then(() => genericAjaxDispatch(
          dispatch, getState, completedUrl,
          'uploadConstituentImportComplete',
          { method: 'POST', json: { correlationId } },
        ))
        .catch(reject);
    });
  };
}

export function uploadNdwsImport(file) {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    genericAjaxDispatch(
      dispatch, getState, NDWS_IMPORT_URL,
      'uploadNdwsImportAuthorize',
      {
        method: 'POST',
        json: { originalFn: file.name },
      },
    ).then(
      authAction => {
        const formData = new FormData();
        Object.entries(authAction.data.params.fields).forEach(
          ([key, value]) => formData.append(key, value),
        );
        formData.append('file', file);

        genericAjaxDispatch(
          dispatch, getState,
          authAction.data.params.url,
          'uploadNdwsImport',
          {
            method: 'POST',
            body: formData,
            auth: false,
            meta: authAction.data,
          },
        ).then(resolve, reject);
      }, reject,
    );
  });
}


export function clearNdwsImportAction() {
  return { type: 'CLEAR_NDWS_IMPORT' };
}

export function clearNdwsImport() {
  return dispatch => dispatch(clearNdwsImportAction());
}

export function updateNdwsImportStatus(correlationId, data) {
  return { type: 'UPDATE_NDWS_IMPORT_STATUS', correlationId, ...data };
}

export function addNdwsImportError(correlationId, error) {
  return { type: 'ADD_NDWS_IMPORT_ERROR', correlationId, error };
}

export function pushNdwsImportCompletion(correlationId) {
  return { type: 'PUSH_NDWS_IMPORT_COMPLETION', correlationId };
}

export function completeNdwsImport(fileId) {
  const correlationId = uuid4();
  const completedUrl = `${NDWS_IMPORT_URL}/${fileId}/complete`;
  return (dispatch, getState) => {
    dispatch(updateNdwsImportStatus(correlationId, {
      state: 'starting',
      errors: [],
      done: 0,
    }));
    return new Promise((resolve, reject) => {
      watchCorrelationId(correlationId, (err, value) => {
        if (err) return; // TODO
        // routingKey is like event.rpc.result.<ev>.<qualName>
        const event = value.routingKey.split('.')[3];
        if (event === 'complete') {
          dispatch(updateNdwsImportStatus(correlationId, {
            state: 'complete',
          }));
          resolve();
        } else if (event === 'start') {
          dispatch(updateNdwsImportStatus(correlationId, {
            state: 'running',
          }));
        } else {
          const content = JSON.parse(value.content);
          if (event === 'error') {
            dispatch(addNdwsImportError(correlationId, content));
          } else if (content.type === '_count') {
            dispatch(updateNdwsImportStatus(correlationId, { total: content.count }));
          } else {
            dispatch(pushNdwsImportCompletion(correlationId));
          }
        }
      })
        .then(() => genericAjaxDispatch(
          dispatch, getState, completedUrl,
          'uploadNdwsImportComplete',
          { method: 'POST', json: { correlationId } },
        ))
        .catch(reject);
    });
  };
}

export function clearQldblueImportAction() {
  return { type: 'CLEAR_QLDBLUE_IMPORT' };
}

export function clearQldblueImport() {
  return dispatch => dispatch(clearQldblueImportAction());
}

export function updateQldblueImportStatus(correlationId, data) {
  return { type: 'UPDATE_QLDBLUE_IMPORT_STATUS', correlationId, ...data };
}

export function addQldblueImportError(correlationId, error) {
  return { type: 'ADD_QLDBLUE_IMPORT_ERROR', correlationId, error };
}

export function pushQldblueImportCompletion(correlationId) {
  return { type: 'PUSH_QLDBLUE_IMPORT_COMPLETION', correlationId };
}

export function completeQldblueConstituentImport(fileId) {
  const correlationId = uuid4();
  const completedUrl = `${QLDBLUE_IMPORT_URL}/${fileId}/complete`;
  return (dispatch, getState) => {
    dispatch(updateQldblueImportStatus(correlationId, {
      state: 'starting',
      errors: [],
      done: 0,
    }));
    return new Promise((resolve, reject) => {
      watchCorrelationId(correlationId, (err, value) => {
        if (err) return; // TODO
        // routingKey is like event.rpc.result.<ev>.<qualName>
        const event = value.routingKey.split('.')[3];
        if (event === 'complete') {
          dispatch(updateQldblueImportStatus(correlationId, {
            state: 'complete',
          }));
          resolve();
        } else if (event === 'start') {
          dispatch(updateQldblueImportStatus(correlationId, {
            state: 'running',
          }));
        } else {
          const content = JSON.parse(value.content);
          if (event === 'error') {
            dispatch(addQldblueImportError(correlationId, content));
          } else if (content.type === '_count') {
            dispatch(updateQldblueImportStatus(correlationId, { total: content.count }));
          } else {
            dispatch(pushQldblueImportCompletion(correlationId));
          }
        }
      })
        .then(() => genericAjaxDispatch(
          dispatch, getState, completedUrl,
          'qldblueConstituentImportComplete',
          { method: 'POST', json: { correlationId } },
        ))
        .catch(reject);
    });
  };
}

export function constituentExport(qsData) {
  return (dispatch, getState) => {
    const state = getState();
    const qsDataFinal = {
      ...qsData,
      organization_id: state.doc.getIn(['authMr', 'organizationId']),
    };
    const qsString = qs.stringify(qsDataFinal, true);
    return genericAjaxDispatch(
      dispatch, getState, `/constituents/export${qsString}`, {
        method: 'GET',
        responseType: 'blob',
      },
    ).then(
      action => {
        if (action.success) {
          const url = window.URL.createObjectURL(new Blob([action.body]));
          const link = document.createElement('a');
          link.href = url;
          const contentDisposition = action.response.headers.get('content-disposition');
          let fileName = 'unknown';
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename=(.+)/);
            // eslint-disable-next-line prefer-destructuring
            if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
          }
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        } else if (action.response.status === 404) {
          message.warning('No matching person found');
        }
      },
    );
  };
}

export function updateNDWSSyncStatusResult(message) {
  return { type: 'UPDATE_NDWS_SYNC_STATUS', message };
}

export function updateNDWSSyncStatusToDefault() {
  return { type: 'UPDATE_NDWS_SYNC_STATUS_TO_DEFAULT' };
}

export function startNDWSSync() {
  const correlationId = uuid4();
  const url = '/ndws/sync/start';
  return (dispatch, getState) => simpleAjaxDispatch(
    url,
    'startNDWSSync',
    { method: 'POST', json: { correlationId } },
  )(dispatch, getState).then(action => {
    const data = { status: '', message: '' };
    if (action.failed) {
      message.warning('Failed to start NDWS sync');
      data.status = 'failed';
      data.message = 'Failed to start NDWS sync';
      dispatch(updateNDWSSyncStatusResult(data));
      return;
    }
    message.success('NDWS sync started');
    data.status = 'loading';
    dispatch(updateNDWSSyncStatusResult(data));
  });
}

export function checkNDWSSyncStatus() {
  const url = '/status/updates?status_type=ndws-sync';
  return (dispatch, getState) => simpleAjaxDispatch(
    url,
    'ndwsPolling',
    { method: 'GET' },
  )(dispatch, getState).then(action => {
    const data = { status: '', message: '' };
    if (action.failed) {
      data.status = 'failed';
      data.message = 'NDWS background check failed';
      dispatch(updateNDWSSyncStatusResult(data));
      return;
    }
    dispatch(updateNDWSSyncStatusResult(action.data));
  });
}

export function updateNDWSLinkageStatusResult(message) {
  return { type: 'UPDATE_NDWS_LINKAGE_STATUS', message };
}

export function updateNDWSLinkageStatusToDefault() {
  return { type: 'UPDATE_NDWS_LINKAGE_STATUS_TO_DEFAULT' };
}

export function checkNDWSLinkageStatus() {
  const url = '/status/updates?status_type=ndws-linkage';
  return (dispatch, getState) => simpleAjaxDispatch(
    url,
    'ndwsLinkagePolling',
    { method: 'GET' },
  )(dispatch, getState).then(action => {
    const data = { status: '', message: '' };
    if (action.failed) {
      data.status = 'failed';
      data.message = 'NDWS linkage failed';
      dispatch(updateNDWSLinkageStatusResult(data));
      return;
    }
    dispatch(updateNDWSLinkageStatusResult(action.data));
  });
}

export function postNDWSOtp(otp, path) {
  return (dispatch, getState) => simpleAjaxDispatch(
    '/ndws/sync/otp',
    'ndwsSyncOtp',
    { method: 'POST', json: { otp, path } },
  )(dispatch, getState).then(action => {
    if (action.data.saved) message.success('One-Time password submitted successfully.');
    else message.warning('Failed to submit One-Time password.');
    const data = {
      status: 'loading',
      message: '',
    };
    dispatch(updateNDWSSyncStatusResult(data));
    return action;
  });
}

function initiateNDWSLinkageResult() {
  return { type: 'INIT_NDWS_LINKAGE_RESULT', message };
}

function ndwsLinkageErrorAction(message) {
  return { type: 'NDWS_LINKAGE_ERROR', message };
}

export function initiateNDWSLinkage(uuid = null) {
  return (dispatch, getState) => simpleAjaxDispatch(
    '/ndws/linkage',
    'initiateNDWSLinkage',
    { method: 'POST', json: { uuid } },
  )(dispatch, getState).then(action => {
    if (!action.data.success) {
      message.warning('NDWS linkage initation failed.');
      console.error(action.data.error);
      dispatch(ndwsLinkageErrorAction(action.data.error));
    } else {
      message.success('NDWS linkage started successfully.');
      dispatch(ndwsLinkageErrorAction(null));
    }
    dispatch(initiateNDWSLinkageResult(action.data));
  });
}