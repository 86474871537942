import React from 'react';
import {
  Modal, Input, Form,
} from 'antd';


const AcceptRequestModalComponent = ({
  visible, handleCancel, handleSubmit, form, tags, relationshipId, organizationName, role,
}) => (
  <Modal
    title="Accept Request"
    visible={visible}
    onCancel={handleCancel}
    onOk={() => handleSubmit(form, relationshipId)}
    style={{ minWidth: 800 }}
  >
    <p><b>{organizationName}</b> has requested to share data with you. By accepting this request, you will be able to view and potentially modify their person data. This request will provide you with a <b>{role}</b> role{' '}
      within <b>{organizationName}</b>. {tags && tags.length > 0 ? <> <b>{organizationName}</b> have elected to only share people they have tagged with <b>{tags.join(' , ')}</b>.</> : null} Would you like to proceed with this data sharing agreement?</p>
    <Form layout="vertical">
      <Form.Item label="Sharing Key:">
        {form.getFieldDecorator('sharingKey', {
          rules: [{ required: true, message: 'Please enter Sharing Key' }],
        })(<Input />)}
      </Form.Item>
    </Form>
  </Modal>
);

// eslint-disable-next-line import/no-default-export
export default Form.create()(AcceptRequestModalComponent);
