import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { QldblueImportModal } from '../../QldblueImportPopup';

import { historyPushPath } from '../../../utils';

@connect(
  state => ({
    organizationId: state.doc.getIn(['authMr', 'organizationId']),
  }),
)
class QldblueImportRoute extends PureComponent {
  handleCancel = () => {
    historyPushPath(
      this.props.history,
      this.props.location,
      '/constituents',
    );
  }

  render() {
    return (<QldblueImportModal
      title="QLDBlue Sync"
      onCancel={this.handleCancel}
    />);
  }
}

export {
  QldblueImportRoute,
};
