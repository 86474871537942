import React, { Fragment, PureComponent } from 'react';

import { Helmet } from 'react-helmet';
import { Breadcrumb as AntBreadcrumb } from 'antd';

const BreadcrumbContext = React.createContext({ levels: [] });

class BreadcrumbLevelWrapper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      levels: [],
    };
  }

  pushLevel = level => {
    // Modify state based on existing state, setState calls are queued.
    this.setState(state => ({ levels: [...state.levels, level] }));
  }

  popLevel = level => {
    // Modify state based on existing state, setState calls are queued.
    this.setState(state => {
      const newLevels = [...state.levels];
      const idx = newLevels.indexOf(level);
      newLevels.splice(idx, 1);
      return {
        levels: newLevels,
      };
    });
  }

  getLevels = () => this.state.levels.map(l => l.props.text)

  render() {
    return (
      <BreadcrumbContext.Provider value={{
        pushLevel: this.pushLevel,
        popLevel: this.popLevel,
        getLevels: this.getLevels,
      }}
      >
        { this.props.children }
      </BreadcrumbContext.Provider>
    );
  }
}

class BreadcrumbLevel extends PureComponent {
    static contextType = BreadcrumbContext;

    componentDidMount() {
      this.context.pushLevel(this);
    }

    componentWillUnmount() {
      this.context.popLevel(this);
    }

    render() { return null; }
}

class Breadcrumb extends PureComponent {
    static contextType = BreadcrumbContext;

    render() {
      const levels = this.context.getLevels();

      const title = [
        'Oho',
        ...(levels.slice(1)),
      ];

      return (
        <Fragment>
          <AntBreadcrumb>
            { levels.map((name, idx) => <AntBreadcrumb.Item key={ idx }>{ name }</AntBreadcrumb.Item>) }
          </AntBreadcrumb>
          <Helmet>
            <title>{ title.join(' » ') }</title>
          </Helmet>
        </Fragment>
      );
    }
}

export {
  BreadcrumbLevel,
  BreadcrumbLevelWrapper,
  Breadcrumb,
};
