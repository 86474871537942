import _ from 'lodash';

export function createReducer(reducerList) {
  const reducerFuncs = reducerList.map(
    value => (_.isFunction(value)
      ? value : value.handleAction.bind(value)),
  );
  let prevState = null;
  return (state, action) => {
    prevState = reducerFuncs.reduce(
      (currState, func) => (_.isNil(func)
        ? currState : func(prevState, currState, action)),
      state,
    );
    return prevState;
  };
}

export class Reducer {
  static ensureStatePath(statePath) {
    if (_.isNil(statePath)) return [];
    if (_.isArray(statePath)) return statePath;
    return [statePath];
  }

  constructor(statePath = null) {
    this.statePath = Reducer.ensureStatePath(statePath);
  }

  reducerShouldUpdate(prevState, currState, action) {
    return true;
  }

  getStatePart(state) {
    return _.isNil(state) ? null : state.getIn(this.statePath);
  }

  handleAction(prevState, currState, action) {
    const prevStatePart = this.getStatePart(prevState);
    const currStatePart = this.getStatePart(currState);

    const nextStatePart = this.reducerShouldUpdate(
      prevStatePart, currStatePart, action,
    ) ? this.update(prevStatePart, currStatePart, action) : currStatePart;

    return currState.setIn(this.statePath, nextStatePart);
  }

  static typeFuncName(type) {
    const camelType = _.upperFirst(_.camelCase(type.trim()));
    if (camelType === '') return null;
    return `handle${camelType}Action`;
  }

  update(prevState, currState, action) {
    const { type } = action;
    if (_.isNil(type)) return currState;

    const func = this[Reducer.typeFuncName(type)];
    if (_.isNil(func)) return currState;

    return func.bind(this)(prevState, currState, action);
  }
}

export class MaterializedReducer extends Reducer {
  reducerShouldUpdate(prevState, currState, action) {
    return prevState !== currState;
  }
}

export class LegacyReducer extends Reducer {
  constructor(reducer, statePath = null) {
    super(statePath);
    this.reducer = reducer;
  }

  update(prevState, currState, action) {
    return this.reducer(currState, action);
  }
}
