import _ from 'lodash';
import { fromJS } from 'immutable';
import {
  Alert, Button, Card, Divider, Form, Icon, Input, Modal, Popover, Spin, Statistic, Tree, Typography, message,
} from 'antd';
import qs from 'querystringify';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { BreadcrumbLevel } from '../BreadcrumbLevel';
import { ErrorsList } from '../ErrorsList';
import * as actions from '../../actions';
import { hasFieldsError } from '../../utils';
import { withContentLayout } from '../layout/Layout';


const EMPTY_LIST = fromJS([]);

class NewTagPopover extends PureComponent {
  state = {
    done: false,
    saving: false,
    error: null,
  }

  componentDidMount() {
    this.refs.input.input.focus();
  }

  handleSuccess = () => {
    this.setState({ done: true, saving: false, error: null });
  }

  handleError = error => {
    this.setState({ done: false, saving: false, error });
  }

  handleSubmit = ev => {
    ev.preventDefault();
    const label = this.refs.input.input.value.trim();

    if (label === '') {
      this.setState({ error: { label: ['Can not be blank'] } });
      return;
    }

    this.setState({ done: false, saving: true, error: null });
    this.props.onSubmit(
      {
        label,
        parent: { id: this.props.parentId },
      },
      this.handleSuccess, this.handleError,
    );
  }

  render() {
    if (this.state.done) return null;

    return (<form onSubmit={ this.handleSubmit }>
      <Input ref="input" />
      <div style={{ paddingTop: '10px' }} />
      { this.state.error ? <ErrorsList errors={ this.state.error } /> : null }
      <Button
        style={{ float: 'right' }}
        size="small"
        type="primary"
        htmlType="submit"
        loading={ this.state.saving }
      ><Icon type="plus" />Create</Button>
      <div style={{ clear: 'both' }} />
    </form>);
  }
}

function renderNodes(tags, selectedKey, onCreate, organization) {
  const editableTags = [];
  tags.filter(tag => tag.label.toLowerCase() !== 'fixture').forEach(tag => {
    // Ignore the root tag
    if (tag.role === 'organization' && tag.children) {
      editableTags.push(renderNodes([tag.children.find(innerTag => innerTag.role === `organization.${organization.id}`)], selectedKey, onCreate, organization));
      return;
    }
    const tagIsOrgTag = (tag.parent || {}).role === 'organization';
    const isSelected = `${tag.id}` === `${selectedKey}`;

    const nodes = renderNodes(
      tag.children || [], selectedKey, onCreate,
    );
    if (tag.label.toLowerCase() === 'smart tag') return;

    const showAddTag = (tag.editable || tagIsOrgTag) && isSelected;

    if (showAddTag) {
      nodes.push(
        <Tree.TreeNode
          title={
            <Popover
              trigger="click"
              placement="bottomLeft"
              title="Tag name"
              content={ <NewTagPopover
                onSubmit={ onCreate }
                parentId={ tag.id }
                key={ Date.now() }
                        /> }
            >
              <Button type='primary' style={{ borderRadius: 0 }} size="small"><Icon type="tag" />New tag</Button>
            </Popover>
          }
          selectable={ false }
          key='_add_tag'
        ></Tree.TreeNode>,
      );
    }

    const showNodes = nodes && nodes.length > 0;

    editableTags.push(<Tree.TreeNode
      title={ <span>
        { tag.label }
      </span> }
      key={ tag.id }
                      >
      { showNodes ? nodes : null }
    </Tree.TreeNode>);
  });
  return editableTags;
}

const WEEKLY = 7;
const MONTHLY = Math.round(365 / 12);
const QUARTERLY = Math.round(365 / 4);
const HALF_YEARLY = Math.round(365 / 2);
const YEARLY = 365;
const VERIFICATION_FREQUENCIES = {
  [WEEKLY]: 'Weekly',
  [MONTHLY]: 'Monthly',
  [QUARTERLY]: 'Quarterly',
  [HALF_YEARLY]: 'Half-Yearly',
  [YEARLY]: 'Yearly',
};
const VERIFICATION_FREQUENCIES_ORDER = Object.keys(VERIFICATION_FREQUENCIES).map(
  val => parseInt(val, 10),
);
VERIFICATION_FREQUENCIES_ORDER.sort((left, right) => {
  if (left < right) { return -1; }
  if (left > right) { return 1; }
  return 0;
});

@withRouter
class TagSettingsCardComponent extends PureComponent {
  componentDidMount = () => {
    this.ensureTagStats();
  }

  componentDidUpdate = () => {
    this.ensureTagStats();
  }

  ensureTagStats = () => {
    const {
      fetchTagStats,
      fetchTagStatsData: data,
      fetchTagStatsInProgress: inProgress,
      tag,
    } = this.props;
    if (tag && !data && !inProgress) {
      fetchTagStats(tag.id);
    }
  }

  handleDelete = ev => {
    const hasChildren = this.props.tag.directChildrenCount > 0;
    Modal.confirm({
      title: 'Are you sure?',
      content: <span>
        This will delete this tag&nbsp;
        { hasChildren ? <strong>and all its children </strong> : '' }
        from any person.
      </span>,
      confirmLoading: true,
      maskClosable: true,
      okText: 'Delete',
      okType: 'primary',
      iconType: 'warning',
      onOk: () => new Promise((resolve, reject) => {
        this.props.deleteTag(this.props.tag.id).then(action => {
          if (action.failed) {
            message.error("Couldn't delete tag");
            reject();
          }
          if (action.success) {
            if (this.props.onDelete) this.props.onDelete();
            resolve();
          }
        });
      }),
    });
  }

  handleSubmit = ev => {
    ev.preventDefault();
    const { form, onSaved, patchTag, tag } = this.props;
    form.validateFields({}, (err, values) => {
      if (err) return;
      values.verificationFrequency = this.toVerificationFreqDays(values.verificationFrequency);
      patchTag(tag.id, values).then(action => {
        if (action.success) onSaved();
        if (action.failed) {
          this._backendErrors = action.data._errors;
          form.validateFields({ force: true }, () => {
            this._backendErrors = null;
          });
        }
      });
    });
  }

  backendErrors = (rule, value, callback) => {
    if (this._backendErrors && this._backendErrors[rule.fullField]) {
      callback(this._backendErrors[rule.fullField]);
    } else {
      callback([]);
    }
  }

  renderTitle() {
    const { form, tag, patchTagInProgress: saving } = this.props;
    const { getFieldDecorator } = form;

    if (!tag) return 'No tag selected';
    if (!tag.editable) {
      return (
        <div>
          <Icon type="lock" />
          <Divider type="vertical" />
          { tag.label }
          <Typography.Text type="secondary" style={{ float: 'right' }}>Built-in tag</Typography.Text>
        </div>
      );
    }

    return (<Form.Item style={{ marginBottom: '0px' }}>
      { getFieldDecorator('label', {
        initialValue: tag.label,
        rules: [
          { required: true },
          { validator: this.backendErrors },
        ],
      })(
        <Input
          style={{ fontWeight: 500, fontSize: '16px' }}
          placeholder="Tag name"
          disabled={ saving }
        />,
      ) }
    </Form.Item>);
  }

  toVerificationFreqIndex(verificationFrequency) {
    if (_.isNil(verificationFrequency)) return 0;
    return VERIFICATION_FREQUENCIES_ORDER.indexOf(
      parseInt(verificationFrequency, 10),
    ) + 1;
  }

  toVerificationFreqDays(idx) {
    const intIdx = parseInt(idx, 10);
    if (intIdx === 0) return null;
    return parseInt(Object.keys(VERIFICATION_FREQUENCIES)[intIdx - 1], 10);
  }

  createStatClickHandler = tags => ev => {
    const qsData = { filters: tags.join(',') };
    this.props.history.push(`/constituents#${qs.stringify(qsData)}`);
  }

  render() {
    const {
      form, onCancel, style, tag,
      patchTagInProgress: saving,
    } = this.props;
    const { getFieldsError } = form;

    const statsData = this.props.fetchTagStatsData
      ? this.props.fetchTagStatsData.toJS()
      : null;

    const cards = [
      { key: 'activeConstituents', lbl: 'Active people', tags: ['smart.active.active'] },
      { key: 'inactiveConstituents', lbl: 'Inactive people', tags: ['!smart.active.active'] },
    ];

    return (
      <Form onSubmit={ this.handleSubmit } style={ style }>
        <Card
          title={ this.renderTitle() }
        >
          { tag
            ? (
              <Fragment>
                <Spin spinning={ !statsData }><div style={{ display: 'flex' }}>
                  { tag.editable && cards.map(card => (
                    <Card
                      key={ card.key }
                      style={{ marginRight: '16px' }}
                      onClick={ this.createStatClickHandler([...card.tags, tag.id]) }
                      hoverable
                    >
                      <Statistic
                        title={ card.lbl }
                        value={ statsData ? statsData[card.key] : '...' }
                      />
                    </Card>
                  )) }
                  { !tag.editable && (
                    <Card
                      style={{ marginRight: '16px' }}
                      onClick={ this.createStatClickHandler([tag.id]) }
                      hoverable
                    >
                      <Statistic
                        title={ 'Static Text' }
                        // value={ statsData ? statsData[card.key] : '...' }
                      />
                    </Card>
                  )}
                </div></Spin>

                <div style={{ clear: 'both' }} />

                {tag.editable && <div style={{ display: 'flex', paddingTop: '32px' }}>
                  <div style={{ flexGrow: 1 }}></div>
                  <Button
                    style={{ marginRight: '8px' }}
                    onClick={ onCancel }
                    disabled={ saving }
                  >
                    Cancel
                  </Button>
                  <Button
                    className='doc-danger-btn-2'
                    style={{ marginRight: '8px' }}
                    onClick={ this.handleDelete }
                    disabled={ saving || !tag.editable }
                  >
                    <Icon type="delete" />Delete
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={ saving }
                    disabled={ saving || !tag.editable || hasFieldsError(getFieldsError()) }
                  >
                    <Icon type="save" />Save
                  </Button>
                </div>}
              </Fragment>
            )
            : 'Select a tag from the list'
          }
        </Card>
      </Form>
    );
  }
}
const TagSettingsCardForm = Form.create()(TagSettingsCardComponent);

export const TagSettingsCard = connect(
  (state, ownProps) => {
    const ownTagId = ownProps.tag
      ? ownProps.tag.id
      : null;
    const fetchHttp = state.doc.getIn(['http', 'fetchTagStats']);
    const isFetchOwn = fetchHttp
      ? fetchHttp.getIn(['meta', 'tagId']) === ownTagId
      : false;

    return {
      fetchTagStatsData: isFetchOwn ? fetchHttp.get('data', null) : null,
      fetchTagStatsInProgress: isFetchOwn ? fetchHttp.get('inProgress', false) : false,
      patchTagInProgress: state.doc.getIn(['http', 'patchTag', 'inProgress'], false),
    };
  },
  actions,
)(TagSettingsCardForm);

@withContentLayout
class TagSettingsPageComponent extends PureComponent {
  state = {
    selectedKey: null,
    expandedKeys: [],
  }

  componentWillMount() {
    if (this.props.organizationId) this.props.fetchTags(this.props.organizationId);
    if (this.props.tagsTree) this.initExpandedKeys(this.props.tagsTree, this.props.organizationId);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.organizationId !== nextProps.organizationId) nextProps.fetchTags(nextProps.organizationId);
    if (this.props.tagsTree !== nextProps.tagsTree) this.initExpandedKeys(nextProps.tagsTree, this.props.organizationId);
  }

  initExpandedKeys = (tags, organizationId) => {
    if (tags.size < 1) return;

    const orgRootTag = tags.get('organization');
    if (!orgRootTag) return;

    const orgTag = orgRootTag.get('children') && orgRootTag.get('children').find(tag => tag.get('role') === `organization.${organizationId}`);
    if (!orgTag) return;

    const key = `${orgTag.get('id')}`;
    if (this.state.expandedKeys.indexOf(key) < 0) {
      this.setState({ expandedKeys: [...this.state.expandedKeys, key], selectedKey: key });
    }
  }

  handleSelect = keys => {
    if (keys.length === 0) {
      this.setState({ selectedKey: null });
    } else {
      this.setState({
        selectedKey: keys[0],
        expandedKeys: [...this.state.expandedKeys, keys[0]],
      });
    }
  }

  handleExpand = expandedKeys => {
    this.setState({ expandedKeys });
  }

  handleCreate = (tag, onComplete, onError) => {
    this.props.addTag(tag)
      .then(action => {
        if (action.failed) {
          onError(action.data._errors);
        } else {
          onComplete();
          this.props.fetchTags(this.props.organizationId);
        }
      })
      .catch(onError);
  }

  handleCancelSelection = () => {
    this.setState({ selectedKey: null });
  }

  handleSave = () => {
    this.props.fetchTags(this.props.organizationId);
  }

  handleDelete = () => {
    this.handleSave();
  }

  render() {
    const {
      fetchTagsInProgress, fetchTagsFailed,
      tagsTree, tagsList,
      organizationId, organizationName,
    } = this.props;
    const { selectedKey } = this.state;

    const tagsTreeJs = tagsTree.toJS ? tagsTree.toJS() : tagsTree;

    const organization = { id: organizationId, name: organizationName };
    const nodes = renderNodes(
      Object.values(tagsTreeJs), selectedKey, this.handleCreate, organization,
    );
    const selectedTag = _.isNil(selectedKey) ? null
      : tagsList.find(tag => `${tag.get('id')}` === selectedKey);
    const selectedTagDisplay = _.isNil(selectedTag) ? null
      : selectedTag.toJS();

    return (<Spin spinning={ fetchTagsInProgress }>
      <BreadcrumbLevel text="Settings" />
      <BreadcrumbLevel text="Tags" />

      { fetchTagsFailed
        ? <Alert
          showIcon
          type="error"
          message="Couldn't fetch your organization's tags. Try again later."
          />
        : <div style={{ display: 'flex' }}>
          <Tree
            autoExpandParent={ false }
            expandedKeys={ this.state.expandedKeys }
            selectedKeys={ [this.state.selectedKey] }
            onSelect={ this.handleSelect }
            onExpand={ this.handleExpand }
          >
            { nodes }
          </Tree>
          <TagSettingsCard
            key={ selectedTagDisplay ? selectedTagDisplay.id : 'null' }
            tag={ selectedTagDisplay }
            onCancel={ this.handleCancelSelection }
            onSaved={ this.handleSave }
            onDelete={ this.handleDelete }
            style={{ marginLeft: '32px', flexGrow: 1, maxWidth: '900px' }}
          />
        </div>
      }
    </Spin>);
  }
}

export const TagSettingsPage = connect(
  state => ({
    fetchTagsInProgress: state.doc.getIn(['http', 'fetchTags', 'inProgress'], true),
    fetchTagsFailed: state.doc.getIn(['http', 'fetchTags', 'failed'], false),
    tagsTree: state.doc.getIn(['tagsMr', 'tree'], EMPTY_LIST),
    tagsList: state.doc.getIn(['tagsMr', 'items'], EMPTY_LIST),
    organizationId: state.doc.getIn(['authMr', 'constituent', 'organization', 'id'], null),
    organizationName: state.doc.getIn(['authMr', 'constituent', 'organization', 'name'], null),
  }),
  actions,
)(TagSettingsPageComponent);
