import _ from 'lodash';
import {
  Alert, Icon, Modal, Progress, Upload, message, Tabs,
} from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import * as actions from '../actions';
import { flattenObj } from '../utils';
import { NDWS_SYNC_IMPORT_HELP_LINK } from '../defaults';
import { NdwsSyncContent } from './NDWSSync';

const { TabPane } = Tabs;
const ndwsAutoProcessingStatusList = ['loading', 'started', 'running', 'waiting', 'syncing'];

export class NdwsImportModalComponent extends PureComponent {
  state = {
    fileId: null,
    fileName: null,
    syncNDWSStarted: false,
    otpForm: null,
  }

  componentWillMount() {
    this.props.clearNdwsImport();
  }

  handleOk = () => {
    this.props.completeNdwsImport(this.state.fileId);
  }

  submitOTP = async () => {
    if (!this.state.otpForm) return;
    this.state.otpForm.validateFields(async (err, values) => {
      if (err) return;
      await this.props.postNDWSOtp(values.otp, 'ndws-sync');
      this.setState({
        otpForm: null,
      });
    });
  }

  setOtpForm = form => {
    this.setState({ otpForm: form });
  }

  handleCancel = evt => {
    if (this.props.onCancel) this.props.onCancel(evt);
  }

  customRequest = ({ file, onError, onProgress, onSuccess }) => {
    this.props.uploadNdwsImport(file).then(
      action => {
        if (action.success) {
          onSuccess(action.response.body);
          this.setState({
            fileId: action.meta.id,
            fileName: action.meta.originalFn,
          });
          if (this.props.onSaved) this.props.onSaved(action);
        } else {
          message.error("We're having trouble uploading your file right now.");
          onError(action.response, action.response.body);
          if (this.props.onSaveFailed) this.props.onSaveFailed(action);
        }
      },
      err => {
        if (this.props.onSaveFailed) this.props.onSaveFailed(err);
      },
    );
  }

  render() {
    const {
      title,
      saveInProgress,
      authInProgress,
      importData,
      syncNDWSResponse,
    } = this.props;
    const { fileId, fileName } = this.state;

    const {
      state: importState,
      errors: importErrors,
      total: importTotal,
      done: importDone,
    } = importData.toJS();
    const importComplete = importState === 'complete';
    const importIncomplete = !_.isNil(importState) && !importComplete;

    const importErrorsCount = importErrors ? importErrors.length : 0;
    const importHasErrors = importErrorsCount > 0;
    const importPercent = _.isNil(importTotal)
      ? 100 : (importDone + importErrorsCount) / importTotal * 100;
    // TODO this successPercent doesn't work for some reason
    /* const importSuccessPercent = _.isNil(importTotal) ?
      0 : (importDone - importErrors.length) / importTotal * 100 */
    // Note that the "exception" at the end of this is overridden by the
    // successPercent being 100% unless errors
    const importProgressStatus = (() => {
      if (!_.isNil(importTotal)) return null;
      return importHasErrors ? 'exception' : 'active';
    })();

    let importErrorMessages = [];
    if (importErrors) {
      importErrorMessages = _.flatten(importErrors.map((error, idx) => (error._errors
        ? Object.entries(flattenObj(error._errors)).map(
          ([field, innerError], innerIdx) => ({
            content: <span><em>{_.startCase(field)}</em>: {innerError}</span>,
            rowIdx: error._import ? error._import.rowIdx : null,
          }),
        )
        : [{
          content: error,
          rowIdx: error._import ? error._import.rowIdx : null,
        }])));
    }

    const {
      true: generalErrorMessages,
      false: rowErrorMessages,
    } = _.groupBy(importErrorMessages, descr => _.isNil(descr.rowIdx));
    if (rowErrorMessages) {
      rowErrorMessages.sort((left, right) => {
        if (left.rowIdx < right.rowIdx) return -1;
        if (left.rowIdx > right.rowIdx) return 1;
        return 0;
      });
    }

    let manualTabPaneStatus = false;
    const autoTabPaneStatus = _.get(syncNDWSResponse, ['message', 'status'], false);
    if (ndwsAutoProcessingStatusList.includes(autoTabPaneStatus)) manualTabPaneStatus = true;

    return (<Modal
      key="modal"
      visible={true}
      title={title}
      onOk={autoTabPaneStatus === 'waiting' ? this.submitOTP : this.handleOk }
      onCancel={this.handleCancel}
      okText= {autoTabPaneStatus === 'waiting' ? 'Submit' : 'Import'}
      cancelText="Close"
      confirmLoading={authInProgress || saveInProgress || importIncomplete}
      style={{ height: 'auto' }}
      okButtonProps={{ style: { display: ((fileName && !importComplete || autoTabPaneStatus === 'waiting')) ? null : 'none' } }}
    >
      <Tabs defaultActiveKey="1">
        <TabPane disabled={fileId} tab="Auto" key="1">
          <NdwsSyncContent setOtpForm={form => this.setOtpForm(form)}/>
        </TabPane>
        <TabPane disabled={manualTabPaneStatus} tab="Manual" key="2" style={{ height: '100%' }} >

          <div style={{ display: 'block', marginBottom: '20px' }}>
            <a href={NDWS_SYNC_IMPORT_HELP_LINK} alt='import help' target="_blank" rel="noopener noreferrer">Need help processing a sync file?</a><br />
          </div>
          {!fileId
            ? <Upload.Dragger
              style={{ padding: '16px' }}
              accept="text/csv,.csv"
              multiple={false}
              customRequest={this.customRequest}
              >

              <p className="ant-upload-drag-icon"><Icon type="inbox" /></p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
            </Upload.Dragger>
            : <p>{fileName}</p>
          }
          {importIncomplete || importComplete
            ? <div>
              <Progress
                percent={importPercent}
                status={importProgressStatus}
                showInfo={false}
              />
              {importTotal ? <div>
                <span><strong>{importDone}</strong> successful / </span>
                <span><strong>{importErrorsCount}</strong> errors / </span>
                <span><strong>{importTotal}</strong> total</span>
              </div> : null}
            </div>
            : null
          }
          {importHasErrors
            ? <Alert
              message="Oh no! Looks like we had some problems"
              description={
                <div>
                  {generalErrorMessages
                    ? <ul>{generalErrorMessages.map(msg => <li>{msg.content}</li>)}</ul>
                    : null
                  }
                  {rowErrorMessages
                    ? <ol>{rowErrorMessages.map(msg => <li value={msg.rowIdx + 1}>{msg.content}</li>)}</ol>
                    : null
                  }
                </div>
              }
              type="error"
              showIcon
              />
            : null
          }
        </TabPane>
      </Tabs>
    </Modal>
    );
  }
}

export const NdwsSyncModal = connect(
  (state, props) => ({
    authErrors: state.doc.getIn(['http', 'uploadNdwsImportAuthorize', 'data', '_errors'], null),
    saveErrors: state.doc.getIn(['http', 'uploadNdwsImport', 'data', '_errors'], null),
    authInProgress: state.doc.getIn(['http', 'uploadNdwsImportAuthorize', 'inProgress'], false),
    saveInProgress: state.doc.getIn(['http', 'uploadNdwsImport', 'inProgress'], false),
    authFailed: state.doc.getIn(['http', 'uploadNdwsImportAuthorize', 'failed'], false),
    saveFailed: state.doc.getIn(['http', 'uploadNdwsImport', 'failed'], false),
    authSuccess: state.doc.getIn(['http', 'uploadNdwsImportAuthorize', 'success'], false),
    saveSuccess: state.doc.getIn(['http', 'uploadNdwsImport', 'success'], false),
    syncNDWSStarted: state.doc.getIn(['http', 'ndwsSync', 'syncNDWSStarted'], false),
    importData: state.doc.getIn(['importNdws']),
    syncNDWSResponse: state.doc.getIn(['ndwsSync'], false),
  }),
  actions,
)(NdwsImportModalComponent);
