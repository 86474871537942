// This file is not used by ConstituentListPage, but is here as a refactored
// version of the previous implementation
import _ from 'lodash';
import React, { PureComponent } from 'react';

import {
  Dropdown, Icon, Menu,
} from 'antd';

import { ActionText } from '../../elements';

const SORT_BY_FIELDS_LIST = [
  { key: 'full_name', label: 'Name', icon: 'user' },
  { key: 'email', label: 'Email', icon: 'mail' },
];
const SORT_BY_FIELDS_MAP = _.fromPairs(SORT_BY_FIELDS_LIST.map(({ key, ...data }) => [key, data]));
const SORT_ORDER_FIELDS_LIST = [
  { key: 'asc', label: 'A-Z', icon: 'down' },
  { key: 'desc', label: 'Z-A', icon: 'up' },
];
const SORT_ORDER_FIELDS_MAP = _.fromPairs(SORT_ORDER_FIELDS_LIST.map(({ key, ...data }) => [key, data]));

class ListSort extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: 'full_name',
      sortOrder: 'asc',
    };
  }

  // Sort order
  handleSortBy = ({ key }) => {
    if (key === this.props.sortBy) return;
    this.props.setSort(key, this.props.sortOrder);
  }

  handleSortOrder = ({ key }) => {
    if (key === this.props.sortOrder) return;
    this.props.setSort(this.props.sortBy, key);
  }

  render() {
    const { sortOrder, sortBy } = this.props;
    return (
      <div>
        <span style={{ paddingRight: '1em' }}>
          Sort by:
        </span>
        <Dropdown
          overlay={ <Menu onClick={ this.handleSortBy }>
            { SORT_BY_FIELDS_LIST.map(({ key, label, icon }) => (<Menu.Item key={ key }>
              <Icon type={ icon } /> { label }
            </Menu.Item>)) }
          </Menu> }
        >
          <ActionText className="ant-dropdown-link" style={{ paddingRight: '1em', color: '#4D524D' }}>
            { SORT_BY_FIELDS_MAP[sortBy].label } <Icon type="down" />
          </ActionText>
        </Dropdown>
        <Dropdown
          overlay={ <Menu onClick={ this.handleSortOrder }>
            { SORT_ORDER_FIELDS_LIST.map(({ key, label, icon }) => (<Menu.Item key={ key }>
              <Icon type={ icon } /> { label }
            </Menu.Item>)) }
          </Menu> }
          style={{ paddingRight: '1em' }}
        >
          <ActionText className="ant-dropdown-link" style={{ color: '#4D524D' }}>
            { SORT_ORDER_FIELDS_MAP[sortOrder].label } <Icon type="down" />
          </ActionText>
        </Dropdown>
      </div>
    );
  }
}

export {
  ListSort,
};
