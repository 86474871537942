import _ from 'lodash';
import {
  Avatar, Button, Divider, List, Tooltip, message, Icon,
  Spin,
} from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router';

import { BreadcrumbLevel } from '../../BreadcrumbLevel';
import * as actions from '../../../actions';
import { historyPushPath } from '../../../utils';

import { WebhookDetailsModal } from './WebhookDetailsModal';
import { LogsModal } from './LogsModal';
import { withContentLayout } from '../../layout/Layout';


class WebhookListItemComponent extends PureComponent {
  handleToggleActiveClick = ev => {
    ev.preventDefault();
    this.props.patchWebhook(
      this.props.id,
      { active: !this.props.active },
    ).then(action => {
      if (action.data._errors) {
        Object.values(action.data._errors).forEach(errors => {
          errors.forEach(error => {
            message.error(<span>{ error }</span>);
          });
        });
      } else {
        this.props.fetchWebhooks(this.props.organizationId);
      }
    });
  }

  handleClick = ev => {
    historyPushPath(
      this.props.history,
      this.props.location,
      `${this.props.match.path}/${this.props.id}/logs`,
    );
  }

  handleEdit = ev => {
    historyPushPath(
      this.props.history,
      this.props.location,
      `${this.props.match.path}/${this.props.id}`,
    );
  }

  renderItemProps() {
    const { status } = this.props;
    if (status === 'okay') return { icon: 'check', color: 'green' };
    if (status === 'error') return { icon: 'close', color: 'red' };
    if (status === 'warning') return { icon: 'exclamation', color: 'orange' };
    if (status === 'disabled') return { icon: 'close', color: 'grey' };
    return { icon: 'question', color: 'gray' };
  }

  renderActionProps() {
    const { status } = this.props;
    if (status === 'disabled') {
      return { buttonIcon: 'check', buttonColor: 'default', buttonText: 'enable' };
    }
    return { buttonIcon: 'close', buttonColor: 'red', buttonText: 'disable' };
  }

  render() {
    const { label, status, url } = this.props;
    const { icon, color } = this.renderItemProps();
    const { buttonIcon, buttonColor, buttonText } = this.renderActionProps();
    return (<List.Item
      actions={[
        <>
          <Icon type="edit" style={{ color: '#1890ff', cursor: 'pointer', marginRight: '10px' }} onClick={this.handleEdit}>Edit</Icon>
          <Button icon={ buttonIcon } style={{ color: buttonColor }} className={buttonColor === 'red' ? 'doc-danger-btn-3' : ''} onClick={ this.handleToggleActiveClick }>{ _.startCase(buttonText) }</Button>
        </>,
      ]}
            >
      <List.Item.Meta
        avatar={
          <Tooltip title={ _.startCase(status) }>
            <Avatar icon={ icon } size="small" style={{ backgroundColor: color }} />
          </Tooltip>
        }
        title={ url }
        description={ label }
        onClick={ this.handleClick }
      />
    </List.Item>);
  }
}

export const WebhookListItem = withRouter(connect(
  state => ({
    organizationId: state.doc.getIn(['authMr', 'constituent', 'organization', 'id'], null),
  }),
  actions,
)(WebhookListItemComponent));

class LogsModalWrapperComponent extends PureComponent {
  render() {
    return (<LogsModal
      webhookId={ this.props.match.params.id }
      visible={ true }
      { ...this.props }
            />);
  }
}
const LogsModalWrapper = withRouter(LogsModalWrapperComponent);

class EditModalWrapperComponent extends PureComponent {
  render() {
    return (<WebhookDetailsModal
      webhookId={ this.props.match.params.id }
      visible={ true }
      isNew = { false }
      { ...this.props }
            />);
  }
}
const EditModalWrapper = withRouter(EditModalWrapperComponent);

@withContentLayout
class WebhookSettingsPageComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      addModalVisible: false,
      editModalVisible: true,
      addModalKey: 0,
    };
  }

  componentWillMount() {
    if (this.props.organizationId) {
      this.props.fetchOrganization(this.props.organizationId);
      this.props.fetchWebhooks(this.props.organizationId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.organizationId !== nextProps.organizationId) nextProps.fetchWebhooks(nextProps.organizationId);
  }

  handleAddClick = () => {
    this.setState({ addModalVisible: true });
  }

  handleEditClick = () => {
    this.setState({ editModalVisible: true });
  }

  handleAddModalOkClick = () => {
    this.props.fetchWebhooks(this.props.organizationId);
    this.handleAddModalCancelClick();
  }

  handleEditModalOkClick = () => {
    this.props.fetchWebhooks(this.props.organizationId);
    this.handleEditModalCancelClick();
  }

  handleAddModalCancelClick = () => {
    this.setState({ addModalVisible: false });
  }

  handleEditModalCancelClick = () => {
    historyPushPath(
      this.props.history,
      this.props.location,
      `${this.props.match.path}`,
    );
  }

  handleAddModalAfterClose = () => {
    this.setState({ addModalKey: this.state.addModalKey + 1 });
  }

  handleLogsModalCancel = ev => {
    historyPushPath(
      this.props.history,
      this.props.location,
      `${this.props.match.path}`,
    );
  }

  renderItem = item => <WebhookListItem { ...item } />

  renderLogsModalWrapper = () => <LogsModalWrapper onCancel={ this.handleLogsModalCancel } />

  renderEditModalWrapper = () => <EditModalWrapper onCancel={ this.handleEditModalCancelClick } onOk={ this.handleEditModalOkClick } visible={ this.state.editModalVisible } />

  renderWebhookWhitelistStatus = () => {
    const { fetchOrganizationInProgress, webhookWhitelistEnabled } = this.props;

    if (fetchOrganizationInProgress) return <Spin spinning={fetchOrganizationInProgress} />;

    const statusText = webhookWhitelistEnabled
      ? 'Webhook whitelisting enabled'
      : 'Webhook whitelisting not enabled';

    const statusColor = webhookWhitelistEnabled ? 'green' : 'tomato';

    return <p style={{ color: statusColor }}>{statusText}</p>;
  }


  render() {
    const { fetchWebhooksInProgress, fetchWebhooksItems } = this.props;
    const items = fetchWebhooksItems ? fetchWebhooksItems.toJS() : [];

    return (<div>
      <BreadcrumbLevel text="Settings" />
      <BreadcrumbLevel text="Webhooks" />

      <Switch>
        <Route path={ `${this.props.match.path}/:id/logs` } render={ this.renderLogsModalWrapper } />
        <Route path={ `${this.props.match.path}/:id` } render={ this.renderEditModalWrapper } />
      </Switch>

      <WebhookDetailsModal
        key={ this.state.addModalKey } // Reset on hide
        visible={ this.state.addModalVisible }
        isNew = {true}
        onOk={ this.handleAddModalOkClick }
        onCancel={ this.handleAddModalCancelClick }
        afterClose={ this.handleAddModalAfterClose }
      />
      <div style={{ display: 'flex' }}>
        <p style={{ flexGrow: 1, paddingRight: '16px' }}>
          Webhooks are an advanced feature that allow external software, such
          as HR systems, to receive notifications from Oho.
        </p>
        <Button.Group style={{ flexShrink: 1 }}>
          <Button icon="plus" onClick={ this.handleAddClick }>Add</Button>
        </Button.Group>
      </div>
      { this.renderWebhookWhitelistStatus() }
      <Divider />
      <List
        loading={ fetchWebhooksInProgress }
        dataSource={ items }
        renderItem={ this.renderItem }
      />
    </div>);
  }
}

export const WebhookSettingsPage = withRouter(connect(
  state => ({
    fetchWebhooksInProgress: state.doc.getIn(['http', 'fetchWebhooks', 'inProgress'], true),
    fetchWebhooksFailed: state.doc.getIn(['http', 'fetchWebhooks', 'failed'], false),
    fetchWebhooksItems: state.doc.getIn(['http', 'fetchWebhooks', 'data', 'items']),
    organizationId: state.doc.getIn(['authMr', 'constituent', 'organization', 'id'], null),
    fetchOrganizationInProgress: state.doc.getIn(['http', 'fetchOrganization', 'inProgress'], true),
    fetchOrganizationSuccess: state.doc.getIn(['http', 'fetchOrganization', 'success'], false),
    webhookWhitelistEnabled: state.doc.getIn(['http', 'fetchOrganization', 'data', 'webhookWhitelistEnabled'], false),
  }),
  actions,
)(WebhookSettingsPageComponent));
