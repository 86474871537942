import _ from 'lodash';

import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Popover,
  Row,
  Spin,
  Switch,
  message,
} from 'antd';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../actions';
import {
  flattenObj,
} from '../../../utils';

import { PortalChild } from '../../elements';

const LABEL_COL_WIDTH = 5;
const WRAPPER_COL_WIDTH = 24 - LABEL_COL_WIDTH;


const renderErrors = errors => {
  if (!errors) return null;
  const flatErrors = flattenObj(errors.toJS());
  const e1 = Object.keys(flatErrors)
    .sort()
    .map(errorKey => (_.isArray(flatErrors[errorKey]) ? flatErrors[errorKey] : [flatErrors[errorKey]])
      .map(msgText => (<div>
        { errorKey.split('.').map(_.startCase).join(' ') }: { msgText }
      </div>)));

  return (<div>
    { _.flatten(e1) }
  </div>);
};

class ConstituentPasswordChangeComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      submitEnabled: false,
      errors: null,
    };
  }

  handleSubmit = ev => {
    ev.preventDefault();
    if (!this.state.submitEnabled) return;
    this.props.patchUser(
      this.props.userId,
      this.props.form.getFieldsValue(['password']),
    );
  }

  passwordMatchValidator = (rule, value, callback) => {
    const otherValue = this.props.form.getFieldValue('password');
    if (value === otherValue) {
      callback([]);
    } else {
      callback(["Passwords don't match"]);
    }
  }

  passwordMatchValidatorTrigger = (rule, value, callback) => {
    this.props.form.validateFields(
      ['passwordAgain'],
      { force: true },
      () => {
        callback([]);
      },
    );
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.patchUserInProgress && nextProps.patchUserErrors) this.setState({ errors: nextProps.patchUserErrors });
    else if (this.props.patchUserInProgress && nextProps.patchUserFailed) message.error("Password couldn't be changed");
    if (this.props.patchUserInProgress && nextProps.patchUserSuccess) {
      message.success('Password changed successfully');
      nextProps.form.setFieldsValue({
        password: '',
        passwordAgain: '',
      });
      this.setState({ submitEnabled: false });
    } else {
      this.setState({
        submitEnabled: (Object
          .values(nextProps.form.getFieldsError())
          .every(errors => !errors)
        ) && nextProps.form.isFieldsTouched(),
      });
    }
  }

  handleValuesChange = () => {
    this.setState({ errors: null });
  };

  render() {
    const { errors, submitEnabled } = this.state;
    const { form, patchUserInProgress } = this.props;
    const { getFieldDecorator } = form;

    const formItemProps = {
      labelCol: { span: LABEL_COL_WIDTH },
      wrapperCol: { span: WRAPPER_COL_WIDTH },
    };

    return (
      <>
        <Form onSubmit={ this.handleSubmit } onChange={this.handleValuesChange}>
          <Form.Item
            label="New password"
            validateStatus={ errors ? 'error' : null }
            help={ renderErrors(errors) }
            { ...formItemProps }
          >
            { getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'Password is required',
                },
                { validator: this.passwordMatchValidatorTrigger },
              ],
            })(
              <Input type="password" />,
            )
        }
          </Form.Item>
          <Form.Item
            label="Re-enter password"
            { ...formItemProps }
          >
            { getFieldDecorator('passwordAgain', {
              rules: [
                {
                  required: true,
                  message: 'Password must be re-entered',
                },
                {
                  validator: this.passwordMatchValidator,
                },
              ],
            })(
              <Input type="password" />,
            )
        }
          </Form.Item>
          <Row><Col offset={ formItemProps.labelCol.span }>
            <Button
              htmlType="submit"
              type="primary"
              disabled={ !submitEnabled }
              loading={ patchUserInProgress }
            >Change password</Button>
          </Col></Row>
        </Form>
      </>

    );
  }
}

const ConstituentPasswordChangeForm = Form.create()(ConstituentPasswordChangeComponent);
const ConstituentPasswordChange = connect(
  state => ({
    patchUserInProgress: state.doc.getIn(['http', 'patchUser', 'inProgress']),
    patchUserSuccess: state.doc.getIn(['http', 'patchUser', 'success']),
    patchUserFailed: state.doc.getIn(['http', 'patchUser', 'failed']),
    patchUserErrors: state.doc.getIn(['http', 'patchUser', 'data', '_errors'], null),
  }),
  actions,
)(ConstituentPasswordChangeForm);

class ConstituentPermissionsComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
    };
  }

  componentWillMount() {
    this.updateErrors(null, this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateErrors(this.props, nextProps);
  }

  updateErrors = (prevProps, nextProps) => {
    const prevDErrors = prevProps && prevProps.deleteUserData
      ? prevProps.deleteUserData.get('_errors') : null;
    const prevAErrors = prevProps && prevProps.addUserData
      ? prevProps.addUserData.get('_errors') : null;
    const prevDInProgress = prevProps ? prevProps.deleteUserInProgress : false;
    const prevAInProgress = prevProps ? prevProps.addUserInProgress : false;

    const nextDErrors = nextProps.deleteUserData
      ? nextProps.deleteUserData.get('_errors') : null;
    const nextAErrors = nextProps.addUserData
      ? nextProps.addUserData.get('_errors') : null;
    const nextDInProgress = nextProps.deleteUserInProgress;
    const nextAInProgress = nextProps.addUserInProgress;

    if (
      prevDErrors === nextDErrors
      && prevAErrors === nextAErrors
    ) return;

    if (prevDInProgress && !nextDInProgress) {
      this.setState({ errors: nextDErrors });
    } else if (prevAInProgress && !nextAInProgress) {
      this.setState({ errors: nextAErrors });
    }
  }

  handleUserChange = value => {
    if (value) return; // shouldn't happen, but just in case
    this.props.removeConstituentUser(
      this.props.constituent.get('id'),
    );
  }

  handlePasswordSubmit = ev => {
    ev.preventDefault();
    // TODO handle API error messages
    this.props.makeConstituentUser(
      this.props.constituent.get('id'),
      this.refs.createUserPassword.input.value,
    );
  }

  handleValuesChange = () => {
    this.setState({ errors: null });
  };

  render() {
    const {
      constituent,
      addUserInProgress, deleteUserInProgress,
      onCancel,
    } = this.props;
    const { errors } = this.state;

    const user = constituent ? constituent.get('user', null) : null;
    const isUser = !!user;
    const isUpgradable = !!(constituent && constituent.get('email', null));

    const formItemProps = {
      labelCol: { span: LABEL_COL_WIDTH },
      wrapperCol: { span: WRAPPER_COL_WIDTH },
    };

    return (<div>
      { isUser && <Form.Item
        label="Login email"
        { ...constituent.get('email') !== constituent.getIn(['user', 'email']) ? { extra: 'Different to person contact email' } : {}}
        { ...formItemProps }
                  >
        { constituent.getIn(['user', 'email']) }
      </Form.Item> }
      <Form.Item
        label="Admin User"
        extra={ isUpgradable
          ? null
          : 'An email address is required to upgrade to an admin user'
        }
        validateStatus={ errors ? 'error' : null }
        help={ renderErrors(errors) }
        { ... formItemProps }
      >
        { isUser || addUserInProgress || deleteUserInProgress || !constituent
          ? <Spin
            style={{ width: '150px' }}
            size="small"
            spinning={ addUserInProgress || deleteUserInProgress }
            >
            <Switch
              onChange={ this.handleUserChange }
              checked={ isUser }
              disabled={ !constituent }
            />
          </Spin>
          : <Popover
            trigger="click"
            placement="bottomLeft"
            title="Create password"
            content={<form onSubmit={ this.handlePasswordSubmit } onChange={this.handleValuesChange}>
              <Input type="password" ref="createUserPassword" />
              <div style={{ paddingTop: '10px' }} />
              <Button
                style={{ float: 'right' }}
                size="small"
                type="primary"
                htmlType="submit"
              >Create</Button>
              <div style={{ clear: 'both' }} />
            </form>}
            >
            <Button
              disabled={ !isUpgradable }
            >Upgrade to admin user</Button>
          </Popover>
        }
      </Form.Item>
      { isUser ? <div>
        <Divider />
        <ConstituentPasswordChange userId={ user.get('id') } />
      </div> : null }
      <PortalChild portal={this.props.footerRef}>
        <Button onClick={onCancel}>Close</Button>
      </PortalChild>
    </div>);
  }
}
const ConstituentPermissions = connect(
  state => ({
    organizationId: state.doc.getIn(['authMr', 'organizationId'], null),
    addUserInProgress: state.doc.getIn(['http', 'addUser', 'inProgress'], false),
    addUserData: state.doc.getIn(['http', 'addUser', 'data'], null),
    deleteUserInProgress: state.doc.getIn(['http', 'deleteUser', 'inProgress'], false),
    deleteUserData: state.doc.getIn(['http', 'deleteUser', 'data'], null),
  }),
  actions,
)(ConstituentPermissionsComponent);

export {
  ConstituentPermissions,
};
