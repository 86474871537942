import { fromJS } from 'immutable';

import { MaterializedReducer } from './base';

function prepareDefineType(asJS) {
  const defineType = fromJS(asJS);
  return defineType;
}

export class DefineTypesMr extends MaterializedReducer {
  update(prevState, state, action) {
    if (!(
      action.type === 'HTTP_UPDATED'
      && action.success
    )) {
      return state;
    }

    if (action.group === 'fetchSelectedDefineType' && action.success) {
      state = state.setIn(['defineTypesMr', 'byId', action.meta.typeId], prepareDefineType(action.data));
    }

    if (action.group === 'fetchDefineTypes' && action.success) {
      const items = state.getIn(['http', action.group, 'data']).toJS();
      state = state.set(
        'defineTypesMr', fromJS({
          fetching: false,
          items,
        }),
      );
    }

    return state;
  }
}
