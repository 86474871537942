import _ from 'lodash';

import { fromJS } from 'immutable';
import { message } from 'antd';

import { MaterializedReducer, Reducer } from './base';
import { STATE } from '../defaults';
import { fieldDisplay, tokenPayload } from '../utils';

function maybeSetRootToken() {
  try {
    const payload = tokenPayload(localStorage.docToken);
    if (payload && !payload.act) {
      localStorage.docRootToken = localStorage.docToken;
    }
  } catch (err) {
    console.error('Token parse error:', err);
  }
}

const processOrgEmails = createdEmails => window.SERVER_DATA.allowedStatesForOrgEmails.map(item => {
  if (createdEmails && createdEmails instanceof Array) {
    const curr = createdEmails && createdEmails.findIndex(createdEmail => createdEmail.type === item.type);
    if (curr > -1) {
      item = { ...item, ...createdEmails[curr] };
    }
  } else if (createdEmails && createdEmails instanceof Object) {
    item = { ...item, ...createdEmails };
  }
  return item;
});

export class AuthReducer extends Reducer {
  handleLogoutAction(prevState, state, action) {
    localStorage.clear();
    message.info('You have successfully logged out');

    return STATE;
  }

  handleRegisterErrorAction(prevState, state, action) {
    const messages = (
      _.isArray(action.message) ? action.message : [action.message]
    ).map(msg => {
      if (msg.message) return msg.message;
      return msg;
    });
    return state.setIn(['auth', 'loginError'], fromJS(messages));
  }

  handleResetPasswordErrorAction(prevState, state, action) {
    return state.mergeIn(['auth'], fromJS({ resetPasswordError: action.message.authMethod.data.idpSsoUrl }));
  }

  handleClearPasswordResetToken(prevState, state, action) {
    return state.setIn(['auth', 'resetToken'], null);
  }

  handleQldLinkageErrorAction(prevState, state, action) {
    return state.setIn(['linkage', 'error'], action.message);
  }

  handleSetTokenAction(prevState, state, action) {
    return state.mergeIn(['auth'], {
      token: action.token,
    });
  }

  handleHttpUpdatedAction(prevState, state, action) {
    // Set profile data
    if (action.success && action.group === 'getMe') {
      delete localStorage.docUsername;
      delete localStorage.docPassword;
      localStorage.docToken = state.getIn(['auth', 'token']);
      maybeSetRootToken();
      return state.mergeIn(['auth'], action.data);
    }

    const isAuthAction = action.group === 'createToken' || action.group === 'createSAMLToken' || action.group === 'verifyTotp';

    // Set token on success
    if (action.success && isAuthAction) {
      delete localStorage.docUsername;
      delete localStorage.docPassword;
      localStorage.docToken = action.data.token;
      maybeSetRootToken();
      return state; // Separate SET_TOKEN action to update state
    }

    // Clear errors
    if (action.inProgress && isAuthAction) {
      return state.mergeIn(['auth'], { loginError: null });
    }

    if (action.response && action.response.status === 401 && (action.group === 'verifyTotp' || action.group === 'disableMFA' || action.group === 'checkRedirectionDetails' || action.group === 'ndwsSyncOtp')) {
      return state;
    }

    // Logout on any unauth
    if (action.response && action.response.status === 401) {
      const msgs = _.get(
        action.data,
        ['_errors', 'authentication'],
        ['Incorrect username or password'],
      );
      delete localStorage.docToken;
      delete localStorage.docRootToken;
      return state.mergeIn(['auth'], {
        token: null,
        rootToken: null,
        rootTokenDetail: null,
        resetToken: null,
        resetTokenDetail: null,
        loginError: fromJS(msgs),
      });

      // Set login/register errors
    }

    if (action.failed && isAuthAction) {
      if (action.data && action.data._errors) {
        return state.setIn(['auth', 'loginError'], fromJS(
          _.flatten(_.toPairs(action.data._errors).map(([field, messages]) => {
            const fieldDisp = fieldDisplay(field);
            if (messages.map) {
              return messages.map(msg => `${fieldDisp}: ${_.capitalize(msg)}`);
            }
            return `${fieldDisp}: ${_.capitalize(messages)}`;
          })),
        ));
      } if (action.body) {
        return state.setIn(['auth', 'loginError'], fromJS([action.body]));
      }
      return state.setIn(['auth', 'loginError'], fromJS(['Unknown error']));
    }

    return state;
  }
}

export class AuthMr extends MaterializedReducer {
  update(prevState, state, action) {
    let authState = state.get('auth');

    if (
      action.type === 'HTTP_UPDATED'
      && action.success
      && (
        action.group === 'fetchOrganization'
        || action.group === 'patchOrganization'
        || action.group === 'activateOrganization'
      )
    ) {
      const data = state.getIn(['http', action.group, 'data']);
      let orgEmails = window.SERVER_DATA.allowedStatesForOrgEmails;
      // this is to make sure that we don't lose any org emails if they exist
      if (authState.getIn(['constituent', 'organization', 'orgEmails'])) {
        orgEmails = authState.getIn(['constituent', 'organization', 'orgEmails']);
        orgEmails = processOrgEmails(orgEmails || null);
      }

      authState = authState.setIn(
        ['constituent', 'organization'],
        data,
      );
      if (orgEmails) {
        authState = authState.setIn(['constituent', 'organization', 'orgEmails'], orgEmails);
      }
      state = state.set('auth', authState);
    } else if (
      action.type === 'HTTP_UPDATED'
      && action.success
      && (
        action.group === 'fetchOrganizationEmail'
        || action.group === 'postOrganizationEmail'
      )
    ) {
      const data = state.getIn(['http', action.group, 'data', 'items']);
      const processedEmails = processOrgEmails((data && data.toJSON()) || null);

      authState = authState.setIn(['constituent', 'organization', 'orgEmails'], processedEmails);
      state = state.set('auth', authState);
    }

    return state.set('authMr', authState.merge({
      authenticated: !!authState.get('token'),
      organizationId: authState.getIn(['constituent', 'organization', 'id']),
    }));
  }
}
